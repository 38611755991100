import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';

import { permissionSelector, userSelector } from '../../../shared/data/store/selectors/auth-selectors';
import { setPermission } from '../../../shared/data/store';
import { AppResource } from '../../../shared/models/app-resource';
import { useGetDefaultRolesQuery, useGetUserAllowedRoutesQuery } from '../../data/queries/core-queries';
import { CrudOperation } from '../../../shared/models/crud-operation';
import { AllowedRoutesPaths as AllowedRoutesPathsFE } from '../../../shared/constants/app-resources';

export const PermissionsSetter = (): null => {
  const currentUser = useSelector(userSelector);
  const dispatch = useDispatch();
  const { data: defaultRoles } = useGetDefaultRolesQuery();
  const { data: allowedRoutesBE } = useGetUserAllowedRoutesQuery();
  const permissions = useSelector(permissionSelector);

  const setPermissionsForAdmin = useCallback(() => {
    Object.entries(permissions).forEach(([appResource, crudOperations]) => {
      Object.keys(crudOperations).forEach((key: CrudOperation) => {
        dispatch(
          setPermission({
            resource: appResource as AppResource,
            operation: key,
          })
        );
      });
    });
  }, [dispatch, permissions]);

  // For now if allowedRoutes response contain particular basic path for given resource,
  // grant access to every operation for that resource
  const setPermissionsBasedOnAllowedRoutes = useCallback(() => {
    Object.entries(AllowedRoutesPathsFE).forEach(([appResource, allowedRoutePathFE]) => {
      if (Object.keys(allowedRoutesBE).some(allowedRoutePathBE => allowedRoutePathBE.startsWith(allowedRoutePathFE))) {
        Object.keys(permissions[appResource]).forEach((operation: CrudOperation) => {
          dispatch(
            setPermission({
              resource: appResource as AppResource,
              operation,
            })
          );
        });
      }
    });
  }, [allowedRoutesBE, dispatch, permissions]);

  const setPermissionsBasedOnUserRoles = useCallback(() => {
    currentUser.roles.forEach(currentUserRole => {
      if (currentUserRole.isDefault) {
        defaultRoles.some(defaultRole => {
          const appResource = defaultRole['name'];
          // eslint-disable-next-line array-callback-return
          return Object.entries(defaultRole).some(([key, value]) => {
            if (key !== 'name' && value === currentUserRole.id) {
              dispatch(
                setPermission({
                  resource: appResource,
                  operation: key as CrudOperation,
                })
              );
              return true;
            }
          });
        });
      }
    });
  }, [currentUser?.roles, defaultRoles, dispatch]);

  useEffect(() => {
    if (currentUser != null && defaultRoles != null && allowedRoutesBE != null) {
      if (currentUser.isAdmin) {
        setPermissionsForAdmin();
      } else {
        setPermissionsBasedOnUserRoles();
        setPermissionsBasedOnAllowedRoutes();
      }
    }
  }, [
    allowedRoutesBE,
    currentUser,
    defaultRoles,
    setPermissionsBasedOnAllowedRoutes,
    setPermissionsBasedOnUserRoles,
    setPermissionsForAdmin,
  ]);

  return null;
};
