import { Card, Col, Form, Row, Space } from 'antd';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DownOutlined } from '@ant-design/icons';
import { useDebounce, useToggle } from 'react-use';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Feature } from '../../../shared/models/features';
import { translationNamespace } from '../../constants/translation-resources';
import { useFindLogsQuery } from '../../data/queries/log-queries';
import { Log } from '../../../shared/models/log';
import { useTableOptions } from '../../../shared/hooks/use-data-options';
import { LogsTable, LogsTableFilters, LogsTableFiltersForm } from '../../components/logs-table';
import { LogsFilters } from '../../models/log';
import { convertTableFiltersToFilters } from '../../data/converters/log-converters';
import { isNonEmptyObject } from '../../../shared/utils/object';
import { Orders } from '../../../shared/constants/order';
import { CustomSearch } from '../../../shared/components/search';
import { CustomFilterButton } from '../../../shared/components/filter-button';

export const LogsListView: FC = () => {
  const { t } = useTranslation(translationNamespace);
  const [filtersForm] = Form.useForm<LogsTableFilters>();
  const { page, setPage, pageSize, order, handleTableChange } = useTableOptions<Log>([['createdAt', Orders.DESC]]);
  const [showFilters, toggleShowFilters] = useToggle(false);
  const [filters, setFilters] = useState<LogsFilters>(null);
  const [searchValue, setSearchValue] = useState<string>();
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>();
  const { data, isLoading } = useFindLogsQuery({
    page,
    pageSize,
    searchValue: debouncedSearchValue,
    filters,
    order,
  });

  const applyFilters = (filters: LogsTableFilters) => {
    const logsFilters = convertTableFiltersToFilters(filters);

    setSearchValue(null);
    setDebouncedSearchValue(null);
    setFilters(logsFilters);
    setPage(1);
  };

  const applySearch = (value: string) => {
    setFilters(null);
    filtersForm.resetFields();
    setSearchValue(value);
    setDebouncedSearchValue(value);
    setPage(1);
  };

  const expandedRowRender = (record: Log) => {
    const { body } = record;
    return isNonEmptyObject(body) ? (
      <ExpandedTableRow>
        <p style={{ marginRight: '10px' }}>body:</p>
        <p style={{ textAlign: 'left' }}>{JSON.stringify(body, undefined, 3)}</p>
      </ExpandedTableRow>
    ) : null;
  };

  const rowExpandable = (record: Log) => {
    return isNonEmptyObject(record.body);
  };

  useDebounce(
    () => {
      if (searchValue != null) {
        setFilters(null);
        filtersForm.resetFields();
        setPage(1);
      }
      setDebouncedSearchValue(searchValue);
    },
    3000,
    [searchValue]
  );

  return (
    <Row>
      <StyledSpace>
        <CustomSearch
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder={t(`${Feature.SHARED}:search`)}
          onSearch={applySearch}
          allowClear
          size="large"
        />
        <CustomFilterButton type="link" onClick={toggleShowFilters}>
          {t(`${Feature.SHARED}:filters`)} <StyledDownOutlined rotate={showFilters ? 180 : 0} />
        </CustomFilterButton>
      </StyledSpace>

      {showFilters && (
        <Col css={marginTop} span={24}>
          <Card title={t(`${Feature.SHARED}:filters`)}>
            <LogsTableFiltersForm form={filtersForm} onApply={applyFilters} />
          </Card>
        </Col>
      )}
      <Col span={24} style={{ marginTop: showFilters || isLoading || data?.total === 0 ? '50px' : '0' }}>
        <LogsTable
          dataSource={data?.resources}
          pagination={{ total: data?.total, current: page, pageSize, position: ['topRight'] }}
          loading={isLoading}
          onChange={handleTableChange}
          expandable={{ expandedRowRender, rowExpandable }}
          scroll={{ x: 800 }}
        />
      </Col>
    </Row>
  );
};

const StyledDownOutlined = styled(DownOutlined)`
  > svg {
    transition: transform 0.2s;
  }
`;

const ExpandedTableRow = styled.pre`
  height: fit-content;
  display: flex;
`;

const StyledSpace = styled(Space)`
  position: absolute;
  z-index: 1;

  .ant-space-item {
    height: 38px;
  }
`;

const marginTop = css`
  margin-top: 50px;
`;
