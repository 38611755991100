import { Table, TableProps } from 'antd';
import styled from '@emotion/styled';

import { colors, border } from '../../constants/styles';

export const CustomTable = <T,>({ ...props }: TableProps<T>) => {
  return <StyledTable bordered {...props} />;
};

const StyledTable = styled(Table)`
  position: relative;
  z-index: 0;

  thead tr th,
  thead tr td {
    padding: 0 !important;
    height: 30px !important;
    text-align: center !important;
    //remove it after applying new design
    background-color: ${colors.primary} !important;
    color: ${colors.white} !important;
    //

    &::before {
      height: 100% !important;
    }

    span.ant-table-column-sorter.ant-table-column-sorter-full {
      margin-right: 10px;
    }

    span[aria-label='caret-up'],
    span[aria-label='caret-down'] {
      color: ${colors.white};
    }

    //remove it after applying new design
    &:hover {
      background-color: ${colors.skyBlue} !important;
    }
    //
  }

  //remove it after applying new design
  thead tr th[aria-sort] {
    background-color: ${colors.skyBlue} !important;
  }
  //

  tbody tr td.ant-table-cell {
    padding: 8px !important;
    text-align: center;
    border-inline-end: ${border('hoverGray')} !important;
    border-bottom: ${border('hoverGray')} !important;

    &.ant-table-column-sort {
      color: ${colors.skyBlue};
      background-color: transparent;
      border-right: ${border('primary')} !important;
      border-left: ${border('primary')} !important;
    }
  }

  tbody tr td.ant-table-cell:last-child {
    border-inline-end: 1px solid #d6d4d6 !important;
  }

  .ant-card .ant-card-body {
    padding: 0;
  }

  ul.ant-pagination {
    margin-top: 0 !important;

    li.ant-pagination-prev,
    li.ant-pagination-next,
    li.ant-pagination-item,
    li.ant-pagination-options .ant-select-selector,
    li.ant-pagination-options input {
      border: ${border('darkGrey')};
    }

    li.ant-pagination-item,
    div.ant-select.ant-pagination-options-size-changer {
      min-width: 25px;
      height: 35px !important;
    }

    ul,
    li,
    li div.ant-select,
    li div.ant-pagination-options-quick-jumper,
    li div.ant-pagination-options-quick-jumper input {
      height: 100% !important;
    }

    li div.ant-select .anticon.anticon-down.ant-select-suffix {
      svg {
        fill: ${colors.primary};
      }
    }

    li a {
      color: ${colors.primary} !important;
    }

    li button svg {
      fill: ${colors.primary};
    }

    .ant-pagination-item-active {
      border-color: ${colors.orange} !important;

      a,
      a:hover {
        color: ${colors.orange} !important;
      }
    }
  }
`;
