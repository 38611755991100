import { Button, Card, Col, Form, message, Row, Space } from 'antd';
import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { useToggle } from 'react-use';
import styled from '@emotion/styled';

import { ConfirmationModal } from '../../../shared/components/confirmation-modal';
import { setResourceName } from '../../../shared/data/store/slices/nav-slice';
import { Feature } from '../../../shared/models/features';
import { ResourceParams } from '../../../shared/models/resource-params';
import { PickSomeRestPartial } from '../../../shared/utils/types';
import { UserDetailsDrawer } from '../../components/user-details-drawer';
import { UserUpsertForm, UserUpsertFormField, UserUpsertFormValues } from '../../components/user-upsert-form';
import { translationNamespace } from '../../constants/translation-resources';
import { convertFormValuesToUser } from '../../data/converters/user-converters';
import {
  useApproveUserMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
} from '../../data/mutations/user-mutations';
import { useGetUserQuery } from '../../data/queries/user-queries';
import { UserUpsert } from '../../models/user';
import { AppResourcePaths } from '../../../shared/constants/app-resources';
import { returnChangedObjectProperties } from '../../../shared/utils/object';
import { UserStatuses } from '../../../shared/constants/user';
import { refresh } from '../../../shared/data/store/thunks/auth-thunks';
import { useAuthService } from '../../../shared/data/services/use-auth-service';
import { permissionSelector, userSelector } from '../../../shared/data/store/selectors/auth-selectors';

export const UserSingleView: FC = () => {
  const { t } = useTranslation(translationNamespace);
  const dispatch = useDispatch();
  const authService = useAuthService();
  const { push: routerPush } = useHistory();
  const { id } = useParams<ResourceParams>();
  const [form] = Form.useForm<UserUpsertFormValues>();
  const [showDetails, toggleShowDetails] = useToggle(false);
  const [showConfirmDeleteModal, toggleShowConfirmDeleteModal] = useToggle(false);
  // const [showConfirmApproveModal, toggleShowConfirmApproveModal] = useToggle(false);
  const parsedId = Number(id);
  const currentUser = useSelector(userSelector);
  const { data: user, isSuccess, isLoading, isFetching } = useGetUserQuery(parsedId);
  const updateMutation = useUpdateUserMutation();
  const deleteMutation = useDeleteUserMutation();
  const approveMutation = useApproveUserMutation();
  const initialUserValues = useRef<UserUpsertFormValues>();
  const permissions = useSelector(permissionSelector);

  const resourceName = user?.email ?? user?.username;
  const areRequestsLoading =
    isLoading || updateMutation.isLoading || deleteMutation.isLoading || approveMutation.isLoading;

  const submit = (values: UserUpsertFormValues) => {
    if (user?.id == null) {
      return;
    }

    const changedProperties = returnChangedObjectProperties(initialUserValues.current, values);
    if (changedProperties == null) {
      message.warning(t(`${Feature.SHARED}:nothingToUpdate`));
      return;
    }

    changedProperties.roles = values.roles;
    changedProperties.defaultRoles = values.defaultRoles;

    const newUser: PickSomeRestPartial<UserUpsert, 'id'> = {
      id: user.id,
      ...convertFormValuesToUser(changedProperties),
    };

    updateMutation.mutate(newUser, {
      onSuccess: () => {
        message.success(t('users.shared.updateSuccessMessage'));
        if (currentUser.id === user.id) {
          dispatch(refresh({ service: authService }));
        }
      },
    });
  };

  const remove = () => {
    deleteMutation.mutate(user.id, {
      onSuccess: () => {
        message.success(t('users.shared.deleteSuccessMessage'));
        routerPush(AppResourcePaths.users);
        toggleShowConfirmDeleteModal();
      },
    });
  };

  // not used for now
  // const approve = () => {
  //   approveMutation.mutate(user.id, {
  //     onSuccess: () => {
  //       message.success(t('users.shared.approveSuccessMessage'));
  //       toggleConfirmApproveModal();
  //     },
  //     onError: error => displayErrors(error, t),
  //   });
  // };

  const triggerSubmit = () => {
    if (user.status === UserStatuses.APPROVED || user.status === UserStatuses.PASSWORD_REQUIRED) {
      form.submit();
      return;
    }
    message.error(t('users.single.cantEditUnapproved'));
  };

  // const items: MenuProps['items'] = [
  // not used for now
  // user?.status === UserStatuses.ACTIVE && {
  //   key: 'deleteAllFromTenant',
  //   onClick: () => toggleConfirmApproveModal(),
  //   label: t('users.shared.approve'),
  // },
  // ];

  useEffect(() => {
    if (user) {
      const fieldsValue: UserUpsertFormValues = {
        [UserUpsertFormField.EMAIL]: user.email,
        [UserUpsertFormField.USERNAME]: user.username,
        [UserUpsertFormField.PASSWORD]: user.password,
        [UserUpsertFormField.FIRST_NAME]: user.firstName == null ? '' : user.firstName,
        [UserUpsertFormField.MIDDLE_NAME]: user.middleName == null ? '' : user.middleName,
        [UserUpsertFormField.LAST_NAME]: user.lastName == null ? '' : user.lastName,
        [UserUpsertFormField.DESCRIPTION]: user.description == null ? '' : user.description,
        [UserUpsertFormField.MOBILE_PHONE]: user.mobilePhone == null ? '' : user.mobilePhone,
        [UserUpsertFormField.TENANTS]: user.groups?.map(group => group.id),
        [UserUpsertFormField.DEFAULT_ROLES]: user.roles?.filter(role => role.isDefault).map(role => role.id),
        [UserUpsertFormField.ROLES]: user.roles?.filter(role => !role.isDefault).map(role => role.id),
      };

      form.setFieldsValue(fieldsValue);
      initialUserValues.current = fieldsValue;
    }
  }, [form, user]);

  useEffect(() => {
    dispatch(setResourceName(resourceName));
  }, [dispatch, resourceName]);

  return (
    <Row justify="space-between" gutter={[16, 24]}>
      <StyledCol span={24}>
        <Space>
          <Button size="large" onClick={toggleShowDetails} loading={areRequestsLoading} disabled={!isSuccess}>
            {t(`${Feature.SHARED}:details`)}
          </Button>
          {permissions.USER.DELETE && (
            <Button
              size="large"
              onClick={toggleShowConfirmDeleteModal}
              loading={areRequestsLoading}
              disabled={user == null}
              icon={<DeleteOutlined />}
              danger
            />
          )}
        </Space>
      </StyledCol>

      <Col span={24}>
        <Card title={t(`${Feature.SHARED}:details`)}>
          <UserUpsertForm form={form} onSubmit={submit} user={user} />
        </Card>
      </Col>

      <Col>
        <Space>
          {permissions.USER.UPDATE && (
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              icon={<CheckOutlined />}
              loading={areRequestsLoading || isFetching}
              onClick={triggerSubmit}
              disabled={!user}
            >
              {t(`${Feature.SHARED}:save`)}
            </Button>
          )}
          <Link to={AppResourcePaths.users}>
            <Button htmlType="button" size="large">
              {t(`${Feature.SHARED}:cancel`)}
            </Button>
          </Link>
        </Space>
      </Col>

      {user && <UserDetailsDrawer user={user} open={showDetails} onClose={toggleShowDetails} />}

      <ConfirmationModal
        open={showConfirmDeleteModal}
        title={t(`${Feature.SHARED}:areYouSure`)}
        onCancel={toggleShowConfirmDeleteModal}
        onOk={remove}
        content={t(`${Feature.SHARED}:confirmDeleteMsg`, {
          resourceName,
          buttonTitle: t(`${Feature.SHARED}:confirm`),
        })}
      />
      {/* not used for now
       <ConfirmationModal
        open={showConfirmApproveModal}
        title={t(`${Feature.SHARED}:areYouSure`)}
        onCancel={toggleConfirmApproveModal}
        onOk={approve}
        content={t('users.shared.confirmApproveMsg', {
          resourceName,
          buttonTitle: t(`${Feature.SHARED}:confirm`),
        })}
      /> */}
    </Row>
  );
};

const StyledCol = styled(Col)`
  display: flex;
  justify-content: end;
`;
