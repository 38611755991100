import { Button, Col, Form, FormInstance, Row, Select } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';

import { Feature } from '../../../shared/models/features';
import { translationNamespace } from '../../constants/translation-resources';
import { Dialect } from '../../models/dialect';
import { SourceStatus } from '../../models/source';
import { Dialects } from '../../constants/dialect';
import { SourceStatuses } from '../../constants/source';
import { RangePicker } from '../../../shared/components/date-picker/date-picker';

enum SourcesTableFilter {
  NAME = 'name',
  DIALECT = 'dialect',
  HOST = 'host',
  DATABASE_NAME = 'databasename',
  STATUS = 'status',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
}

export interface SourcesTableFilters {
  [SourcesTableFilter.NAME]: string[];
  [SourcesTableFilter.DIALECT]: Dialect[];
  [SourcesTableFilter.HOST]: string[];
  [SourcesTableFilter.DATABASE_NAME]: string[];
  [SourcesTableFilter.STATUS]: SourceStatus[];
  [SourcesTableFilter.CREATED_AT]: [Date?, Date?];
  [SourcesTableFilter.UPDATED_AT]: [Date?, Date?];
}

interface SourcesTableFiltersFormProps {
  onApply?: (filters: SourcesTableFilters) => void;
  form: FormInstance<SourcesTableFilters>;
}

export const SourcesTableFiltersForm: FC<SourcesTableFiltersFormProps> = ({ onApply, form }) => {
  const { t } = useTranslation(translationNamespace);

  const onClear = () => {
    form.resetFields();
    onApply?.(form.getFieldsValue());
  };

  return (
    <Form form={form} layout="vertical" onFinish={onApply}>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item label={t(`${Feature.SHARED}:name`)} name={SourcesTableFilter.NAME}>
            <Select suffixIcon={null} size="large" mode="tags" allowClear open={false} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t('sources.shared.dialect')} name={SourcesTableFilter.DIALECT}>
            <Select size="large" mode="multiple" allowClear>
              {Object.values(Dialects).map((dialect, index) => (
                <Select.Option key={index} value={dialect}>
                  {dialect}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t('sources.shared.host.label')} name={SourcesTableFilter.HOST}>
            <Select suffixIcon={null} size="large" mode="tags" allowClear open={false} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t('sources.shared.databasename.label')} name={SourcesTableFilter.DATABASE_NAME}>
            <Select suffixIcon={null} size="large" mode="tags" allowClear open={false} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t(`${Feature.SHARED}:status`)} name={SourcesTableFilter.STATUS}>
            <Select size="large" mode="multiple" allowClear>
              {Object.values(SourceStatuses).map((sourceStatus, i) => (
                <Select.Option key={i} value={sourceStatus}>
                  {t(`shared.status.${sourceStatus.toLowerCase()}`)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t(`${Feature.SHARED}:createdAt`)} name={SourcesTableFilter.CREATED_AT}>
            <RangePicker css={fullWidthStyles} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label={t(`${Feature.SHARED}:updatedAt`)} name={SourcesTableFilter.UPDATED_AT}>
            <RangePicker css={fullWidthStyles} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col>
          <Button type="primary" htmlType="submit" size="large" icon={<CheckOutlined />}>
            {t(`${Feature.SHARED}:apply`)}
          </Button>
        </Col>
        <Col>
          <Button htmlType="button" size="large" onClick={onClear}>
            {t(`${Feature.SHARED}:clear`)}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const fullWidthStyles = css`
  width: 100%;
  border-radius: 0;
`;
