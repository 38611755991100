import axios from 'axios';
import { FindAllDefinitionsRequest, FindAllDefinitionsResponse, GenericDefinitionResponse } from '@pccr-ifc/pccr-ifc';

import { useService } from '../../hooks/use-service';
import { Config } from '../../models/config';

export const BASE_PATH = '/report/definition';

function createDefinitionSharedService(config: Config) {
  const findDefinitions = (request: FindAllDefinitionsRequest) =>
    axios.post<FindAllDefinitionsResponse>(`${config.API_URL}${BASE_PATH}/findAll`, request, {
      withCredentials: true,
    });

  const getDefinition = async (id: number) => {
    const response = await axios.get<{ definition: GenericDefinitionResponse }>(`${config.API_URL}${BASE_PATH}/${id}`, {
      withCredentials: true,
    });
    return response.data.definition;
  };

  return {
    findDefinitions,
    getDefinition,
  };
}

export const useDefinitionSharedService = () => useService(createDefinitionSharedService);
