import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { DocumentActionRequest, DocumentActionResponse } from '@pccr-ifc/pccr-ifc';
import { useTranslation } from 'react-i18next';

import { useApprovalService } from '../services/use-approval-service';
import { getResourceListQueryKeyRoot } from '../../../shared/utils/resource-query-key';
import { displayErrors } from '../../../shared/utils/error';

export function useHandleDocumentActionMutation() {
  const approvalService = useApprovalService();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse<DocumentActionResponse>, unknown, DocumentActionRequest>(
    data => approvalService.handleDocumentAction(data),
    {
      onSuccess() {
        queryClient.invalidateQueries(getResourceListQueryKeyRoot('DOCUMENT'));
      },
      onError(error) {
        displayErrors(error, t);
      },
    }
  );
}
