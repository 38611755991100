import { TableProps } from 'antd';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Log } from '../../../shared/models/log';
import { getLanguageFromI18nLanguage } from '../../../shared/utils/language';
import { translationNamespace } from '../../constants/translation-resources';
import { getColumns } from './columns';
import { CustomTable } from '../../../shared/components/table';

export const LogsTable: FC<Omit<TableProps<Log>, 'columns' | 'rowKey'>> = props => {
  const { t, i18n } = useTranslation(translationNamespace);
  const columns = useMemo(() => getColumns(t, getLanguageFromI18nLanguage(i18n.language)), [i18n.language, t]);

  return <CustomTable<Log> columns={columns} rowKey={log => log.id} {...props} />;
};
