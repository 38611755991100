import { Button, Card, Col, Form, message, Row, Space, Tooltip } from 'antd';
import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { CheckOutlined, DeleteOutlined, QuestionOutlined } from '@ant-design/icons';
import { unwrapResult } from '@reduxjs/toolkit';
import { useToggle } from 'react-use';
import styled from '@emotion/styled';

import { ConfirmationModal } from '../../../shared/components/confirmation-modal';
import { setResourceName } from '../../../shared/data/store/slices/nav-slice';
import { Feature } from '../../../shared/models/features';
import { ResourceParams } from '../../../shared/models/resource-params';
import { isFormValidationError, PickSomeRestPartial } from '../../../shared/utils/types';
import { DefinitionDetailsDrawer } from '../../components/definition-details-drawer';
import {
  DefinitionUpsertForm,
  DefinitionUpsertFormField,
  DefinitionUpsertFormValues,
} from '../../components/definition-upsert-form';
import { translationNamespace } from '../../constants/translation-resources';
import { convertFormValuesToDefinition } from '../../data/converters/definition-converters';
import { useDeleteDefinitionMutation, useUpdateDefinitionMutation } from '../../data/mutations/definition-mutations';
import { useDefinitionService } from '../../data/services/use-definition-service';
import { checkDefinition } from '../../data/store/thunks/definition-thunks';
import { Definition } from '../../../shared/models/definition';
import { AppResourcePaths } from '../../../shared/constants/app-resources';
import { displayErrors } from '../../../shared/utils/error';
import { returnChangedObjectProperties } from '../../../shared/utils/object';
import { useGetDefinitionQuery } from '../../../shared/data/queries/definition-queries';
import { permissionSelector } from '../../../shared/data/store/selectors/auth-selectors';

export const DefinitionSingleView: FC = () => {
  const { t } = useTranslation(translationNamespace);
  const dispatch = useDispatch();
  const { push: routerPush } = useHistory();
  const { id } = useParams<ResourceParams>();
  const [form] = Form.useForm<DefinitionUpsertFormValues>();
  const [showDetails, toggleShowDetails] = useToggle(false);
  const [showConfirmDeleteModal, toggleShowConfirmDeleteModal] = useToggle(false);
  const parsedId = Number(id);
  const { data: definition, isSuccess, isLoading, isFetching } = useGetDefinitionQuery(parsedId);
  const updateMutation = useUpdateDefinitionMutation();
  const deleteMutation = useDeleteDefinitionMutation();
  const definitionService = useDefinitionService();
  const initialDefinitionValues = useRef<DefinitionUpsertFormValues>();
  const permissions = useSelector(permissionSelector);

  const areRequestsLoading = isLoading || updateMutation.isLoading || deleteMutation.isLoading;

  const submit = (values: DefinitionUpsertFormValues) => {
    if (definition?.id == null) {
      return;
    }

    const changedProperties = returnChangedObjectProperties(initialDefinitionValues.current, values);

    if (!changedProperties) {
      message.warning(t(`${Feature.SHARED}:nothingToUpdate`));
      return;
    }

    const newDefinition: PickSomeRestPartial<Definition, 'id'> = {
      id: definition.id,
      ...convertFormValuesToDefinition(changedProperties),
    };

    updateMutation.mutate(newDefinition, {
      onSuccess: () => {
        message.success(t('definitions.shared.updateSuccessMessage'));
      },
    });
  };

  const remove = () => {
    if (definition.id == null) {
      return;
    }

    deleteMutation.mutate(definition.id, {
      onSuccess: () => {
        message.success(t('definitions.shared.deleteSuccessMessage'));
        routerPush(AppResourcePaths.definitions);
      },
    });
  };

  const triggerSubmit = () => {
    form.submit();
  };

  const check = async () => {
    const validationResult = await form.validateFields().catch(error => {
      return error;
    });

    if (isFormValidationError(validationResult)) {
      return;
    }

    const newDefinition = convertFormValuesToDefinition(form.getFieldsValue());

    dispatch(checkDefinition({ request: newDefinition, service: definitionService }))
      .then(unwrapResult)
      .then(() => {
        message.success(t('shared.check.successful'));
      })
      .catch(error => displayErrors(error, t));
  };

  useEffect(() => {
    if (definition != null) {
      const fieldsValue = {
        [DefinitionUpsertFormField.NAME]: definition.name,
        [DefinitionUpsertFormField.TYPE]: definition.type,
        [DefinitionUpsertFormField.TENANT]: definition.groupId,
        [DefinitionUpsertFormField.SOURCE]: definition.sourceId,
        [DefinitionUpsertFormField.SQL_PARAMS]: definition.sqlParams
          ? Object.entries(definition.sqlParams).map(([key, value]) => {
              return {
                [DefinitionUpsertFormField.SQL_PARAM_KEY]: key,
                // [DefinitionUpsertFormField.SQL_PARAM_TYPE]: mapValueTypeToQueryParamType(value),
                [DefinitionUpsertFormField.SQL_PARAM_VALUE]: value,
              };
            })
          : [],
        [DefinitionUpsertFormField.SQL]: definition.sql,
      };

      form.setFieldsValue(fieldsValue);
      initialDefinitionValues.current = fieldsValue;

      form.validateFields();
    }
  }, [form, definition]);

  useEffect(() => {
    dispatch(setResourceName(definition?.name));
  }, [dispatch, definition?.name]);

  return (
    <Row justify="space-between" gutter={[16, 24]}>
      <StyledCol span={24}>
        <Space>
          <Button size="large" onClick={toggleShowDetails} loading={areRequestsLoading} disabled={!isSuccess}>
            {t(`${Feature.SHARED}:details`)}
          </Button>
          {permissions.DEFINITION.DELETE && (
            <Button
              size="large"
              onClick={toggleShowConfirmDeleteModal}
              loading={areRequestsLoading}
              disabled={definition == null}
              icon={<DeleteOutlined />}
              danger
            />
          )}
        </Space>
      </StyledCol>
      <Col span={24}>
        <Card title={t(`${Feature.SHARED}:details`)}>
          <DefinitionUpsertForm definition={definition} form={form} onSubmit={submit} />
        </Card>
      </Col>
      <Col>
        <Space>
          {permissions.DEFINITION.UPDATE && (
            <Tooltip title={t('shared.check.tooltip.definition')}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                icon={<CheckOutlined />}
                loading={areRequestsLoading || isFetching}
                onClick={triggerSubmit}
                disabled={!definition}
              >
                {t(`${Feature.SHARED}:save`)}
              </Button>
            </Tooltip>
          )}

          <Button type="dashed" size="large" icon={<QuestionOutlined />} onClick={check}>
            {t('shared.check.label')}
          </Button>
          <Link to={AppResourcePaths.definitions}>
            <Button htmlType="button" size="large">
              {t(`${Feature.SHARED}:cancel`)}
            </Button>
          </Link>
        </Space>
      </Col>
      {definition && <DefinitionDetailsDrawer definition={definition} open={showDetails} onClose={toggleShowDetails} />}
      <ConfirmationModal
        open={showConfirmDeleteModal}
        title={t(`${Feature.SHARED}:areYouSure`)}
        onCancel={toggleShowConfirmDeleteModal}
        onOk={remove}
        content={t(`${Feature.SHARED}:confirmDeleteMsg`, {
          resourceName: definition?.name,
          buttonTitle: t(`${Feature.SHARED}:confirm`),
        })}
      />
    </Row>
  );
};

const StyledCol = styled(Col)`
  display: flex;
  justify-content: end;
`;
