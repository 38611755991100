import { Steps } from 'antd';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { useBreakpoints } from '../../../shared/hooks/use-breakpoints';
import { translationNamespace } from '../../constants/translation-resources';
import { ForgotPasswordContextProvider } from './forgot-password-context';
import { steps } from './steps';

const { Step } = Steps;

export const ForgotPasswordView: FC = () => {
  const { md } = useBreakpoints();
  const { t } = useTranslation(translationNamespace);
  const [currentStep, setCurrentStep] = useState(0);

  const StepComponent = useMemo(() => steps[currentStep].component, [currentStep]);

  const changeStep = (step: number) => {
    setCurrentStep(step);
  };

  return (
    <Container>
      <StyledSteps size={md ? 'small' : 'default'} current={currentStep} onChange={changeStep} responsive>
        {steps.map((item, i) => (
          <Step key={item.key} title={t(item.nameTranslationKey)} disabled={currentStep < i} />
        ))}
      </StyledSteps>
      <StepContainer>
        <ForgotPasswordContextProvider currentStep={currentStep} setCurrentStep={changeStep}>
          <StepComponent />
        </ForgotPasswordContextProvider>
      </StepContainer>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  margin: 0 auto;
  width: 100%;
  max-width: 760px;
  padding: 24px 24px 0px;
`;

const StepContainer = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
`;

const StyledSteps = styled(Steps)`
  margin-bottom: 24px;
`;
