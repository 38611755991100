import { Table, TableProps } from 'antd';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getLanguageFromI18nLanguage } from '../../../shared/utils/language';
import { translationNamespace } from '../../constants/translation-resources';
import { View } from '../../models/view';
import { getColumns } from './columns';

export const ViewsTable: FC<Omit<TableProps<View>, 'columns' | 'rowKey'>> = props => {
  const { t, i18n } = useTranslation(translationNamespace);
  const columns = useMemo(() => getColumns(t, getLanguageFromI18nLanguage(i18n.language)), [i18n.language, t]);

  return <Table<View> columns={columns} rowKey={view => view.id} {...props} />;
};
