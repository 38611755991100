import { CoreTheme } from '../../shared/models/theme';

export const coreDefaultTheme: CoreTheme = {
  layouts: {
    sideNavigation: {
      header: {
        height: 64,
        color: '#ffffff',
      },
      sider: {
        width: 208,
      },
      footer: {
        height: 48,
      },
    },
    fullPage: {
      mainArea: {
        bgColor: '#ffffff',
        boxShadow:
          '0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)',
      },
      header: {
        bgColor: '#ffffff',
        color: 'inherit',
      },
      sider: {
        width: 30,
        bgColor: '#e5e5e5',
      },
      footer: {
        height: 48,
        bgColor: '#ffffff',
      },
    },
  },
  contextSelector: {
    color: '#ffffff',
  },
  logo: {
    inverted: {
      color: '#ffffff',
    },
    shape: {
      color: '#69c0ff',
    },
  },
};
