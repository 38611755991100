import { AppResource } from '../../shared/models/app-resource';

export const defaultRolesNamesToAppResourceMap: Record<string, AppResource> = {
  'Report Data': 'REPORT',
  'Report Preset': 'PRESET',
  'Report Definition': 'DEFINITION',
  'Report View': 'VIEW',
  'Report Source': 'SOURCE',
  Automation: 'JOB',
  'Access Tenant': 'TENANT',
  'Access User': 'USER',
  'Access Policy': 'POLICY',
  'Access Role': 'ROLE',
  Log: 'LOG',
  Approval: 'BATCH',
};
