import { Dropdown } from 'antd';
import styled from '@emotion/styled';

import { colors, border } from '../../../shared/constants/styles';

export const DropdownMenu = ({ children, items }) => (
  <Dropdown menu={items} dropdownRender={dropdownRender}>
    {children}
  </Dropdown>
);

const dropdownRender = ({ props }) => {
  const { items } = props;

  return (
    <StyledUl>
      {items.map(({ key, onClick, label, icon }) => {
        return (
          <StyledLi key={key} onClick={onClick}>
            {icon && <ImgWrapper>{icon}</ImgWrapper>}
            {label}
          </StyledLi>
        );
      })}
    </StyledUl>
  );
};

export const StyledActionButton = styled.div`
  padding: 0 15px;
  position: relative;
  min-width: 64px;
  height: 64px;
  border-left: ${border('white')};
  color: ${colors.white};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 19px;

  :hover {
    cursor: pointer;
    border-left: ${border('white')};
    background-color: ${colors.blueHover};
  }
`;

export const StyledUl = styled.ul`
  position: relative;
  margin: 0;
  margin-top: -5px;
  padding: 0;
  list-style-type: none;
  outline: none;
  background-clip: padding-box;
  background-color: ${colors.grey};
`;

export const StyledLi = styled.li`
  position: relative;
  margin: 0;
  padding: 14px;
  display: flex;
  text-align: center;
  align-items: center;
  font-weight: normal;
  list-style-type: none;
  border: ${border('primary')};
  border-bottom: none;
  color: ${colors.primary};
  transition: all 0.2s;
  cursor: pointer;

  :last-child {
    border-bottom: ${border('primary')};
  }

  :hover {
    background-color: ${colors.white};
  }
`;

const ImgWrapper = styled.div`
  margin-right: 8px;
  width: 24px;
`;
