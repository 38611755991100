import { FindAllDocumentsResponse } from '@pccr-ifc/pccr-ifc';
import { DocumentSearchResults } from '../../models/document';

export function convertSearchDocumentsResponse(response: FindAllDocumentsResponse): DocumentSearchResults {
  return {
    total: response.total,
    resources: response.documents.rows,
    infoColumns: response.documents.infoColumns,
  };
}
