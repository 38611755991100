import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { SchedulerAttributes } from '@pccr-ifc/pccr-ifc';

import { CommonFindAllQueryParams, QueryRequest } from '../../../shared/models/api/request';
import { deleteEmptyArrayObjProps } from '../../../shared/utils/object';
import { Job, JobsFilters } from '../../models/job';
import { convertJob, convertSearchResponse } from '../converters/job-converters';
import { useJobService } from '../services/use-job-service';
import { ResourceSearchResult } from '../../../shared/models/api/response';
import { getResourceListQueryKeyRoot, getSingleResourceQueryKey } from '../../../shared/utils/resource-query-key';
import { displayErrors } from '../../../shared/utils/error';

export function useGetJobQuery(id: number) {
  const jobService = useJobService();
  const { t } = useTranslation();

  return useQuery<SchedulerAttributes, unknown, Job>(
    getSingleResourceQueryKey('JOB', id),
    () => jobService.getJob(id),
    {
      enabled: id != null,
      onError: error => displayErrors(error, t),
      select: convertJob,
    }
  );
}

export function useFindJobsQuery({
  page,
  pageSize,
  searchValue,
  filters,
  order,
  attributes = ['id', 'name', 'cron', 'status', 'enabled', 'createdAt', 'updatedAt'],
}: CommonFindAllQueryParams<Job, JobsFilters>) {
  const jobService = useJobService();

  return useQuery<ResourceSearchResult<Job>, unknown>(
    [getResourceListQueryKeyRoot('JOB'), { page, pageSize }, searchValue, filters, order, attributes],
    async () => {
      const request: QueryRequest<Job, JobsFilters> = {
        limit: pageSize,
        offset: (page - 1) * pageSize,
        attributes,
      };

      if (order?.length > 0) {
        request.order = order;
      }

      if (searchValue) {
        request.search = searchValue;
      }

      if (filters && Object.values(filters).some(val => val != null)) {
        request.filters = deleteEmptyArrayObjProps(filters);
      }

      const response = await jobService.findJobs(request);
      return convertSearchResponse(response.data);
    }
  );
}
