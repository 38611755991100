import { FindAllBatchesResponse, Reason as ReasonIFC } from '@pccr-ifc/pccr-ifc';

import { DocumentsSearchResult, Reason } from '../../models/api/response';

export function convertSearchBatchesResponse(response: FindAllBatchesResponse): DocumentsSearchResult {
  return {
    total: response.total,
    resources: response.batches,
    numberOfDocuments: response.numberOfDocuments,
  };
}

export function convertGetReasonsResponse(response: ReasonIFC[]): Reason[] {
  return response.map(reason => ({
    value: reason.msg,
    id: reason.id,
  }));
}
