import { ConfigProvider as AntdConfigProvider } from 'antd';
import { FC, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { GuardProvider } from 'react-router-guards';
import { ThemeProvider } from '@emotion/react';

import '../../i18n';
import { AppRoutes } from '../../../shared/components/app-routes';
import { BreakpointProvider } from '../../../shared/components/breakpoint';
import { ConfigProvider } from '../../../shared/components/config';
import { compileTimeConfig } from '../../../shared/constants/config';
import { RuntimeConfig } from '../../../shared/models/config';
import { Layout } from '../../components/layouts';
import { translationNamespace } from '../../constants/translation-resources';
import { store } from '../../data/store';
import { layoutGuard } from '../../guards/layout';
import { loggedInGuard } from '../../guards/logged-in';
import { resourceNameGuard } from '../../guards/resource-name';
import { defaultTheme } from '../../themes/default-theme';
import { getAntdLocale, getAntdValidateMessages } from '../../utils/antd-locale';
import { NotFoundView } from '../not-found';
import { changePasswordGuard } from '../../guards/change-password';
import { useConst } from '../../../shared/hooks/use-const';
import { QUERY_CLIENT_SETTINGS } from '../../constants/query-client-settings';
import { AutomaticLogout } from '../../../auth/components/automatic-logout';
import { configProviderTypography, configProviderTheme } from '../../themes/config-provider-theme';
import { PermissionsSetter } from '../../components/permissions-setter';

export type AppProps = {
  initialPropsScriptId?: string;
  runtimeConfig?: RuntimeConfig;
};

export const App: FC<AppProps> = ({ initialPropsScriptId, runtimeConfig }) => {
  const { t, i18n } = useTranslation(translationNamespace);
  const queryClient = useConst(() => new QueryClient(QUERY_CLIENT_SETTINGS));

  const form = useMemo(() => {
    return {
      validateMessages: getAntdValidateMessages(t),
    };
  }, [t]);

  useEffect(() => {
    if (initialPropsScriptId) {
      document.getElementById(initialPropsScriptId)?.remove();
    }
  }, [initialPropsScriptId, runtimeConfig]);

  return (
    <ConfigProvider runtimeConfig={runtimeConfig}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={defaultTheme}>
            <AntdConfigProvider
              locale={getAntdLocale(i18n.language)}
              direction={i18n.dir()}
              form={form}
              theme={configProviderTheme}
              typography={configProviderTypography}
            >
              <BreakpointProvider>
                <Helmet htmlAttributes={{ lang: i18n.language, dir: i18n.dir() }} />
                <GuardProvider
                  guards={[layoutGuard, loggedInGuard, resourceNameGuard, changePasswordGuard]}
                  error={NotFoundView}
                >
                  <Layout>
                    <PermissionsSetter />
                    <AutomaticLogout />
                    <AppRoutes />
                    {compileTimeConfig.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
                  </Layout>
                </GuardProvider>
              </BreakpointProvider>
            </AntdConfigProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </Provider>
    </ConfigProvider>
  );
};
