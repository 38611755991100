import { TableColumnType } from 'antd';
import React from 'react';
import { TFunction } from 'react-i18next';

import { LinkedName } from '../../../shared/components/linked-name';
import { AppResourcePaths, AppResources } from '../../../shared/constants/app-resources';
import { Feature } from '../../../shared/models/features';
import { Language } from '../../../shared/models/language';
import { Tenant } from '../../../shared/models/tenant';
import { formatISODate } from '../../../shared/utils/date';
import { Actions as DefaultActions } from './actions';
import { PermissionsState } from '../../../shared/data/store';

export const getColumns = (
  t: TFunction<Feature.ACCESS>,
  language: Language,
  permissions: PermissionsState,
  customActions: (tenant: Tenant) => React.ReactElement = (tenant: Tenant) => <DefaultActions tenant={tenant} />,
  sortingEnabled = true
): TableColumnType<Tenant>[] => {
  return [
    {
      title: t(`${Feature.SHARED}:name`),
      dataIndex: 'name',
      render: (name: string, tenant: Tenant) => (
        <LinkedName name={name} to={`${AppResourcePaths.tenants}/${tenant.id}`} />
      ),
      sorter: sortingEnabled,
    },
    {
      title: t(`${Feature.SHARED}:createdAt`),
      dataIndex: 'createdAt',
      render: (createdAt: Tenant['createdAt']) => formatISODate(createdAt, language),
      sorter: true,
      width: 130,
      defaultSortOrder: 'descend',
    },
    {
      title: t(`${Feature.SHARED}:updatedAt`),
      dataIndex: 'updatedAt',
      render: (updatedAt: Tenant['updatedAt']) => formatISODate(updatedAt, language),
      sorter: true,
      width: 130,
    },
    {
      key: 'action',
      align: 'right',
      render: (_, tenant) => customActions(tenant),
      width: 130,
      hidden: !permissions[AppResources.TENANT]['UPDATE'] && !permissions[AppResources.TENANT]['DELETE'],
    },
  ];
};
