import { Button, Col, Form, FormInstance, Row, Select } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';

import { Feature } from '../../../shared/models/features';
import { translationNamespace } from '../../constants/translation-resources';
import { ViewStatus } from '../../models/view';
import { ViewStatuses } from '../../constants/view';
import { RangePicker } from '../../../shared/components/date-picker/date-picker';

enum ViewsTableFilter {
  NAME = 'name',
  VIEW_NAME = 'viewName',
  SCHEMA_NAME = 'schemaName',
  STATUS = 'status',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
}

export interface ViewsTableFilters {
  [ViewsTableFilter.NAME]: string[];
  [ViewsTableFilter.VIEW_NAME]: string[];
  [ViewsTableFilter.SCHEMA_NAME]: string[];
  [ViewsTableFilter.STATUS]: ViewStatus[];
  [ViewsTableFilter.CREATED_AT]: [Date?, Date?];
  [ViewsTableFilter.UPDATED_AT]: [Date?, Date?];
}

interface ViewsTableFiltersFormProps {
  onApply?: (filters: ViewsTableFilters) => void;
  form: FormInstance<ViewsTableFilters>;
}

export const ViewsTableFiltersForm: FC<ViewsTableFiltersFormProps> = ({ onApply, form }) => {
  const { t } = useTranslation(translationNamespace);

  const onClear = () => {
    form.resetFields();
    onApply?.(form.getFieldsValue());
  };

  return (
    <Form form={form} layout="vertical" onFinish={onApply}>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item label={t(`${Feature.SHARED}:name`)} name={ViewsTableFilter.NAME}>
            <Select suffixIcon={null} size="large" mode="tags" allowClear open={false} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t('views.shared.viewName.label')} name={ViewsTableFilter.VIEW_NAME}>
            <Select suffixIcon={null} size="large" mode="tags" allowClear open={false} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t('views.shared.schemaName.label')} name={ViewsTableFilter.SCHEMA_NAME}>
            <Select suffixIcon={null} size="large" mode="tags" allowClear open={false} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t(`${Feature.SHARED}:status`)} name={ViewsTableFilter.STATUS}>
            <Select size="large" mode="multiple" allowClear>
              {Object.values(ViewStatuses).map((viewStatus, i) => (
                <Select.Option key={i} value={viewStatus}>
                  {t(`shared.status.${viewStatus.toLowerCase()}`)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label={t(`${Feature.SHARED}:createdAt`)} name={ViewsTableFilter.CREATED_AT}>
            <RangePicker css={fullWidthStyles} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label={t(`${Feature.SHARED}:updatedAt`)} name={ViewsTableFilter.UPDATED_AT}>
            <RangePicker css={fullWidthStyles} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col>
          <Button type="primary" htmlType="submit" size="large" icon={<CheckOutlined />}>
            {t(`${Feature.SHARED}:apply`)}
          </Button>
        </Col>
        <Col>
          <Button htmlType="button" size="large" onClick={onClear}>
            {t(`${Feature.SHARED}:clear`)}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const fullWidthStyles = css`
  width: 100%;
`;
