import { Checkbox, Descriptions, Divider, Drawer, DrawerProps, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { useToggle } from 'react-use';

import { useGetTenantQuery } from '../../../access/data/queries/tenant-queries';
import { useLogsQueryForTimeline } from '../../../logs/data/queries/log-queries';
import { LogsTimeline } from '../../../shared/components/logs-timeline';
import { UserName } from '../../../shared/components/user-name';
import { Feature } from '../../../shared/models/features';
import { formatISODate } from '../../../shared/utils/date';
import { getLanguageFromI18nLanguage } from '../../../shared/utils/language';
import { translationNamespace } from '../../constants/translation-resources';
import { useGetSourceQuery } from '../../data/queries/source-queries';
import { View } from '../../models/view';
import { getViewStatusTagColor } from '../../utils/color-mappers';
import { LogsFilters } from '../../../logs/models/log';

interface ViewDetailsDrawerProps extends DrawerProps {
  view: View;
}

export const ViewDetailsDrawer = ({ view, ...props }: ViewDetailsDrawerProps) => {
  const { t, i18n } = useTranslation(translationNamespace);
  const [includeGetLogs, toggleIncludeGetLogs] = useToggle(false);
  const filters: LogsFilters = {
    entityType: 'view',
    resourceId: [view.id],
    method: includeGetLogs ? undefined : ['PATCH', 'POST', 'PUT', 'DELETE'],
  };
  const logQueryResult = useLogsQueryForTimeline(filters, { enabled: props.open });
  const { data: sourceRes } = useGetSourceQuery(view?.sourceId);
  const { data: tenant } = useGetTenantQuery(view?.groupId);

  return (
    <Drawer {...props} title={t(`${Feature.SHARED}:details`)} width={400} placement="right" destroyOnClose>
      <Descriptions column={1} bordered css={withMarginBottom}>
        <Descriptions.Item label={t(`${Feature.SHARED}:name`)}>{view.name}</Descriptions.Item>
        <Descriptions.Item label={t('shared.source.label')}>{sourceRes?.source.name ?? ''}</Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:tenant.label`)}>{tenant?.name ?? ''}</Descriptions.Item>
        <Descriptions.Item label={t('views.shared.viewName.label')}>{view.viewName}</Descriptions.Item>
        <Descriptions.Item label={t('views.shared.schemaName.label')}>{view.schemaName}</Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:status`)}>
          {view.status ? (
            <Tag color={getViewStatusTagColor(view.status)}>{t(`shared.status.${view.status.toLowerCase()}`)}</Tag>
          ) : (
            ''
          )}
        </Descriptions.Item>

        <Descriptions.Item label={t(`${Feature.SHARED}:createdAt`)}>
          {view.createdAt ? formatISODate(view.createdAt, getLanguageFromI18nLanguage(i18n.language)) : ''}
        </Descriptions.Item>

        <Descriptions.Item label={t(`${Feature.SHARED}:createdBy`)}>
          <UserName userId={logQueryResult.isLoading ? undefined : view.createdBy} />
        </Descriptions.Item>

        <Descriptions.Item label={t(`${Feature.SHARED}:updatedAt`)}>
          {view.updatedAt ? formatISODate(view.updatedAt, getLanguageFromI18nLanguage(i18n.language)) : ''}
        </Descriptions.Item>

        <Descriptions.Item label={t(`${Feature.SHARED}:updatedBy`)}>
          <UserName userId={logQueryResult.isLoading ? undefined : view.updatedBy} />
        </Descriptions.Item>
      </Descriptions>

      <Divider>{t(`${Feature.SHARED}:log`)}</Divider>
      <Checkbox checked={includeGetLogs} onChange={toggleIncludeGetLogs} css={withMarginBottom}>
        {t(`${Feature.SHARED}:includeGetLogs`)}
      </Checkbox>
      <LogsTimeline queryResult={logQueryResult} />
    </Drawer>
  );
};

const withMarginBottom = css`
  margin-bottom: 32px;
`;
