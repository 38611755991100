import { Button, Form, Input, Space, Typography } from 'antd';
import { FC, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined, ArrowRightOutlined, LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';

import { useQuery } from '../../../../shared/hooks/use-query';
import { CommonQueryParamKey } from '../../../../shared/models/common-query-params';
import { Feature } from '../../../../shared/models/features';
import { translationNamespace } from '../../../constants/translation-resources';
import { RegisterContext, RegisterFormField, RegisterFormValues } from '../register-context';
import {
  redundantSpacebarsRule,
  userPasswordNewUsernameRule,
  userPasswordRulesWithoutUsernameRule,
} from '../../../../shared/utils/form-rules';
import { showOnlyOneError } from '../../../../shared/styles/form-styles';

const { Title, Text } = Typography;

type IntroFormValues = Pick<
  RegisterFormValues,
  RegisterFormField.EMAIL | RegisterFormField.PASSWORD | RegisterFormField.CONFIRM_PASSWORD
>;

export const Intro: FC = () => {
  const { t } = useTranslation(translationNamespace);
  const [form] = Form.useForm();
  const { nextStep, previousStep, setValues, formValues } = useContext(RegisterContext);
  const query = useQuery();
  const parsedPrev = query.get(CommonQueryParamKey.PREV);
  const prevUrl = parsedPrev ?? '/auth/login';

  const proceed = useCallback(
    (values: IntroFormValues) => {
      setValues(values);
      nextStep();
    },
    [setValues, nextStep]
  );

  return (
    <Space direction="vertical">
      <Title level={2}>{t('registerView.steps.intro.title')}</Title>
      <Text type="secondary">{t('registerView.steps.intro.description')}</Text>
      <Form layout="vertical" form={form} onFinish={proceed} initialValues={formValues} requiredMark={false}>
        <Form.Item
          name={RegisterFormField.USERNAME}
          label={t(`${Feature.SHARED}:username.label`)}
          rules={[{ required: true, min: 6, max: 190 }, redundantSpacebarsRule(t)]}
        >
          <Input prefix={<UserOutlined />} placeholder={t(`${Feature.SHARED}:username.placeholder`)} />
        </Form.Item>
        <Form.Item
          name={RegisterFormField.EMAIL}
          label={t(`${Feature.SHARED}:email.label`)}
          rules={[{ required: true, type: 'email' }]}
        >
          <Input prefix={<MailOutlined />} placeholder={t(`${Feature.SHARED}:email.placeholder`)} type="email" />
        </Form.Item>
        <Form.Item
          name={RegisterFormField.PASSWORD}
          label={t(`${Feature.SHARED}:password.label`)}
          dependencies={[RegisterFormField.USERNAME]}
          rules={[
            ...userPasswordRulesWithoutUsernameRule(t),
            userPasswordNewUsernameRule(form, RegisterFormField.USERNAME, t),
          ]}
          css={showOnlyOneError}
        >
          <Input.Password prefix={<LockOutlined />} placeholder={t(`${Feature.SHARED}:password.placeholder`)} />
        </Form.Item>
        <Form.Item
          name={RegisterFormField.CONFIRM_PASSWORD}
          label={t('shared.confirmPassword.label')}
          dependencies={[RegisterFormField.PASSWORD]}
          rules={[
            { required: true },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue(RegisterFormField.PASSWORD) === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('shared.confirmPassword.error')));
              },
            }),
          ]}
        >
          <Input.Password prefix={<LockOutlined />} placeholder={t('shared.confirmPassword.placeholder')} />
        </Form.Item>
        <ButtonGroup>
          <Link to={prevUrl}>
            <ButtonNoPaddingLeft type="link" htmlType="button" icon={<ArrowLeftOutlined />} onClick={previousStep}>
              {t('shared.back')}
            </ButtonNoPaddingLeft>
          </Link>
          <Form.Item>
            <StyledButton type="primary" htmlType="submit" icon={<ArrowRightOutlined />}>
              {t('shared.continue')}
            </StyledButton>
          </Form.Item>
        </ButtonGroup>
      </Form>
    </Space>
  );
};

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const StyledButton = styled(Button)`
  margin-top: 16px;
`;

const ButtonNoPaddingLeft = styled(StyledButton)`
  padding-left: 0;
`;
