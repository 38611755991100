import { Language } from '../models/language';

export function getLanguageFromI18nLanguage(i18nLanguage: string): Language {
  switch (i18nLanguage) {
    case 'en-GB':
      return Language.EnglishGB;
    case 'de-DE':
      return Language.German;
    default:
      return Language.EnglishGB;
  }
}
