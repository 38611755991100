import {
  differenceInHours,
  differenceInMilliseconds,
  format,
  formatDistanceToNowStrict,
  formatISO,
  isValid,
  parseISO,
} from 'date-fns';

import { i18nDateFnsLocaleMap } from '../constants/date-fns';
import { Language } from '../models/language';

export function isValidISODate(date: string): boolean {
  let result = false;
  try {
    result = isValid(parseISO(date));
  } catch (error) {
    return false;
  }

  return result;
}

export function formatISODate(isoDate: string, language: Language) {
  return isValidISODate(isoDate)
    ? format(parseISO(isoDate), 'Pp', { locale: i18nDateFnsLocaleMap[language] })
    : isoDate;
}

export function formatTime(date: Date, language: Language) {
  return format(date, 'p', { locale: i18nDateFnsLocaleMap[language] });
}

export function convertDateRangeToISO(dateRange: [Date?, Date?]): [string, string] {
  return [
    formatISO(dateRange[0] ?? new Date(0), { representation: 'date' }),
    formatISO(dateRange[1] ?? new Date(), { representation: 'date' }),
  ];
}

export function formatTimeRemainingToNow(date: Date, locale: Locale, completeMessage: string) {
  const msToComplete = differenceInMilliseconds(date, new Date());
  if (msToComplete <= 0) return completeMessage;

  const hoursToComplete = differenceInHours(date, new Date());
  return formatDistanceToNowStrict(date, {
    locale,
    unit: hoursToComplete > 24 ? 'day' : hoursToComplete < 1 ? 'minute' : 'hour',
  });
}
