import axios from 'axios';
import { FindAllHistoryRequest, FindAllHistoryResponse } from '@pccr-ifc/pccr-ifc';

import { useService } from '../../../shared/hooks/use-service';
import { Config } from '../../../shared/models/config';

function createLogService(config: Config) {
  const BASE_PATH = '/history';

  const findLogs = (request: FindAllHistoryRequest) =>
    axios.post<FindAllHistoryResponse>(`${config.API_URL}${BASE_PATH}/findAll`, request, {
      withCredentials: true,
    });

  return { findLogs };
}

export const useLogService = () => useService(createLogService);
