import { combineReducers } from '@reduxjs/toolkit';
import { authSlice, layoutSlice, loaderSlice, navSlice, permissionSlice } from './slices';

export const sharedReducer = combineReducers({
  loader: loaderSlice,
  layout: layoutSlice,
  nav: navSlice,
  auth: authSlice,
  permission: permissionSlice,
});

export type SharedState = ReturnType<typeof sharedReducer>;
