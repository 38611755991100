import axios from 'axios';
import DataLoader from 'dataloader';
import { FindAllUsersResponse, User as BeUser, UserWithIncludes, FindAllUsersRequest } from '@pccr-ifc/pccr-ifc';

import { Config } from '../../../shared/models/config';
import { useService } from '../../../shared/hooks/use-service';
import { userIdentifierAttributes } from '../../constants/user';
import { UserIdentifierAttribute } from '../../models/user';

function createUserService(config: Config) {
  const BASE_PATH = '/user';

  const findUsers = (request: FindAllUsersRequest) =>
    axios.post<FindAllUsersResponse>(`${config.API_URL}${BASE_PATH}/findAll`, request, { withCredentials: true });

  const fetchUserById = async (id: number) => {
    const response = await axios.get<{ user: UserWithIncludes }>(`${config.API_URL}${BASE_PATH}/${id}`, {
      withCredentials: true,
    });
    return response.data.user;
  };

  const createUser = async (request: { user: Omit<BeUser, 'id'> }) => {
    const response = await axios.post<{ user: UserWithIncludes }>(`${config.API_URL}${BASE_PATH}/add`, request, {
      withCredentials: true,
    });

    return response.data.user;
  };

  const updateUser = async ({ id, ...request }: Partial<BeUser>) => {
    const response = await axios.patch<{ user: UserWithIncludes }>(
      `${config.API_URL}${BASE_PATH}/${id}`,
      { user: request },
      { withCredentials: true }
    );

    return response.data.user;
  };

  const deleteUser = (id: number) =>
    axios.delete<void>(`${config.API_URL}${BASE_PATH}/${id}`, {
      withCredentials: true,
    });

  // disabled for now (no registration)
  const approveUser = (id: number) =>
    axios.get<{ user: BeUser }>(`${config.API_URL}${BASE_PATH}/approve/${id}`, { withCredentials: true });

  const identifierBatchLoader = new DataLoader(
    async (ids: number[]) => {
      const response = await findUsers({
        startRow: 0,
        numberOfResults: ids.length,
        filters: { id: ids },
        attributes: [...userIdentifierAttributes],
      });
      const byId = new Map(
        response.data.users.map((user: Pick<UserWithIncludes, UserIdentifierAttribute>) => [user.id, user])
      );
      return ids.map(id => byId.get(id) ?? new Error(`User not available`));
    },
    { cache: false }
  );

  const fetchUserName = (id: number) => identifierBatchLoader.load(id);

  return {
    findUsers,
    createUser,
    updateUser,
    deleteUser,
    approveUser,
    fetchUserById,
    fetchUserName,
  };
}

export const useUserService = () => useService(createUserService);
