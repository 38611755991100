import { Card, Col, Form, message, Row, Space } from 'antd';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DownOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { useDebounce, useToggle } from 'react-use';
import { css } from '@emotion/react';

import { GenerateReportFormValues } from '../../../shared/components/generate-report-form-fields';
import { convertFormValuesToGenerateReport } from '../../../shared/data/converters/report-converters';
import { useTableOptions } from '../../../shared/hooks/use-data-options';
import { Feature } from '../../../shared/models/features';
import { GenerateReportModal } from '../../components/generate-report-modal';
import { ReportsTable, ReportsTableFilters, ReportsTableFiltersForm } from '../../components/reports-table';
import { translationNamespace } from '../../constants/translation-resources';
import { useFindReportsQuery } from '../../data/queries/report-queries';
import { Report, ReportsFilters } from '../../models/report';
import { convertTableFiltersToFilters } from '../../data/converters/report-converters';
import { Orders } from '../../../shared/constants/order';
import { useGenerateReportMutation } from '../../data/mutations/report-mutations';
import { CustomSearch } from '../../../shared/components/search';
import { FixedButton } from '../../../shared/components/fixed-button';
import iconAdd from '../../assets/images/icon_add.svg';
import { CustomFilterButton } from '../../../shared/components/filter-button';
import { permissionSelector } from '../../../shared/data/store/selectors/auth-selectors';

export const ReportsListView: FC = () => {
  const { t } = useTranslation(translationNamespace);
  const generateReportLoading = useSelector(state => state.reports.report.generate.loading);
  const generateMutation = useGenerateReportMutation();
  const { page, setPage, pageSize, order, handleTableChange } = useTableOptions<Report>([['createdAt', Orders.DESC]]);
  const [showFilters, toggleShowFilters] = useToggle(false);
  const [filters, setFilters] = useState<ReportsFilters>(null);
  const [filtersForm] = Form.useForm<ReportsTableFilters>();
  const [searchValue, setSearchValue] = useState<string>();
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>();
  const [showGenerateReportModal, toggleShowGenerateReportModal] = useToggle(false);
  const { data, isLoading } = useFindReportsQuery({
    page,
    pageSize,
    searchValue: debouncedSearchValue,
    filters,
    order,
  });
  const permissions = useSelector(permissionSelector);

  const applyFilters = (filters: ReportsTableFilters) => {
    const reportsFilters = convertTableFiltersToFilters(filters);

    setSearchValue(null);
    setDebouncedSearchValue(null);
    setFilters(reportsFilters);
    setPage(1);
  };

  const applySearch = (value: string) => {
    setFilters(null);
    filtersForm.resetFields();
    setSearchValue(value);
    setDebouncedSearchValue(value);
    setPage(1);
  };

  const generate = (values: GenerateReportFormValues) => {
    const report = convertFormValuesToGenerateReport(values);

    generateMutation.mutate(report, {
      onSuccess() {
        message.success(t('reports.list.generateSuccessMessage'));
        toggleShowGenerateReportModal();
      },
    });
  };

  useDebounce(
    () => {
      if (searchValue != null) {
        setFilters(null);
        filtersForm.resetFields();
        setPage(1);
      }
      setDebouncedSearchValue(searchValue);
    },
    3000,
    [searchValue]
  );

  return (
    <Row>
      <StyledSpace>
        <CustomSearch
          value={searchValue}
          onChange={event => setSearchValue(event.target.value)}
          placeholder={t(`${Feature.SHARED}:search`)}
          onSearch={applySearch}
        />
        <CustomFilterButton type="link" onClick={toggleShowFilters}>
          {t(`${Feature.SHARED}:filters`)} <StyledDownOutlined rotate={showFilters ? 180 : 0} />
        </CustomFilterButton>
      </StyledSpace>
      {permissions.REPORT.CREATE && (
        <FixedButton type="primary" size="large" onClick={toggleShowGenerateReportModal} iconSrc={iconAdd}>
          {t('reports.shared.generate.report')}
        </FixedButton>
      )}
      {showFilters && (
        <Col css={marginTop} span={24}>
          <Card title={t(`${Feature.SHARED}:filters`)}>
            <ReportsTableFiltersForm form={filtersForm} onApply={applyFilters} />
          </Card>
        </Col>
      )}
      <Col span={24} style={{ marginTop: showFilters || isLoading || data?.filteredReportCount === 0 ? '50px' : '0' }}>
        <ReportsTable
          dataSource={data?.resources}
          pagination={{
            total: data?.filteredReportCount,
            current: page,
            pageSize,
            showSizeChanger: data?.total > 10,
            position: ['topRight'],
          }}
          loading={isLoading}
          onChange={handleTableChange}
          scroll={{ x: 1200 }}
        />
      </Col>
      <StyledDiv>
        <div>{!isLoading && data && `${data.filteredReportCount} ${t(`${Feature.SHARED}:of`)} ${data.total}`}</div>
      </StyledDiv>
      <GenerateReportModal
        open={showGenerateReportModal}
        onCancel={toggleShowGenerateReportModal}
        onSubmit={generate}
        confirmLoading={generateReportLoading}
      />
    </Row>
  );
};

const StyledDownOutlined = styled(DownOutlined)`
  > svg {
    transition: transform 0.2s;
  }
`;

const StyledDiv = styled.div`
  padding: 16px;
  display: flex;
  justify-content: end;
  width: 100%;
`;

const StyledSpace = styled(Space)`
  position: absolute;
  z-index: 1;

  .ant-space-item {
    height: 38px;
  }
`;

const marginTop = css`
  margin-top: 50px;
`;
