import { css } from '@emotion/react';
import { Tag } from 'antd';
import { FC } from 'react';
import { Policy } from '../../../shared/models/policy';
import { getArrayUniqueValues } from '../../../shared/utils/array';

interface PoliciesCellProps {
  policies?: Policy[];
}

export const PoliciesCell: FC<PoliciesCellProps> = ({ policies }) => {
  const uniquePolicies = policies?.length > 0 ? getArrayUniqueValues(policies?.map(policy => policy.name)) : [];

  return (
    <>
      {uniquePolicies.map(policyName => (
        <Tag css={whiteSpaceNormal} key={policyName}>
          {policyName}
        </Tag>
      ))}
    </>
  );
};

const whiteSpaceNormal = css`
  white-space: normal;
`;
