import { TenantStats } from '../models/tenant';

export function countTenantStats(tenantStats: Omit<TenantStats, 'parentGroupId'>): number {
  if (tenantStats == null) {
    return null;
  }

  const statsToCount: (keyof TenantStats)[] = [
    'definitions',
    'groups',
    'presets',
    'reports',
    'roles',
    'sources',
    'users',
    'views',
  ];

  const statsCount = statsToCount.reduce((acc, propertyName) => {
    const curr = tenantStats[propertyName];
    if (curr != null) {
      const nonNullValues = curr.filter(value => value != null && value);
      acc += nonNullValues.length;
    }

    return acc;
  }, 0);

  return statsCount;
}
