import { Button, Form, Input, message, Space, Typography } from 'antd';
import { FC, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { unwrapResult } from '@reduxjs/toolkit';

import { useAuthService } from '../../../../shared/data/services/use-auth-service';
import { registerLoadingSelector } from '../../../../shared/data/store/selectors/auth-selectors';
import { register } from '../../../../shared/data/store/thunks/auth-thunks';
import { Feature } from '../../../../shared/models/features';
import { translationNamespace } from '../../../constants/translation-resources';
import { RegisterContext, RegisterFormField, RegisterFormValues } from '../register-context';
import { displayErrors } from '../../../../shared/utils/error';

const { Title, Text } = Typography;

type DetailsFormValues = Pick<
  RegisterFormValues,
  RegisterFormField.EMAIL | RegisterFormField.PASSWORD | RegisterFormField.CONFIRM_PASSWORD
>;

export const Details: FC = () => {
  const { t } = useTranslation(translationNamespace);
  const [form] = Form.useForm();
  const { previousStep, setValues, formValues, nextStep } = useContext(RegisterContext);
  const dispatch = useDispatch();
  const authService = useAuthService();
  const requestLoading = useSelector(registerLoadingSelector);

  const submit = useCallback(() => {
    const detailsStepValues = form.getFieldsValue();

    dispatch(
      register({
        request: { ...formValues, ...detailsStepValues },
        service: authService,
      })
    )
      .then(unwrapResult)
      .then(() => {
        message.success(t('registerView.steps.details.registerSuccessMessage'));
        nextStep();
      })
      .catch(error => displayErrors(error, t));
  }, [authService, dispatch, form, formValues, nextStep, t]);

  const proceed = useCallback(
    (values: DetailsFormValues) => {
      setValues(values);
      submit();
    },
    [setValues, submit]
  );

  const onPreviousStep = () => {
    const detailsStepValues = form.getFieldsValue();
    setValues(detailsStepValues);
    previousStep();
  };

  return (
    <Space direction="vertical">
      <Title level={2}>{t('registerView.steps.details.title')}</Title>
      <Text type="secondary">{t('registerView.steps.details.description')}</Text>
      <Form layout="vertical" form={form} onFinish={proceed} initialValues={formValues} requiredMark={false}>
        <Form.Item
          name={RegisterFormField.FIRST_NAME}
          label={t(`${Feature.SHARED}:firstName.label`)}
          rules={[{ type: 'string' }]}
        >
          <Input placeholder={t(`${Feature.SHARED}:firstName.placeholder`)} />
        </Form.Item>
        <Form.Item
          name={RegisterFormField.MIDDLE_NAME}
          label={t(`${Feature.SHARED}:middleName.label`)}
          rules={[{ type: 'string' }]}
        >
          <Input placeholder={t(`${Feature.SHARED}:middleName.placeholder`)} />
        </Form.Item>
        <Form.Item
          name={RegisterFormField.LAST_NAME}
          label={t(`${Feature.SHARED}:lastName.label`)}
          rules={[{ type: 'string' }]}
        >
          <Input placeholder={t(`${Feature.SHARED}:lastName.placeholder`)} />
        </Form.Item>
        <Form.Item
          name={RegisterFormField.MOBILE_PHONE}
          label={t(`${Feature.SHARED}:mobilePhone.label`)}
          rules={[{ type: 'string' }]}
        >
          <Input placeholder={t(`${Feature.SHARED}:mobilePhone.placeholder`)} />
        </Form.Item>
        <ButtonGroup>
          <ButtonNoPaddingLeft type="link" htmlType="button" icon={<ArrowLeftOutlined />} onClick={onPreviousStep}>
            {t('shared.back')}
          </ButtonNoPaddingLeft>
          <Form.Item>
            <StyledButton type="primary" htmlType="submit" icon={<ArrowRightOutlined />} loading={requestLoading}>
              {t(`${Feature.SHARED}:create`)}
            </StyledButton>
          </Form.Item>
        </ButtonGroup>
      </Form>
    </Space>
  );
};

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const StyledButton = styled(Button)`
  margin-top: 16px;
`;

const ButtonNoPaddingLeft = styled(StyledButton)`
  padding-left: 0;
`;
