import { Space, Typography } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { translationNamespace } from '../../constants/translation-resources';
import { fontSize, colors } from '../../../shared/constants/styles';
import logo from '../../assets/images/Paragon_Logo.png';
import fullLogo from '../../assets/images/paragon-logo-full-page.png';
import { useConfig } from '../../../shared/hooks/use-config';

interface LogoProps {
  inverted?: boolean;
}

const { Text } = Typography;

export const Logo: FC<LogoProps> = ({ inverted = false }) => {
  const { t } = useTranslation(translationNamespace);
  const { NODE_ENV } = useConfig();
  const appName =
    NODE_ENV === 'development' ? t('appNameDev') : NODE_ENV === 'production' ? t('appName') : t('appNameTest');

  return (
    <StyledSpace>
      <Text css={logoStyles(inverted)}>{appName}</Text>
    </StyledSpace>
  );
};

const StyledSpace = styled(Space)`
  float: left;
`;

const logoStyles = (inverted: boolean) => css`
  padding-left: 5.75em;
  background-image: url(${inverted ? fullLogo : logo});
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 5em;
  color: ${inverted ? colors.primary : colors.white};
  font-size: ${fontSize.ONE_POINT_FIVE};
  cursor: pointer;
`;
