import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { RoleWithIncludes, Role as BeRole } from '@pccr-ifc/pccr-ifc';
import { useTranslation } from 'react-i18next';

import { PickSomeRestPartial } from '../../../shared/utils/types';
import { RoleUpsert } from '../../models/role';
import { useRoleService } from '../services/use-role-service';
import { Role } from '../../../shared/models/role';
import {
  onCreateResourceMutationSuccess,
  onDeleteResourceMutationSuccess,
  onUpdateResourceMutationSuccess,
} from '../../../shared/utils/mutation';
import { displayErrors } from '../../../shared/utils/error';

export function useCreateRoleMutation() {
  const roleService = useRoleService();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse<RoleWithIncludes>, unknown, Omit<RoleUpsert, 'id'>>(
    role => {
      const request = role as Omit<BeRole, 'id'>;
      return roleService.createRole(request);
    },
    {
      onSuccess(response) {
        onCreateResourceMutationSuccess(queryClient, 'ROLE', response.data);
      },
      onError(error) {
        displayErrors(error, t);
      },
    }
  );
}

export function useUpdateRoleMutation() {
  const roleService = useRoleService();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse<RoleWithIncludes>, unknown, PickSomeRestPartial<RoleUpsert, 'id'>>(
    role => roleService.updateRole(role),
    {
      onSuccess(response, vars) {
        onUpdateResourceMutationSuccess(queryClient, 'ROLE', response.data, vars.id);
      },
      onError(error) {
        displayErrors(error, t);
      },
    }
  );
}

export function useDeleteRoleMutation() {
  const roleService = useRoleService();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse<void>, unknown, Role['id']>(id => roleService.deleteRole(id), {
    onSuccess(_response, id) {
      onDeleteResourceMutationSuccess(queryClient, 'ROLE', id);
    },
    onError(error) {
      displayErrors(error, t);
    },
  });
}
