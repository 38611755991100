import { TableColumnType } from 'antd';
import { TFunction } from 'react-i18next';

import { Feature } from '../../../shared/models/features';
import { Document } from '../../models/document';
import { capitalize } from '../../../shared/utils/string';

export const getColumns = (t: TFunction<Feature.APPROVAL>, infoColumns: string[] = []): TableColumnType<Document>[] => {
  return [
    {
      title: t('shared.pages'),
      render: (_, document) => `${document.fromPage} — ${document.toPage}`,
      width: 90,
    },
    {
      title: t(`${Feature.SHARED}:status`),
      width: 160,
      dataIndex: 'status',
      render: status => t(`${Feature.APPROVAL}:documentsTable.statuses.${status}`),
    },
    {
      title: t('documentsTable.columns.documentId'),
      width: 250,
      dataIndex: 'documentId',
    },
    ...infoColumns.map(key => ({
      dataIndex: ['infos', key],
      title: capitalize(key),
      width: 90,
    })),
  ];
};
