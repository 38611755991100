import { Card, Col, Form, Row, Space } from 'antd';
import { FC, useState } from 'react';
import { useDebounce, useToggle } from 'react-use';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { useTableOptions } from '../../../shared/hooks/use-data-options';
import { Feature } from '../../../shared/models/features';
import {
  DefinitionsTable,
  DefinitionsTableFilters,
  DefinitionsTableFiltersForm,
} from '../../components/definitions-table';
import { translationNamespace } from '../../constants/translation-resources';
import { DefinitionsFilters, DefinitionWithoutColumnTypes } from '../../../shared/models/definition';
import { convertTableFiltersToFilters } from '../../data/converters/definition-converters';
import { Orders } from '../../../shared/constants/order';

import { useFindDefinitionsQuery } from '../../../shared/data/queries/definition-queries';
import { CustomSearch } from '../../../shared/components/search';
import { CustomFilterButton } from '../../../shared/components/filter-button';
import { FixedButton } from '../../../shared/components/fixed-button';
import { permissionSelector } from '../../../shared/data/store/selectors/auth-selectors';

export const DefinitionsListView: FC = () => {
  const { t } = useTranslation(translationNamespace);

  const [filtersForm] = Form.useForm<DefinitionsTableFilters>();
  const { page, setPage, pageSize, order, handleTableChange } = useTableOptions<DefinitionWithoutColumnTypes>([
    ['createdAt', Orders.DESC],
  ]);
  const [showFilters, toggleShowFilters] = useToggle(false);
  const [filters, setFilters] = useState<DefinitionsFilters>(null);
  const [searchValue, setSearchValue] = useState<string>();
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>();
  const { data, isLoading } = useFindDefinitionsQuery({
    page,
    pageSize,
    searchValue: debouncedSearchValue,
    filters,
    order,
  });
  const { url } = useRouteMatch();
  const permissions = useSelector(permissionSelector);

  const applyFilters = (values: DefinitionsTableFilters) => {
    const definitionsFilters = convertTableFiltersToFilters(values);

    setSearchValue(null);
    setDebouncedSearchValue(null);
    setFilters(definitionsFilters);
    setPage(1);
  };

  const applySearch = (value: string) => {
    setFilters(null);
    filtersForm.resetFields();
    setSearchValue(value);
    setDebouncedSearchValue(value);
    setPage(1);
  };

  useDebounce(
    () => {
      if (searchValue != null) {
        setFilters(null);
        filtersForm.resetFields();
        setPage(1);
      }
      setDebouncedSearchValue(searchValue);
    },
    3000,
    [searchValue]
  );

  return (
    <Row>
      <StyledSpace>
        <CustomSearch
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder={t(`${Feature.SHARED}:search`)}
          onSearch={applySearch}
          allowClear
          size="large"
        />
        <CustomFilterButton type="link" onClick={toggleShowFilters}>
          {t(`${Feature.SHARED}:filters`)} <StyledDownOutlined rotate={showFilters ? 180 : 0} />
        </CustomFilterButton>
      </StyledSpace>
      {permissions.DEFINITION.CREATE && (
        <Link to={`${url}/new`}>
          <FixedButton type="primary" size="large">
            {t('definitions.list.new')}
          </FixedButton>
        </Link>
      )}
      {showFilters && (
        <Col span={24} css={marginTop}>
          <Card title={t(`${Feature.SHARED}:filters`)}>
            <DefinitionsTableFiltersForm form={filtersForm} onApply={applyFilters} />
          </Card>
        </Col>
      )}
      <Col span={24} style={{ marginTop: showFilters || isLoading || data?.total === 0 ? '50px' : '0' }}>
        <DefinitionsTable
          dataSource={data?.resources}
          pagination={{
            total: data?.total,
            current: page,
            pageSize,
            showSizeChanger: data?.total > 10,
            position: ['topRight'],
          }}
          loading={isLoading}
          onChange={handleTableChange}
          scroll={{ x: 1200 }}
        />
      </Col>
    </Row>
  );
};

const StyledDownOutlined = styled(DownOutlined)`
  > svg {
    transition: transform 0.2s;
  }
`;

const StyledSpace = styled(Space)`
  position: absolute;
  z-index: 1;

  .ant-space-item {
    height: 38px;
  }
`;

const marginTop = css`
  margin-top: 50px;
`;
