import { FC } from 'react';
import { useStore } from 'react-redux';
import { Switch } from 'react-router-dom';

import { appRoutes } from '../../../core/routes/app-routes';
import { useAuthService } from '../../data/services/use-auth-service';
import { DynamicRoutes } from '../dynamic-routes';

export const AppRoutes: FC = () => {
  const store = useStore();
  const authService = useAuthService();

  return (
    <Switch>
      {DynamicRoutes({ routes: appRoutes, dispatch: store.dispatch, getState: store.getState, authService })}
    </Switch>
  );
};
