import styled from '@emotion/styled';
import { Alert } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { translationNamespace } from '../../constants/translation-resources';

export const DataNotAvailableWarning: FC = () => {
  const { t } = useTranslation(translationNamespace);
  return <StyledAlert message={t('reports.single.dataNotAvailable')} type="warning" />;
};

const StyledAlert = styled(Alert)`
  text-align: center;
  margin-bottom: 20px;
`;
