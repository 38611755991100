import axios from 'axios';
import {
  FindAllSourcesResponse,
  ReportSource as BeSource,
  SourceWithTablesResponse,
  FindAllSourcesRequest,
} from '@pccr-ifc/pccr-ifc';

import { useService } from '../../../shared/hooks/use-service';
import { Config } from '../../../shared/models/config';

function createSourceService(config: Config) {
  const BASE_PATH = '/report/source';

  const findSources = (request: FindAllSourcesRequest) =>
    axios.post<FindAllSourcesResponse>(`${config.API_URL}${BASE_PATH}/findAll`, request, {
      withCredentials: true,
    });

  const getSource = (id: number) =>
    axios.get<SourceWithTablesResponse>(`${config.API_URL}${BASE_PATH}/${id}`, {
      withCredentials: true,
    });

  const createSource = async (request: { source: Omit<BeSource, 'id' | 'status'> }) => {
    const response = await axios.post<{ source: BeSource }>(`${config.API_URL}${BASE_PATH}/add`, request, {
      withCredentials: true,
    });
    return response.data.source;
  };

  const updateSource = async ({ id, ...request }: Partial<BeSource>) => {
    const response = await axios.patch<{ source: BeSource }>(
      `${config.API_URL}${BASE_PATH}/${id}`,
      { source: request },
      {
        withCredentials: true,
      }
    );
    return response.data.source;
  };

  const deleteSource = (id: number) =>
    axios.delete<void>(`${config.API_URL}${BASE_PATH}/${id}`, {
      withCredentials: true,
    });

  const checkSource = (request: { source: Omit<BeSource, 'id' | 'status'> }) =>
    axios.post<void>(`${config.API_URL}${BASE_PATH}/check`, request, {
      withCredentials: true,
    });

  return { findSources, getSource, createSource, updateSource, deleteSource, checkSource };
}

export type SourceService = ReturnType<typeof useSourceService>;

export const useSourceService = () => useService(createSourceService);
