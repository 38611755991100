import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { UserGroupWithIncludes } from '@pccr-ifc/pccr-ifc';
import { useTranslation } from 'react-i18next';

import { convertReassignAllRequest } from '../converters/tenant-converters';
import { useTenantService } from '../services/use-tenant-service';
import { TenantUpsert } from '../../models/tenant';
import { PickSomeRestPartial } from '../../../shared/utils/types';
import { getLogsQueryKeyForTimeline } from '../../../logs/data/queries/log-queries';
import { TenantQueryKey } from '../queries/tenant-queries';
import { Tenant } from '../../../shared/models/tenant';
import {
  onCreateResourceMutationSuccess,
  onDeleteResourceMutationSuccess,
  onUpdateResourceMutationSuccess,
} from '../../../shared/utils/mutation';
import { getSingleResourceQueryKey } from '../../../shared/utils/resource-query-key';
import { displayErrors } from '../../../shared/utils/error';

export function useCreateTenantMutation() {
  const tenantService = useTenantService();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse<UserGroupWithIncludes>, unknown, Omit<TenantUpsert, 'id'>>(
    tenant => {
      const request = { group: tenant };
      return tenantService.createTenant(request);
    },
    {
      onSuccess(response) {
        onCreateResourceMutationSuccess(queryClient, 'TENANT', response.data);
      },
      onError(error) {
        displayErrors(error, t);
      },
    }
  );
}

export function useUpdateTenantMutation() {
  const tenantService = useTenantService();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse<UserGroupWithIncludes>, unknown, PickSomeRestPartial<TenantUpsert, 'id'>>(
    ({ id, ...tenant }) => {
      const request = { group: tenant };
      return tenantService.updateTenant(request, id);
    },
    {
      onSuccess(response, vars) {
        onUpdateResourceMutationSuccess(queryClient, 'TENANT', response.data, vars.id);
        queryClient.invalidateQueries([TenantQueryKey.STATS, vars.id]);
      },
      onError(error) {
        displayErrors(error, t);
      },
    }
  );
}

export function useDeleteTenantMutation() {
  const tenantService = useTenantService();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse<void>, unknown, Tenant['id']>(id => tenantService.deleteTenant(id), {
    onSuccess(_response, id) {
      onDeleteResourceMutationSuccess(queryClient, 'TENANT', id);
    },
    onError(error) {
      displayErrors(error, t);
    },
  });
}

export function useReassignAllFromTenantMutation() {
  const tenantService = useTenantService();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse<void>, unknown, { currentTenantId: Tenant['id']; newTenantId: Tenant['id'] }>(
    ({ currentTenantId, newTenantId }) => {
      const request = convertReassignAllRequest(newTenantId);
      return tenantService.reassignAllFromTenant(request, currentTenantId);
    },
    {
      onSuccess(_response, vars) {
        queryClient.invalidateQueries(getSingleResourceQueryKey('TENANT', vars.currentTenantId));
        queryClient.invalidateQueries(getSingleResourceQueryKey('TENANT', vars.newTenantId));
        queryClient.invalidateQueries([TenantQueryKey.STATS, vars.currentTenantId]);
        queryClient.invalidateQueries([TenantQueryKey.STATS, vars.newTenantId]);
        queryClient.invalidateQueries(getLogsQueryKeyForTimeline('TENANT', vars.currentTenantId));
      },
      onError(error) {
        displayErrors(error, t);
      },
    }
  );
}

export function useDeleteAllFromTenantMutation() {
  const tenantService = useTenantService();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse<void>, unknown, Tenant['id']>(id => tenantService.deleteAllFromTenant(id), {
    onSuccess(_response, id) {
      queryClient.invalidateQueries(getSingleResourceQueryKey('TENANT', id));
      queryClient.invalidateQueries([TenantQueryKey.STATS, id]);
      queryClient.invalidateQueries(getLogsQueryKeyForTimeline('TENANT', id));
    },
    onError(error) {
      displayErrors(error, t);
    },
  });
}
