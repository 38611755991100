import { useQuery } from 'react-query';
import { UserGroupWithIncludes as BeTenant } from '@pccr-ifc/pccr-ifc';
import { useTranslation } from 'react-i18next';

import { CommonFindAllQueryParams, QueryRequest } from '../../../shared/models/api/request';
import { Tenant } from '../../../shared/models/tenant';
import { deleteEmptyArrayObjProps } from '../../../shared/utils/object';
import { TenantsFilters, TenantStats } from '../../models/tenant';
import { convertSearchResponse, convertTenant } from '../converters/tenant-converters';
import { useTenantService } from '../services/use-tenant-service';
import { ResourceSearchResult } from '../../../shared/models/api/response';
import { getResourceListQueryKeyRoot, getSingleResourceQueryKey } from '../../../shared/utils/resource-query-key';
import { displayErrors } from '../../../shared/utils/error';

export enum TenantQueryKey {
  STATS = 'stats',
}

export function useGetTenantQuery(id: number, enabled = true) {
  const tenantService = useTenantService();
  const { t } = useTranslation();

  return useQuery<BeTenant, unknown, Tenant>(
    getSingleResourceQueryKey('TENANT', id),
    async () => {
      const response = await tenantService.getTenant(id);
      return response.data;
    },
    {
      enabled: id != null && enabled,
      select: convertTenant,
      onError: error => displayErrors(error, t),
    }
  );
}

export function useFindTenantsQuery(
  {
    page,
    pageSize,
    searchValue,
    filters,
    order,
    attributes = ['id', 'name', 'groupId', 'createdAt', 'updatedAt'],
  }: CommonFindAllQueryParams<Tenant, TenantsFilters>,
  enabled = true
) {
  const tenantService = useTenantService();

  return useQuery<ResourceSearchResult<Tenant>, unknown>(
    [getResourceListQueryKeyRoot('TENANT'), { page, pageSize }, searchValue, filters, order, attributes],
    async () => {
      const request: QueryRequest<Tenant, TenantsFilters> = {
        numberOfResults: pageSize,
        startRow: (page - 1) * pageSize,
        attributes,
      };

      if (order?.length > 0) {
        request.order = order;
      }

      if (searchValue) {
        request.search = searchValue;
      }

      if (filters && Object.values(filters).some(val => val != null)) {
        request.filters = deleteEmptyArrayObjProps(filters);
      }

      const response = await tenantService.findTenants(request);
      return convertSearchResponse(response.data);
    },
    { enabled }
  );
}

export function useGetTenantStatsQuery(id: number) {
  const tenantService = useTenantService();

  return useQuery<TenantStats, unknown>(
    [TenantQueryKey.STATS, id],
    async () => {
      const response = await tenantService.getTenantStats(id);
      const { definitions, groups, parentGroupId, presets, reports, roles, sources, users, views } = response.data;
      return { definitions, groups, parentGroupId, presets, reports, roles, sources, users, views } as TenantStats;
    },
    {
      enabled: id != null,
    }
  );
}
