import { Button, Card, Col, Form, message, Row, Space } from 'antd';
import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { useToggle } from 'react-use';
import styled from '@emotion/styled';

import { setResourceName } from '../../../shared/data/store/slices/nav-slice';
import { Feature } from '../../../shared/models/features';
import { ResourceParams } from '../../../shared/models/resource-params';
import { PresetUpsertForm, PresetUpsertFormField, PresetUpsertFormValues } from '../../components/preset-upsert-form';
import { translationNamespace } from '../../constants/translation-resources';
import { Preset } from '../../models/preset';
import { useGetPresetQuery } from '../../data/queries/preset-queries';
import { PickSomeRestPartial } from '../../../shared/utils/types';
import { convertFormValuesToPreset } from '../../data/converters/preset-converters';
import { useDeletePresetMutation, useUpdatePresetMutation } from '../../data/mutations/preset-mutations';
import { ConfirmationModal } from '../../../shared/components/confirmation-modal';
import { PresetDetailsDrawer } from '../../components/preset-details-drawer';
import { AppResourcePaths } from '../../../shared/constants/app-resources';
import { returnChangedObjectProperties } from '../../../shared/utils/object';
import { permissionSelector } from '../../../shared/data/store/selectors/auth-selectors';

export const PresetSingleView: FC = () => {
  const { t } = useTranslation(translationNamespace);
  const dispatch = useDispatch();
  const { id } = useParams<ResourceParams>();
  const { push: routerPush } = useHistory();
  const [form] = Form.useForm<PresetUpsertFormValues>();
  const parsedId = Number(id);
  const { data: preset, isSuccess, isLoading: presetLoading, isFetching } = useGetPresetQuery(parsedId);
  const updateMutation = useUpdatePresetMutation();
  const deleteMutation = useDeletePresetMutation();
  const [showDetails, toggleShowDetails] = useToggle(false);
  const [showConfirmDeleteModal, toggleShowConfirmDeleteModal] = useToggle(false);
  const initialPresetValues = useRef<PresetUpsertFormValues>();
  const permissions = useSelector(permissionSelector);

  const areRequestsLoading = presetLoading || updateMutation.isLoading || deleteMutation.isLoading;

  const submit = (values: PresetUpsertFormValues) => {
    const changedProperties = returnChangedObjectProperties(initialPresetValues.current, values);

    if (!changedProperties) {
      message.warning(t(`${Feature.SHARED}:nothingToUpdate`));
      return;
    }

    // BE cannot update single properties of the preset request, that's why everytime we change
    // any of request fields we need to add the rest of the fields to send to BE
    if (changedProperties.search !== undefined || changedProperties.filters !== undefined) {
      changedProperties.reportColumns = values.reportColumns;
    }
    if (changedProperties.reportColumns !== undefined) {
      changedProperties.search = values.search;
      changedProperties.filters = values.filters;
    }

    const newPreset: PickSomeRestPartial<Preset, 'id'> = {
      id: preset.id,
      ...convertFormValuesToPreset(changedProperties),
    };

    updateMutation.mutate(newPreset, {
      onSuccess: () => {
        message.success(t('presets.shared.updateSuccessMessage'));
      },
    });
  };

  const remove = () => {
    if (preset.id == null) {
      return;
    }

    deleteMutation.mutate(preset.id, {
      onSuccess: () => {
        message.success(t('presets.shared.deleteSuccessMessage'));
        routerPush(AppResourcePaths.presets);
      },
    });
  };

  const triggerSubmit = () => {
    form.submit();
  };

  useEffect(() => {
    if (preset) {
      const fieldsValue = {
        [PresetUpsertFormField.NAME]: preset.name,
        [PresetUpsertFormField.DEFINITION]: preset.definitionId,
        [PresetUpsertFormField.REPORT_COLUMNS]: preset.query.attributes,
        [PresetUpsertFormField.SEARCH]: preset.query.search,
        [PresetUpsertFormField.FILTERS]:
          preset.query.filters == null
            ? []
            : Object.entries(preset.query.filters).map(([column, value]) => {
                return {
                  [PresetUpsertFormField.FILTER_COLUMN]: column,
                  [PresetUpsertFormField.FILTER_VALUE]: value,
                };
              }),
      };

      form.setFieldsValue(fieldsValue);
      initialPresetValues.current = fieldsValue;
    }
  }, [form, preset]);

  useEffect(() => {
    dispatch(setResourceName(preset?.name));
  }, [dispatch, preset?.name]);

  return (
    <Row justify="space-between" gutter={[16, 24]}>
      <StyledCol span={24}>
        <Space>
          <Button size="large" onClick={toggleShowDetails} loading={areRequestsLoading} disabled={!isSuccess}>
            {t(`${Feature.SHARED}:details`)}
          </Button>
          {permissions.PRESET.DELETE && (
            <Button
              size="large"
              onClick={toggleShowConfirmDeleteModal}
              loading={areRequestsLoading}
              disabled={preset == null}
              icon={<DeleteOutlined />}
              danger
            />
          )}
        </Space>
      </StyledCol>
      <Col span={24}>
        <Card title={t(`${Feature.SHARED}:details`)}>
          <PresetUpsertForm preset={preset} form={form} onSubmit={submit} />
        </Card>
      </Col>
      <Col>
        <Space>
          {permissions.PRESET.UPDATE && (
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              icon={<CheckOutlined />}
              onClick={triggerSubmit}
              loading={areRequestsLoading || isFetching}
            >
              {t(`${Feature.SHARED}:save`)}
            </Button>
          )}
          <Link to="../presets">
            <Button htmlType="button" size="large">
              {t(`${Feature.SHARED}:cancel`)}
            </Button>
          </Link>
        </Space>
      </Col>
      {preset && <PresetDetailsDrawer preset={preset} open={showDetails} onClose={toggleShowDetails} />}
      <ConfirmationModal
        open={showConfirmDeleteModal}
        title={t(`${Feature.SHARED}:areYouSure`)}
        onCancel={toggleShowConfirmDeleteModal}
        onOk={remove}
        content={t(`${Feature.SHARED}:confirmDeleteMsg`, {
          resourceName: preset?.name,
          buttonTitle: t(`${Feature.SHARED}:confirm`),
        })}
      />
    </Row>
  );
};

const StyledCol = styled(Col)`
  display: flex;
  justify-content: end;
`;
