import { Checkbox, Descriptions, Divider, Drawer, DrawerProps, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { useToggle } from 'react-use';

import { useGetTenantQuery } from '../../../access/data/queries/tenant-queries';
import { useLogsQueryForTimeline } from '../../../logs/data/queries/log-queries';
import { LogsTimeline } from '../../../shared/components/logs-timeline';
import { UserName } from '../../../shared/components/user-name';
import { Definition } from '../../../shared/models/definition';
import { Feature } from '../../../shared/models/features';
import { formatISODate } from '../../../shared/utils/date';
import { getLanguageFromI18nLanguage } from '../../../shared/utils/language';
import { translationNamespace } from '../../constants/translation-resources';
import { useGetSourceQuery } from '../../data/queries/source-queries';
import { getDefinitionStatusTagColor } from '../../utils/color-mappers';
import { LogsFilters } from '../../../logs/models/log';

interface DefinitionDetailsDrawerProps extends DrawerProps {
  definition: Definition;
}

export const DefinitionDetailsDrawer = ({ definition, ...props }: DefinitionDetailsDrawerProps) => {
  const { t, i18n } = useTranslation(translationNamespace);
  const [includeGetLogs, toggleIncludeGetLogs] = useToggle(false);
  const filters: LogsFilters = {
    entityType: 'definition',
    resourceId: [definition.id],
    method: includeGetLogs ? undefined : ['PATCH', 'POST', 'PUT', 'DELETE'],
  };
  const logQueryResult = useLogsQueryForTimeline(filters, { enabled: props.open });

  const { data: sourceRes } = useGetSourceQuery(definition?.sourceId, props.open);
  const { data: tenant } = useGetTenantQuery(definition?.groupId, props.open);

  return (
    <Drawer {...props} title={t(`${Feature.SHARED}:details`)} width={400} placement="right" destroyOnClose>
      <Descriptions column={1} bordered css={withMarginBottom}>
        <Descriptions.Item label={t(`${Feature.SHARED}:name`)}>{definition.name}</Descriptions.Item>
        <Descriptions.Item label={t('shared.source.label')}>{sourceRes?.source.name ?? ''}</Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:tenant.label`)}>{tenant?.name ?? ''}</Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:type`)}>{definition.type}</Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:status`)}>
          {definition.status ? (
            <Tag color={getDefinitionStatusTagColor(definition.status)}>
              {t(`shared.status.${definition.status.toLowerCase()}`)}
            </Tag>
          ) : (
            ''
          )}
        </Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:error`)}>{definition.error}</Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:createdAt`)}>
          {definition.createdAt ? formatISODate(definition.createdAt, getLanguageFromI18nLanguage(i18n.language)) : ''}
        </Descriptions.Item>

        <Descriptions.Item label={t(`${Feature.SHARED}:createdBy`)}>
          <UserName userId={logQueryResult.isLoading ? undefined : definition.createdBy} />
        </Descriptions.Item>

        <Descriptions.Item label={t(`${Feature.SHARED}:updatedAt`)}>
          {definition.updatedAt ? formatISODate(definition.updatedAt, getLanguageFromI18nLanguage(i18n.language)) : ''}
        </Descriptions.Item>

        <Descriptions.Item label={t(`${Feature.SHARED}:updatedBy`)}>
          <UserName userId={logQueryResult.isLoading ? undefined : definition.createdBy} />
        </Descriptions.Item>
      </Descriptions>

      <Divider>{t(`${Feature.SHARED}:log`)}</Divider>
      <Checkbox checked={includeGetLogs} onChange={toggleIncludeGetLogs} css={withMarginBottom}>
        {t(`${Feature.SHARED}:includeGetLogs`)}
      </Checkbox>
      <LogsTimeline queryResult={logQueryResult} />
    </Drawer>
  );
};

const withMarginBottom = css`
  margin-bottom: 32px;
`;
