import { FC } from 'react';

interface ConditionalWrapperProps {
  condition: boolean;
  wrapper: (children: React.ReactNode) => React.ReactElement;
}

export const ConditionalWrapper: FC<ConditionalWrapperProps> = ({ children, condition, wrapper }) => {
  return condition ? wrapper(children) : <>{children}</>;
};
