import { Skeleton } from 'antd';

import { User } from '../../models/user';
import { useGetUserNameQuery } from '../../../access/data/queries/user-queries';

export type UserIdentifierProps = {
  user?: User;
  userId?: User['id'];
};

export const UserName = ({ user, userId }: UserIdentifierProps): JSX.Element => {
  const { data, isLoading, isError } = useGetUserNameQuery(user ? undefined : userId);

  if (user == null && userId == null) return <></>;

  const userData = user ?? data;

  if (userData && (user || !isError)) {
    const { firstName, lastName, username, email } = userData;
    const name = firstName || lastName ? `${firstName ?? ''} ${lastName ?? ''}`.trim() : username || email;

    if (name) return <>{name}</>;
  }

  return <Skeleton paragraph={false} active={isLoading} />;
};
