import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Feature } from '../../../models/features';

export type LoaderRequiredConditions = 'user';

interface LoaderState {
  visible: boolean;
  loadedConditions: Record<LoaderRequiredConditions, boolean>;
}

const initialState: LoaderState = {
  visible: true,
  loadedConditions: {
    user: false,
  },
};

export interface LoaderRequiredStateChange {
  condition: LoaderRequiredConditions;
  completed: boolean;
}

const slice = createSlice({
  name: `${Feature.SHARED}/loader`,
  initialState,
  reducers: {
    changeRequiredCondition(state, action: PayloadAction<LoaderRequiredStateChange>) {
      state.loadedConditions[action.payload.condition] = action.payload.completed;

      // Check whether all states already completed
      const allConditionsLoaded = Object.keys(state.loadedConditions).every(
        condition => state.loadedConditions[condition as LoaderRequiredConditions] === true
      );

      if (allConditionsLoaded === true) {
        state.visible = false;
      }
    },
  },
});

export const { changeRequiredCondition } = slice.actions;
export const loaderSlice = slice.reducer;
