import { Route } from '../../shared/models/route';
import { translationNamespace } from '../constants/translation-resources';
import { BatchesListView } from '../views/batches-list';
import batchesIcon from '../assets/images/menu-items/icon_acknowledge.svg';

export const approvalRoutes: Route = {
  path: '/documents',
  nameTranslationKey: `${translationNamespace}:navigation.approval`,
  displayInSideNavigation: true,
  icon: batchesIcon,
  view: BatchesListView,
  meta: {
    requiredPermission: ['BATCH', 'READ'],
  },
};
