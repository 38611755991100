import { SorterResult, SortOrder } from 'antd/lib/table/interface';
import { Order } from '../models/order';
import { Orders } from '../constants/order';

export function convertAntdOrderToOrder(order: SortOrder): Order {
  switch (order) {
    case 'ascend':
      return Orders.ASC;
    case 'descend':
      return Orders.DESC;
    default:
      return null;
  }
}

export function convertOrderToAntdOrder(order: Order): SortOrder {
  switch (order) {
    case Orders.ASC:
      return 'ascend';
    case Orders.DESC:
      return 'descend';
    default:
      return null;
  }
}

export function convertSorterResultToRequestOrder<T>(sorterResult: SorterResult<T>): [keyof T, Order] {
  // This convertion assumes dataIndex is 1-level attribute (object T's prop) and falls back to columnKey
  return [
    (sorterResult.column.dataIndex?.toString() ?? sorterResult.columnKey.toString()) as keyof T,
    convertAntdOrderToOrder(sorterResult.order),
  ];
}

export function convertSorterResultsToRequestOrder<T>(
  sorterResults: SorterResult<T> | SorterResult<T>[]
): [keyof T, Order][] {
  if (Array.isArray(sorterResults)) {
    return sorterResults
      .filter(result => result.order != null)
      .map(result => {
        return convertSorterResultToRequestOrder(result);
      });
  }

  return sorterResults.order == null ? [] : [convertSorterResultToRequestOrder(sorterResults)];
}
