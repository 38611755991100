import { FindAllGroupsResponse, UserGroupWithIncludes as BeTenant } from '@pccr-ifc/pccr-ifc';

import { Tenant } from '../../../shared/models/tenant';
import { convertDateRangeToISO } from '../../../shared/utils/date';
import { TenantUpsertFormValues } from '../../components/tenant-upsert-form';
import { TenantsTableFilters } from '../../components/tenants-table';
import { TenantsFilters, TenantUpsert } from '../../models/tenant';
import { ResourceSearchResult } from '../../../shared/models/api/response';
import { convertUser } from './user-converters';

export function convertTenant(beTenant: BeTenant): Tenant {
  return {
    id: beTenant.id,
    name: beTenant.name,
    groupId: beTenant.groupId,
    users: beTenant.users?.map(convertUser),
    groups: beTenant.groups?.map(convertTenant),
    customerId: String(beTenant.customerId),
    createdAt: beTenant.createdAt as string,
    updatedAt: beTenant.updatedAt as string,
    createdBy: beTenant.createdBy,
    updatedBy: beTenant.updatedBy,
  };
}

export function convertSearchResponse(response: FindAllGroupsResponse): ResourceSearchResult<Tenant> {
  return {
    total: response.total,
    resources: response.groups.map(convertTenant),
  };
}

export function convertFormValuesToTenantUpsert(values: Partial<TenantUpsertFormValues>): Omit<TenantUpsert, 'id'> {
  return {
    name: values.name,
    users: values.users,
    groupId: values.parent,
    customerId: values.customerId === undefined ? undefined : Number(values.customerId),
  };
}

export function convertTableFiltersToFilters(tableFilters: TenantsTableFilters): TenantsFilters {
  const tenantsFilters: TenantsFilters = { name: tableFilters.name };

  if (tableFilters.createdAt != null) {
    tenantsFilters.createdAt = convertDateRangeToISO(tableFilters.createdAt);
  }
  if (tableFilters.updatedAt != null) {
    tenantsFilters.updatedAt = convertDateRangeToISO(tableFilters.updatedAt);
  }

  return tenantsFilters;
}

export function convertReassignAllRequest(newTenantId: number): { newGroupId: number } {
  return {
    newGroupId: newTenantId,
  };
}
