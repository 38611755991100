import { FindAllUsersResponse, UserWithIncludes as BeUser } from '@pccr-ifc/pccr-ifc';

import { User } from '../../../shared/models/user';
import { convertDateRangeToISO } from '../../../shared/utils/date';
import { convertEmptyStringsToUndefined, deleteEmptyArrayObjProps } from '../../../shared/utils/object';
import { UserUpsertFormValues } from '../../components/user-upsert-form';
import { UsersTableFilters } from '../../components/users-table';
import { superAdmin } from '../../constants/roles';
import { UsersFilters, UserUpsert } from '../../models/user';
import { ResourceSearchResult } from '../../../shared/models/api/response';
import { convertRole } from './role-converters';
import { convertTenant } from './tenant-converters';

export function convertUser(beUser: BeUser): User {
  return {
    id: beUser.id,
    isAdmin: beUser.isAdmin,
    email: beUser.email,
    username: beUser.username,
    firstName: beUser.firstName,
    middleName: beUser.middleName,
    lastName: beUser.lastName,
    description: beUser.description,
    mobilePhone: beUser.mobilePhone,
    groups: beUser.groups?.map(group => convertTenant(group)),
    roles: beUser.roles?.map(convertRole),
    status: beUser.status,
    createdAt: beUser.createdAt as string,
    updatedAt: beUser.updatedAt as string,
    createdBy: beUser.createdBy,
    updatedBy: beUser.updatedBy,
  };
}

export function convertSearchResponse(response: FindAllUsersResponse): ResourceSearchResult<User> {
  return {
    total: response.total,
    resources: response.users.map(convertUser),
  };
}

export function convertFilters(userFilters: UsersFilters): UsersFilters {
  const result: UsersFilters = {
    id: userFilters.id,
    isAdmin: userFilters.isAdmin,
    firstName: userFilters.firstName,
    lastName: userFilters.lastName,
    username: userFilters.username,
    status: userFilters.status,
    email: userFilters.email,
    mobilePhone: userFilters.mobilePhone,
    createdAt: userFilters.createdAt,
    updatedAt: userFilters.updatedAt,
  };

  const cleanResult = deleteEmptyArrayObjProps(result);

  return Object.values(cleanResult).some(val => val != null) ? cleanResult : null;
}

export function convertFormValuesToUser(values: Partial<UserUpsertFormValues>): Omit<UserUpsert, 'id'> {
  const user = {
    email: values.email,
    username: values.username,
    password: values.password,
    firstName: values.firstName,
    middleName: values.middleName,
    lastName: values.lastName,
    description: values.description,
    mobilePhone: values.mobilePhone,
    groups: values.tenants,
    roles: [...(values.roles || []), ...(values.defaultRoles || [])],
  };

  return convertEmptyStringsToUndefined(user);
}

export function convertTableFiltersToFilters(tableFilters: UsersTableFilters): UsersFilters {
  const usersFilters: UsersFilters = {
    username: tableFilters.username,
    email: tableFilters.email,
    status: tableFilters.status,
    firstName: tableFilters.firstName,
    lastName: tableFilters.lastName,
    mobilePhone: tableFilters.mobilePhone,
  };

  if (tableFilters.createdAt != null) {
    usersFilters.createdAt = convertDateRangeToISO(tableFilters.createdAt);
  }
  if (tableFilters.updatedAt != null) {
    usersFilters.updatedAt = convertDateRangeToISO(tableFilters.updatedAt);
  }

  if (tableFilters.rolesNames?.length > 0) {
    usersFilters.rolesNames = [...tableFilters.rolesNames];
    const superAdminIndex = tableFilters.rolesNames.findIndex(roleName => roleName === superAdmin);
    if (superAdminIndex > -1) {
      usersFilters.isAdmin = true;
      usersFilters.rolesNames.splice(superAdminIndex, 1);
    }
  }

  return usersFilters;
}
