import { TagProps } from 'antd';

import { DefinitionStatus } from '../../shared/models/definition';
import { Dialect } from '../models/dialect';
import { SourceStatus } from '../models/source';
import { ViewStatus } from '../models/view';
import { DefinitionStatuses } from '../../shared/constants/definition';
import { Dialects } from '../constants/dialect';
import { SourceStatuses } from '../constants/source';
import { ViewStatuses } from '../constants/view';
import { ReportStatus } from '../models/report';
import { ReportStatuses } from '../constants/report';
import { colors } from '../../shared/constants/styles';

const DialectsColors = {
  [Dialects.POSTGRES]: '#85406b',
  [Dialects.MSSQL]: '#595959',
  [Dialects.MYSQL]: '#0c7ab8',
};

export const getDialectTagColor = (dialect: Dialect): TagProps['color'] => {
  return DialectsColors[dialect] ?? 'grey';
};

export const getDefinitionStatusTagColor = (status: DefinitionStatus): TagProps['color'] => {
  switch (status) {
    case DefinitionStatuses.VALID:
      return colors.green;
    case DefinitionStatuses.INVALID:
      return colors.orange;
    default:
      return 'grey';
  }
};

export const getViewStatusTagColor = (status: ViewStatus): TagProps['color'] => {
  switch (status) {
    case ViewStatuses.VALID:
      return 'green';
    case ViewStatuses.INVALID:
      return 'red';
    default:
      return 'default';
  }
};

export const getSourceStatusTagColor = (status: SourceStatus): TagProps['color'] => {
  switch (status) {
    case SourceStatuses.VALID:
      return colors.green;
    case SourceStatuses.INVALID:
      return colors.orange;
    default:
      return 'grey';
  }
};

export const getReportStatusTagColor = (status: ReportStatus) => {
  switch (status) {
    case ReportStatuses.STARTED:
      return 'blue';
    case ReportStatuses.FINISHED:
      return 'green';
    case ReportStatuses.FAILED:
    case ReportStatuses.ERROR:
      return 'red';
    case ReportStatuses.CANCELLED:
    case ReportStatuses.DELETING:
      return 'yellow';

    default:
      return 'default';
  }
};

export const getReportStatusCustomTagColor = (status: string) => {
  switch (status) {
    case ReportStatuses.STARTED:
    case ReportStatuses.QUEUED:
      return colors.skyBlue;
    case ReportStatuses.FINISHED:
      return colors.green;
    case ReportStatuses.FAILED:
    case ReportStatuses.ERROR:
      return colors.orange;
    case ReportStatuses.CANCELLED:
    case ReportStatuses.DELETING:
      return colors.failedGrey;
    default:
      return 'grey';
  }
};
