import { Button, Dropdown, message } from 'antd';
import { MenuProps } from 'antd';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import { useToggle } from 'react-use';

import { ConfirmationModal } from '../../../shared/components/confirmation-modal';
import { Feature } from '../../../shared/models/features';
import { translationNamespace } from '../../constants/translation-resources';
import { useDeleteViewMutation } from '../../data/mutations/view-mutations';
import { View } from '../../models/view';
import { displayErrors } from '../../../shared/utils/error';

interface ActionsProps {
  view: View;
}

export const Actions: FC<ActionsProps> = ({ view }) => {
  const { t } = useTranslation(translationNamespace);
  const deleteMutation = useDeleteViewMutation();
  const [showConfirmDeleteModal, toggleShowConfirmDeleteModal] = useToggle(false);

  const remove = useCallback(() => {
    deleteMutation.mutate(
      { id: view.id },
      {
        onSuccess: () => {
          message.success(t('views.shared.deleteSuccessMessage'));
          toggleShowConfirmDeleteModal();
        },
        onError: error => displayErrors(error, t),
      }
    );
  }, [deleteMutation, t, view.id, toggleShowConfirmDeleteModal]);

  const items: MenuProps['items'] = [
    {
      key: 'edit',
      label: <Link to={`views/${view.id}`}>{t(`${Feature.SHARED}:edit`)}</Link>,
    },
    {
      key: 'remove',
      onClick: () => toggleShowConfirmDeleteModal(),
      label: t(`${Feature.SHARED}:remove`),
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }} trigger={['click']}>
        <Button type="link" icon={<MenuOutlined />}>
          {t(`${Feature.SHARED}:actions`)}
        </Button>
      </Dropdown>
      <ConfirmationModal
        open={showConfirmDeleteModal}
        title={t(`${Feature.SHARED}:areYouSure`)}
        onCancel={toggleShowConfirmDeleteModal}
        onOk={remove}
        content={t(`${Feature.SHARED}:confirmDeleteMsg`, {
          resourceName: view.name,
          buttonTitle: t(`${Feature.SHARED}:confirm`),
        })}
      />
    </>
  );
};
