import { createSelector } from 'reselect';
import { sharedSelector } from './base-selector';

export const authSelector = createSelector(sharedSelector, state => state.auth);
export const permissionSelector = createSelector(sharedSelector, state => state.permission);
export const userSelector = createSelector(authSelector, state => state.user);
export const loggedInSelector = createSelector(authSelector, state => state.loggedIn);
export const registerLoadingSelector = createSelector(authSelector, state => state.register.loading);
export const verifyEmailLoadingSelector = createSelector(authSelector, state => state.verifyEmail.loading);
export const requestPasswordChangeLoadingSelector = createSelector(
  authSelector,
  state => state.requestPasswordChange.loading
);
export const changePasswordLoadingSelector = createSelector(authSelector, state => state.changePassword.loading);
