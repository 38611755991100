import axios from 'axios';
import { User as BeUser, ChangePasswordRequest } from '@pccr-ifc/pccr-ifc';

import { Config } from '../../../shared/models/config';
import { useService } from '../../../shared/hooks/use-service';

function createUserProfileService(config: Config) {
  const EDIT_SELF_PATH = '/user/edit/own';
  const AUTH_PATH = '/auth';

  const updateUserProfile = async (request: { user: Partial<BeUser> }) => {
    const response = await axios.patch<{ user: BeUser }>(`${config.API_URL}${EDIT_SELF_PATH}`, request, {
      withCredentials: true,
    });
    return response.data.user;
  };

  const userProfileChangePassword = (request: ChangePasswordRequest) =>
    axios.post<void>(`${config.API_URL}${AUTH_PATH}/changePassword`, request, {
      withCredentials: true,
    });

  return { updateUserProfile, userProfileChangePassword };
}

export const useUserProfileService = () => useService(createUserProfileService);
