import { Button, Col, Form, FormInstance, Row, Select } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';

import { Feature } from '../../../shared/models/features';
import { translationNamespace } from '../../constants/translation-resources';
import { RangePicker } from '../../../shared/components/date-picker/date-picker';

enum ReportsTableFilter {
  NAME = 'name',
  DEFINITION_NAME = 'definitionName',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
}

export interface ReportsTableFilters {
  [ReportsTableFilter.NAME]: string[];
  [ReportsTableFilter.DEFINITION_NAME]: string[];
  [ReportsTableFilter.CREATED_AT]: [Date?, Date?];
  [ReportsTableFilter.UPDATED_AT]: [Date?, Date?];
}

interface ReportsTableFiltersFormProps {
  onApply?: (filters: ReportsTableFilters) => void;
  form: FormInstance<ReportsTableFilters>;
}

export const ReportsTableFiltersForm: FC<ReportsTableFiltersFormProps> = ({ onApply, form }) => {
  const { t } = useTranslation(translationNamespace);

  const onClear = () => {
    form.resetFields();
    onApply?.(form.getFieldsValue());
  };

  return (
    <Form form={form} layout="vertical" onFinish={onApply}>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item label={t(`${Feature.SHARED}:name`)} name={ReportsTableFilter.NAME}>
            <Select suffixIcon={null} size="large" mode="tags" allowClear open={false} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label={t(`${Feature.SHARED}:definition.label`)} name={ReportsTableFilter.DEFINITION_NAME}>
            <Select suffixIcon={null} size="large" mode="tags" allowClear open={false} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label={t(`${Feature.SHARED}:createdAt`)} name={ReportsTableFilter.CREATED_AT}>
            <RangePicker css={fullWidthStyles} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label={t(`${Feature.SHARED}:updatedAt`)} name={ReportsTableFilter.UPDATED_AT}>
            <RangePicker css={fullWidthStyles} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <Button type="primary" htmlType="submit" size="large" icon={<CheckOutlined />}>
            {t(`${Feature.SHARED}:apply`)}
          </Button>
        </Col>
        <Col>
          <Button htmlType="button" size="large" onClick={onClear}>
            {t(`${Feature.SHARED}:clear`)}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const fullWidthStyles = css`
  width: 100%;
  border-radius: 0;
`;
