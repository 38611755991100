import { FindAllDocumentsRequest, GetFilterResponse } from '@pccr-ifc/pccr-ifc';

import { cleanObject, isNonEmptyObject } from '../../../shared/utils/object';
import { BeDocumentFilters, DocumentsFilters } from '../../models/batch';
import { Values } from '../../../shared/utils/types';
import { DocumentFiltersType } from '../../constants/documents';

type BeDocumentFiltersValues = Values<BeDocumentFilters>;

function returnFilterBasedOnFilterType(
  tableFilters: DocumentsFilters,
  columnName: string,
  filterType: DocumentFiltersType
): BeDocumentFiltersValues {
  if (filterType === 'string' || Array.isArray(filterType)) return tableFilters[columnName] as string;

  if (filterType === 'dateRange') {
    const from = tableFilters[columnName]?.[0] as Date;
    const to = tableFilters[columnName]?.[1] as Date;
    if (from !== undefined || to !== undefined) return { from, to };
  }

  if (filterType === 'integerRange') {
    const from =
      tableFilters[`from_${columnName}`] === undefined ? undefined : Number(tableFilters[`from_${columnName}`]);
    const to = tableFilters[`to_${columnName}`] === undefined ? undefined : Number(tableFilters[`to_${columnName}`]);
    if (from !== undefined || to !== undefined) return { to, from };
  }
}

export function convertDocumentsFilters(
  tableFilters: DocumentsFilters,
  filtersColumns: GetFilterResponse['filter']['columns']
) {
  const clearedTableFilters = cleanObject(tableFilters);
  const documentsFilters: FindAllDocumentsRequest['filter'] = {};

  for (const key in filtersColumns) {
    documentsFilters[key] = returnFilterBasedOnFilterType(clearedTableFilters, key, filtersColumns[key]);
  }

  const clearedFilters = cleanObject(documentsFilters);
  return isNonEmptyObject(clearedFilters) ? clearedFilters : undefined;
}
