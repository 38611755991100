import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Feature } from '../../../models/features';
import { Layout } from '../../../models/layout';

interface LayoutState {
  currentLayout: Layout;
}

const initialState: LayoutState = {
  currentLayout: Layout.SIDE_NAVIGATION,
};

const slice = createSlice({
  name: `${Feature.SHARED}/layout`,
  initialState,
  reducers: {
    changeLayout(state, action: PayloadAction<Layout>) {
      state.currentLayout = action.payload;
    },
  },
});

export const { changeLayout } = slice.actions;
export const layoutSlice = slice.reducer;
