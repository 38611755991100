import { Button, Card, Col, Form, message, Row, Space } from 'antd';
import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { useToggle } from 'react-use';
import styled from '@emotion/styled';

import { ConfirmationModal } from '../../../shared/components/confirmation-modal';
import { setResourceName } from '../../../shared/data/store/slices/nav-slice';
import { Feature } from '../../../shared/models/features';
import { Policy } from '../../../shared/models/policy';
import { ResourceParams } from '../../../shared/models/resource-params';
import { PickSomeRestPartial } from '../../../shared/utils/types';
import { PolicyDetailsDrawer } from '../../components/policy-details-drawer';
import { PolicyUpsertForm, PolicyUpsertFormField, PolicyUpsertFormValues } from '../../components/policy-upsert-form';
import { translationNamespace } from '../../constants/translation-resources';
import { useDeletePolicyMutation, useUpdatePolicyMutation } from '../../data/mutations/policy-mutations';
import { useGetPolicyQuery } from '../../data/queries/policy-queries';
import { AppResourcePaths } from '../../../shared/constants/app-resources';
import { returnChangedObjectProperties } from '../../../shared/utils/object';
import { permissionSelector } from '../../../shared/data/store/selectors/auth-selectors';

export const PolicySingleView: FC = () => {
  const { t } = useTranslation(translationNamespace);
  const dispatch = useDispatch();
  const { push: routerPush } = useHistory();
  const { id } = useParams<ResourceParams>();
  const [form] = Form.useForm<PolicyUpsertFormValues>();
  const [showDetails, toggleShowDetails] = useToggle(false);
  const [showConfirmDeleteModal, toggleShowConfirmDeleteModal] = useToggle(false);
  const parsedId = Number(id);
  const { data: policy, isSuccess, isLoading, isFetching } = useGetPolicyQuery(parsedId);
  const updateMutation = useUpdatePolicyMutation();
  const deleteMutation = useDeletePolicyMutation();
  const initialPolicyValues = useRef<PolicyUpsertFormValues>();
  const permissions = useSelector(permissionSelector);

  const areRequestsLoading = isLoading || updateMutation.isLoading || deleteMutation.isLoading;

  const submit = (values: PolicyUpsertFormValues) => {
    if (policy?.id == null) {
      return;
    }

    const changedProperties = returnChangedObjectProperties(initialPolicyValues.current, values);

    if (!changedProperties) {
      message.warning(t(`${Feature.SHARED}:nothingToUpdate`));
      return;
    }

    const newPolicy: PickSomeRestPartial<Policy, 'id'> = {
      id: policy.id,
      ...changedProperties,
    };

    updateMutation.mutate(newPolicy, {
      onSuccess: () => {
        message.success(t('policies.shared.updateSuccessMessage'));
      },
    });
  };

  const remove = () => {
    if (policy.id == null) {
      return;
    }

    deleteMutation.mutate(policy.id, {
      onSuccess: () => {
        message.success(t('policies.shared.deleteSuccessMessage'));
        routerPush(AppResourcePaths.policies);
      },
    });
  };

  const triggerSubmit = () => {
    form.submit();
  };

  useEffect(() => {
    if (policy) {
      const policyUpsertFormFieldValues = {
        [PolicyUpsertFormField.NAME]: policy.name,
        [PolicyUpsertFormField.PATH]: policy.path,
        [PolicyUpsertFormField.METHOD]: policy.method,
        [PolicyUpsertFormField.REQUEST]: policy.request,
      };

      form.setFieldsValue(policyUpsertFormFieldValues);
      initialPolicyValues.current = policyUpsertFormFieldValues;
    }
  }, [form, policy]);

  useEffect(() => {
    dispatch(setResourceName(policy?.name));
  }, [dispatch, policy?.name]);

  return (
    <Row justify="space-between" gutter={[16, 24]}>
      <StyledCol span={24}>
        <Space>
          <Button size="large" onClick={toggleShowDetails} loading={areRequestsLoading} disabled={!isSuccess}>
            {t(`${Feature.SHARED}:details`)}
          </Button>
          {permissions.POLICY.DELETE && (
            <Button
              size="large"
              onClick={toggleShowConfirmDeleteModal}
              loading={areRequestsLoading}
              disabled={policy == null}
              icon={<DeleteOutlined />}
              danger
            />
          )}
        </Space>
      </StyledCol>
      <Col span={24}>
        <Card title={t(`${Feature.SHARED}:details`)}>
          <PolicyUpsertForm form={form} onSubmit={submit} />
        </Card>
      </Col>
      <Col>
        <Space>
          {permissions.POLICY.UPDATE && (
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              icon={<CheckOutlined />}
              loading={areRequestsLoading || isFetching}
              onClick={triggerSubmit}
              disabled={!policy}
            >
              {t(`${Feature.SHARED}:save`)}
            </Button>
          )}
          <Link to={AppResourcePaths.policies}>
            <Button htmlType="button" size="large">
              {t(`${Feature.SHARED}:cancel`)}
            </Button>
          </Link>
        </Space>
      </Col>

      {policy && <PolicyDetailsDrawer policy={policy} open={showDetails} onClose={toggleShowDetails} />}

      <ConfirmationModal
        open={showConfirmDeleteModal}
        title={t(`${Feature.SHARED}:areYouSure`)}
        onCancel={toggleShowConfirmDeleteModal}
        onOk={remove}
        content={t(`${Feature.SHARED}:confirmDeleteMsg`, {
          resourceName: policy?.name,
          buttonTitle: t(`${Feature.SHARED}:confirm`),
        })}
      />
    </Row>
  );
};

const StyledCol = styled(Col)`
  display: flex;
  justify-content: end;
`;
