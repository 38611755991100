import { Input } from 'antd';
import type { SearchProps } from 'antd/es/input/Search';
import { FC } from 'react';
import styled from '@emotion/styled';
import { SearchOutlined } from '@ant-design/icons';

import { border } from '../../constants/styles';

const { Search } = Input;

export const CustomSearch: FC<SearchProps> = ({ ...props }) => {
  return (
    <StyledSearch
      prefix={<SearchOutlined style={{ opacity: '0.2' }} />}
      enterButton={false}
      allowClear
      size="large"
      {...props}
    />
  );
};

const StyledSearch = styled(Search)`
  width: 290px;

  .ant-input-affix-wrapper {
    padding: 6px 11px;
    border: ${border('primary')};
  }

  .ant-input-group-addon {
    display: none;
  }
`;
