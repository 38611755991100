import { ColProps, Divider, Empty, Form, FormInstance, Input, Radio, Select } from 'antd';
import { Rule } from 'rc-field-form/lib/interface';
import { FC, useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Feature } from '../../../shared/models/features';
import { Method } from '../../../shared/models/method';
import { getLanguageFromI18nLanguage } from '../../../shared/utils/language';
import { JobTasks, jobTasksMap } from '../../constants/job-tasks';
import { translationNamespace } from '../../constants/translation-resources';
import { convertJobRequestToTask } from '../../data/converters/job-converters';
import { Job } from '../../models/job';
import { JobRequestService, JobTask } from '../../models/job-task';
import { cronValidator, formatCron, isValidCRON } from '../../utils/cron';
import { redundantSpacebarsRule } from '../../../shared/utils/form-rules';

export enum JobUpsertFormField {
  NAME = 'name',
  CRON = 'cron',
  ENABLED = 'enabled',
  OVERWRITABLE = 'overwritable',
  TASK = 'task',
  REQUEST = 'request',
  REQUEST_SERVICE = 'service',
  REQUEST_PATH = 'path',
  REQUEST_METHOD = 'method',
  REQUEST_HEADERS = 'headers',
  REQUEST_BODY = 'body',
}

export interface JobUpsertFormRequestValues {
  [JobUpsertFormField.REQUEST_SERVICE]: JobRequestService;
  [JobUpsertFormField.REQUEST_PATH]: string;
  [JobUpsertFormField.REQUEST_METHOD]: Method;
  [JobUpsertFormField.REQUEST_HEADERS]: Record<string, string>;
  [JobUpsertFormField.REQUEST_BODY]: unknown;
}

export interface JobUpsertFormValues {
  [JobUpsertFormField.NAME]: string;
  [JobUpsertFormField.CRON]: string;
  [JobUpsertFormField.ENABLED]: boolean;
  [JobUpsertFormField.OVERWRITABLE]: boolean;
  [JobUpsertFormField.TASK]: JobTask;
  [JobUpsertFormField.REQUEST]: JobUpsertFormRequestValues;
}

interface Props {
  form: FormInstance<JobUpsertFormValues>;
  onSubmit?: (values: JobUpsertFormValues) => void;
  job?: Job;
  task?: JobTask;
  isNew?: boolean;
}

export const JobUpsertForm: FC<Props> = ({ form, onSubmit, job, task = null, isNew }) => {
  const { t, i18n } = useTranslation(translationNamespace);
  const [cron, setCron] = useState<string>(job?.cron);
  const [selectedTask, setSelectedTask] = useState<JobTask>(task);

  const cronValdiator: Rule = useMemo(() => {
    return { validator: (...args) => cronValidator(t, ...args) };
  }, [t]);

  const formattedCron = useMemo(() => {
    if (cron && isValidCRON(cron)) {
      return formatCron(cron, getLanguageFromI18nLanguage(i18n.language));
    }

    return '';
  }, [cron, i18n.language]);

  const TaskForm = useMemo(() => {
    if (selectedTask == null) {
      return null;
    }

    return jobTasksMap[selectedTask].component;
  }, [selectedTask]);

  const taskTemplate = useMemo(() => {
    if (selectedTask == null) {
      return null;
    }

    return jobTasksMap[selectedTask].template;
  }, [selectedTask]);

  const setTaskFieldsValue = useCallback(
    (values: Record<string | number, unknown>) => {
      if (values == null) {
        return;
      }

      const vals = {
        [JobUpsertFormField.REQUEST]: {
          [JobUpsertFormField.REQUEST_BODY]: {
            ...values,
          },
        },
      };

      form.setFieldsValue(vals);
    },
    [form]
  );

  useEffect(() => {
    if (taskTemplate == null) {
      return;
    }

    form.setFieldsValue({
      [JobUpsertFormField.REQUEST]: {
        ...taskTemplate,
      },
    });
  }, [form, taskTemplate]);

  useEffect(() => {
    if (job?.request == null) {
      return;
    }

    setSelectedTask(convertJobRequestToTask(job.request));
  }, [job]);

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      layout="horizontal"
      labelCol={{ lg: 4, xl: 4 }}
      wrapperCol={{ lg: 16, xl: 10 }}
      requiredMark={false}
    >
      <Form.Item
        label={t(`${Feature.SHARED}:name`)}
        name={JobUpsertFormField.NAME}
        rules={[{ required: true, max: 180 }, redundantSpacebarsRule(t)]}
      >
        <Input placeholder={t('jobs.upsert.name.placeholder')} />
      </Form.Item>
      <Form.Item
        label={t('jobs.shared.schedule')}
        name={JobUpsertFormField.CRON}
        extra={formattedCron}
        rules={[{ required: true }, cronValdiator]}
      >
        <Input placeholder={t('jobs.shared.cron.placeholder')} onChange={e => setCron(e.target.value)} />
      </Form.Item>
      <Form.Item
        label={t('shared.enabled')}
        name={JobUpsertFormField.ENABLED}
        rules={[{ required: true }]}
        initialValue={true}
      >
        <Radio.Group size="large">
          <Radio value={true}>{t(`${Feature.SHARED}:yes`)}</Radio>
          <Radio value={false}>{t(`${Feature.SHARED}:no`)}</Radio>
        </Radio.Group>
      </Form.Item>

      {isNew && (
        <Form.Item
          label={t('jobs.shared.overwritable')}
          name={JobUpsertFormField.OVERWRITABLE}
          rules={[{ required: true }]}
          initialValue={true}
        >
          <Radio.Group size="large">
            <Radio value={true}>{t(`${Feature.SHARED}:yes`)}</Radio>
            <Radio value={false}>{t(`${Feature.SHARED}:no`)}</Radio>
          </Radio.Group>
        </Form.Item>
      )}

      <Form.Item name={JobUpsertFormField.TASK} label={t('jobs.upsert.task.label')} rules={[{ required: true }]}>
        <Select allowClear showSearch onChange={(value: JobTask) => setSelectedTask(value)}>
          {Object.values(JobTasks).map((jobTask, index) => (
            <Select.Option key={index} value={jobTask}>
              {t(`jobs.upsert.task.${jobTask}`)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Divider orientation="left">{t('jobs.upsert.task.details')}</Divider>
      <Form.Item name={JobUpsertFormField.REQUEST} noStyle>
        {selectedTask ? (
          <TaskForm
            namePath={[JobUpsertFormField.REQUEST, JobUpsertFormField.REQUEST_BODY]}
            setFieldsValue={setTaskFieldsValue}
            customWrapperCol={customWrapperCol}
            job={job}
          />
        ) : (
          <Form.Item wrapperCol={customWrapperCol}>
            <Empty description={t('jobs.upsert.task.noTask')} />
          </Form.Item>
        )}
      </Form.Item>
    </Form>
  );
};

const customWrapperCol: ColProps = { offset: 4, span: 16, xl: { offset: 4, span: 10 } };
