import { CompileTimeConfig } from '../models/config';

const parsedPort = Number(process.env.PORT);

export const compileTimeConfig: CompileTimeConfig = {
  NODE_ENV: process.env.NODE_ENV ?? 'development',
  API_URL: process.env.REACT_APP_API_URL ?? '',
  SSR: process.env.REACT_APP_SSR === 'true',
  CLIENT_PORT: !Number.isNaN(parsedPort) ? parsedPort : 3000,
  REPORTING_SERVICE_URL: process.env.REACT_APP_REPORTING_SERVICE_URL ?? '',
};
