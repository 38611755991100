import { Rule } from 'antd/lib/form';
import { TFunction } from 'react-i18next';
import { FormInstance } from 'antd/es/form/hooks/useForm';

import { Feature } from '../models/features';

const userPasswordBasicRule: Rule = {
  required: true,
  type: 'string',
  whitespace: true,
  min: 12,
  max: 180,
};

const userPasswordPattern =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!$%&@*()\-+=:.~,{}[\]<>?/\\|^_#])[A-Za-z\d!$%&@*()\-+=:.~,{}[\]<>?/\\|^_#\p{L}]*$/gmu;

function userPasswordPatternRule(t: TFunction<Feature[]>): Rule {
  return {
    pattern: userPasswordPattern,
    message: t(`${Feature.SHARED}:errors.generic_error.password_weak`),
  };
}

function userPasswordQwertyRule(t: TFunction<Feature[] | Feature>): Rule {
  return {
    validator(_, value) {
      if (typeof value === 'string' && value.includes('qwerty')) {
        return Promise.reject(new Error(t(`${Feature.SHARED}:errors.generic_error.weak_pass_qwerty`)));
      }
      return Promise.resolve();
    },
  };
}

function userPasswordConsecutiveRule(t: TFunction<Feature[] | Feature>): Rule {
  return {
    validator(_, value) {
      if (typeof value === 'string' && value.length > 0) {
        for (let i = 0; i < value.length; i++) {
          if (
            value.charCodeAt(i) === value.charCodeAt(i + 1) - 1 &&
            value.charCodeAt(i) === value.charCodeAt(i + 2) - 2
          ) {
            return Promise.reject(new Error(t(`${Feature.SHARED}:errors.generic_error.weak_pass_consecutive`)));
          }
        }
      }
      return Promise.resolve();
    },
  };
}

function userPasswordRepetitionRule(t: TFunction<Feature[] | Feature>): Rule {
  return {
    validator(_, value) {
      if (typeof value === 'string' && value.length > 0) {
        for (let i = 0; i < value.length; i++) {
          if (value.charAt(i) === value.charAt(i + 1) && value.charAt(i) === value.charAt(i + 2)) {
            return Promise.reject(new Error(t(`${Feature.SHARED}:errors.generic_error.weak_pass_repetitions`)));
          }
        }
      }

      return Promise.resolve();
    },
  };
}

export function userPasswordRulesWithoutUsernameRule(t: TFunction<Feature[] | Feature>): Rule[] {
  return [
    userPasswordBasicRule,
    userPasswordPatternRule(t),
    userPasswordQwertyRule(t),
    userPasswordConsecutiveRule(t),
    userPasswordRepetitionRule(t),
  ];
}

export function userPasswordCurrentUsernameRule(username: string, t: TFunction<Feature[] | Feature>): Rule {
  return {
    validator(_, value) {
      if (typeof value === 'string' && value.includes(username)) {
        return Promise.reject(new Error(t(`${Feature.SHARED}:errors.generic_error.weak_pass_username`)));
      }
      return Promise.resolve();
    },
  };
}

export function userPasswordNewUsernameRule(
  { getFieldValue }: FormInstance,
  usernameFieldNamePath: string,
  t: TFunction<Feature[] | Feature>
): Rule {
  return {
    validator(_, value) {
      if (typeof value === 'string' && value.includes(getFieldValue(usernameFieldNamePath))) {
        return Promise.reject(new Error(t(`${Feature.SHARED}:errors.generic_error.weak_pass_username`)));
      }
      return Promise.resolve();
    },
  };
}

export function redundantSpacebarsRule(t: TFunction<Feature[] | Feature>): Rule {
  return {
    pattern: /^\S+( \S+)*$/,
    message: t(`${Feature.SHARED}:removeRedundantSpacebars`),
  };
}

export function noDuplicatesRule({ getFieldValue }: FormInstance, listName: string, error: string): Rule {
  return {
    validator(_, value) {
      if (getFieldValue(listName).filter(item => item === value).length < 2) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(error));
    },
  };
}

export function onlyNumbersRule(t: TFunction<Feature[] | Feature>): Rule {
  return {
    pattern: /^\d*$/,
    message: t(`${Feature.SHARED}:provideCorrectNumber`),
  };
}
