import { createAsyncThunk } from '@reduxjs/toolkit';
import { LoginResponse, RefreshResponse } from '@pccr-ifc/pccr-ifc';

import {
  ChangePasswordRequest,
  LoginRequest,
  RegisterRequest,
  RegisterResponse,
  RequestPasswordChangeResponse,
  ResendEmailResponse,
  VerifyEmailResponse,
} from '../../../models/api/auth';
import { Feature } from '../../../models/features';
import { RequestWithService } from '../../../models/request';
import { AuthService } from '../../services/use-auth-service';
import { updateLastRefreshTime } from '../../../../auth/components/automatic-logout';

export const register = createAsyncThunk<RegisterResponse, RequestWithService<RegisterRequest, AuthService>>(
  `${Feature.SHARED}/auth/register`,
  async ({ request, service }, { rejectWithValue }) => {
    try {
      const response = await service.register(request);
      return response.data as RegisterResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const verifyEmail = createAsyncThunk<VerifyEmailResponse, RequestWithService<string, AuthService>>(
  `${Feature.SHARED}/auth/verifyEmail`,
  async ({ request, service }, { rejectWithValue }) => {
    try {
      const response = await service.verifyEmail(request);
      return response.data as VerifyEmailResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const resendEmail = createAsyncThunk<ResendEmailResponse, RequestWithService<string, AuthService>>(
  `${Feature.SHARED}/auth/resendEmail`,
  async ({ request, service }, { rejectWithValue }) => {
    try {
      const response = await service.resendEmail(request);
      return response.data as ResendEmailResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const requestPasswordChange = createAsyncThunk<
  RequestPasswordChangeResponse,
  RequestWithService<string, AuthService>
>(`${Feature.SHARED}/auth/requestPasswordChange`, async ({ request, service }, { rejectWithValue }) => {
  try {
    const response = await service.requestPasswordChange(request);
    return response.data as RequestPasswordChangeResponse;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const changePassword = createAsyncThunk<void, RequestWithService<ChangePasswordRequest, AuthService>>(
  `${Feature.SHARED}/auth/changePassword`,
  async ({ request, service }, { rejectWithValue }) => {
    try {
      await service.changePassword(
        { password: request.password, confirmPassword: request.confirmPassword },
        request.token
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const logIn = createAsyncThunk<LoginResponse, RequestWithService<LoginRequest, AuthService>>(
  `${Feature.SHARED}/auth/logIn`,
  async ({ request: { username, password }, service }, { rejectWithValue }) => {
    try {
      const response = await service.login(username, password);
      updateLastRefreshTime();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const refresh = createAsyncThunk<RefreshResponse, RequestWithService<void, AuthService>>(
  `${Feature.SHARED}/auth/refresh`,
  async ({ service }, { rejectWithValue }) => {
    try {
      updateLastRefreshTime();
      const response = await service.refresh();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const logOut = createAsyncThunk<void, RequestWithService<void, AuthService>>(
  `${Feature.SHARED}/auth/logOut`,
  async ({ service }, { rejectWithValue }) => {
    try {
      await service.logout();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
