import { Form, FormInstance, Input, InputNumber, Radio, Select } from 'antd';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';

import { useFindTenantsQuery } from '../../../access/data/queries/tenant-queries';
import { Feature } from '../../../shared/models/features';
import { translationNamespace } from '../../constants/translation-resources';
import { Dialect } from '../../models/dialect';
import { redundantSpacebarsRule } from '../../../shared/utils/form-rules';
import { Source } from '../../models/source';
import { Dialects } from '../../constants/dialect';

export enum SourceUpsertFormField {
  NAME = 'name',
  DIALECT = 'dialect',
  HOST = 'host',
  PORT = 'port',
  DATABASE_NAME = 'databasename',
  USERNAME = 'username',
  PASSWORD = 'password',
  TENANT = 'tenant',
}

export interface SourceUpsertFormValues {
  [SourceUpsertFormField.NAME]: string;
  [SourceUpsertFormField.DIALECT]: Dialect;
  [SourceUpsertFormField.HOST]: string;
  [SourceUpsertFormField.PORT]: number;
  [SourceUpsertFormField.DATABASE_NAME]: string;
  [SourceUpsertFormField.USERNAME]: string;
  [SourceUpsertFormField.PASSWORD]: string;
  [SourceUpsertFormField.TENANT]: number;
}

interface Props {
  form: FormInstance<SourceUpsertFormValues>;
  onSubmit?: (values: SourceUpsertFormValues) => void;
  initialValues?: Partial<SourceUpsertFormValues>;
  source?: Source;
  sourceTenantLoading?: boolean;
}

export const SourceUpsertForm: FC<Props> = ({ form, onSubmit, initialValues, source, sourceTenantLoading }) => {
  const { t } = useTranslation(translationNamespace);
  const [tenantsSearchValue, setTenantsSearchValue] = useState<string>();
  const [tenantsDebouncedSearchValue, setTenantsDebouncedSearchValue] = useState<string>();
  const { data: tenantsData, isLoading: tenantsLoading } = useFindTenantsQuery({
    page: 1,
    pageSize: 25,
    searchValue: tenantsDebouncedSearchValue,
    attributes: ['id', 'name'],
  });

  const tenantSelectOptions = useMemo(() => {
    return tenantsData?.resources.map((tenant, index) => (
      <Select.Option key={index} value={tenant.id}>
        {tenant.name}
      </Select.Option>
    ));
  }, [tenantsData?.resources]);

  const applyTenantsSearch = (value: string) => {
    setTenantsSearchValue(value);
    setTenantsDebouncedSearchValue(value);
  };

  const clearTenantsSearch = () => {
    setTenantsSearchValue('');
    setTenantsDebouncedSearchValue('');
  };

  useDebounce(
    () => {
      setTenantsDebouncedSearchValue(tenantsSearchValue);
    },
    3000,
    [tenantsSearchValue]
  );

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={onSubmit}
      layout="horizontal"
      labelCol={{ lg: 4, xl: 4 }}
      wrapperCol={{ lg: 16, xl: 10 }}
      requiredMark={false}
    >
      <Form.Item
        label={t(`${Feature.SHARED}:name`)}
        name={SourceUpsertFormField.NAME}
        rules={[{ required: true, max: 180 }, redundantSpacebarsRule(t)]}
      >
        <Input placeholder={t('sources.upsert.name.placeholder')} />
      </Form.Item>
      <Form.Item label={t(`${Feature.SHARED}:tenant.label`)} name={SourceUpsertFormField.TENANT}>
        <Select
          allowClear={source == null}
          showSearch
          filterOption={false}
          placeholder={t(`${Feature.SHARED}:tenant.placeholder`)}
          loading={tenantsLoading || sourceTenantLoading}
          onSearch={applyTenantsSearch}
          onSelect={clearTenantsSearch}
        >
          {tenantSelectOptions}
        </Select>
      </Form.Item>
      <Form.Item
        label={t('sources.shared.dialect')}
        name={SourceUpsertFormField.DIALECT}
        rules={[{ required: true }]}
        initialValue={Dialects.POSTGRES}
      >
        <Radio.Group>
          {Object.values(Dialects).map((dialect, index) => (
            <Radio key={index} value={dialect}>
              {dialect}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item style={{ width: '100%', marginBottom: 0 }} label={t('sources.shared.address.label')}>
        <Form.Item
          style={{ display: 'inline-block', width: '70%' }}
          name={SourceUpsertFormField.HOST}
          rules={[{ required: true }]}
          messageVariables={{ label: t('sources.shared.host.label') }}
        >
          <Input style={{ width: '100%' }} placeholder={t('sources.shared.host.placeholder')} />
        </Form.Item>

        <Form.Item
          style={{ display: 'inline-block', width: '30%' }}
          name={SourceUpsertFormField.PORT}
          rules={[{ required: true }]}
          messageVariables={{ label: t('sources.upsert.port.label') }}
        >
          <InputNumber style={{ width: '100%' }} placeholder={t('sources.upsert.port.placeholder')} />
        </Form.Item>
      </Form.Item>
      <Form.Item
        label={t('sources.shared.databasename.label')}
        name={SourceUpsertFormField.DATABASE_NAME}
        rules={[{ required: true }, redundantSpacebarsRule(t)]}
      >
        <Input placeholder={t('sources.shared.databasename.placeholder')} />
      </Form.Item>
      <Form.Item
        label={t(`${Feature.SHARED}:username.label`)}
        name={SourceUpsertFormField.USERNAME}
        rules={[{ required: true }]}
      >
        <Input autoComplete="off" placeholder={t(`${Feature.SHARED}:username.placeholder`)} />
      </Form.Item>
      <Form.Item
        label={t(`${Feature.SHARED}:password.label`)}
        name={SourceUpsertFormField.PASSWORD}
        rules={[{ required: source == null }]}
      >
        <Input.Password autoComplete="off" placeholder={t(`${Feature.SHARED}:password.placeholder`)} />
      </Form.Item>
    </Form>
  );
};
