import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { UserWithIncludes, User as BeUser } from '@pccr-ifc/pccr-ifc';
import { useTranslation } from 'react-i18next';

import { User } from '../../../shared/models/user';
import { PickSomeRestPartial } from '../../../shared/utils/types';
import { UserUpsert } from '../../models/user';
import { useUserService } from '../services/use-user-service';
import {
  onCreateResourceMutationSuccess,
  onDeleteResourceMutationSuccess,
  onUpdateResourceMutationSuccess,
} from '../../../shared/utils/mutation';
import { displayErrors } from '../../../shared/utils/error';

export function useCreateUserMutation() {
  const userService = useUserService();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<UserWithIncludes, unknown, Omit<UserUpsert, 'id'>>(
    user => {
      const request = { user } as { user: Omit<BeUser, 'id'> };
      return userService.createUser(request);
    },
    {
      onSuccess(response) {
        onCreateResourceMutationSuccess(queryClient, 'USER', response);
      },
      onError(error) {
        displayErrors(error, t);
      },
    }
  );
}

export function useUpdateUserMutation() {
  const userService = useUserService();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<UserWithIncludes, unknown, PickSomeRestPartial<UserUpsert, 'id'>>(
    user => userService.updateUser(user),
    {
      onSuccess(response, vars) {
        onUpdateResourceMutationSuccess(queryClient, 'USER', response, vars.id);
      },
      onError(error) {
        displayErrors(error, t);
      },
    }
  );
}

export function useDeleteUserMutation() {
  const userService = useUserService();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse<void>, unknown, User['id']>(id => userService.deleteUser(id), {
    onSuccess(_response, id) {
      onDeleteResourceMutationSuccess(queryClient, 'USER', id);
    },
    onError(error) {
      displayErrors(error, t);
    },
  });
}

// disabled for now
export function useApproveUserMutation() {
  const userService = useUserService();
  // const queryClient = useQueryClient();

  return useMutation<AxiosResponse<{ user: BeUser }>, unknown, User['id']>(id => userService.approveUser(id), {
    // can't set dataQuery here, type BeUser !== UserWithIncludes
    onSuccess(_response, id) {
      // queryClient.invalidateQueries(getSingleResourceQueryKey('USER', id));
      // queryClient.invalidateQueries(getResourceListQueryKeyRoot('USER'));
    },
  });
}
