import { UserRequiredRoute } from '../../core/models/allowed-required-routes';
import { Methods } from '../../shared/constants/method';

const rejectDocumentPath = '/approval/document/action/rejected';
export const rejectDocumentRequiredRoute: UserRequiredRoute = {
  path: rejectDocumentPath,
  method: Methods.POST,
};

const approveLayoutBatchPath = '/approval/batches/action/approved_layout';
export const approveBatchLayoutRequiredRoute: UserRequiredRoute = {
  path: approveLayoutBatchPath,
  method: Methods.POST,
};

const approveQuantityBatchPath = '/approval/batches/action/approved_quantity';
export const approveQuantityBatchRequiredRoute: UserRequiredRoute = {
  path: approveQuantityBatchPath,
  method: Methods.POST,
};

const approveBothBatchPath = '/approval/batches/action/approved';
export const approveBothBatchRequiredRoute: UserRequiredRoute = {
  path: approveBothBatchPath,
  method: Methods.POST,
};

const rejectBatchPath = '/approval/batches/action/rejected';
export const rejectBatchRequiredRoute: UserRequiredRoute = {
  path: rejectBatchPath,
  method: Methods.POST,
};

const setFixDatePath = '/approval/batches/fixeddate';
export const setFixDateRequiredRoute: UserRequiredRoute = {
  path: setFixDatePath,
  method: Methods.POST,
};
