import { css } from '@emotion/react';
import { Alert, Divider, Form, FormInstance, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';

import { redundantSpacebarsRule } from '../../../shared/utils/form-rules';
import { Feature } from '../../../shared/models/features';
import { User } from '../../../shared/models/user';
import { translationNamespace } from '../../constants/translation-resources';

export enum UserProfileUpsertFormField {
  EMAIL = 'email',
  USERNAME = 'username',
  FIRST_NAME = 'firstName',
  MIDDLE_NAME = 'middleName',
  LAST_NAME = 'lastName',
  MOBILE_PHONE = 'mobilePhone',
  DESCRIPTION = 'description',
  ROLES = 'roles',
  TENANTS = 'tenants',
}

export interface UserProfileUpsertFormValues {
  [UserProfileUpsertFormField.EMAIL]: string;
  [UserProfileUpsertFormField.USERNAME]: string;
  [UserProfileUpsertFormField.FIRST_NAME]?: string;
  [UserProfileUpsertFormField.MIDDLE_NAME]?: string;
  [UserProfileUpsertFormField.LAST_NAME]?: string;
  [UserProfileUpsertFormField.DESCRIPTION]?: string;
  [UserProfileUpsertFormField.MOBILE_PHONE]?: string;
  [UserProfileUpsertFormField.ROLES]?: string[];
  [UserProfileUpsertFormField.TENANTS]?: string[];
}

interface Props {
  onSubmit: (values: UserProfileUpsertFormValues) => void;
  form: FormInstance<UserProfileUpsertFormValues>;
  user: User;
}

export const UserProfileUpsertForm: FC<Props> = ({ onSubmit, form, user }) => {
  const { t } = useTranslation(translationNamespace);

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      layout="horizontal"
      labelCol={{ lg: 4, xl: 4 }}
      wrapperCol={{ lg: 16, xl: 10 }}
      requiredMark={false}
    >
      <Form.Item
        label={t(`${Feature.SHARED}:email.label`)}
        name={UserProfileUpsertFormField.EMAIL}
        rules={[{ required: true }, { type: 'email' }]}
      >
        <Input readOnly css={notAllowedStyles} placeholder={t(`${Feature.SHARED}:email.placeholder`)} />
      </Form.Item>
      <Form.Item
        label={t(`${Feature.SHARED}:username.label`)}
        name={UserProfileUpsertFormField.USERNAME}
        rules={[{ required: true, min: 6, max: 190 }, redundantSpacebarsRule(t)]}
      >
        <Input readOnly css={notAllowedStyles} placeholder={t(`${Feature.SHARED}:username.placeholder`)} />
      </Form.Item>
      <Form.Item
        label={t(`${Feature.SHARED}:firstName.label`)}
        name={UserProfileUpsertFormField.FIRST_NAME}
        rules={[{ type: 'string', min: 2 }, redundantSpacebarsRule(t)]}
      >
        <Input placeholder={t(`${Feature.SHARED}:firstName.placeholder`)} />
      </Form.Item>
      <Form.Item
        label={t(`${Feature.SHARED}:middleName.label`)}
        name={UserProfileUpsertFormField.MIDDLE_NAME}
        rules={[{ type: 'string' }, redundantSpacebarsRule(t)]}
      >
        <Input placeholder={t(`${Feature.SHARED}:middleName.placeholder`)} />
      </Form.Item>
      <Form.Item
        label={t(`${Feature.SHARED}:lastName.label`)}
        name={UserProfileUpsertFormField.LAST_NAME}
        rules={[{ type: 'string' }, redundantSpacebarsRule(t)]}
      >
        <Input placeholder={t(`${Feature.SHARED}:lastName.placeholder`)} />
      </Form.Item>
      <Form.Item label={t(`${Feature.SHARED}:description.label`)} name={UserProfileUpsertFormField.DESCRIPTION}>
        <Input.TextArea placeholder={t(`${Feature.SHARED}:description.placeholder`)} />
      </Form.Item>
      <Form.Item label={t(`${Feature.SHARED}:mobilePhone.label`)} name={UserProfileUpsertFormField.MOBILE_PHONE}>
        <Input placeholder={t(`${Feature.SHARED}:mobilePhone.placeholder`)} />
      </Form.Item>

      <Divider orientation="left">{t(`${Feature.SHARED}:role.plural`)}</Divider>
      {user?.roles?.length > 0 ? (
        <>
          <Form.List name={UserProfileUpsertFormField.ROLES}>
            {fields =>
              fields.map(field => (
                <Form.Item name={field.name} key={field.key} label={t(`${Feature.SHARED}:role.label`)}>
                  <Input readOnly css={notAllowedStyles} />
                </Form.Item>
              ))
            }
          </Form.List>
        </>
      ) : (
        <Form.Item label={t(`${Feature.SHARED}:role.label`)}>
          <Alert css={shorterStyles} message={t('userProfile.noRoles')} type="warning" banner />
        </Form.Item>
      )}

      <Divider orientation="left">{t(`${Feature.SHARED}:tenant.plural`)}</Divider>
      {user?.groups?.length > 0 ? (
        <>
          <Form.List name={UserProfileUpsertFormField.TENANTS}>
            {fields =>
              fields.map(field => (
                <Form.Item name={field.name} key={field.key} label={t(`${Feature.SHARED}:tenant.label`)}>
                  <Input readOnly css={notAllowedStyles} />
                </Form.Item>
              ))
            }
          </Form.List>
        </>
      ) : (
        <Form.Item label={t(`${Feature.SHARED}:tenant.label`)}>
          <Alert css={shorterStyles} message={t('userProfile.noTenants')} type="warning" banner />
        </Form.Item>
      )}
    </Form>
  );
};

const notAllowedStyles = css`
  cursor: not-allowed;
`;

const shorterStyles = css`
  line-height: 120%;
`;
