import { useContext } from 'react';

import { Config } from '../models/config';
import { ServicesContainerContext } from '../components/services-container';

/**
 * Get a service instance based on given config from ServiceContainerContext.
 * The instance is lazily created and stored in the context if it does not exist yet.
 *
 * @param createService Factory function for the service, which receives a Config as an argument.
 * @param token A symbol uniquely identifying the service. Optional if `createService` has constant reference and is unique for the service.
 * @returns Service instance
 */
export function useService<T>(createService: (config: Config) => T, token?: symbol): T {
  const getService = useContext(ServicesContainerContext);
  return getService(token ?? createService, createService);
}
