import { Button, Form, message, Modal, ModalProps, Space } from 'antd';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import {
  GenerateReportFormField,
  GenerateReportFormFields,
  GenerateReportFormValues,
} from '../../../shared/components/generate-report-form-fields';
import { Feature } from '../../../shared/models/features';
import { translationNamespace } from '../../constants/translation-resources';
import { convertFormValuesToCheckReportSize } from '../../../shared/data/converters/report-converters';
import { checkReportSize } from '../../data/store/thunks/report-thunks';
import { useReportService } from '../../data/services/use-report-service';
import { getLanguageFromI18nLanguage } from '../../../shared/utils/language';
import { formatNumber } from '../../../shared/utils/number';

interface GenerateReportModalProps extends ModalProps {
  onSubmit?: (values: GenerateReportFormValues) => void;
}

export const GenerateReportModal: FC<GenerateReportModalProps> = ({ onSubmit, confirmLoading, onCancel, ...props }) => {
  const { t, i18n } = useTranslation(translationNamespace);
  const dispatch = useDispatch();
  const reportService = useReportService();
  const [form] = Form.useForm<GenerateReportFormValues>();
  const checkReportSizeLoading = useSelector(state => state.reports.report.checkSize.loading);
  const lang = getLanguageFromI18nLanguage(i18n.language);

  const checkSize = async () => {
    try {
      await form.validateFields([GenerateReportFormField.DEFINITION_ID]);
    } catch (e) {
      return;
    }

    const reportToCheck = convertFormValuesToCheckReportSize(form.getFieldsValue());

    dispatch(checkReportSize({ request: reportToCheck, service: reportService }))
      .then(unwrapResult)
      .then(count => {
        message.info(`${t('reports.list.rowsNumber')} ${formatNumber(isNaN(count) ? 0 : count, lang)}`);
      })
      .catch(() => {
        message.error(t('shared.check.unsuccessful'));
      });
  };

  const triggerSubmit = () => {
    form.submit();
  };

  const submit = (values: GenerateReportFormValues) => {
    onSubmit?.(values);
  };

  const generateStepButtons = (
    <Space css={spaceStyles}>
      <Button type="primary" onClick={checkSize} ghost loading={checkReportSizeLoading}>
        {t('reports.list.checkSize')}
      </Button>
      <Button type="default" onClick={onCancel}>
        {t(`${Feature.SHARED}:cancel`)}
      </Button>
      <Button type="primary" onClick={triggerSubmit} loading={confirmLoading}>
        {t(`${Feature.SHARED}:generate`)}
      </Button>
    </Space>
  );

  const setFieldsValue = useCallback(
    (values: Record<string | number, unknown>) => {
      form.setFieldsValue({
        ...values,
      });
    },
    [form]
  );

  return (
    <Modal
      {...props}
      centered
      title={t('reports.shared.generate.report')}
      onCancel={onCancel}
      footer={generateStepButtons}
      destroyOnClose
    >
      <Form preserve={false} form={form} layout="horizontal" onFinish={submit}>
        <GenerateReportFormFields setFieldsValue={setFieldsValue} />
      </Form>
    </Modal>
  );
};

const spaceStyles = css`
  display: flex;
  justify-content: flex-end;

  .ant-space-item:first-of-type {
    margin-right: auto;
  }
`;
