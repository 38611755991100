import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import { convertSearchDocumentsResponse } from '../converters/document-converters';
import { DocumentSearchResults, FindDocumentsQueryParams } from '../../models/document';
import { getResourceListQueryKeyRoot } from '../../../shared/utils/resource-query-key';
import { useApprovalService } from '../services/use-approval-service';
import { displayErrors } from '../../../shared/utils/error';

export function useFindDocumentsQuery({ batchName, page, pageSize, search, filter, wId }: FindDocumentsQueryParams) {
  const documentService = useApprovalService();
  const { t } = useTranslation();

  return useQuery<DocumentSearchResults, unknown>(
    [getResourceListQueryKeyRoot('DOCUMENT'), batchName, page, pageSize, search, filter, wId],
    async () => {
      const request = {
        batchName,
        currentPage: page,
        limitOnPage: pageSize,
        search,
        filter,
        wId,
      };

      const response = await documentService.findDocuments(request);
      return convertSearchDocumentsResponse(response.data);
    },
    {
      onError: error => displayErrors(error, t),
      staleTime: 30000,
    }
  );
}
