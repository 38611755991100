import { css } from '@emotion/react';
import { Typography } from 'antd';
import { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';

const { Text } = Typography;

interface Props extends LinkProps {
  name: string;
  disabled?: boolean;
}

export const LinkedName: FC<Props> = ({ name, disabled = false, ...props }) => {
  return (
    <Link {...props} css={disabled && disabledStyle}>
      <Text css={wrapAnywhere}>{name}</Text>
    </Link>
  );
};

const wrapAnywhere = css`
  overflow-wrap: anywhere;
`;

const disabledStyle = css`
  pointer-events: none;
`;
