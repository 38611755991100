import { ChangePasswordRequest } from '../../../shared/models/api/auth';
import { ForgotPasswordFormValues } from '../../views/forgot-password/forgot-password-context';

export function convertFormValuesToChangePasswordRequest(
  formValues: ForgotPasswordFormValues,
  token: string
): ChangePasswordRequest {
  return {
    password: formValues.password,
    confirmPassword: formValues.confirmPassword,
    token,
  };
}
