import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { App } from './core/views/app';
import reportWebVitals from './reportWebVitals';
import { compileTimeConfig } from './shared/constants/config';

const initialProps = window.__INITIAL_PROPS__;
delete window.__INITIAL_PROPS__;

const rootElement = document.getElementById('root');

const app = (
  <React.StrictMode>
    <BrowserRouter>
      <App {...initialProps} />
    </BrowserRouter>
  </React.StrictMode>
);

if (initialProps?.runtimeConfig?.SSR || compileTimeConfig.SSR) {
  ReactDOM.hydrate(app, rootElement);
} else {
  ReactDOM.render(app, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
