import { Descriptions, Divider, Drawer, DrawerProps, Tag } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

import { translationNamespace } from '../../constants/translation-resources';
import { Report } from '../../models/report';
import { formatISODate, formatTimeRemainingToNow } from '../../../shared/utils/date';
import { getLanguageFromI18nLanguage } from '../../../shared/utils/language';
import { Feature } from '../../../shared/models/features';
import { formatSeconds } from '../../../shared/utils/number';
import { useLogsQueryForTimeline } from '../../../logs/data/queries/log-queries';
import { LogsTimeline } from '../../../shared/components/logs-timeline';
import { UserName } from '../../../shared/components/user-name';
import { LogsFilters } from '../../../logs/models/log';
import { i18nDateFnsLocaleMap } from '../../../shared/constants/date-fns';
import { getReportStatusTagColor } from '../../utils/color-mappers';
import { isNonEmptyObject } from '../../../shared/utils/object';

interface ReportDetailsDrawerProps extends DrawerProps {
  report: Report;
}

export const ReportDetailsDrawer: FC<ReportDetailsDrawerProps> = ({ report, ...props }) => {
  const { t, i18n } = useTranslation(translationNamespace);
  const filters: LogsFilters = {
    resourceId: [report.uuid],
    entityType: 'report',
    method: ['PATCH', 'POST', 'PUT', 'DELETE'],
  };
  const logQueryResult = useLogsQueryForTimeline(filters, { enabled: props.open });

  const executionTime =
    report.executionTime != null
      ? formatSeconds(report.executionTime, getLanguageFromI18nLanguage(i18n.language))
      : null;

  const timeToExpire = formatTimeRemainingToNow(
    new Date(report.expireDate),
    i18nDateFnsLocaleMap[i18n.language],
    t('reports.shared.expired')
  );

  return (
    <Drawer {...props} title={t(`${Feature.SHARED}:details`)} width={400} placement="right" destroyOnClose>
      <Descriptions column={1} bordered css={withMarginBottom}>
        <Descriptions.Item label={t(`${Feature.SHARED}:name`)}>{report.name}</Descriptions.Item>

        {isNonEmptyObject(report.sqlParams) && (
          <Descriptions.Item contentStyle={{ padding: '5px' }} label={t(`${Feature.SHARED}:queryParams.label`)}>
            <Descriptions css={queryParamsStyles} column={1} bordered>
              {Object.entries(report.sqlParams).map(([key, value]) => (
                <Descriptions.Item label={key}>{value}</Descriptions.Item>
              ))}
            </Descriptions>
          </Descriptions.Item>
        )}

        <Descriptions.Item label={t(`${Feature.SHARED}:definition.label`)}>{report.definition.name}</Descriptions.Item>
        <Descriptions.Item label={t('reports.shared.executionTime')}>{executionTime}</Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:status`)}>
          <Tag color={getReportStatusTagColor(report.status)}>
            {t(`reports.shared.reportStatus.${report.status.toLowerCase()}`)}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label={t('reports.shared.expiresIn')}>{timeToExpire}</Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:createdAt`)}>
          {report.createdAt ? formatISODate(report.createdAt, getLanguageFromI18nLanguage(i18n.language)) : ''}
        </Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:createdBy`)}>
          <UserName userId={report.createdBy} />
        </Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:updatedAt`)}>
          {report.updatedAt ? formatISODate(report.updatedAt, getLanguageFromI18nLanguage(i18n.language)) : ''}
        </Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:updatedBy`)}>
          <UserName userId={report.updatedBy} />
        </Descriptions.Item>
      </Descriptions>
      <Divider>{t(`${Feature.SHARED}:log`)}</Divider>
      <LogsTimeline queryResult={logQueryResult} />
    </Drawer>
  );
};

const withMarginBottom = css`
  margin-bottom: 32px;
`;

const queryParamsStyles = css`
  .ant-descriptions-item-label,
  .ant-descriptions-item-content {
    padding: 10px 5px;
  }
`;
