export enum Feature {
  ACCESS = 'access',
  CORE = 'core',
  AUTH = 'auth',
  LOGS = 'logs',
  AUTOMATION = 'automation',
  REPORTS = 'reports',
  SHARED = 'shared',
  APPROVAL = 'approval',
}
