import axios from 'axios';

import { AnyApiResponse } from '../../../shared/utils/types';
import { Config } from '../../../shared/models/config';
import { useService } from '../../../shared/hooks/use-service';

function createViewService(config: Config) {
  const BASE_PATH = '/report/view';

  const findViews = (request: AnyApiResponse) =>
    axios.post<AnyApiResponse>(`${config.API_URL}${BASE_PATH}/findAll`, request, {
      withCredentials: true,
    });

  const getView = (id: number) =>
    axios.get<AnyApiResponse>(`${config.API_URL}${BASE_PATH}/${id}`, {
      withCredentials: true,
    });

  const createView = (request: AnyApiResponse) =>
    axios.post<AnyApiResponse>(`${config.API_URL}${BASE_PATH}/add`, request, {
      withCredentials: true,
    });

  const updateView = (request: AnyApiResponse, id: number) =>
    axios.patch<AnyApiResponse>(`${config.API_URL}${BASE_PATH}/${id}`, request, {
      withCredentials: true,
    });

  const deleteView = (id: number) =>
    axios.delete<AnyApiResponse>(`${config.API_URL}${BASE_PATH}/${id}`, {
      withCredentials: true,
    });

  return { findViews, getView, createView, updateView, deleteView };
}

export const useViewService = () => useService(createViewService);
