import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { ReportSource as BeSource } from '@pccr-ifc/pccr-ifc';
import { useTranslation } from 'react-i18next';

import { PickSomeRestPartial } from '../../../shared/utils/types';
import { Source, SourceUpsert } from '../../models/source';
import { useSourceService } from '../services/use-source-service';
import { onDeleteResourceMutationSuccess } from '../../../shared/utils/mutation';
import { getResourceListQueryKeyRoot, getSingleResourceQueryKey } from '../../../shared/utils/resource-query-key';
import { getLogsQueryKeyForTimeline } from '../../../logs/data/queries/log-queries';
import { displayErrors } from '../../../shared/utils/error';

export function useCreateSourceMutation() {
  const sourceService = useSourceService();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<BeSource, unknown, Omit<SourceUpsert, 'id'>>(source => sourceService.createSource({ source }), {
    onSuccess() {
      queryClient.invalidateQueries(getResourceListQueryKeyRoot('SOURCE'));
    },
    onError(error) {
      displayErrors(error, t);
    },
  });
}

export function useUpdateSourceMutation() {
  const sourceService = useSourceService();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<BeSource, unknown, PickSomeRestPartial<SourceUpsert, 'id'>>(
    source => sourceService.updateSource(source),
    {
      onSuccess(_response, vars) {
        queryClient.invalidateQueries(getResourceListQueryKeyRoot('SOURCE'));
        queryClient.invalidateQueries(getSingleResourceQueryKey('SOURCE', vars.id));
        queryClient.invalidateQueries(getLogsQueryKeyForTimeline('SOURCE', vars.id));
      },
      onError(error) {
        displayErrors(error, t);
      },
    }
  );
}

export function useDeleteSourceMutation() {
  const sourceService = useSourceService();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse<void>, unknown, Source['id']>(id => sourceService.deleteSource(id), {
    onSuccess(_response, id) {
      onDeleteResourceMutationSuccess(queryClient, 'SOURCE', id);
    },
    onError(error) {
      displayErrors(error, t);
    },
  });
}
