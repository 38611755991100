import { FC } from 'react';
import { DefaultRootState } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { GuardedRoute, GuardFunction, PageComponent } from 'react-router-guards';
import { Dispatch } from '@reduxjs/toolkit';
import { defaultGuardedRouteMeta } from '../../constants/default-guarded-route-meta';
import { AuthService } from '../../data/services/use-auth-service';
import { GuardedRouteMeta, Route } from '../../models/route';

type Props = {
  routes: Route[];
  basePath?: string;
  guards?: GuardFunction[];
  loadingView?: PageComponent;
  errorView?: PageComponent;
  meta?: GuardedRouteMeta;
  dispatch?: Dispatch;
  getState?: () => DefaultRootState;
  authService?: AuthService;
};

export const DynamicRoutes: FC<Props> = ({
  routes,
  basePath = '',
  guards,
  loadingView,
  errorView,
  meta,
  dispatch,
  getState,
  authService,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): any => {
  return routes.map(route => {
    const View = route.view;
    const finalLoadingView = route.loadingView ?? loadingView;
    const finalErrorView = route.errorView ?? errorView;
    const mergedGuards = [...(guards ?? []), ...(route.guards ?? [])];
    const mergedMeta: GuardedRouteMeta = {
      ...defaultGuardedRouteMeta,
      authService,
      dispatch,
      getState,
      ...meta,
      ...route.meta,
    };
    return route.children?.length > 0 ? (
      DynamicRoutes({
        routes: route.children,
        basePath: `${basePath}${route.path}`,
        guards: mergedGuards,
        loadingView: finalLoadingView,
        errorView: finalErrorView,
        meta: mergedMeta,
        dispatch,
        getState,
        authService,
      })
    ) : (
      <GuardedRoute
        key={`${basePath}${route.path}`}
        path={`${basePath}${route.path}`}
        exact={route.exact ?? true}
        guards={mergedGuards}
        loading={finalLoadingView}
        error={finalErrorView}
        meta={mergedMeta}
      >
        {route.redirectTo != null ? <Redirect to={route.redirectTo} /> : <View />}
      </GuardedRoute>
    );
  });
};
