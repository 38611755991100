import { FindAllSourcesResponse } from '@pccr-ifc/pccr-ifc';

import { convertDateRangeToISO } from '../../../shared/utils/date';
import { SourceUpsertFormValues } from '../../components/source-upsert-form';
import { SourcesTableFilters } from '../../components/sources-table';
import { Source, SourcesFilters, SourceUpsert } from '../../models/source';
import { ResourceSearchResult } from '../../../shared/models/api/response';

export function convertSearchResponse(response: FindAllSourcesResponse): ResourceSearchResult<Source> {
  return {
    total: response.total,
    resources: response.sources as Source[],
  };
}

export function convertFormValuesToSource(values: Partial<SourceUpsertFormValues>): Omit<SourceUpsert, 'id'> {
  return {
    name: values.name,
    dialect: values.dialect,
    host: values.host,
    port: values.port,
    databasename: values.databasename,
    username: values.username,
    password: values.password,
    groupId: values.tenant,
  };
}

export function convertTableFiltersToFilters(tableFilters: SourcesTableFilters): SourcesFilters {
  const sourcesFilters: SourcesFilters = {
    name: tableFilters.name,
    dialect: tableFilters.dialect,
    host: tableFilters.host,
    databasename: tableFilters.databasename,
    status: tableFilters.status,
  };

  if (tableFilters.createdAt != null) {
    sourcesFilters.createdAt = convertDateRangeToISO(tableFilters.createdAt);
  }
  if (tableFilters.updatedAt != null) {
    sourcesFilters.updatedAt = convertDateRangeToISO(tableFilters.updatedAt);
  }

  return sourcesFilters;
}
