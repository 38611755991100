import {
  GenerateReportFormField,
  GenerateReportFormSQLParamsValue,
  GenerateReportFormValues,
} from '../../components/generate-report-form-fields';
import { GenerateReportData } from '../../models/api/report';

function convertSqlFormValuesToReport(
  values: GenerateReportFormSQLParamsValue[]
): Pick<GenerateReportData, 'sqlParams'> {
  return values.reduce((map, { key, value }) => {
    if (key != null) {
      map = {
        ...map,
        [key]: value,
      };
    }

    return map;
  }, {});
}

export function convertFormValuesToCheckReportSize(
  values: Pick<GenerateReportFormValues, GenerateReportFormField.DEFINITION_ID | GenerateReportFormField.SQL_PARAMS>
): Pick<GenerateReportData, 'definitionId' | 'sqlParams'> {
  const report: Pick<GenerateReportData, 'definitionId' | 'sqlParams'> = {
    definitionId: values.definitionId,
  };

  if (values.sqlParams?.filter(param => param != null).length > 0) {
    report.sqlParams = convertSqlFormValuesToReport(values.sqlParams);
  }

  return report;
}

export function convertFormValuesToGenerateReport(values: GenerateReportFormValues): GenerateReportData {
  const report: GenerateReportData = {
    name: values.name,
    definitionId: values.definitionId,
    daysToExpire: values.daysToExpire,
  };

  if (values.sqlParams?.length > 0) {
    report.sqlParams = convertSqlFormValuesToReport(values.sqlParams);
  }

  return report;
}

export function convertGenerateReportToFormValues(report: GenerateReportData): GenerateReportFormValues {
  if (report == null) return null;

  const formValues: GenerateReportFormValues = {
    name: report.name,
    definitionId: report.definitionId,
    daysToExpire: report.daysToExpire,
    sqlParams: [],
  };

  if (report.sqlParams != null) {
    formValues.sqlParams = Object.entries(report.sqlParams).reduce((acc, [key, value]) => {
      acc.push({ key, value });
      return acc;
    }, []);
  }

  return formValues;
}
