import { MinBreakpointsTypes, MaxBreakpointsTypes } from '../models/breakpoints';

export const minAvailableBreakpoints: Record<MinBreakpointsTypes, number> = {
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

export const maxAvailableBreakpoints: Record<MaxBreakpointsTypes, number> = {
  md: 991.98,
  lg: 1199.98,
  xl: 1599.98,
};

export const breakpointQueries = Object.entries(maxAvailableBreakpoints).reduce<
  Partial<Record<MaxBreakpointsTypes, string>>
>((acc, [key, width]) => {
  acc[key] = `(max-width: ${width}px)`;
  return acc;
}, {});

export const breakpointQueriesKeys = Object.keys(breakpointQueries) as MaxBreakpointsTypes[];
