import { TagProps } from 'antd';

import { Method } from '../../shared/models/method';
import { UserStatus } from '../../shared/models/user';
import { Methods } from '../../shared/constants/method';
import { UserStatuses } from '../../shared/constants/user';

export const getMethodTagColor = (method: Method): TagProps['color'] => {
  switch (method) {
    case Methods.GET:
      return 'green';
    case Methods.POST:
      return 'blue';
    case Methods.PATCH:
      return 'yellow';
    case Methods.DELETE:
      return 'red';

    default:
      return 'default';
  }
};

export const getUserStatusTagColor = (status: UserStatus): TagProps['color'] => {
  switch (status) {
    case UserStatuses.PENDING:
      return 'yellow';
    case UserStatuses.ACTIVE:
      return 'orange';
    case UserStatuses.APPROVED:
      return 'green';

    default:
      return 'default';
  }
};
