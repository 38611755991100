import { Button, Card, Col, Form, message, Row, Space, Tooltip } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { CheckOutlined, QuestionOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import { useQuery } from '../../../shared/hooks/use-query';
import { CommonQueryParamKey } from '../../../shared/models/common-query-params';
import { Feature } from '../../../shared/models/features';
import { SourceUpsertForm, SourceUpsertFormValues } from '../../components/source-upsert-form';
import { translationNamespace } from '../../constants/translation-resources';
import { convertFormValuesToSource } from '../../data/converters/source-converters';
import { useCreateSourceMutation } from '../../data/mutations/source-mutations';
import { checkSource } from '../../data/store/thunks/source-thunks';
import { useSourceService } from '../../data/services/use-source-service';
import { AppResourcePaths } from '../../../shared/constants/app-resources';

export const SourceNewView: FC = () => {
  const { t } = useTranslation(translationNamespace);
  const { push: routerPush } = useHistory();
  const [form] = Form.useForm<SourceUpsertFormValues>();
  const query = useQuery();
  const mutation = useCreateSourceMutation();
  const dispatch = useDispatch();
  const sourceService = useSourceService();

  const parsedPrev = query.get(CommonQueryParamKey.PREV);
  const prevUrl = parsedPrev ?? AppResourcePaths.sources;

  const submit = (values: SourceUpsertFormValues) => {
    const newSource = convertFormValuesToSource(values);

    mutation.mutate(newSource, {
      onSuccess: () => {
        message.success(t('sources.shared.createSuccessMessage'));
        routerPush(parsedPrev ?? `${AppResourcePaths.sources}`);
      },
    });
  };

  const triggerSubmit = () => {
    form.submit();
  };

  const check = () => {
    const source = convertFormValuesToSource(form.getFieldsValue());

    dispatch(checkSource({ request: source, service: sourceService }))
      .then(unwrapResult)
      .then(() => {
        message.success(t('shared.check.successful'));
      })
      .catch(() => {
        message.error(t('shared.check.unsuccessful'));
      });
  };

  return (
    <Row gutter={[16, 24]}>
      <Col span={24}>
        <Card title={t(`${Feature.SHARED}:details`)}>
          <SourceUpsertForm form={form} onSubmit={submit} />
        </Card>
      </Col>
      <Col>
        <Space>
          <Tooltip title={t('shared.check.tooltip.source.checkFirst')}>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              icon={<CheckOutlined />}
              loading={mutation.isLoading}
              onClick={triggerSubmit}
            >
              {t(`${Feature.SHARED}:create`)}
            </Button>
          </Tooltip>

          <Tooltip title={t('shared.check.tooltip.source.providePassword')}>
            <Button type="dashed" size="large" icon={<QuestionOutlined />} onClick={check}>
              {t('shared.check.label')}
            </Button>
          </Tooltip>

          <Link to={prevUrl}>
            <Button htmlType="button" size="large">
              {t(`${Feature.SHARED}:cancel`)}
            </Button>
          </Link>
        </Space>
      </Col>
    </Row>
  );
};
