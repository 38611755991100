import axios from 'axios';
import {
  FindAllPoliciesResponse,
  Policy as BePolicy,
  FindAllPoliciesRequest,
  FindAllPolicyTemplatesResponse,
} from '@pccr-ifc/pccr-ifc';

import { Policy } from '../../../shared/models/policy';
import { Config } from '../../../shared/models/config';
import { useService } from '../../../shared/hooks/use-service';

function createPolicyService(config: Config) {
  const BASE_PATH = '/policy';

  const findPolicies = (request: FindAllPoliciesRequest) =>
    axios.post<FindAllPoliciesResponse>(`${config.API_URL}${BASE_PATH}/findAll`, request, {
      withCredentials: true,
    });

  const getPolicy = (id: Policy['id']) =>
    axios.get<BePolicy>(`${config.API_URL}${BASE_PATH}/${id}`, {
      withCredentials: true,
    });

  const getTemplates = () =>
    axios.get<FindAllPolicyTemplatesResponse>(`${config.API_URL}${BASE_PATH}/template`, {
      withCredentials: true,
    });

  const createPolicy = (request: { policy: Omit<BePolicy, 'id'> }) =>
    axios.post<BePolicy>(`${config.API_URL}${BASE_PATH}/add`, request, {
      withCredentials: true,
    });

  const updatePolicy = ({ id, ...request }: Partial<BePolicy>) =>
    axios.patch<BePolicy>(
      `${config.API_URL}${BASE_PATH}/${id}`,
      { policy: request },
      {
        withCredentials: true,
      }
    );

  const deletePolicy = (id: number) =>
    axios.delete<void>(`${config.API_URL}${BASE_PATH}/${id}`, {
      withCredentials: true,
    });

  return { findPolicies, getTemplates, getPolicy, createPolicy, updatePolicy, deletePolicy };
}

export const usePolicyService = () => useService(createPolicyService);
