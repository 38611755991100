import { TableColumnType, Tag } from 'antd';
import { TFunction } from 'react-i18next';
import { LinkedName } from '../../../shared/components/linked-name';
import { Feature } from '../../../shared/models/features';
import { Language } from '../../../shared/models/language';
import { formatISODate } from '../../../shared/utils/date';
import { View } from '../../models/view';
import { getViewStatusTagColor } from '../../utils/color-mappers';
import { Actions } from './actions';

export const getColumns = (t: TFunction<Feature.REPORTS>, language: Language): TableColumnType<View>[] => {
  return [
    {
      title: t(`${Feature.SHARED}:name`),
      dataIndex: 'name',
      render: (name: View['name'], view: View) => <LinkedName name={name} to={`views/${view.id}`} />,
      sorter: true,
    },
    {
      title: t('views.shared.viewName.label'),
      dataIndex: 'viewName',
      sorter: true,
    },
    {
      title: t('views.shared.schemaName.label'),
      dataIndex: 'schemaName',
      sorter: true,
    },
    {
      title: t(`${Feature.SHARED}:status`),
      dataIndex: 'status',
      render: (status: View['status']) =>
        status ? <Tag color={getViewStatusTagColor(status)}>{t(`shared.status.${status.toLowerCase()}`)}</Tag> : '',
      sorter: true,
      width: 120,
    },
    {
      title: t(`${Feature.SHARED}:createdAt`),
      dataIndex: 'createdAt',
      render: (createdAt: View['createdAt']) => formatISODate(createdAt, language),
      sorter: true,
      width: 130,
      defaultSortOrder: 'descend',
    },
    {
      title: t(`${Feature.SHARED}:updatedAt`),
      dataIndex: 'updatedAt',
      render: (updatedAt: View['updatedAt']) => formatISODate(updatedAt, language),
      sorter: true,
      width: 130,
    },
    {
      key: 'action',
      align: 'right',
      render: (_, view) => <Actions view={view} />,
      width: 130,
    },
  ];
};
