import { LoadingOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { List, Modal, ModalProps } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { AppResourcePaths } from '../../../shared/constants/app-resources';
import { useTenantStats } from '../../hooks/use-tenant-stats';
import { TenantStats } from '../../models/tenant';

interface TenantStatsModalProps extends ModalProps {
  type: keyof Omit<TenantStats, 'parentGroupId'>;
  stats: Omit<TenantStats, 'parentGroupId'>;
}

export const TenantStatsModal: FC<TenantStatsModalProps> = ({ type, stats, ...props }) => {
  const { selectedResources, loading } = useTenantStats(stats, type);

  return (
    <Modal centered footer={null} keyboard {...props}>
      {loading && <LoadingOutlined css={loadingStyles} />}
      {selectedResources != null && (
        <List>
          {selectedResources.map(resource => (
            <List.Item>
              <Link css={linkStyles} to={`${AppResourcePaths[type]}/${resource[type === 'reports' ? 'uuid' : 'id']}`}>
                {resource.name}
              </Link>
            </List.Item>
          ))}
        </List>
      )}
    </Modal>
  );
};

const linkStyles = css`
  word-break: break-all;
`;

const loadingStyles = css`
  display: block;
`;
