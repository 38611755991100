import { Button, Dropdown, message } from 'antd';
import { MenuProps } from 'antd';
import { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import { useToggle } from 'react-use';

import { ConfirmationModal } from '../../../shared/components/confirmation-modal';
import { Feature } from '../../../shared/models/features';
import { translationNamespace } from '../../constants/translation-resources';
import { useDeleteUserMutation } from '../../data/mutations/user-mutations';
import { User } from '../../../shared/models/user';
import { UserStatuses } from '../../../shared/constants/user';
import { permissionSelector } from '../../../shared/data/store/selectors/auth-selectors';
import { AppResources } from '../../../shared/constants/app-resources';

export interface ActionsProps {
  user: User;
}

export const Actions: FC<ActionsProps> = ({ user }) => {
  const { t } = useTranslation(translationNamespace);
  const deleteMutation = useDeleteUserMutation();
  const permissions = useSelector(permissionSelector);
  const [showConfirmDeleteModal, toggleShowConfirmDeleteModal] = useToggle(false);

  const resourceName = user.email ?? user.username;
  const isAbleToEdit = user.status === UserStatuses.APPROVED || user.status === UserStatuses.PASSWORD_REQUIRED;

  const remove = useCallback(() => {
    deleteMutation.mutate(user.id, {
      onSuccess: () => {
        message.success(t('users.shared.deleteSuccessMessage'));
        toggleShowConfirmDeleteModal();
      },
    });
  }, [deleteMutation, t, toggleShowConfirmDeleteModal, user.id]);

  const items = useMemo<MenuProps['items']>(() => {
    const menuItems: MenuProps['items'] = [];

    if (isAbleToEdit ? permissions[AppResources.USER]['UPDATE'] : true) {
      menuItems.push({
        key: 'edit',
        label: (
          <Link to={`users/${user.id}`}>
            {t(`${isAbleToEdit ? `${Feature.SHARED}:edit` : `${Feature.SHARED}:view`}`)}
          </Link>
        ),
      });
    }
    if (permissions[AppResources.USER]['DELETE']) {
      menuItems.push({
        key: 'remove',
        onClick: () => toggleShowConfirmDeleteModal(),
        label: t(`${Feature.SHARED}:remove`),
      });
    }

    return menuItems;
  }, [isAbleToEdit, permissions, user.id, t, toggleShowConfirmDeleteModal]);

  return (
    <>
      {items.length > 0 && (
        <>
          <Dropdown menu={{ items }} trigger={['click']}>
            <Button type="link" icon={<MenuOutlined />}>
              {t(`${Feature.SHARED}:actions`)}
            </Button>
          </Dropdown>
          <ConfirmationModal
            open={showConfirmDeleteModal}
            title={t(`${Feature.SHARED}:areYouSure`)}
            onCancel={toggleShowConfirmDeleteModal}
            onOk={remove}
            content={t(`${Feature.SHARED}:confirmDeleteMsg`, {
              resourceName,
              buttonTitle: t(`${Feature.SHARED}:confirm`),
            })}
          />
        </>
      )}
    </>
  );
};
