import { TableProps } from 'antd';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Document, DocumentSearchResults } from '../../models/document';
import { translationNamespace } from '../../constants/translation-resources';
import { getColumns } from './columns';
import { CustomTable } from '../../../shared/components/table';

export type DocumentsTableProps = Omit<TableProps<Document>, 'columns' | 'rowKey' | 'dataSource'> & {
  dataSource: DocumentSearchResults;
};

export const DocumentsTable: FC<DocumentsTableProps> = ({ dataSource, ...props }) => {
  const { t } = useTranslation(translationNamespace);
  const columns = useMemo(() => getColumns(t, dataSource?.infoColumns), [t, dataSource?.infoColumns]);

  return (
    <CustomTable<Document>
      dataSource={dataSource?.resources}
      columns={columns}
      rowKey={doc => doc.documentId}
      {...props}
    />
  );
};
