import { User as BeUser } from '@pccr-ifc/pccr-ifc';

import { convertEmptyStringObjPropsToNull } from '../../../shared/utils/object';
import { UserProfileUpsert } from '../../models/user-profile';

export function convertToUpsertRequest(values: UserProfileUpsert): { user: Partial<BeUser> } {
  const user = {
    firstName: values.firstName,
    middleName: values.middleName,
    lastName: values.lastName,
    description: values.description,
    mobilePhone: values.mobilePhone,
  };

  return { user: convertEmptyStringObjPropsToNull(user) };
}
