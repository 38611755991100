import { Button, Card, Col, Form, message, Row, Space } from 'antd';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { CheckOutlined } from '@ant-design/icons';

import { useQuery } from '../../../shared/hooks/use-query';
import { CommonQueryParamKey } from '../../../shared/models/common-query-params';
import { Feature } from '../../../shared/models/features';
import { TenantUpsertForm, TenantUpsertFormValues } from '../../components/tenant-upsert-form';
import { translationNamespace } from '../../constants/translation-resources';
import { convertFormValuesToTenantUpsert } from '../../data/converters/tenant-converters';
import { useCreateTenantMutation } from '../../data/mutations/tenant-mutations';
import { NewResourceQueryParamKey } from '../../models/params';
import { AppResourcePaths } from '../../../shared/constants/app-resources';

export const TenantNewView: FC = () => {
  const { t } = useTranslation(translationNamespace);
  const { push: routerPush } = useHistory();
  const [form] = Form.useForm<TenantUpsertFormValues>();
  const query = useQuery();
  const mutation = useCreateTenantMutation();
  const parentId = Number(query.get(NewResourceQueryParamKey.TENANT) ?? undefined);

  const parsedPrev = query.get(CommonQueryParamKey.PREV);
  const prevUrl = parsedPrev ?? AppResourcePaths.tenants;

  const submit = (values: TenantUpsertFormValues) => {
    const newTenant = convertFormValuesToTenantUpsert(values);

    mutation.mutate(newTenant, {
      onSuccess: () => {
        message.success(t('tenants.shared.createSuccessMessage'));
        routerPush(parsedPrev ?? `${AppResourcePaths.tenants}`);
      },
    });
  };

  const triggerSubmit = () => {
    form.submit();
  };

  useEffect(() => {
    if (parentId) {
      form.setFieldsValue({ parent: parentId });
    }
  }, [form, parentId]);

  return (
    <Row gutter={[16, 24]}>
      <Col span={24}>
        <Card title={t(`${Feature.SHARED}:details`)}>
          <TenantUpsertForm form={form} onSubmit={submit} />
        </Card>
      </Col>
      <Col>
        <Space>
          <Button type="primary" htmlType="submit" size="large" icon={<CheckOutlined />} onClick={triggerSubmit}>
            {t(`${Feature.SHARED}:create`)}
          </Button>
          <Link to={prevUrl}>
            <Button htmlType="button" size="large">
              {t(`${Feature.SHARED}:cancel`)}
            </Button>
          </Link>
        </Space>
      </Col>
    </Row>
  );
};
