import { Form, Input, Modal, ModalProps } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';

import { Feature } from '../../../shared/models/features';
import { Tenant } from '../../../shared/models/tenant';
import { translationNamespace } from '../../constants/translation-resources';
import { onlyNumbersRule, redundantSpacebarsRule } from '../../../shared/utils/form-rules';

enum EditTenantFormField {
  NAME = 'name',
  CUSTOMER_ID = 'customerId',
}

export interface EditTenantFormValues {
  [EditTenantFormField.NAME]: string;
  [EditTenantFormField.CUSTOMER_ID]: string;
}

interface EditTenantModalProps extends ModalProps {
  tenant?: Tenant;
  onSubmit?: (values: EditTenantFormValues) => void;
  onCancel?: () => void;
}

export const EditTenantModal: FC<EditTenantModalProps> = ({
  tenant,
  onSubmit,
  onOk,
  okButtonProps,
  onCancel,
  ...props
}) => {
  const { t } = useTranslation(translationNamespace);
  const [form] = Form.useForm<EditTenantFormValues>();

  const triggerSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    form.submit();
    onOk?.(event);
  };

  const submit = (values: EditTenantFormValues) => {
    onSubmit?.(values);
  };

  const closeAndRevertChanges = () => {
    onCancel();
    form.resetFields();
  };

  return (
    <Form
      form={form}
      layout="horizontal"
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 18 }}
      requiredMark={false}
      onFinish={submit}
    >
      <Modal
        {...props}
        centered
        title={t('tenants.single.edit')}
        okText={t(`${Feature.SHARED}:save`)}
        cancelText={t(`${Feature.SHARED}:cancel`)}
        okButtonProps={{ ...okButtonProps, icon: <CheckOutlined />, htmlType: 'submit' }}
        onOk={triggerSubmit}
        onCancel={closeAndRevertChanges}
      >
        <Form.Item
          label={t(`${Feature.SHARED}:name`)}
          name={EditTenantFormField.NAME}
          css={fullWidthStyles}
          rules={[{ max: 180 }, redundantSpacebarsRule(t)]}
          initialValue={tenant?.name}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('tenants.shared.customerId')}
          name={EditTenantFormField.CUSTOMER_ID}
          rules={[{ len: 6 }, onlyNumbersRule(t)]}
          initialValue={tenant?.customerId}
        >
          <Input css={fullWidthStyles} placeholder={t('tenants.upsert.customerId.placeholder')} />
        </Form.Item>
      </Modal>
    </Form>
  );
};

const fullWidthStyles = css`
  width: 100%;
`;
