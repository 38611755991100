import { css } from '@emotion/react';
import { Form, FormInstance, Input } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { translationNamespace } from '../../constants/translation-resources';
import {
  userPasswordRulesWithoutUsernameRule,
  userPasswordCurrentUsernameRule,
} from '../../../shared/utils/form-rules';
import { userSelector } from '../../../shared/data/store/selectors/auth-selectors';
import { showOnlyOneError } from '../../../shared/styles/form-styles';

export enum UserProfilePasswordChangeFormField {
  CURRENT_PASSWORD = 'currentPassword',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirmPassword',
}

export interface UserProfilePasswordChangeFormValues {
  [UserProfilePasswordChangeFormField.CURRENT_PASSWORD]: string;
  [UserProfilePasswordChangeFormField.PASSWORD]: string;
  [UserProfilePasswordChangeFormField.CONFIRM_PASSWORD]: string;
}

interface Props {
  form: FormInstance<UserProfilePasswordChangeFormValues>;
  onSubmit: (values: UserProfilePasswordChangeFormValues) => void;
}

export const UserProfilePasswordChangeForm: FC<Props> = ({ form, onSubmit }) => {
  const { t } = useTranslation(translationNamespace);
  const currentUser = useSelector(userSelector);

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      layout="horizontal"
      labelCol={{ lg: 4, xl: 4 }}
      wrapperCol={{ lg: 16, xl: 10 }}
      requiredMark={false}
    >
      <Form.Item
        label={t('userProfile.changePassword.current.label')}
        name={UserProfilePasswordChangeFormField.CURRENT_PASSWORD}
        rules={[{ required: true }]}
      >
        <Input.Password placeholder={t('userProfile.changePassword.current.placeholder')} />
      </Form.Item>
      <Form.Item
        label={t('userProfile.changePassword.new.label')}
        name={UserProfilePasswordChangeFormField.PASSWORD}
        rules={[...userPasswordRulesWithoutUsernameRule(t), userPasswordCurrentUsernameRule(currentUser.username, t)]}
        css={showOnlyOneError}
      >
        <Input.Password placeholder={t('userProfile.changePassword.new.placeholder')} />
      </Form.Item>
      <Form.Item
        label={t('shared.confirmPassword.label')}
        name={UserProfilePasswordChangeFormField.CONFIRM_PASSWORD}
        css={marginBottomStyles}
        dependencies={[UserProfilePasswordChangeFormField.PASSWORD]}
        rules={[
          { required: true },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue(UserProfilePasswordChangeFormField.PASSWORD) === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('shared.confirmPassword.error')));
            },
          }),
        ]}
      >
        <Input.Password placeholder={t('userProfile.changePassword.confirm.placeholder')} />
      </Form.Item>
    </Form>
  );
};

const marginBottomStyles = css`
  margin-bottom: 52px;
`;
