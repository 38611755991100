import { Button, Form, Input, Modal, ModalProps, Select, Space } from 'antd';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PolicyTemplate } from '@pccr-ifc/pccr-ifc';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { translationNamespace } from '../../constants/translation-resources';
import { Feature } from '../../../shared/models/features';
import { redundantSpacebarsRule } from '../../../shared/utils/form-rules';
import { isRequestArrayPattern } from '../../utils/policy-utils';
import { useGetPolicyTemplateQuery } from '../../data/queries/policy-queries';

enum GeneratePolicyFormField {
  NAME = 'name',
  TYPE = 'type',
  REQUEST = 'request',
  REQUEST_KEY = 'request_key',
  REQUEST_TYPE = 'request_key',
}

export interface GeneratePolicyFormValues {
  [GeneratePolicyFormField.NAME]: string;
  [GeneratePolicyFormField.TYPE]: string;
  [GeneratePolicyFormField.REQUEST]: string[];
}

interface CreatePolicyModalProps extends ModalProps {
  onSubmit?: (values: GeneratePolicyFormValues, template: PolicyTemplate) => void;
}

const generatePolicyInitialValue: string[] = [''];

export const GeneratePolicyModal: FC<CreatePolicyModalProps> = ({ onSubmit, onCancel, ...props }) => {
  const { t } = useTranslation(translationNamespace);
  const [form] = Form.useForm<GeneratePolicyFormValues>();
  const [currentTemplate, setCurrentTemplate] = useState<PolicyTemplate>();
  const { data, isLoading } = useGetPolicyTemplateQuery(props.open);

  const triggerSubmit = () => {
    form.submit();
  };

  const submit = (values: GeneratePolicyFormValues) => {
    onSubmit?.(values, currentTemplate);
  };

  const closeAndRevertChanges = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onCancel(event);
    form.resetFields();
  };

  const handleChange = (templateName: string) => {
    const currentTemplate = data.templates.find(template => template.name === templateName);

    setCurrentTemplate(currentTemplate);
    form.setFieldValue(GeneratePolicyFormField.REQUEST, generatePolicyInitialValue);
  };

  return (
    <Modal
      {...props}
      centered
      title={t('policies.list.generateNew')}
      onOk={triggerSubmit}
      okText={t(`${Feature.SHARED}:generate`)}
      onCancel={closeAndRevertChanges}
      afterClose={() => setCurrentTemplate(undefined)}
      destroyOnClose
    >
      <Form
        form={form}
        layout="horizontal"
        labelCol={{ span: 3 }}
        preserve={false}
        requiredMark={false}
        onFinish={submit}
      >
        <Form.Item
          label={t(`${Feature.SHARED}:name`)}
          name={GeneratePolicyFormField.NAME}
          css={fullWidthStyles}
          rules={[{ required: true, max: 180 }, redundantSpacebarsRule(t)]}
        >
          <Input placeholder={t('policies.upsert.placeholder')} />
        </Form.Item>

        <Form.Item label={t(`${Feature.SHARED}:type`)} name={GeneratePolicyFormField.TYPE} rules={[{ required: true }]}>
          <Select loading={isLoading} allowClear onChange={handleChange}>
            {data?.templates.map((template, i) => (
              <Select.Option key={i} label={template.name} value={template.name}>
                {template.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {currentTemplate !== undefined && (
          <Form.List name={GeneratePolicyFormField.REQUEST} initialValue={generatePolicyInitialValue}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...field }, index) => (
                  <StyledSpace key={key} align="baseline">
                    <Form.Item {...field} name={GeneratePolicyFormField.REQUEST_KEY} wrapperCol={{ span: 24 }}>
                      <Input placeholder={t(`${Feature.SHARED}:request`)} disabled />
                    </Form.Item>
                    <Form.Item {...field} name={GeneratePolicyFormField.REQUEST_TYPE} wrapperCol={{ span: 24 }}>
                      <Input placeholder={currentTemplate?.request.replace(/::.*$/, '')} disabled />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={name}
                      messageVariables={{ label: t(`${Feature.SHARED}:value`) }}
                      key={key}
                      rules={[{ required: true, type: 'string' }]}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} hidden={index === 0} />
                  </StyledSpace>
                ))}
                {isRequestArrayPattern(currentTemplate) && (
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      {t('policies.list.add')}
                    </Button>
                  </Form.Item>
                )}
              </>
            )}
          </Form.List>
        )}
      </Form>
    </Modal>
  );
};

const fullWidthStyles = css`
  width: 100%;
`;

const marginBottomStyles = css`
  margin-bottom: 8px;
`;

const StyledSpace = styled(Space)`
  display: flex;

  ${marginBottomStyles}

  > div:not(:last-of-type) {
    width: 100%;
  }
`;
