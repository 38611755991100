import { ResourceSearchResult } from '../../shared/models/api/response';
import { BeDocumentFilters } from './batch';

export type BatchAndDocumentStatus =
  | 'waiting'
  | 'done'
  | 'rejected'
  | 'processed'
  | 'approved'
  | 'approved_quantity'
  | 'approved_layout';

export type DocumentId = string;

export interface Document {
  documentId: DocumentId;
  application: string;
  dataEntry: string;
  status: BatchAndDocumentStatus;
  fromPage: string;
  toPage: string;
  infos: Record<string, string>;
}

export interface DocumentSearchResults extends ResourceSearchResult<Document> {
  infoColumns: string[];
}

export interface FindDocumentsQueryParams {
  batchName: string;
  page: number;
  pageSize: number;
  search?: string;
  filter?: BeDocumentFilters;
  wId: string;
}

export const disabledBatchAndDocumentStatus: BatchAndDocumentStatus[] = ['rejected', 'processed'];
export const approvedBatchAndDocumentStatus: BatchAndDocumentStatus[] = ['approved'];
