import { FC, useEffect, useState } from 'react';
import { MaxBreakpointsTypes } from '../../models/breakpoints';
import { BreakpointContext } from './breakpoint-context';
import { breakpointQueries, breakpointQueriesKeys } from '../../constants/breakpoints';

export const BreakpointProvider: FC = ({ children }) => {
  const [queryMatch, setQueryMatch] = useState<Partial<Record<MaxBreakpointsTypes, boolean>>>({
    md: false,
    lg: false,
    xl: false,
  });

  useEffect(() => {
    const mediaQueryLists: Partial<Record<MaxBreakpointsTypes, MediaQueryList>> = {};
    let isAttached = false;

    const handleQueryListener = () => {
      const updatedMatches = breakpointQueriesKeys.reduce<Partial<Record<MaxBreakpointsTypes, boolean>>>(
        (acc, media) => {
          acc[media] = mediaQueryLists[media]?.matches;
          return acc;
        },
        {}
      );

      setQueryMatch(updatedMatches);
    };

    if (window?.matchMedia) {
      const matches: Partial<Record<MaxBreakpointsTypes, boolean>> = {};

      breakpointQueriesKeys.forEach(media => {
        mediaQueryLists[media] = window.matchMedia(breakpointQueries[media]);
        matches[media] = mediaQueryLists[media].matches;
      });
      setQueryMatch(matches);

      isAttached = true;
      breakpointQueriesKeys.forEach(media => {
        mediaQueryLists[media].addEventListener('change', handleQueryListener);
      });
    }

    return () => {
      if (isAttached) {
        breakpointQueriesKeys.forEach(media => {
          mediaQueryLists[media].removeEventListener('change', handleQueryListener);
        });
      }
    };
  }, []);

  return <BreakpointContext.Provider value={queryMatch}>{children}</BreakpointContext.Provider>;
};
