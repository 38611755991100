import { Checkbox, Descriptions, Divider, Drawer, DrawerProps, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { format } from 'date-fns';
import { useToggle } from 'react-use';

import { formatISODate } from '../../../shared/utils/date';
import { getLanguageFromI18nLanguage } from '../../../shared/utils/language';
import { translationNamespace } from '../../constants/translation-resources';
import { Job } from '../../models/job';
import { formatCron, isValidCRON } from '../../utils/cron';
import { Feature } from '../../../shared/models/features';
import { useLogsQueryForTimeline } from '../../../logs/data/queries/log-queries';
import { LogsTimeline } from '../../../shared/components/logs-timeline';
import { UserName } from '../../../shared/components/user-name';
import { LogsFilters } from '../../../logs/models/log';
import { getJobStatusTagColor } from '../../utils/color-mappers';
import { i18nDateFnsLocaleMap } from '../../../shared/constants/date-fns';
import { formatSeconds } from '../../../shared/utils/number';

interface JobDetailsDrawerProps extends DrawerProps {
  job: Job;
}

export const JobDetailsDrawer = ({ job, ...props }: JobDetailsDrawerProps): JSX.Element => {
  const { t, i18n } = useTranslation(translationNamespace);
  const [includeGetLogs, toggleIncludeGetLogs] = useToggle(false);
  const filters: LogsFilters = {
    entityType: 'schedule',
    resourceId: [job.id],
    method: includeGetLogs ? undefined : ['PATCH', 'POST', 'PUT', 'DELETE'],
  };
  const logQueryResult = useLogsQueryForTimeline(filters, { enabled: props.open });

  return (
    <Drawer {...props} title={t(`${Feature.SHARED}:details`)} width={400} placement="right" destroyOnClose>
      <Descriptions column={1} bordered css={withMarginBottom}>
        <Descriptions.Item label={t(`${Feature.SHARED}:name`)}>{job.name}</Descriptions.Item>
        <Descriptions.Item label={t('jobs.shared.schedule')}>
          {isValidCRON(job.cron) && formatCron(job.cron, getLanguageFromI18nLanguage(i18n.language))}
        </Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:status`)}>
          {job.status && (
            <Tag color={getJobStatusTagColor(job.status)}>{t(`jobs.shared.status.${job.status.toLowerCase()}`)}</Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item label={t('shared.enabled')}>
          <Tag color={job.enabled === true ? 'green' : 'red'}>
            {t(`${Feature.SHARED}:${job.enabled ? `yes` : `no`}`)}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label={t('jobs.shared.overwritable')}>
          <Tag color={job.overwritable === true ? 'green' : 'red'}>
            {t(`${Feature.SHARED}:${job.overwritable ? `yes` : `no`}`)}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label={t('jobs.single.executionStart')}>
          {job.executionStart && format(job.executionStart, 'Pp', { locale: i18nDateFnsLocaleMap[i18n.language] })}
        </Descriptions.Item>
        <Descriptions.Item label={t('jobs.single.executionDuration')}>
          {formatSeconds(job.executionDuration, getLanguageFromI18nLanguage(i18n.language))}
        </Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:error`)}>{job.errors}</Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:createdAt`)}>
          {job.createdAt && formatISODate(job.createdAt, getLanguageFromI18nLanguage(i18n.language))}
        </Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:createdBy`)}>
          <UserName userId={logQueryResult.isLoading ? undefined : job.createdBy} />
        </Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:updatedAt`)}>
          {job.updatedAt && formatISODate(job.updatedAt, getLanguageFromI18nLanguage(i18n.language))}
        </Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:updatedBy`)}>
          <UserName userId={logQueryResult.isLoading ? undefined : job.updatedBy} />
        </Descriptions.Item>
      </Descriptions>

      <Divider>{t(`${Feature.SHARED}:log`)}</Divider>
      <Checkbox checked={includeGetLogs} onChange={toggleIncludeGetLogs} css={withMarginBottom}>
        {t(`${Feature.SHARED}:includeGetLogs`)}
      </Checkbox>
      <LogsTimeline queryResult={logQueryResult} />
    </Drawer>
  );
};

const withMarginBottom = css`
  margin-bottom: 32px;
`;
