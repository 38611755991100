import { Button, Card, Col, Form, message, Row, Space, Tooltip } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { CheckOutlined, QuestionOutlined } from '@ant-design/icons';
import { unwrapResult } from '@reduxjs/toolkit';

import { useQuery } from '../../../shared/hooks/use-query';
import { CommonQueryParamKey } from '../../../shared/models/common-query-params';
import { Feature } from '../../../shared/models/features';
import { DefinitionUpsertForm, DefinitionUpsertFormValues } from '../../components/definition-upsert-form';
import { translationNamespace } from '../../constants/translation-resources';
import { convertFormValuesToDefinition } from '../../data/converters/definition-converters';
import { useCreateDefinitionMutation } from '../../data/mutations/definition-mutations';
import { useDefinitionService } from '../../data/services/use-definition-service';
import { checkDefinition } from '../../data/store/thunks/definition-thunks';
import { AppResourcePaths } from '../../../shared/constants/app-resources';
import { displayErrors } from '../../../shared/utils/error';
import { isFormValidationError } from '../../../shared/utils/types';

export const DefinitionNewView: FC = () => {
  const { t } = useTranslation(translationNamespace);
  const dispatch = useDispatch();
  const { push: routerPush } = useHistory();
  const [form] = Form.useForm<DefinitionUpsertFormValues>();
  const query = useQuery();
  const definitionService = useDefinitionService();
  const mutation = useCreateDefinitionMutation();

  const parsedPrev = query.get(CommonQueryParamKey.PREV);
  const prevUrl = parsedPrev ?? AppResourcePaths.definitions;

  const submit = (values: DefinitionUpsertFormValues) => {
    const definition = convertFormValuesToDefinition(values);

    mutation.mutate(definition, {
      onSuccess: () => {
        message.success(t('definitions.shared.createSuccessMessage'));
        routerPush(parsedPrev ?? `${AppResourcePaths.definitions}`);
      },
    });
  };

  const triggerSubmit = () => {
    form.submit();
  };

  const check = async () => {
    const validationResult = await form.validateFields().catch(error => {
      return error;
    });

    if (isFormValidationError(validationResult)) {
      return;
    }

    const definition = convertFormValuesToDefinition(form.getFieldsValue());

    dispatch(checkDefinition({ request: definition, service: definitionService }))
      .then(unwrapResult)
      .then(() => {
        message.success(t('shared.check.successful'));
      })
      .catch(error => displayErrors(error, t));
  };

  return (
    <Row gutter={[16, 24]}>
      <Col span={24}>
        <Card title={t(`${Feature.SHARED}:details`)}>
          <DefinitionUpsertForm form={form} onSubmit={submit} />
        </Card>
      </Col>
      <Col>
        <Space>
          <Tooltip title={t('shared.check.tooltip.definition')}>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              icon={<CheckOutlined />}
              loading={mutation.isLoading}
              onClick={triggerSubmit}
            >
              {t(`${Feature.SHARED}:create`)}
            </Button>
          </Tooltip>
          <Button type="dashed" size="large" icon={<QuestionOutlined />} onClick={check}>
            {t('shared.check.label')}
          </Button>
          <Link to={prevUrl}>
            <Button htmlType="button" size="large">
              {t(`${Feature.SHARED}:cancel`)}
            </Button>
          </Link>
        </Space>
      </Col>
    </Row>
  );
};
