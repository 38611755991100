import { Button, Popover, Timeline } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { UseInfiniteQueryResult } from 'react-query';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { formatISODate } from '../../utils/date';
import { isNonEmptyObject } from '../../utils/object';
import { translationNamespace } from '../../constants/translation-resources';
import { Log } from '../../models/log';
import { ResourceSearchResult } from '../../models/api/response';
import { getLanguageFromI18nLanguage } from '../../utils/language';
import { UserName } from '../user-name';
import { Feature } from '../../models/features';

interface LogsTimelineProps {
  queryResult: UseInfiniteQueryResult<ResourceSearchResult<Log>, unknown>;
}

export const LogsTimeline = ({ queryResult }: LogsTimelineProps): JSX.Element => {
  const { t, i18n } = useTranslation(translationNamespace);

  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = queryResult;
  const allItems = useMemo(() => data?.pages?.flatMap(page => page.resources) ?? [], [data?.pages]);

  const generateLogDataBlock = (log: Log) => (
    <>
      <div>{`${t('method')}: ${log.method}`}</div>
      <div>{`${t('path')}: ${log.path}`}</div>
      <div>
        {t('user')}: <UserName userId={log.userId} />
      </div>
    </>
  );

  const logDetails = (log: Log) => <PopoverDetailsBox>{JSON.stringify(log.body, undefined, 3)}</PopoverDetailsBox>;

  const items =
    allItems.length > 0 &&
    allItems.map((log, index) => ({
      key: index,
      children: isNonEmptyObject(log.body) ? (
        <Popover placement="left" content={logDetails(log)} title={t('details')} css={popoverBorder}>
          <div>{generateLogDataBlock(log)}</div>
        </Popover>
      ) : (
        generateLogDataBlock(log)
      ),
      label: formatISODate(log.createdAt, getLanguageFromI18nLanguage(i18n.language)),
    }));

  return (
    <>
      <Timeline
        mode="left"
        // If `hasNextPage` display dotted timeline as an empty pending dot
        pending={isLoading ? t(`${Feature.SHARED}:loadingMessage`) : hasNextPage}
        pendingDot={isLoading || isFetchingNextPage ? undefined : <></>}
        items={items}
      />
      {hasNextPage && (
        <Button block onClick={() => fetchNextPage()} disabled={isFetchingNextPage}>
          {t(`${Feature.SHARED}:loadMore`)}
        </Button>
      )}
    </>
  );
};

const PopoverDetailsBox = styled('pre')`
  max-height: 320px;
  padding-right: 30px;
  max-width: 500px;
`;

const popoverBorder = css`
  border: 2px solid silver;
  border-radius: 5px;
  padding-left: 1px;
`;
