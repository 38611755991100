import axios from 'axios';
import { LoginResponse, RefreshResponse } from '@pccr-ifc/pccr-ifc';

import { AnyApiResponse } from '../../utils/types';
import { Config } from '../../models/config';
import { useService } from '../../hooks/use-service';

const BASE_PATH = '/auth';

function createAuthService(config: Config) {
  const register = (request: AnyApiResponse) => axios.post(`${config.API_URL}${BASE_PATH}/register`, request);

  const verifyEmail = (token: string) =>
    axios.get(`${config.API_URL}${BASE_PATH}/confirmEmail`, {
      params: {
        token,
      },
    });

  const resendEmail = (email: string) =>
    axios.get(`${config.API_URL}${BASE_PATH}/resendEmail`, {
      params: {
        email,
      },
    });

  const requestPasswordChange = (email: string) =>
    axios.post(`${config.API_URL}${BASE_PATH}/forgotPassword`, {
      email,
    });

  const changePassword = (request: AnyApiResponse, token: string) =>
    axios.post(`${config.API_URL}${BASE_PATH}/changeForgottenPassword`, request, {
      params: {
        token,
      },
    });

  const login = (username: string, password: string) =>
    axios.post<LoginResponse>(
      `${config.API_URL}${BASE_PATH}/login`,
      {
        username,
        password,
      },
      { withCredentials: true }
    );

  const refresh = () =>
    axios.post<RefreshResponse>(`${config.API_URL}${BASE_PATH}/refresh`, null, {
      withCredentials: true,
    });

  const logout = () => axios.post(`${config.API_URL}${BASE_PATH}/logout`, null, { withCredentials: true });

  return {
    login,
    refresh,
    logout,
    register,
    verifyEmail,
    resendEmail,
    requestPasswordChange,
    changePassword,
  };
}

export const useAuthService = () => useService(createAuthService);

export type AuthService = ReturnType<typeof useAuthService>;
