import { css } from '@emotion/react';
import { Tag } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Role } from '../../../shared/models/role';
import { translationNamespace } from '../../constants/translation-resources';

interface RolesCellProps {
  roles?: Role[];
  isAdmin: boolean;
}

export const RolesCell: FC<RolesCellProps> = ({ roles, isAdmin }) => {
  const { t } = useTranslation(translationNamespace);

  return (
    <>
      {isAdmin ? (
        <Tag color={'magenta'}>{t('shared.superAdmin')}</Tag>
      ) : (
        roles?.map((role, index) => (
          <Tag color={role.isDefault ? '' : 'blue'} css={whiteSpaceNormal} key={index}>
            {role.name}
          </Tag>
        ))
      )}
    </>
  );
};

const whiteSpaceNormal = css`
  white-space: normal;
`;
