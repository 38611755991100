export function secondsToMMSS(value: number): string {
  let minutes: number | string = Math.floor(value / 60);
  let seconds: number | string = value - minutes * 60;

  if (minutes < 10) {
    minutes = '0' + minutes;
  }

  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  if (value >= 0) {
    return minutes + ':' + seconds;
  } else {
    return null;
  }
}
