import isEqual from 'lodash.isequal';

function defaultPredicate(val: unknown): boolean {
  return val == null || (typeof val === 'string' && val.length < 1) || (Array.isArray(val) && val.length === 0);
}

export function cleanObject<T extends object = object>(obj: T, predicate = defaultPredicate): Partial<T> {
  Object.entries(obj).forEach(([key, val]) => {
    if (predicate(val)) {
      delete obj[key];
    }
  });
  return obj;
}

export function deleteUndefinedObjProps<T extends object = object>(obj: T): Partial<T> {
  return cleanObject(obj, val => {
    return val === undefined;
  });
}

export function deleteUndefinedOrNullObjProps<T extends object = object>(obj: T): Partial<T> {
  return cleanObject(obj, val => {
    return val == null;
  });
}

export function deleteEmptyArrayObjProps<T extends object = object>(obj: T): Partial<T> {
  return cleanObject(obj, val => {
    return Array.isArray(val) && val.length === 0;
  });
}

export function deleteEmptyStringObjProps<T extends object = object>(obj: T): Partial<T> {
  return cleanObject(obj, val => {
    return typeof val === 'string' && val.length < 1;
  });
}

export function isNonEmptyObject(value: unknown): value is Record<PropertyKey, unknown> {
  return value && typeof value === 'object' && Object.keys(value).length > 0;
}

export function convertEmptyStringObjPropsToNull<T extends object = object>(obj: T) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    acc = {
      ...acc,
      [key]: value === '' ? null : value,
    };
    return acc;
  }, {} as T);
}

export function convertEmptyStringsToUndefined<T extends object = object>(values: T) {
  return Object.fromEntries(Object.entries(values).map(([key, value]) => [key, value === '' ? undefined : value])) as T;
}

export function isPlainObject(value: unknown): boolean {
  return value !== null && typeof value === 'object' && Object.getPrototypeOf(value) === Object.prototype;
}

export function returnChangedObjectProperties<T extends object>(oldObj: T, newObj: T): Partial<T> {
  const changedProperties = Object.entries(newObj).reduce((acc, [key, value]) => {
    if (isPlainObject(value) && isPlainObject(oldObj[key])) {
      const changedNestedProperties = returnChangedObjectProperties(oldObj[key], value);

      if (changedNestedProperties != null) {
        acc = {
          ...acc,
          [key]: changedNestedProperties,
        };
      }
    } else if (!isEqual(value, oldObj[key])) {
      acc = {
        ...acc,
        [key]: value,
      };
    }

    return acc;
  }, {});

  return Object.keys(changedProperties).length > 0 ? changedProperties : null;
}
