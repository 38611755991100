import { Button, Form, Input, message, Space, Typography } from 'antd';
import { FC, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined, ArrowRightOutlined, MailOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { unwrapResult } from '@reduxjs/toolkit';

import { useAuthService } from '../../../../shared/data/services/use-auth-service';
import { requestPasswordChangeLoadingSelector } from '../../../../shared/data/store/selectors/auth-selectors';
import { requestPasswordChange } from '../../../../shared/data/store/thunks/auth-thunks';
import { useQuery } from '../../../../shared/hooks/use-query';
import { CommonQueryParamKey } from '../../../../shared/models/common-query-params';
import { Feature } from '../../../../shared/models/features';
import { translationNamespace } from '../../../constants/translation-resources';
import { ForgotPasswordContext, ForgotPasswordFormField, ForgotPasswordFormValues } from '../forgot-password-context';
import { displayErrors } from '../../../../shared/utils/error';

const { Title, Text } = Typography;

type IntroFormValues = Pick<ForgotPasswordFormValues, ForgotPasswordFormField.EMAIL>;

export const Request: FC = () => {
  const { t } = useTranslation(translationNamespace);
  const [form] = Form.useForm();
  const { setValues } = useContext(ForgotPasswordContext);
  const dispatch = useDispatch();
  const authService = useAuthService();
  const query = useQuery();
  const requestLoading = useSelector(requestPasswordChangeLoadingSelector);
  const parsedPrev = query.get(CommonQueryParamKey.PREV);
  const prevUrl = parsedPrev ?? '/auth/login';

  const submit = useCallback(
    (values: IntroFormValues) => {
      setValues(values);
      dispatch(requestPasswordChange({ request: values[ForgotPasswordFormField.EMAIL], service: authService }))
        .then(unwrapResult)
        .then(() => {
          message.success(t('forgotPasswordView.steps.request.requestSuccessMessage'));
        })
        .catch(error => displayErrors(error, t));
    },
    [authService, dispatch, setValues, t]
  );

  return (
    <Space direction="vertical">
      <Title level={2}>{t('forgotPasswordView.steps.request.title')}</Title>
      <Text type="secondary">{t('forgotPasswordView.steps.request.description')}</Text>
      <Form layout="vertical" form={form} onFinish={submit} requiredMark={false}>
        <Form.Item
          name={ForgotPasswordFormField.EMAIL}
          label={t(`${Feature.SHARED}:email.label`)}
          rules={[{ required: true, type: 'email' }]}
        >
          <Input prefix={<MailOutlined />} placeholder={t(`${Feature.SHARED}:email.placeholder`)} type="email" />
        </Form.Item>
        <ButtonGroup>
          <Link to={prevUrl}>
            <ButtonNoPaddingLeft type="link" htmlType="button" icon={<ArrowLeftOutlined />}>
              {t('shared.back')}
            </ButtonNoPaddingLeft>
          </Link>
          <Form.Item>
            <StyledButton type="primary" htmlType="submit" icon={<ArrowRightOutlined />} loading={requestLoading}>
              {t('forgotPasswordView.steps.request.action')}
            </StyledButton>
          </Form.Item>
        </ButtonGroup>
      </Form>
    </Space>
  );
};

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const StyledButton = styled(Button)`
  margin-top: 16px;
`;

const ButtonNoPaddingLeft = styled(StyledButton)`
  padding-left: 0;
`;
