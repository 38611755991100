import { TableProps } from 'antd';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getLanguageFromI18nLanguage } from '../../../shared/utils/language';
import { translationNamespace } from '../../constants/translation-resources';
import { User } from '../../../shared/models/user';
import { getColumns } from './columns';
import { CustomTable } from '../../../shared/components/table';
import { permissionSelector } from '../../../shared/data/store/selectors/auth-selectors';

type Props = Omit<TableProps<User>, 'columns' | 'rowKey'> & {
  customActions?: (user: User) => React.ReactElement;
  sortingEnabled?: boolean;
};

export const UsersTable: FC<Props> = ({ customActions, sortingEnabled = true, ...props }) => {
  const { t, i18n } = useTranslation(translationNamespace);
  const permissions = useSelector(permissionSelector);

  const columns = useMemo(
    () => getColumns(t, getLanguageFromI18nLanguage(i18n.language), customActions, sortingEnabled, permissions),
    [i18n.language, t, customActions, sortingEnabled, permissions]
  );

  return <CustomTable<User> columns={columns} rowKey={user => user.id} {...props} />;
};
