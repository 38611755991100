import { ErrorDetails, ErrorMetaType } from '@pccr-error/pccr-error';
import { SerializedError } from '@reduxjs/toolkit';
import { message } from 'antd';
import { TFunction } from 'i18next';

import { Feature } from '../models/features';
import {
  isAxiosError,
  isBackendSqlErrorDetails,
  isBackendErrorResponse,
  isBackendPlainError,
  isArrayBuffer,
  transformBufferToObject,
} from './types';
import { capitalize } from './string';

// TODO: switch to IFC
const sqlFailedErrorCode = 'sql_failed';

interface SerializedErrorExtended extends SerializedError {
  metaCode?: ErrorMetaType;
  details?: ErrorDetails;
}

//TODO: dont export this
export function serializeError(error: unknown): SerializedErrorExtended {
  let serializedError: SerializedErrorExtended = null;

  if (error instanceof Error) {
    serializedError = {
      name: error.name,
      message: error.message,
      stack: error.stack,
    };
  }

  if (isAxiosError(error)) {
    serializedError = {
      code: error.response.status.toString(),
      name: error.name,
      stack: error.stack,
    };

    if (isArrayBuffer(error.response.data)) {
      error.response.data = transformBufferToObject(error.response.data);
    }

    if (isBackendErrorResponse(error.response.data)) {
      const { metaCode, code, details } = error.response.data;
      serializedError.metaCode = metaCode;

      if (details != null) {
        serializedError.details = details;
      }

      if (metaCode !== ErrorMetaType.Joivalidation) {
        serializedError.message = `${Feature.SHARED}:errors.${metaCode}.${code}`;
      }

      if (code === sqlFailedErrorCode) {
        serializedError.code = code;
      }
    } else if (isBackendPlainError(error.response.data)) {
      const { statusMessage } = error.response.data;
      serializedError.message = error.response.status.toString() + ' ' + statusMessage;
    } else {
      serializedError.message = `${Feature.SHARED}:unexpectedException`;
    }
  }

  return serializedError;
}

export function displayErrors(error: unknown, t: TFunction) {
  const serializedError = serializeError(error);

  if (serializedError.metaCode === ErrorMetaType.Joivalidation) {
    serializedError.details.errors.forEach(joiError =>
      message.error(capitalize(t(`${Feature.SHARED}:errors.${ErrorMetaType.Joivalidation}.${joiError.type}`, joiError)))
    );
  } else if (serializedError.code === sqlFailedErrorCode && isBackendSqlErrorDetails(serializedError.details)) {
    message.error(
      capitalize(t(serializedError.message, serializedError.details)) + ' ' + serializedError.details.message
    );
  } else {
    message.error(
      capitalize(t(serializedError.message, serializedError.details)) ?? t(`${Feature.SHARED}:somethingWentWrong`)
    );
  }
}
