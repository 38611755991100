import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { SourceWithTablesResponse } from '@pccr-ifc/pccr-ifc';

import { CommonFindAllQueryParams, QueryRequest } from '../../../shared/models/api/request';
import { deleteEmptyArrayObjProps } from '../../../shared/utils/object';
import { SourceGetResults } from '../../models/api/source';
import { Source, SourcesFilters } from '../../models/source';
import { convertSearchResponse } from '../converters/source-converters';
import { useSourceService } from '../services/use-source-service';
import { isAxiosError, isBackendErrorResponse } from '../../../shared/utils/types';
import { ResourceSearchResult } from '../../../shared/models/api/response';
import { getResourceListQueryKeyRoot, getSingleResourceQueryKey } from '../../../shared/utils/resource-query-key';
import { displayErrors } from '../../../shared/utils/error';

export function useGetSourceQuery(id: number, enabled = true) {
  const sourceService = useSourceService();
  const { t } = useTranslation();

  return useQuery<SourceWithTablesResponse, unknown, SourceGetResults>(
    getSingleResourceQueryKey('SOURCE', id),
    async () => {
      try {
        const response = await sourceService.getSource(id);
        return response.data;
      } catch (error) {
        if (isAxiosError(error) && isBackendErrorResponse(error.response.data['error'])) {
          return error.response.data as SourceWithTablesResponse;
        }
        throw error;
      }
    },
    {
      onError: error => displayErrors(error, t),
      enabled: enabled && id != null,
      select: data => data as SourceGetResults,
    }
  );
}

export function useFindSourcesQuery(
  {
    page,
    pageSize,
    searchValue,
    filters,
    order,
    attributes = ['id', 'name', 'dialect', 'host', 'port', 'databasename', 'status', 'createdAt', 'updatedAt'],
  }: CommonFindAllQueryParams<Source, SourcesFilters>,
  enabled = true
) {
  const sourceService = useSourceService();

  return useQuery<ResourceSearchResult<Source>, unknown>(
    [getResourceListQueryKeyRoot('SOURCE'), { page, pageSize }, searchValue, filters, order, attributes],
    async () => {
      const request: QueryRequest<Source, SourcesFilters> = {
        limit: pageSize,
        offset: (page - 1) * pageSize,
        attributes,
      };

      if (order?.length > 0) {
        request.order = order;
      }

      if (searchValue) {
        request.search = searchValue;
      }

      if (filters && Object.values(filters).some(val => val != null)) {
        request.filters = deleteEmptyArrayObjProps(filters);
      }

      const response = await sourceService.findSources(request);
      return convertSearchResponse(response.data);
    },
    { enabled }
  );
}
