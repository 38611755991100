import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { UserAllowedRoutes } from '../../models/allowed-required-routes';
import { convertDefaultRolesResponse, convertGetUserAllowedRoutesResponse } from '../converters/core-converters';
import { useCoreService } from '../services/use-core-service';
import { loggedInSelector } from '../../../shared/data/store/selectors/auth-selectors';
import { DefaultRole } from '../../models/default-role';

export enum CoreQueryKey {
  ALLOWED_ROUTES = 'allowedRoutes',
  DEFAULT_ROLES = 'defaultRoles',
}

export function useGetUserAllowedRoutesQuery() {
  const coreService = useCoreService();
  const loggedIn = useSelector(loggedInSelector);

  return useQuery<UserAllowedRoutes, unknown>(
    [CoreQueryKey.ALLOWED_ROUTES],
    async () => {
      const response = await coreService.getUserAllowedRoutes();
      return convertGetUserAllowedRoutesResponse(response.data);
    },
    {
      enabled: loggedIn,
      staleTime: 15 * 60 * 1000,
    }
  );
}

export function useGetDefaultRolesQuery() {
  const roleService = useCoreService();
  const loggedIn = useSelector(loggedInSelector);

  return useQuery<DefaultRole[], unknown>(
    CoreQueryKey.DEFAULT_ROLES,
    async () => {
      const response = await roleService.getDefaultRoles();
      return convertDefaultRolesResponse(response.data);
    },
    {
      enabled: loggedIn,
      staleTime: Infinity,
    }
  );
}
