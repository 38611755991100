export enum Breakpoint {
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  XXL = 'xxl',
}

export type BreakpointsTypes = Breakpoint;
export type MinBreakpointsTypes = BreakpointsTypes;
export type MaxBreakpointsTypes = Exclude<BreakpointsTypes, 'xxl'>;
