import axios from 'axios';
import {
  FindAllReportsResponse,
  FindAllReportDetailsResponse,
  ExportReportResponse,
  ExportReportRequest,
  FindAllReportsRequest,
  FindAllReportDetailsRequest,
  GetReportResponse,
  GenerateReportResponse,
} from '@pccr-ifc/pccr-ifc';

import { GenerateReportData, GenerateReportRequest } from '../../../shared/models/api/report';
import { Config } from '../../../shared/models/config';
import { useService } from '../../../shared/hooks/use-service';

function createReportService(config: Config) {
  const BASE_PATH = '/report/data';
  const REPORT_FILE_BASE_PATH = '/report/file';

  const findReports = (request: FindAllReportsRequest) =>
    axios.post<FindAllReportsResponse>(`${config.API_URL}${BASE_PATH}/findAll`, request, {
      withCredentials: true,
    });

  const getReport = async (uuid: string) => {
    const response = await axios.get<GetReportResponse>(`${config.API_URL}${BASE_PATH}/${uuid}`, {
      withCredentials: true,
    });
    return response.data.report;
  };

  const getReportDetails = (uuid: string, request: FindAllReportDetailsRequest) =>
    axios.post<FindAllReportDetailsResponse>(`${config.API_URL}${BASE_PATH}/details/${uuid}`, request, {
      withCredentials: true,
    });

  const deleteReport = (uuid: string) =>
    axios.delete<void>(`${config.API_URL}${BASE_PATH}/${uuid}`, {
      withCredentials: true,
    });

  const generateReport = (request: GenerateReportRequest) =>
    axios.post<GenerateReportResponse>(`${config.API_URL}${BASE_PATH}/generate`, request, {
      withCredentials: true,
    });

  const exportReport = (uuid: string, request: ExportReportRequest) =>
    axios.post<ExportReportResponse>(`${config.API_URL}${BASE_PATH}/export/${uuid}`, request, {
      withCredentials: true,
    });

  const checkReportSize = async (request: Pick<GenerateReportData, 'definitionId' | 'sqlParams'>) => {
    const response = await axios.post<{ report: { rows: number } }>(
      `${config.API_URL}${BASE_PATH}/getReportSize`,
      { report: request },
      {
        withCredentials: true,
      }
    );
    return response.data.report.rows;
  };

  const deleteReportFile = (id: number) =>
    axios.delete<void>(`${config.API_URL}${REPORT_FILE_BASE_PATH}/${id}`, {
      withCredentials: true,
    });

  const getPresignedUrl = (id: number) =>
    axios.get<{ url: string }>(`${config.API_URL}${REPORT_FILE_BASE_PATH}/presign/${id}`, {
      withCredentials: true,
    });

  return {
    findReports,
    getReport,
    getReportDetails,
    deleteReport,
    generateReport,
    exportReport,
    checkReportSize,
    deleteReportFile,
    getPresignedUrl,
  };
}

export type ReportService = ReturnType<typeof useReportService>;

export const useReportService = () => useService(createReportService);
