import { Button, Card, Col, Dropdown, Form, Input, message, Row, Select, Space, Tooltip } from 'antd';
import { MenuProps } from 'antd';
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useDebounce, useList, useToggle } from 'react-use';
import { DeleteOutlined, DownOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { setResourceName } from '../../../shared/data/store/slices/nav-slice';
import { useTableOptions } from '../../../shared/hooks/use-data-options';
import { Feature } from '../../../shared/models/features';
import { ResourceParams } from '../../../shared/models/resource-params';
import { ExportModal } from '../../components/export-modal';
import {
  ReportDataTable,
  ReportDataTableFilters,
  ReportDataTableFiltersForm,
} from '../../components/report-data-table';
import { ReportDetailsDrawer } from '../../components/report-details-drawer';
import { translationNamespace } from '../../constants/translation-resources';
import { useFindPresetsQuery, useGetPresetQuery } from '../../data/queries/preset-queries';
import { useFindReportDataQuery, useGetReportQuery } from '../../data/queries/report-queries';
import { ExportReportRequest } from '../../models/api/export';
import { ExportType } from '../../models/export';
import { ReportData, ReportDataFilters, ReportDataTableFiltersWithTypes } from '../../models/report';
import { generateTableColumns } from '../../utils/data-converters';
import { getLanguageFromI18nLanguage } from '../../../shared/utils/language';
import { convertReportDataTableFiltersToFilters } from '../../data/converters/report-converters';
import { ConfirmationModal } from '../../../shared/components/confirmation-modal';
import { useDeleteReportMutation, useExportReportMutation } from '../../data/mutations/report-mutations';
import { DataNotAvailableWarning } from '../../components/report-data-table/data-not-available-warning';
import { ReportFilesDrawer } from '../../components/report-files-drawer';
import { AppResourcePaths } from '../../../shared/constants/app-resources';
import { ExportTypes } from '../../constants/export';
import { NoSelectedColumnsWarning } from '../../components/report-data-table/no-selected-columns-warning';
import { permissionSelector } from '../../../shared/data/store/selectors/auth-selectors';
import { ReportFiltersTooltip } from './report-filters-tooltip';

const { Search } = Input;

const tooltip = (
  <svg viewBox="64 64 896 896" width="1em" height="1em" aria-hidden="true">
    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
    <path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"></path>
  </svg>
);

export const ReportSingleView: FC = () => {
  const { t, i18n } = useTranslation(translationNamespace);
  const dispatch = useDispatch();
  const { push: routerPush } = useHistory();
  const deleteMutation = useDeleteReportMutation();
  const exportMutation = useExportReportMutation();
  const [filtersForm] = Form.useForm<ReportDataTableFilters>();
  const { id: uuid } = useParams<ResourceParams>();
  const { page, setPage, pageSize, order, handleTableChange } = useTableOptions<ReportData>();
  const [showFilters, toggleShowFilters] = useToggle(false);
  const [showDetails, toggleShowDetails] = useToggle(false);
  const [showFiles, toggleShowFiles] = useToggle(false);
  const [showConfirmDeleteModal, toggleShowConfirmDeleteModal] = useToggle(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [exportType, setExportType] = useState<ExportType>(null);
  const [filters, setFilters] = useState<ReportDataFilters>(null);
  const [searchValue, setSearchValue] = useState<string>();
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>();
  const [
    toggledAttributes,
    {
      set: setToggledAttributes,
      push: pushToggledAttribute,
      removeAt: removeToggledAttributeAt,
      reset: resetToggledAttributes,
    },
  ] = useList<keyof ReportData>([]);
  const { data: report, isLoading: reportLoading } = useGetReportQuery(uuid);
  const { data: reportDataRes, isLoading: reportDataLoading } = useFindReportDataQuery(
    uuid,
    page,
    pageSize,
    debouncedSearchValue,
    filters,
    order,
    toggledAttributes
  );
  const [presetsSearchValue, setPresetsSearchValue] = useState<string>();
  const [presetsDebouncedSearchValue, setPresetsDebouncedSearchValue] = useState<string>();
  const { data: presetsData, isLoading: presetsLoading } = useFindPresetsQuery(
    {
      page: 1,
      pageSize: 25,
      searchValue: presetsDebouncedSearchValue,
      attributes: ['id', 'name'],
    },
    report?.definitionId,
    report?.definitionId != null
  );
  const [selectedPresetId, setSelectedPresetId] = useState<number>();
  const { data: selectedPreset, isLoading: selectedPresetLoading } = useGetPresetQuery(selectedPresetId);
  const permissions = useSelector(permissionSelector);

  const reportDataAvailable = reportDataRes?.report.executionTime != null;

  const allAttributesWithTypes = useMemo(() => {
    return report?.definition.columnTypes ?? [];
  }, [report?.definition.columnTypes]);

  const allAttributes = useMemo(() => {
    return allAttributesWithTypes.map(attrWithType => attrWithType.name);
  }, [allAttributesWithTypes]);

  const toggledAttributesWithTypes = useMemo(() => {
    return allAttributesWithTypes.filter(attrWithType => toggledAttributes.includes(attrWithType.name));
  }, [allAttributesWithTypes, toggledAttributes]);

  const attributeSelectOptions = useMemo(() => {
    return allAttributes.map((attribute, index) => (
      <Select.Option key={index} value={attribute}>
        {attribute}
      </Select.Option>
    ));
  }, [allAttributes]);

  const toggledColumns = useMemo(() => {
    const allColumns = generateTableColumns(report?.definition.columnTypes, getLanguageFromI18nLanguage(i18n.language));

    return allColumns.filter(col => toggledAttributes.includes(String(col.dataIndex)));
  }, [i18n.language, report?.definition.columnTypes, toggledAttributes]);

  const dataFiltered = useMemo(() => {
    return (
      (filters != null && Object.values(filters).some(val => val?.length > 0)) ||
      searchValue?.length > 0 ||
      toggledAttributes.length !== allAttributes.length ||
      selectedPresetId != null
    );
  }, [allAttributes.length, filters, searchValue?.length, toggledAttributes.length, selectedPresetId]);

  const presetSelectOptions = useMemo(() => {
    return (
      presetsData?.resources.map((preset, index) => (
        <Select.Option key={index} value={preset.id}>
          {preset.name}
        </Select.Option>
      )) ?? []
    );
  }, [presetsData?.resources]);

  const selectColumn = (columnKey: string) => {
    pushToggledAttribute(columnKey);
  };

  const deselectColumn = (columnKey: string) => {
    const index = toggledAttributes.indexOf(columnKey);
    if (index < 0) {
      return;
    }

    removeToggledAttributeAt(index);
  };

  const applyFilters = (filters: ReportDataTableFilters) => {
    const cleanedFiltersWithTypes = Object.entries(filters).reduce((acc, [key, value]) => {
      if (value === undefined) return acc;
      acc[key] = { value, type: toggledAttributesWithTypes.find(attribute => attribute.name === key).type };
      return acc;
    }, {} as ReportDataTableFiltersWithTypes);
    const reportDataFilters = convertReportDataTableFiltersToFilters(cleanedFiltersWithTypes);

    setSearchValue(null);
    setFilters(reportDataFilters);
    setPage(1);
  };

  const applySearch = (value: string) => {
    setFilters(null);
    setSearchValue(value);
    setDebouncedSearchValue(value);
    setPage(1);
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    setDebouncedSearchValue(e.target.value);
  };

  const applyPresetsSearch = (value: string) => {
    setPresetsSearchValue(value);
    setPresetsDebouncedSearchValue(value);
  };

  const selectPreset = (value: number) => {
    setSelectedPresetId(value);
  };

  const clearPreset = () => {
    setToggledAttributes(allAttributes);
    setSearchValue(null);
    setDebouncedSearchValue(null);
    setFilters(null);
    toggleShowFilters(false);
  };

  const getAvailablePresetFilters = useCallback(
    (presetFilters: Record<string, unknown>): ReportDataFilters => {
      if (presetFilters == null) {
        return null;
      }

      const filtersArray = Object.entries(presetFilters);
      if (filtersArray.length <= 0) {
        return null;
      }

      const filteredArray = filtersArray.filter(([key]) => allAttributes.includes(key));
      const availableFilters = filteredArray.reduce((acc, [key, value]) => {
        if (key != null && Array.isArray(value)) {
          acc = {
            ...acc,
            [key]: value,
          };
        }

        return acc;
      }, {} as ReportDataFilters);

      return availableFilters;
    },
    [allAttributes]
  );

  const remove = () => {
    if (report.uuid == null) {
      return;
    }

    deleteMutation.mutate(report.uuid, {
      onSuccess: () => {
        message.success(t('reports.shared.deleteSuccessMessage'));
        routerPush(AppResourcePaths.reports);
      },
    });
  };

  const exportData = useCallback(
    (exportType: ExportType, filtered: boolean, compression: boolean, filename: string) => {
      if (report?.uuid == null) {
        return;
      }

      setShowExportModal(false);

      let request: ExportReportRequest = {
        exportType,
        compression,
        attributes: filtered ? toggledAttributes : allAttributes,
        filename,
      };

      if (filtered) {
        request = {
          ...request,
          filters: filters != null && Object.keys(filters).length > 0 ? filters : undefined,
          order: order?.length > 0 ? order : undefined,
          search: searchValue?.length > 0 ? searchValue : undefined,
        };
      }

      exportMutation.mutate(
        { uuid: report.uuid, request },
        {
          onSuccess() {
            message.success(t('reports.single.export.successMessage'));
          },
        }
      );
    },

    [report?.uuid, toggledAttributes, allAttributes, exportMutation, filters, order, searchValue, t]
  );

  const triggerExportData = (expType: ExportType) => {
    setExportType(expType);
    setShowExportModal(true);
  };

  const items: MenuProps['items'] = Object.values(ExportTypes).map(type => ({
    key: type,
    onClick: () => triggerExportData(type),
    label: t('reports.single.export.exportTo', { type }),
  }));

  useEffect(() => {
    dispatch(setResourceName(report?.name));
  }, [dispatch, report?.name]);

  useEffect(() => {
    setToggledAttributes(allAttributes);
  }, [allAttributes, setToggledAttributes]);

  useDebounce(
    () => {
      if (searchValue != null) {
        setFilters(null);
        filtersForm.resetFields();
        setPage(1);
      }
      setDebouncedSearchValue(searchValue);
    },
    3000,
    [searchValue]
  );

  useDebounce(
    () => {
      setPresetsDebouncedSearchValue(presetsSearchValue);
    },
    3000,
    [presetsSearchValue]
  );

  useEffect(() => {
    if (selectedPreset != null) {
      setToggledAttributes(selectedPreset.query.attributes.filter(attr => allAttributes.includes(attr)));

      const availablePresetFilters = getAvailablePresetFilters(selectedPreset.query.filters);
      setFilters(availablePresetFilters);
      toggleShowFilters(availablePresetFilters != null ? true : false);

      setSearchValue(selectedPreset.query.search);
    }
  }, [allAttributes, getAvailablePresetFilters, selectedPreset, setToggledAttributes, toggleShowFilters]);

  return (
    <Row justify="space-between" gutter={[16, 24]}>
      <Col span={12}>
        <Space>
          <StyledSearch
            size="large"
            allowClear
            placeholder={t(`${Feature.SHARED}:search`)}
            onSearch={applySearch}
            onChange={handleSearchChange}
            value={searchValue}
          />
          <StyledButton type="link" onClick={toggleShowFilters}>
            {t(`${Feature.SHARED}:filters`)} <StyledDownOutlined rotate={showFilters ? 180 : 0} />
          </StyledButton>
        </Space>
      </Col>

      <Col span={12}>
        <Space align="end" css={spaceStyles}>
          <Dropdown menu={{ items }} placement="bottomRight" trigger={['click']} disabled={!reportDataAvailable}>
            <Button size="large">
              {t('reports.single.export.label')} <DownOutlined />
            </Button>
          </Dropdown>
          <Button size="large" onClick={toggleShowFiles} loading={reportLoading} disabled={report?.files?.length === 0}>
            {t('reports.single.files.label')}
          </Button>
          <Button size="large" onClick={toggleShowDetails} loading={reportLoading} disabled={report == null}>
            {t(`${Feature.SHARED}:details`)}
          </Button>
          {permissions.REPORT.DELETE && (
            <Button
              size="large"
              onClick={toggleShowConfirmDeleteModal}
              loading={reportLoading}
              disabled={report == null}
              icon={<DeleteOutlined />}
              danger
            />
          )}
        </Space>
      </Col>

      <Col span={24}>
        <Space>
          <StyledSelect
            size="large"
            filterOption={false}
            showSearch
            allowClear
            placeholder={t('reports.single.selectPreset')}
            onSearch={applyPresetsSearch}
            onChange={selectPreset}
            loading={presetsLoading}
            onClear={clearPreset}
          >
            {presetSelectOptions}
          </StyledSelect>

          <StyledSelect
            size="large"
            mode="multiple"
            placeholder={t('reports.single.allColumns')}
            allowClear
            maxTagCount={4}
            loading={reportLoading || selectedPresetLoading}
            onSelect={selectColumn}
            onDeselect={deselectColumn}
            onClear={resetToggledAttributes}
            value={toggledAttributes}
          >
            {attributeSelectOptions}
          </StyledSelect>
        </Space>
      </Col>

      {showFilters && (
        <Col span={24}>
          <StyledCard
            title={
              <>
                {t(`${Feature.SHARED}:filters`)}
                <Tooltip title={<ReportFiltersTooltip />}>{tooltip}</Tooltip>
              </>
            }
          >
            <ReportDataTableFiltersForm
              onApply={applyFilters}
              attributes={toggledAttributesWithTypes}
              form={filtersForm}
            />
          </StyledCard>
        </Col>
      )}

      <Col span={24}>
        <Card>
          {reportDataRes != null && !reportDataAvailable && <DataNotAvailableWarning />}
          {toggledColumns.length === 0 && <NoSelectedColumnsWarning />}
          <ReportDataTable
            dataSource={reportDataRes?.data}
            columns={toggledColumns}
            pagination={{
              total: reportDataRes?.count,
              current: page,
              pageSize,
              showSizeChanger: reportDataRes?.count > 10,
            }}
            loading={reportLoading || reportDataLoading || (toggledColumns.length > 0 && !reportDataAvailable)}
            scroll={{ x: toggledColumns.length * 160 }}
            onChange={handleTableChange}
          />
        </Card>
      </Col>

      {report && <ReportDetailsDrawer report={report} open={showDetails} onClose={toggleShowDetails} />}
      {report && (
        <ReportFilesDrawer files={report.files} open={showFiles} onClose={toggleShowFiles} uuid={report.uuid} />
      )}

      <ExportModal
        open={showExportModal}
        reportName={report?.name}
        dataFiltered={dataFiltered}
        exportType={exportType}
        onCancel={() => setShowExportModal(false)}
        onExport={exportData}
      />
      <ConfirmationModal
        open={showConfirmDeleteModal}
        title={t(`${Feature.SHARED}:areYouSure`)}
        onCancel={toggleShowConfirmDeleteModal}
        onOk={remove}
        content={t(`${Feature.SHARED}:confirmDeleteMsg`, {
          resourceName: report?.name,
          buttonTitle: t(`${Feature.SHARED}:confirm`),
        })}
      />
    </Row>
  );
};

const StyledSearch = styled(Search)`
  width: 100%;
  max-width: 320px;
`;

const StyledSelect = styled(Select)`
  min-width: 200px;
`;

const StyledButton = styled(Button)`
  margin: 4px 0;
`;

const StyledDownOutlined = styled(DownOutlined)`
  > svg {
    transition: transform 0.2s;
  }
`;

const spaceStyles = css`
  display: flex;
  justify-content: end;
`;

const StyledCard = styled(Card)`
  .ant-card-head-title {
    display: flex;
    align-items: center;

    svg {
      margin-left: 8px;
      cursor: help;
    }
  }
`;
