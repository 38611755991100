import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Preset as BePreset } from '@pccr-ifc/pccr-ifc';

import { CommonFindAllQueryParams, QueryRequest } from '../../../shared/models/api/request';
import { deleteEmptyArrayObjProps } from '../../../shared/utils/object';
import { Preset, PresetsFilters } from '../../models/preset';
import { convertSearchResponse } from '../converters/preset-converters';
import { usePresetService } from '../services/use-preset-service';
import { ResourceSearchResult } from '../../../shared/models/api/response';
import { getResourceListQueryKeyRoot, getSingleResourceQueryKey } from '../../../shared/utils/resource-query-key';
import { displayErrors } from '../../../shared/utils/error';

export function useGetPresetQuery(id: number) {
  const presetService = usePresetService();
  const { t } = useTranslation();

  return useQuery<BePreset, unknown, Preset>(
    getSingleResourceQueryKey('PRESET', id),
    () => presetService.getPreset(id),
    {
      enabled: id != null,
      onError: error => displayErrors(error, t),
      select: data => data as Preset,
    }
  );
}

export function useFindPresetsQuery(
  {
    page,
    pageSize,
    searchValue,
    filters,
    order,
    attributes = ['id', 'name', 'createdAt', 'updatedAt'],
  }: CommonFindAllQueryParams<Omit<Preset, 'definition'>, PresetsFilters>,
  definitionId?: number,
  enabled = true
) {
  const presetService = usePresetService();

  return useQuery<ResourceSearchResult<Preset>, unknown>(
    [getResourceListQueryKeyRoot('PRESET'), { page, pageSize }, searchValue, filters, order, definitionId, attributes],
    async () => {
      const request: QueryRequest<Omit<Preset, 'definition'>, PresetsFilters> = {
        limit: pageSize,
        offset: (page - 1) * pageSize,
        attributes,
      };

      if (order?.length > 0) {
        request.order = order;
      }

      if (searchValue) {
        request.search = searchValue;
      }

      if (filters && Object.values(filters).some(val => val != null)) {
        request.filters = deleteEmptyArrayObjProps(filters);
      }

      const response = await presetService.findPresets(request, definitionId);
      return convertSearchResponse(response.data);
    },
    { enabled }
  );
}
