import { Language } from '../models/language';

export function formatNumber(number: number, locale: string, options?: Intl.NumberFormatOptions): string {
  return new Intl.NumberFormat(locale, options).format(number);
}

export function formatSeconds(
  seconds: number,
  language: Language = Language.EnglishGB,
  options?: Omit<Intl.NumberFormatOptions, 'style' | 'unit'>
): string {
  return (
    seconds != null &&
    new Intl.NumberFormat(language, { style: 'unit', unit: 'second', unitDisplay: 'long', ...options }).format(seconds)
  );
}
