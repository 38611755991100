import { useFindPresetsQuery } from '../../reports/data/queries/preset-queries';
import { useFindReportsQuery } from '../../reports/data/queries/report-queries';
import { useFindSourcesQuery } from '../../reports/data/queries/source-queries';
import { useFindViewsQuery } from '../../reports/data/queries/view-queries';
import { useFindDefinitionsQuery } from '../../shared/data/queries/definition-queries';
import { CommonFindAllQueryParams } from '../../shared/models/api/request';
import { ResourceFilters } from '../../shared/utils/types';
import { useFindRolesQuery } from '../data/queries/role-queries';
import { TenantStats } from '../models/tenant';

type TenantStatsResource = { id?: number; uuid?: string; name: string };

export function useTenantStats(
  stats: Omit<TenantStats, 'parentGroupId'>,
  type: keyof Omit<TenantStats, 'parentGroupId'>
): { selectedResources: TenantStatsResource[]; loading: boolean } {
  const isDataAvailable = stats !== undefined && type !== undefined;
  const commonParams: CommonFindAllQueryParams<TenantStatsResource, ResourceFilters<TenantStatsResource, 'name'>> = {
    page: 1,
    pageSize: isDataAvailable ? stats[type].length : 0,
    filters: isDataAvailable ? { id: stats[type] } : undefined,
    attributes: ['id', 'name'],
  };
  const reportParams: CommonFindAllQueryParams<TenantStatsResource, ResourceFilters<TenantStatsResource, 'name'>> = {
    page: 1,
    pageSize: isDataAvailable ? stats[type].length : 0,
    filters: isDataAvailable ? { id: stats[type] } : undefined,
    attributes: ['uuid', 'name'],
  };

  const { data: reportsData, isLoading: reportsLoading } = useFindReportsQuery(reportParams, type === 'reports');
  const { data: definitionsData, isLoading: definitionsLoading } = useFindDefinitionsQuery(
    commonParams,
    type === 'definitions'
  );
  const { data: presetsData, isLoading: presetsLoading } = useFindPresetsQuery(commonParams, null, type === 'presets');
  const { data: sourcesData, isLoading: sourcesLoading } = useFindSourcesQuery(commonParams, type === 'sources');
  const { data: viewsData, isLoading: viewsLoading } = useFindViewsQuery(commonParams, type === 'views');
  const { data: rolesData, isLoading: rolesLoading } = useFindRolesQuery(commonParams, null, type === 'roles');

  const resources = {
    reports: reportsData?.resources,
    definitions: definitionsData?.resources,
    presets: presetsData?.resources,
    sources: sourcesData?.resources,
    views: viewsData?.resources,
    roles: rolesData?.resources,
  };

  const resourcesLoading = {
    reports: reportsLoading,
    definitions: definitionsLoading,
    presets: presetsLoading,
    sources: sourcesLoading,
    views: viewsLoading,
    roles: rolesLoading,
  };

  return { selectedResources: resources[type], loading: resourcesLoading[type] };
}
