import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Feature } from '../../../models/features';

interface NavState {
  resourceName: string;
}

const initialState: NavState = {
  resourceName: null,
};

const slice = createSlice({
  name: `${Feature.SHARED}/nav`,
  initialState,
  reducers: {
    setResourceName(state, action: PayloadAction<string>) {
      state.resourceName = action.payload;
    },
  },
});

export const { setResourceName } = slice.actions;
export const navSlice = slice.reducer;
