import axios from 'axios';
import { ValidateResponse } from '@pccr-ifc/pccr-ifc';

import { Config } from '../../../shared/models/config';
import { useService } from '../../../shared/hooks/use-service';

function createSessionService(config: Config) {
  const BASE_PATH = '/auth';

  const validate = () =>
    axios.get<ValidateResponse>(`${config.API_URL}${BASE_PATH}/validate`, {
      withCredentials: true,
    });

  return { validate };
}

export const useSessionService = () => useService(createSessionService);
