import { AppResource } from '../models/app-resource';
import { EntityType } from '../models/log';

//TODO: documents/approval stuff
export const resourceToEntityMap: Partial<Record<AppResource, EntityType>> = {
  REPORT: 'report',
  PRESET: 'preset',
  DEFINITION: 'definition',
  VIEW: 'view',
  SOURCE: 'source',
  JOB: 'schedule',
  TENANT: 'tenant',
  USER: 'user',
  POLICY: 'policy',
  ROLE: 'role',
};
