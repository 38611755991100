import { GuardFunction } from 'react-router-guards';
import { GuardedRouteMeta } from '../../shared/models/route';
import { UserStatuses } from '../../shared/constants/user';

export const changePasswordGuard: GuardFunction = (to, from, next) => {
  const { getState, loggedIn }: GuardedRouteMeta = to.meta;

  if (loggedIn) {
    if (getState().shared.auth.loggedIn) {
      if (getState().shared.auth.user.status !== UserStatuses.PASSWORD_REQUIRED) {
        return next();
      }

      return next.redirect(`/auth/profile`);
    }
  }
  next();
};
