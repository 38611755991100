import { Breadcrumb } from 'antd';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ConditionalWrapper } from '../../../shared/components/conditional-wrapper';
import { appRoutes } from '../../routes/app-routes';
import { getBreadcrumbItems } from './utils';

export const Breadcrumbs: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const resourceName = useSelector(state => state.shared.nav.resourceName);

  const items = useMemo(() => getBreadcrumbItems(resourceName, appRoutes, pathname, t), [resourceName, pathname, t]);

  const breadcrumbItems = items
    .map(
      ({ path, icon, title, isLinked }, index) =>
        (icon != null || title) && {
          icon,
          title,
          key: index,
          path,
          isLinked,
        }
    )
    .filter(Boolean);

  function itemRender(route) {
    return (
      <ConditionalWrapper condition={route.isLinked} wrapper={children => <Link to={route.path}>{children}</Link>}>
        {route.icon && <StyledImage src={route.icon} alt={'Route Icon'} />}
        <AdjustedSpan withIcon={route.icon != null}>{route.title}</AdjustedSpan>
      </ConditionalWrapper>
    );
  }

  return <Breadcrumb css={breadcrumbsStyles} items={breadcrumbItems} itemRender={itemRender} />;
};

const breadcrumbsStyles = css`
  margin: 16px 0;
  word-break: break-all;

  li,
  a {
    display: flex;
    align-items: center;
  }
`;

const AdjustedSpan = styled.span<{ withIcon?: boolean }>`
  margin-left: ${props => (props.withIcon ? 4 : 0)}px;
`;

const StyledImage = styled.img`
  height: 16px;
  opacity: 0.5;
`;
