import { Step } from '../../../models/step';
import { Change } from './change';
import { Finish } from './finish';
import { Request } from './request';

export const steps: Step[] = [
  {
    key: 'request',
    nameTranslationKey: 'forgotPasswordView.steps.request.label',
    component: Request,
  },
  {
    key: 'change',
    nameTranslationKey: 'forgotPasswordView.steps.change.label',
    component: Change,
    tokenStep: true,
  },
  {
    key: 'finish',
    nameTranslationKey: 'forgotPasswordView.steps.finish.label',
    component: Finish,
  },
];
