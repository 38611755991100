import { Button, Dropdown } from 'antd';
import { MenuProps } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuOutlined } from '@ant-design/icons';

import { Feature } from '../../../shared/models/features';
import { translationNamespace } from '../../constants/translation-resources';
import { ActionsProps } from '../users-table';
import { User } from '../../../shared/models/user';

type Props = ActionsProps & {
  onRemove?: (user: User) => void;
};

export const TenantUserActions: FC<Props> = ({ user, onRemove }) => {
  const { t } = useTranslation(translationNamespace);

  const items: MenuProps['items'] = [
    {
      key: 'remove',
      onClick: () => onRemove(user),
      label: t('tenants.single.unassign'),
    },
  ];

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <Button type="link" icon={<MenuOutlined />}>
        {t(`${Feature.SHARED}:actions`)}
      </Button>
    </Dropdown>
  );
};
