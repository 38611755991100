import { Feature } from '../../shared/models/features';
import { Route } from '../../shared/models/route';
import { HomepageView } from '../views/homepage/homepage-view';
import homeIcon from '../assets/images/menu-icons/icon_home.svg';

export const coreRoutes: Route = {
  path: '/',
  nameTranslationKey: `${Feature.CORE}:navigation.homepage`,
  displayInSideNavigation: true,
  view: HomepageView,
  icon: homeIcon,
};
