import { FindAllReportsResponse } from '@pccr-ifc/pccr-ifc';
import { GenericType } from '@pccr-ifc/pccr-ifc/lib/cjs/definition/GenericDefinitionResponse';

import { convertDateRangeToISO } from '../../../shared/utils/date';
import { ReportsTableFilters } from '../../components/reports-table';
import {
  Report,
  ReportDataFilters,
  ReportDataTableFiltersWithTypes,
  ReportSearchResult,
  ReportsFilters,
} from '../../models/report';

export function convertSearchResponse(response: FindAllReportsResponse): ReportSearchResult {
  return {
    total: response.total,
    filteredReportCount: response.filteredReportCount,
    resources: response.reports as Report[],
  };
}

export function convertTableFiltersToFilters(tableFilters: ReportsTableFilters): ReportsFilters {
  const reportsFilters: ReportsFilters = { name: tableFilters.name, definitionName: tableFilters.definitionName };

  if (tableFilters.createdAt != null) {
    reportsFilters.createdAt = convertDateRangeToISO(tableFilters.createdAt);
  }
  if (tableFilters.updatedAt != null) {
    reportsFilters.updatedAt = convertDateRangeToISO(tableFilters.updatedAt);
  }

  return reportsFilters;
}

function convertDateTimeRangeToBackendFormat(dateRange: [Date?, Date?]) {
  const completeDateRange = [dateRange[0] ?? new Date(0), dateRange[1] ?? new Date()];
  return completeDateRange.map(date => date.toISOString().slice(0, 19).replace('T', '@'));
}

function returnFilterValueBasedOnType(type: GenericType, value: unknown): unknown[] {
  switch (type) {
    case 'date':
      return convertDateTimeRangeToBackendFormat(value as [Date?, Date?]);
    case 'boolean':
      return [value];
    case 'number':
      return (value as string[]).map(number => Number(number.replace(',', '.')));
    default:
      return value as [];
  }
}

export function convertReportDataTableFiltersToFilters(
  tableFilters: ReportDataTableFiltersWithTypes
): ReportDataFilters {
  const reportDataFilters: ReportDataFilters = Object.entries(tableFilters).reduce((acc, [key, body]) => {
    acc = {
      ...acc,
      [key]: returnFilterValueBasedOnType(body.type, body.value),
    };

    return acc;
  }, {} as ReportDataFilters);

  return reportDataFilters;
}
