import { Button, Divider, Form, FormInstance, Input, Select, Space } from 'antd';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { translationNamespace } from '../../constants/translation-resources';
import { useFindTenantsQuery } from '../../data/queries/tenant-queries';
import { useFindUsersQuery } from '../../data/queries/user-queries';
import { Feature } from '../../../shared/models/features';
import { noDuplicatesRule, onlyNumbersRule, redundantSpacebarsRule } from '../../../shared/utils/form-rules';
import { UserStatuses } from '../../../shared/constants/user';
import { showOnlyOneError } from '../../../shared/styles/form-styles';
import { ResourceSelect } from '../../../shared/components/resource-select';

export enum TenantUpsertFormField {
  NAME = 'name',
  PARENT = 'parent',
  USERS = 'users',
  CUSTOMER_ID = 'customerId',
}

export interface TenantUpsertFormValues {
  [TenantUpsertFormField.NAME]: string;
  [TenantUpsertFormField.PARENT]?: number;
  [TenantUpsertFormField.USERS]?: number[];
  [TenantUpsertFormField.CUSTOMER_ID]: string;
}

interface Props {
  form: FormInstance<TenantUpsertFormValues>;
  onSubmit?: (values: TenantUpsertFormValues) => void;
}

export const TenantUpsertForm: FC<Props> = ({ form, onSubmit }) => {
  const currentTenantId = form.getFieldValue(TenantUpsertFormField.PARENT);
  const { t } = useTranslation(translationNamespace);
  const [tenantsSearchValue, setTenantsSearchValue] = useState<string>();
  const [tenantsDebouncedSearchValue, setTenantsDebouncedSearchValue] = useState<string>();
  const { data: currentTenantData, isLoading: currentTenantLoading } = useFindTenantsQuery(
    {
      page: 1,
      pageSize: 1,
      attributes: ['id', 'name'],
      filters: { id: [currentTenantId] },
    },
    currentTenantId != null
  );
  const { data: tenantsData, isLoading: tenantsLoading } = useFindTenantsQuery({
    page: 1,
    pageSize: 25,
    searchValue: tenantsDebouncedSearchValue,
    attributes: ['id', 'name'],
  });
  const [usersSearchValue, setUsersSearchValue] = useState<string>();
  const [usersDebouncedSearchValue, setUsersDebouncedSearchValue] = useState<string>();
  const { data: usersData, isLoading: usersLoading } = useFindUsersQuery(
    {
      page: 1,
      pageSize: 25,
      searchValue: usersDebouncedSearchValue,
      attributes: ['id', 'status', 'email'],
    },
    {
      groups: {
        attributes: ['id', 'name', 'customerId'],
      },
    }
  );

  const userSelectOptions = useMemo(() => {
    return usersData?.resources
      .filter(user => user.status === UserStatuses.APPROVED || user.status === UserStatuses.PASSWORD_REQUIRED)
      .map((user, index) => (
        <Select.Option key={index} value={user.id}>
          {user.groups.length > 0
            ? `${user.email} - ${t('tenants.single.assignUserModal.alreadyAssigned')}`
            : user.email}
        </Select.Option>
      ));
  }, [t, usersData?.resources]);

  useDebounce(
    () => {
      setTenantsDebouncedSearchValue(tenantsSearchValue);
    },
    3000,
    [tenantsSearchValue]
  );

  useDebounce(
    () => {
      setUsersDebouncedSearchValue(usersSearchValue);
    },
    3000,
    [usersSearchValue]
  );

  const applyUsersSearch = (value: string) => {
    setUsersSearchValue(value);
    setUsersDebouncedSearchValue(value);
  };

  const clearUsersSearch = () => {
    setUsersSearchValue('');
    setUsersDebouncedSearchValue('');
  };

  const applyTenantsSearch = (value: string) => {
    setTenantsSearchValue(value);
    setTenantsDebouncedSearchValue(value);
  };

  const clearTenantsSearch = () => {
    setTenantsSearchValue('');
    setTenantsDebouncedSearchValue('');
  };

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      layout="horizontal"
      labelCol={{ lg: 5, xl: 5 }}
      wrapperCol={{ lg: 16, xl: 10 }}
      requiredMark={false}
    >
      <Form.Item
        label={t(`${Feature.SHARED}:name`)}
        name={TenantUpsertFormField.NAME}
        rules={[{ required: true, type: 'string', max: 180 }, redundantSpacebarsRule(t)]}
      >
        <Input placeholder={t(`${Feature.SHARED}:tenant.placeholder`)} />
      </Form.Item>
      <Form.Item label={t('tenants.shared.parentName')} name={TenantUpsertFormField.PARENT}>
        <ResourceSelect
          css={fullWidthStyles}
          allowClear
          showSearch
          filterOption={false}
          placeholder={t('tenants.upsert.parent.placeholder')}
          loading={tenantsLoading || currentTenantLoading}
          onSearch={applyTenantsSearch}
          onSelect={clearTenantsSearch}
          resources={tenantsData?.resources}
          currentResource={currentTenantData?.resources[0]}
          resourceSearchValue={tenantsSearchValue}
        />
      </Form.Item>
      <Form.Item
        label={t('tenants.shared.customerId')}
        name={TenantUpsertFormField.CUSTOMER_ID}
        rules={[{ required: true, len: 6 }, onlyNumbersRule(t)]}
        css={customerIdStyles}
      >
        <Input css={fullWidthStyles} placeholder={t('tenants.upsert.customerId.placeholder')} />
      </Form.Item>

      <Divider orientation="left">{t('shared.users')}</Divider>
      <Form.List name={TenantUpsertFormField.USERS}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(field => (
              <Form.Item
                key={field.key}
                label={t(`${Feature.SHARED}:email.label`)}
                css={noMarginBottomStyles}
                wrapperCol={{ span: 14 }}
              >
                <StyledSpace align="baseline">
                  <Form.Item
                    {...field}
                    name={field.name}
                    messageVariables={{ label: t(`${Feature.SHARED}:email.label`) }}
                    rules={[
                      { required: true },
                      noDuplicatesRule(form, TenantUpsertFormField.USERS, t('tenants.shared.duplicateWarning')),
                    ]}
                    css={fullWidthStyles}
                  >
                    <Select
                      showSearch
                      filterOption={false}
                      placeholder={t(`${Feature.SHARED}:email.placeholder`)}
                      loading={usersLoading}
                      onSearch={applyUsersSearch}
                      onSelect={clearUsersSearch}
                      css={fullWidthStyles}
                    >
                      {userSelectOptions}
                    </Select>
                  </Form.Item>
                  <MinusCircleOutlined
                    onClick={() => {
                      remove(field.name);
                      setTimeout(() => {
                        form.validateFields();
                      }, 0);
                    }}
                  />
                </StyledSpace>
              </Form.Item>
            ))}
            <Form.Item>
              <StyledButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                {t('tenants.shared.addUser')}
              </StyledButton>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};

const marginBottomStyles = css`
  margin-bottom: 52px;
`;

const customerIdStyles = css`
  ${marginBottomStyles}
  ${showOnlyOneError}
`;

const noMarginBottomStyles = css`
  margin-bottom: 0;
`;

const fullWidthStyles = css`
  width: 100%;
`;

const StyledSpace = styled(Space)`
  width: 100%;

  > div:first-of-type {
    width: 100%;
    max-width: 350px;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 180px;
`;
