import { Button, message, Space, Spin, Typography } from 'antd';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { unwrapResult } from '@reduxjs/toolkit';

import { useAuthService } from '../../../../shared/data/services/use-auth-service';
import { verifyEmail } from '../../../../shared/data/store/thunks/auth-thunks';
import { translationNamespace } from '../../../constants/translation-resources';
import { RegisterContext } from '../register-context';
import { displayErrors } from '../../../../shared/utils/error';

const { Title, Text } = Typography;

export const Finish: FC = () => {
  const { t } = useTranslation(translationNamespace);
  const dispatch = useDispatch();
  const authService = useAuthService();
  const { token } = useContext(RegisterContext);
  const [loading, setLoading] = useState(true);
  const [requestFired, setRequestFired] = useState(false);

  useEffect(() => {
    if (!requestFired && token?.length > 0) {
      setRequestFired(true);
      dispatch(verifyEmail({ request: token, service: authService }))
        .then(unwrapResult)
        .then(() => {
          setLoading(false);
          message.success(t('registerView.steps.finish.verificationSuccessMessage'));
        })
        .catch(error => displayErrors(error, t));
    }
  }, [authService, dispatch, requestFired, t, token]);

  return (
    <StyledSpace direction="vertical" align={loading ? 'center' : undefined}>
      {loading || !requestFired ? (
        <Spin tip={t('registerView.steps.finish.verificationLoaderMessage')} />
      ) : (
        <>
          <Title level={2}>{t('registerView.steps.finish.title')}</Title>
          <Text type="secondary">{t('registerView.steps.finish.description')}</Text>
          <ButtonGroup>
            <Link to="/auth/login">
              <StyledButton type="primary" icon={<ArrowRightOutlined />}>
                {t('shared.login')}
              </StyledButton>
            </Link>
          </ButtonGroup>
        </>
      )}
    </StyledSpace>
  );
};

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

const StyledButton = styled(Button)`
  margin-top: 16px;
`;

const StyledSpace = styled(Space)`
  width: 100%;
`;
