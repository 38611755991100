import { Button, Col, Form, FormInstance, Row, Select } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';

import { translationNamespace } from '../../constants/translation-resources';
import { Feature } from '../../../shared/models/features';
import { Method } from '../../../shared/models/method';
import { Methods } from '../../../shared/constants/method';
import { RangePicker } from '../../../shared/components/date-picker/date-picker';

enum LogsTableFilter {
  PATH = 'path',
  METHOD = 'method',
  USER_ID = 'userId',
  RESOURCE_ID = 'resourceId',
  CREATED_AT = 'createdAt',
}

export interface LogsTableFilters {
  [LogsTableFilter.PATH]: string[];
  [LogsTableFilter.METHOD]: Method[];
  [LogsTableFilter.USER_ID]: number[];
  [LogsTableFilter.RESOURCE_ID]: number[];
  [LogsTableFilter.CREATED_AT]: [Date?, Date?];
}

interface LogsTableFiltersFormProps {
  onApply?: (filters: LogsTableFilters) => void;
  form?: FormInstance<LogsTableFilters>;
}

export const LogsTableFiltersForm: FC<LogsTableFiltersFormProps> = ({ onApply, form }) => {
  const { t } = useTranslation(translationNamespace);

  const onClear = () => {
    form.resetFields();
    onApply?.(form.getFieldsValue());
  };

  return (
    <Form form={form} layout="vertical" onFinish={onApply}>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item label={t(`${Feature.SHARED}:path`)} name={LogsTableFilter.PATH}>
            <Select suffixIcon={null} size="large" mode="tags" allowClear open={false} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label={t(`${Feature.SHARED}:method`)} name={LogsTableFilter.METHOD}>
            <Select size="large" mode="multiple" allowClear>
              {Object.values(Methods).map((method, i) => (
                <Select.Option key={i} value={method}>
                  {t(`${Feature.SHARED}:methods.${method.toLocaleLowerCase()}`)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label={t('list.logsTable.userId')} name={LogsTableFilter.USER_ID}>
            <Select suffixIcon={null} size="large" mode="tags" allowClear open={false} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label={t('list.logsTable.resourceId')} name={LogsTableFilter.RESOURCE_ID}>
            <Select suffixIcon={null} size="large" mode="tags" allowClear open={false} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label={t(`${Feature.SHARED}:createdAt`)} name={LogsTableFilter.CREATED_AT}>
            <RangePicker css={fullWidthStyles} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <Button type="primary" htmlType="submit" size="large" icon={<CheckOutlined />}>
            {t(`${Feature.SHARED}:apply`)}
          </Button>
        </Col>
        <Col>
          <Button htmlType="button" size="large" onClick={onClear}>
            {t(`${Feature.SHARED}:clear`)}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const fullWidthStyles = css`
  width: 100%;
`;
