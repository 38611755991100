import { ErrorResponseBody } from '@pccr-error/pccr-error';
import { AxiosError } from 'axios';
import { SqlErrorDetail } from '@pccr-error/pccr-error/lib/esm/ErrorCode';

import { GenerateReportFormField, GenerateReportFormValues } from '../components/generate-report-form-fields';
import { FormValidationError } from '../models/error';
import { isPlainObject } from './object';
import { Order } from '../models/order';

export type PickSomeRestPartial<T, P extends keyof T> = Partial<T> & Pick<T, P>;

export type ResourceFilters<R, E extends keyof R = never> = {
  [P in Exclude<keyof R, E>]?: R[P] extends boolean ? boolean : R[P] extends readonly unknown[] ? R[P] : R[P][];
};

export type ArrayElement<T extends readonly unknown[]> = T extends readonly (infer E)[] ? E : never;

export type ReducedArrayProps<T, K extends keyof T> = T[K] extends unknown[] ? ArrayElement<T[K]> : T[K];

export type ChangePropTypes<T extends object, P extends keyof T, C> = Omit<T, P> & { [K in P]?: C };

export type Values<T extends object> = T[keyof T];

export type RequireProperty<T extends object, R extends keyof T> = Omit<T, R> & Required<Pick<T, R>>;

// Just to check the actual types
export type Expand<T> = { [K in keyof T]: T[K] };

// Type "AnyApiResponse" is used to remove lint warnings. When IFC models will be ready
// we can switch from this type to a proper one
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyApiResponse = any;

export type UnionFromEnum<T extends string | number> = T extends string ? `${T}` : T;

export type MultiColumnOrder<T> = [keyof T, Order][];

export function isAxiosError<T = unknown>(error): error is AxiosError<T> {
  return error.isAxiosError != null;
}

export function isBackendErrorResponse(error): error is ErrorResponseBody {
  return typeof error === 'object' && error.metaCode != null && error.code != null && error.message != null;
}

export function isArrayBuffer(buffer: unknown): buffer is ArrayBuffer | ArrayBufferView {
  return ArrayBuffer.isView(buffer) || buffer instanceof ArrayBuffer;
}

export function transformBufferToObject(buffer: ArrayBuffer | ArrayBufferView): object {
  return JSON.parse(new TextDecoder('utf-8').decode(buffer));
}

//Tymczasowy ifc
interface BackendPlainError {
  statusMessage: string;
}

export function isBackendPlainError(error): error is BackendPlainError {
  return typeof error === 'object' && error.statusMessage != null;
}

export function isBackendSqlErrorDetails(error): error is SqlErrorDetail {
  return typeof error === 'object' && error.dialect != null && error.message != null;
}

export function isArrayOfStrings(array): array is string[] {
  return Array.isArray(array) && array.every(element => typeof element === 'string');
}

export function isGenerateReportFormValues(values): values is GenerateReportFormValues {
  return (
    isPlainObject(values) &&
    [GenerateReportFormField.NAME, GenerateReportFormField.DEFINITION_ID].every(key =>
      Object.keys(values).includes(key)
    )
  );
}

export function isFormValidationError(value: unknown): value is FormValidationError {
  return typeof value === 'object' && Object.hasOwn(value, 'errorFields');
}
