import { Feature } from '../../shared/models/features';
import { Route } from '../../shared/models/route';
import { DefinitionNewView } from '../views/definitions/definition-new';
import { DefinitionSingleView } from '../views/definitions/definition-single';
import { DefinitionsListView } from '../views/definitions/definitions-list';
import { PresetNewView } from '../views/presets/preset-new';
import { PresetSingleView } from '../views/presets/preset-single';
import { PresetsListView } from '../views/presets/presets-list';
import { ReportSingleView } from '../views/reports/report-single';
import { ReportsListView } from '../views/reports/reports-list';
import { SourceNewView } from '../views/sources/source-new';
import { SourceSingleView } from '../views/sources/source-single';
import { SourcesListView } from '../views/sources/sources-list';
import { ViewNewView } from '../views/views/view-new';
import { ViewSingleView } from '../views/views/view-single';
import { ViewsListView } from '../views/views/views-list';
import reportIcon from '../assets/images/menu-icons/icon_reports.svg';

export const reportRoutes: Route = {
  path: '/reports',
  nameTranslationKey: `${Feature.SHARED}:report.labelPlural`,
  icon: reportIcon,
  displayInSideNavigation: true,
  hideIfEmpty: true,
  children: [
    {
      path: '/list',
      children: [
        {
          path: '',
          nameTranslationKey: `${Feature.REPORTS}:navigation.reports`,
          view: ReportsListView,
          displayInSideNavigation: true,
          meta: {
            requiredPermission: ['REPORT', 'READ'],
          },
        },
        {
          path: '/:id',
          view: ReportSingleView,
          displayInSideNavigation: false,
          meta: {
            isResourcePath: true,
          },
        },
      ],
    },
    {
      path: '/presets',
      children: [
        {
          path: '',
          nameTranslationKey: `${Feature.SHARED}:presets`,
          view: PresetsListView,
          displayInSideNavigation: true,
          meta: {
            requiredPermission: ['PRESET', 'READ'],
          },
        },
        {
          path: '/new',
          nameTranslationKey: `${Feature.SHARED}:new`,
          view: PresetNewView,
          displayInSideNavigation: false,
        },
        {
          path: '/:id',
          view: PresetSingleView,
          displayInSideNavigation: false,
          meta: {
            isResourcePath: true,
          },
        },
      ],
    },
    {
      path: '/definitions',
      children: [
        {
          path: '',
          nameTranslationKey: `${Feature.SHARED}:definition.labelPlural`,
          view: DefinitionsListView,
          displayInSideNavigation: true,
          meta: {
            requiredPermission: ['DEFINITION', 'READ'],
          },
        },
        {
          path: '/new',
          nameTranslationKey: `${Feature.SHARED}:new`,
          view: DefinitionNewView,
          displayInSideNavigation: false,
        },
        {
          path: '/:id',
          view: DefinitionSingleView,
          displayInSideNavigation: false,
          meta: {
            isResourcePath: true,
          },
        },
      ],
    },
    {
      path: '/views',
      children: [
        {
          path: '',
          nameTranslationKey: `${Feature.SHARED}:views`,
          view: ViewsListView,
          displayInSideNavigation: true,
          meta: {
            requiredPermission: ['VIEW', 'READ'],
          },
        },
        {
          path: '/new',
          nameTranslationKey: `${Feature.SHARED}:new`,
          view: ViewNewView,
          displayInSideNavigation: false,
        },
        {
          path: '/:id',
          view: ViewSingleView,
          displayInSideNavigation: false,
          meta: {
            isResourcePath: true,
          },
        },
      ],
    },
    {
      path: '/sources',
      children: [
        {
          path: '',
          nameTranslationKey: `${Feature.SHARED}:sources`,
          view: SourcesListView,
          displayInSideNavigation: true,
          meta: {
            requiredPermission: ['SOURCE', 'READ'],
          },
        },
        {
          path: '/new',
          nameTranslationKey: `${Feature.SHARED}:new`,
          view: SourceNewView,
          displayInSideNavigation: false,
        },
        {
          path: '/:id',
          view: SourceSingleView,
          displayInSideNavigation: false,
          meta: {
            isResourcePath: true,
          },
        },
      ],
    },
  ],
};
