import { TableColumnType } from 'antd';
import { TFunction } from 'react-i18next';
import { LinkedName } from '../../../shared/components/linked-name';
import { Feature } from '../../../shared/models/features';
import { Language } from '../../../shared/models/language';
import { Role } from '../../../shared/models/role';
import { formatISODate } from '../../../shared/utils/date';
import { PoliciesCell } from '../policies-cell';
import { Actions } from './actions';
import { PermissionsState } from '../../../shared/data/store';
import { AppResources } from '../../../shared/constants/app-resources';

export const getColumns = (
  t: TFunction<Feature.ACCESS>,
  language: Language,
  permissions: PermissionsState
): TableColumnType<Role>[] => {
  return [
    {
      title: t(`${Feature.SHARED}:name`),
      dataIndex: 'name',
      render: (name: Role['name'], role: Role) => <LinkedName name={name} to={`roles/${role.id}`} />,
      sorter: true,
    },
    {
      title: t(`${Feature.SHARED}:policies`),
      dataIndex: 'policies',
      render: (policies: Role['policies']) => <PoliciesCell policies={policies} />,
    },
    {
      title: t(`${Feature.SHARED}:createdAt`),
      dataIndex: 'createdAt',
      render: (createdAt: Role['createdAt']) => formatISODate(createdAt, language),
      sorter: true,
      width: 130,
      defaultSortOrder: 'descend',
    },
    {
      title: t(`${Feature.SHARED}:updatedAt`),
      dataIndex: 'updatedAt',
      render: (updatedAt: Role['updatedAt']) => formatISODate(updatedAt, language),
      sorter: true,
      width: 130,
    },
    {
      key: 'action',
      align: 'right',
      render: (_, role) => <Actions role={role} />,
      width: 130,
      hidden: !permissions[AppResources.ROLE]['UPDATE'] && !permissions[AppResources.ROLE]['DELETE'],
    },
  ];
};
