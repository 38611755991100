import { Route } from '../../shared/models/route';
import { translationNamespace } from '../constants/translation-resources';
import { translationNamespace as sharedTranslationNamespace } from '../../shared/constants/translation-resources';
import { PoliciesListView } from '../views/policies/policies-list';
import { PolicyNewView } from '../views/policies/policy-new';
import { PolicySingleView } from '../views/policies/policy-single';
import { RoleNewView } from '../views/roles/role-new';
import { RoleSingleView } from '../views/roles/role-single';
import { RolesListView } from '../views/roles/roles-list';
import { TenantNewView } from '../views/tenants/tenant-new';
import { TenantSingleView } from '../views/tenants/tenant-single';
import { TenantsListView } from '../views/tenants/tenants-list';
import { UserNewView } from '../views/users/user-new';
import { UserSingleView } from '../views/users/user-single';
import { UsersListView } from '../views/users/users-list';
import accessIcon from '../assets/images/menu-icons/icon_authenticate.svg';

export const accessRoutes: Route = {
  path: '/access',
  nameTranslationKey: `${translationNamespace}:navigation.root`,
  icon: accessIcon,
  displayInSideNavigation: true,
  hideIfEmpty: true,
  children: [
    {
      path: '/tenants',
      children: [
        {
          path: '',
          nameTranslationKey: `${translationNamespace}:navigation.tenants`,
          view: TenantsListView,
          displayInSideNavigation: true,
          meta: {
            requiredPermission: ['TENANT', 'READ'],
          },
        },
        {
          path: '/new',
          nameTranslationKey: `${sharedTranslationNamespace}:new`,
          view: TenantNewView,
          displayInSideNavigation: false,
        },
        {
          path: '/:id',
          view: TenantSingleView,
          displayInSideNavigation: false,
          meta: {
            isResourcePath: true,
          },
        },
      ],
    },
    {
      path: '/users',
      children: [
        {
          path: '',
          nameTranslationKey: `${translationNamespace}:shared.users`,
          view: UsersListView,
          displayInSideNavigation: true,
          meta: {
            requiredPermission: ['USER', 'READ'],
          },
        },
        {
          path: '/new',
          nameTranslationKey: `${sharedTranslationNamespace}:new`,
          view: UserNewView,
          displayInSideNavigation: false,
        },
        {
          path: '/:id',
          view: UserSingleView,
          displayInSideNavigation: false,
          meta: {
            isResourcePath: true,
          },
        },
      ],
    },
    {
      path: '/policies',
      children: [
        {
          path: '',
          nameTranslationKey: `${sharedTranslationNamespace}:policies`,
          view: PoliciesListView,
          displayInSideNavigation: true,
          meta: {
            requiredPermission: ['POLICY', 'READ'],
          },
        },
        {
          path: '/new',
          nameTranslationKey: `${sharedTranslationNamespace}:new`,
          view: PolicyNewView,
          displayInSideNavigation: false,
        },
        {
          path: '/:id',
          view: PolicySingleView,
          displayInSideNavigation: false,
          meta: {
            isResourcePath: true,
          },
        },
      ],
    },
    {
      path: '/roles',
      children: [
        {
          path: '',
          nameTranslationKey: `${sharedTranslationNamespace}:role.plural`,
          view: RolesListView,
          displayInSideNavigation: true,
          meta: {
            requiredPermission: ['ROLE', 'READ'],
          },
        },
        {
          path: '/new',
          nameTranslationKey: `${sharedTranslationNamespace}:new`,
          view: RoleNewView,
          displayInSideNavigation: false,
        },
        {
          path: '/:id',
          view: RoleSingleView,
          displayInSideNavigation: false,
          meta: {
            isResourcePath: true,
          },
        },
      ],
    },
  ],
};
