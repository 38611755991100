import { GenerateReportFormFields } from '../../shared/components/generate-report-form-fields';
import { generateReportTemplate } from './job-task-templates';

export const JobRequestServices = {
  Report: 'Report',
  User: 'User',
  ApiGateway: 'ApiGateway',
} as const;

export const JobTasks = {
  GENERATE_REPORT: 'generateReport',
} as const;

export const jobTasksMap = {
  [JobTasks.GENERATE_REPORT]: {
    template: generateReportTemplate,
    component: GenerateReportFormFields,
  },
};
