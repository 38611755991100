import { Button, Dropdown } from 'antd';
import { FC, useMemo } from 'react';
import { MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { DownOutlined, TranslationOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';

import { Language } from '../../../shared/models/language';
import { supportedLanguages } from '../../constants/languages';
import { translationNamespace } from '../../constants/translation-resources';
import { DropdownMenu, StyledActionButton } from '../dropdown-menu';
import { border, colors } from '../../../shared/constants/styles';
import languageIcon from '../../assets/images/icon_language.svg';
import { Environment } from '../../../shared/constants/environment';

interface LanguageSelectorProps {
  withArrowIcon?: boolean;
  isFullPage?: boolean;
  env?: Environment;
}

export const LanguageSelector: FC<LanguageSelectorProps> = ({ env, withArrowIcon = true, isFullPage = false }) => {
  const { t, i18n } = useTranslation(translationNamespace);

  const languageOptions = useMemo(
    () => supportedLanguages.filter(language => language !== i18n.language),
    [i18n.language]
  );

  const changeLanguage = (language: Language) => {
    i18n.changeLanguage(language);
  };

  const items: MenuProps['items'] = languageOptions.map(language => ({
    key: language,
    onClick: () => changeLanguage(language),
    label: <span style={{ margin: 'auto' }}>{t(`languages.${language}`)}</span>,
  }));

  return (
    <>
      {isFullPage && (
        <Dropdown menu={{ items }}>
          <LanguageButton type="text" icon={<TranslationOutlined />}>
            {t(`languages.${i18n.language}`)} {withArrowIcon && <DownOutlined />}
          </LanguageButton>
        </Dropdown>
      )}
      {!isFullPage && (
        <DropdownMenu items={{ items }}>
          <ActionButton env={env} style={{ backgroundImage: `url(${languageIcon})` }} aria-label="translation">
            <span>{t(`languages.${i18n.language}`)}</span>
          </ActionButton>
        </DropdownMenu>
      )}
    </>
  );
};

export const LanguageButton = styled(Button)`
  padding: 0;
  color: ${colors.black} !important;
`;

const ActionButton = styled(StyledActionButton)<{ env?: Environment }>`
  border-right: ${border('white')};
  background-position: center 30%;

  span {
    position: absolute;
    top: 14px;
    left: 37%;
  }

  &:hover {
    background-color: ${props =>
      props.env === 'development' ? colors.pingHover : props.env === 'test' ? colors.turquoiseHover : colors.blueHover};
  }
`;
