import { Button, ButtonProps } from 'antd';
import styled from '@emotion/styled';

import { colors, border } from '../../constants/styles';
import filterIcon from '../../assets/images/icon_filter.svg';

export const CustomFilterButton = ({ ...props }: ButtonProps) => {
  return <StyledFilterButton icon={<img src={filterIcon} alt={'Icon'} />} {...props} />;
};

const StyledFilterButton = styled(Button)`
  height: 100%;
  vertical-align: middle;
  white-space: nowrap;
  border: ${border('primary')};
  color: ${colors.primary};
  background-color: ${colors.grey};

  &:hover {
    color: ${colors.primary} !important;
  }

  .ant-btn-icon > img {
    width: 12px;
  }
`;
