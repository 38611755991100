import { PermissionsState } from '../../shared/data/store';
import { Route } from '../../shared/models/route';

export function getNavigationItemsFromRoutes(routes: Route[], permissions: PermissionsState): Route[] {
  if (permissions == null) {
    return;
  }

  return routes
    .map(route => {
      if (route.nameTranslationKey == null && route.children?.length > 0) {
        const rootElement = route.children.find(childrenRoute => childrenRoute.path === '');

        if (rootElement != null) {
          return {
            ...rootElement,
            path: `${route.path}${rootElement.path}`,
          };
        }
      }

      return route;
    })
    .filter(route => {
      return (
        route.displayInSideNavigation === true &&
        (route.meta?.requiredPermission == null ||
          permissions[route.meta?.requiredPermission[0]][route.meta?.requiredPermission[1]])
      );
    })
    .map(route => {
      if (route.children?.length > 0) {
        const childrenNavigationItems = getNavigationItemsFromRoutes(route.children, permissions);
        return {
          ...route,
          displayInSideNavigation: !(childrenNavigationItems.length === 0 && route.hideIfEmpty),
          children: childrenNavigationItems,
        };
      }

      return route;
    });
}

type RouteKey = { key: string; parentKey?: string };

export function getCurrentRouteKey(locationPath: string, navigationItems: Route[], basePath = ''): RouteKey | null {
  let key: RouteKey | null = null;

  navigationItems.some(item => {
    const path = `${basePath}${item.path}`;

    if (item.children?.length > 0) {
      const childrenKey = getCurrentRouteKey(locationPath, item.children, path);

      if (childrenKey != null) {
        key = childrenKey;
        return true;
      }
    }

    const pathPattern = new RegExp(path === '/' ? `^${path}$` : `^${path}`);

    if (pathPattern.test(locationPath) === true) {
      key = {
        key: path,
        parentKey: basePath !== '' ? basePath : undefined,
      };

      return true;
    }

    return false;
  });

  return key;
}
