import { Checkbox, Descriptions, Divider, Drawer, DrawerProps, Tag } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { useToggle } from 'react-use';

import { Feature } from '../../../shared/models/features';
import { Role } from '../../../shared/models/role';
import { formatISODate } from '../../../shared/utils/date';
import { getLanguageFromI18nLanguage } from '../../../shared/utils/language';
import { translationNamespace } from '../../constants/translation-resources';
import { useLogsQueryForTimeline } from '../../../logs/data/queries/log-queries';
import { LogsTimeline } from '../../../shared/components/logs-timeline';
import { UserName } from '../../../shared/components/user-name';
import { LogsFilters } from '../../../logs/models/log';

interface RoleDetailsDrawerProps extends DrawerProps {
  role: Role;
}

export const RoleDetailsDrawer = ({ role, ...props }: RoleDetailsDrawerProps) => {
  const { t, i18n } = useTranslation(translationNamespace);
  const [includeGetLogs, toggleIncludeGetLogs] = useToggle(false);
  const filters: LogsFilters = {
    entityType: 'role',
    resourceId: [role.id],
    method: includeGetLogs ? undefined : ['PATCH', 'POST', 'PUT', 'DELETE'],
  };
  const logQueryResult = useLogsQueryForTimeline(filters, { enabled: props.open });

  const policies = useMemo(() => {
    return role.policies?.length > 0
      ? role.policies.map((policy, index) => (
          <Tag css={whiteSpaceNormal} key={index}>
            {policy.name}
          </Tag>
        ))
      : '';
  }, [role]);

  return (
    <Drawer {...props} title={t('roles.single.details')} width={400} placement="right" destroyOnClose>
      <Descriptions column={1} bordered css={withMarginBottom}>
        <Descriptions.Item label={t(`${Feature.SHARED}:name`)}>{role.name}</Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:policies`)}>{policies}</Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:createdAt`)}>
          {role.createdAt ? formatISODate(role.createdAt, getLanguageFromI18nLanguage(i18n.language)) : ''}
        </Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:createdBy`)}>
          <UserName userId={logQueryResult.isLoading ? undefined : role.createdBy} />
        </Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:updatedAt`)}>
          {role.updatedAt ? formatISODate(role.updatedAt, getLanguageFromI18nLanguage(i18n.language)) : ''}
        </Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:updatedBy`)}>
          <UserName userId={logQueryResult.isLoading ? undefined : role.updatedBy} />
        </Descriptions.Item>
      </Descriptions>

      <Divider>{t(`${Feature.SHARED}:log`)}</Divider>
      <Checkbox checked={includeGetLogs} onChange={toggleIncludeGetLogs} css={withMarginBottom}>
        {t(`${Feature.SHARED}:includeGetLogs`)}
      </Checkbox>
      <LogsTimeline queryResult={logQueryResult} />
    </Drawer>
  );
};

const withMarginBottom = css`
  margin-bottom: 32px;
`;

const whiteSpaceNormal = css`
  white-space: normal;
`;
