import { Button, Form, Input, message, Space } from 'antd';
import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { unwrapResult } from '@reduxjs/toolkit';

import { useAuthService } from '../../../shared/data/services/use-auth-service';
import { logIn } from '../../../shared/data/store/thunks/auth-thunks';
import { useQuery } from '../../../shared/hooks/use-query';
import { CommonQueryParamKey } from '../../../shared/models/common-query-params';
import { Feature } from '../../../shared/models/features';
import { translationNamespace } from '../../constants/translation-resources';
import { displayErrors } from '../../../shared/utils/error';

enum LoginFormField {
  PASSWORD = 'password',
  EMAIL_OR_USERNAME = 'emailOrUsername',
}

type LoginFormValues = Record<LoginFormField, string>;

export const LoginForm: FC = () => {
  const { t } = useTranslation(translationNamespace);
  const { push: routerPush } = useHistory();
  const authService = useAuthService();
  const dispatch = useDispatch();
  const [form] = Form.useForm<LoginFormValues>();
  const loading = useSelector(state => state.shared.auth.logIn.loading);
  const closeMsgRef = useRef<() => void>();
  const query = useQuery();
  const prevUrl = query.get(CommonQueryParamKey.PREV) ?? '/';

  const submit = ({ emailOrUsername, password }: LoginFormValues) => {
    dispatch(logIn({ request: { username: emailOrUsername, password }, service: authService }))
      .then(unwrapResult)
      .then(() => {
        message.success(t('loginForm.successMessage'));
        routerPush(prevUrl);
      })
      .catch(error => displayErrors(error, t));
  };

  useEffect(() => {
    if (loading) {
      closeMsgRef.current = message.loading(t(`${Feature.SHARED}:loadingMessage`), 0);
    } else {
      closeMsgRef.current?.();
    }
  }, [loading, t]);

  return (
    <Form layout="vertical" form={form} onFinish={submit} requiredMark={false}>
      <Form.Item
        name={LoginFormField.EMAIL_OR_USERNAME}
        label={t('loginForm.emailOrUsername.label')}
        rules={[{ required: true }]}
      >
        <Input placeholder={t('loginForm.emailOrUsername.placeholder')} />
      </Form.Item>
      <Form.Item
        name={LoginFormField.PASSWORD}
        label={t(`${Feature.SHARED}:password.label`)}
        rules={[{ required: true }]}
      >
        <Input.Password placeholder={t('loginForm.passwordPlaceholder')} />
      </Form.Item>
      <ButtonGroup>
        <Space direction="vertical">
          <Link to="/auth/register">
            <ButtonNoPaddingLeft disabled type="link" htmlType="button">
              {t('loginForm.register')}
            </ButtonNoPaddingLeft>
          </Link>
          <Link to="/auth/forgot-password">
            <ButtonNoPaddingLeft type="link" htmlType="button">
              {t('loginForm.forgot')}
            </ButtonNoPaddingLeft>
          </Link>
        </Space>

        <Form.Item>
          <Button type="primary" htmlType="submit" icon={<ArrowRightOutlined />} loading={loading}>
            {t('shared.login')}
          </Button>
        </Form.Item>
      </ButtonGroup>
    </Form>
  );
};

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const ButtonNoPaddingLeft = styled(Button)`
  padding-left: 0;
`;
