import axios from 'axios';
import { FindAllPresetsResponse, Preset as BePreset, FindAllPresetsRequest } from '@pccr-ifc/pccr-ifc';

import { Config } from '../../../shared/models/config';
import { useService } from '../../../shared/hooks/use-service';

function createPresetService(config: Config) {
  const BASE_PATH = '/report/preset';

  const findPresets = (request: FindAllPresetsRequest, definitionId?: number) =>
    axios.post<FindAllPresetsResponse>(
      `${config.API_URL}${BASE_PATH}/findall${definitionId != null ? `/${definitionId}` : ''}`,
      request,
      {
        withCredentials: true,
      }
    );

  const getPreset = async (id: number) => {
    const response = await axios.get<{ preset: BePreset }>(`${config.API_URL}${BASE_PATH}/${id}`, {
      withCredentials: true,
    });
    return response.data.preset;
  };

  const createPreset = async (request: { preset: Omit<BePreset, 'id'> }) => {
    const response = await axios.post<{ preset: BePreset }>(`${config.API_URL}${BASE_PATH}/add`, request, {
      withCredentials: true,
    });
    return response.data.preset;
  };

  const updatePreset = async ({ id, ...request }: Partial<BePreset>) => {
    const response = await axios.patch<{ preset: BePreset }>(
      `${config.API_URL}${BASE_PATH}/${id}`,
      { preset: request },
      {
        withCredentials: true,
      }
    );
    return response.data.preset;
  };

  const deletePreset = (id: number) =>
    axios.delete<void>(`${config.API_URL}${BASE_PATH}/${id}`, {
      withCredentials: true,
    });

  return { findPresets, getPreset, createPreset, updatePreset, deletePreset };
}

export const usePresetService = () => useService(createPresetService);
