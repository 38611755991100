import { Route } from '../../shared/models/route';
import { translationNamespace } from '../constants/translation-resources';
import { LogsListView } from '../views/list/logs-list';
import logsIcon from '../assets/images/menu-icons/icon_logs.svg';

export const logsRoutes: Route = {
  path: '/logs',
  nameTranslationKey: `${translationNamespace}:navigation.logs`,
  view: LogsListView,
  displayInSideNavigation: true,
  icon: logsIcon,
  meta: {
    requiredPermission: ['LOG', 'READ'],
  },
};
