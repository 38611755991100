import { Button, Col, Form, FormInstance, Row, Select } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';

import { Feature } from '../../../shared/models/features';
import { translationNamespace } from '../../constants/translation-resources';
import { UserStatus } from '../../../shared/models/user';
import { UserStatuses } from '../../../shared/constants/user';
import { RangePicker } from '../../../shared/components/date-picker/date-picker';

enum UsersTableFilter {
  USERNAME = 'username',
  EMAIL = 'email',
  ROLES_NAMES = 'rolesNames',
  STATUS = 'status',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  MOBILE_PHONE = 'mobilePhone',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
}

export interface UsersTableFilters {
  [UsersTableFilter.USERNAME]: string[];
  [UsersTableFilter.EMAIL]: string[];
  [UsersTableFilter.ROLES_NAMES]: string[];
  [UsersTableFilter.STATUS]: UserStatus[];
  [UsersTableFilter.FIRST_NAME]: string[];
  [UsersTableFilter.LAST_NAME]: string[];
  [UsersTableFilter.MOBILE_PHONE]: string[];
  [UsersTableFilter.CREATED_AT]: [Date?, Date?];
  [UsersTableFilter.UPDATED_AT]: [Date?, Date?];
}

interface UsersTableFiltersFormProps {
  onApply?: (filters: UsersTableFilters) => void;
  form: FormInstance<UsersTableFilters>;
}

export const UsersTableFiltersForm: FC<UsersTableFiltersFormProps> = ({ onApply, form }) => {
  const { t } = useTranslation(translationNamespace);

  const apply = (values: UsersTableFilters) => {
    onApply?.(values);
  };

  const onClear = () => {
    form.resetFields();
    apply(form.getFieldsValue());
  };

  return (
    <Form form={form} layout="vertical" onFinish={apply}>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item label={t(`${Feature.SHARED}:username.label`)} name={UsersTableFilter.USERNAME}>
            <Select suffixIcon={null} mode="tags" size="large" allowClear open={false} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={t(`${Feature.SHARED}:email.label`)}
            name={UsersTableFilter.EMAIL}
            rules={[{ type: 'array', defaultField: { type: 'email' } }]}
            initialValue={[]}
          >
            <Select suffixIcon={null} mode="tags" size="large" allowClear open={false} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t(`${Feature.SHARED}:role.plural`)} name={UsersTableFilter.ROLES_NAMES}>
            <Select suffixIcon={null} mode="tags" size="large" allowClear open={false} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t(`${Feature.SHARED}:status`)} name={UsersTableFilter.STATUS}>
            <Select size="large" mode="multiple" allowClear>
              {Object.values(UserStatuses).map((userStatus, i) => (
                <Select.Option key={i} value={userStatus}>
                  {t(`users.shared.status.${userStatus.toLowerCase()}`)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t(`${Feature.SHARED}:firstName.label`)} name={UsersTableFilter.FIRST_NAME}>
            <Select suffixIcon={null} mode="tags" size="large" allowClear open={false} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t(`${Feature.SHARED}:lastName.label`)} name={UsersTableFilter.LAST_NAME}>
            <Select suffixIcon={null} mode="tags" size="large" allowClear open={false} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t(`${Feature.SHARED}:mobilePhone.label`)} name={UsersTableFilter.MOBILE_PHONE}>
            <Select suffixIcon={null} mode="tags" size="large" allowClear open={false} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t(`${Feature.SHARED}:createdAt`)} name={UsersTableFilter.CREATED_AT}>
            <RangePicker css={fullWidthStyles} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t(`${Feature.SHARED}:updatedAt`)} name={UsersTableFilter.UPDATED_AT}>
            <RangePicker css={fullWidthStyles} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <Button type="primary" htmlType="submit" size="large" icon={<CheckOutlined />}>
            {t(`${Feature.SHARED}:apply`)}
          </Button>
        </Col>
        <Col>
          <Button htmlType="button" size="large" onClick={onClear}>
            {t(`${Feature.SHARED}:clear`)}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const fullWidthStyles = css`
  width: 100%;
  border-radius: 0;
`;
