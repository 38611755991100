import { createSlice, PayloadAction, SerializedError, unwrapResult } from '@reduxjs/toolkit';
import { Feature } from '../../../models/features';
import { User } from '../../../models/user';
import {
  changePassword,
  logIn,
  logOut,
  refresh,
  register,
  requestPasswordChange,
  resendEmail,
  verifyEmail,
} from '../thunks/auth-thunks';

interface AuthState {
  user: User;
  loggedIn: boolean;
  register: {
    loading: boolean;
    error: SerializedError;
  };
  verifyEmail: {
    loading: boolean;
    error: SerializedError;
  };
  resendEmail: {
    loading: boolean;
    error: SerializedError;
  };
  requestPasswordChange: {
    loading: boolean;
    error: SerializedError;
  };
  changePassword: {
    loading: boolean;
    error: SerializedError;
  };
  logIn: {
    loading: boolean;
    error: SerializedError;
  };
  refresh: {
    loading: boolean;
    error: SerializedError;
  };
  logOut: {
    loading: boolean;
    error: SerializedError;
  };
}

const initialState: AuthState = {
  user: null,
  loggedIn: false,
  register: {
    loading: false,
    error: null,
  },
  verifyEmail: {
    loading: false,
    error: null,
  },
  resendEmail: {
    loading: false,
    error: null,
  },
  requestPasswordChange: {
    loading: false,
    error: null,
  },
  changePassword: {
    loading: false,
    error: null,
  },
  logIn: {
    loading: false,
    error: null,
  },
  refresh: {
    loading: false,
    error: null,
  },
  logOut: {
    loading: false,
    error: null,
  },
};

const slice = createSlice({
  name: `${Feature.SHARED}/auth`,
  initialState,
  reducers: {
    setLoggedIn(state, action: PayloadAction<boolean>) {
      state.loggedIn = unwrapResult(action);
    },
  },
  extraReducers: builder => {
    builder.addCase(register.fulfilled, state => {
      state.register.loading = false;
    });
    builder.addCase(register.pending, state => {
      state.register.loading = true;
    });
    builder.addCase(register.rejected, (state, action) => {
      state.register.loading = false;
      state.register.error = action.error;
    });
    builder.addCase(verifyEmail.fulfilled, state => {
      state.verifyEmail.loading = false;
    });
    builder.addCase(verifyEmail.pending, state => {
      state.verifyEmail.loading = true;
    });
    builder.addCase(verifyEmail.rejected, (state, action) => {
      state.verifyEmail.loading = false;
      state.verifyEmail.error = action.error;
    });
    builder.addCase(resendEmail.fulfilled, state => {
      state.resendEmail.loading = false;
    });
    builder.addCase(resendEmail.pending, state => {
      state.resendEmail.loading = true;
    });
    builder.addCase(resendEmail.rejected, (state, action) => {
      state.resendEmail.loading = false;
      state.resendEmail.error = action.error;
    });
    builder.addCase(requestPasswordChange.fulfilled, state => {
      state.requestPasswordChange.loading = false;
    });
    builder.addCase(requestPasswordChange.pending, state => {
      state.requestPasswordChange.loading = true;
    });
    builder.addCase(requestPasswordChange.rejected, (state, action) => {
      state.requestPasswordChange.loading = false;
      state.requestPasswordChange.error = action.error;
    });
    builder.addCase(changePassword.fulfilled, state => {
      state.changePassword.loading = false;
    });
    builder.addCase(changePassword.pending, state => {
      state.changePassword.loading = true;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.changePassword.loading = false;
      state.changePassword.error = action.error;
    });
    builder.addCase(logIn.fulfilled, (state, action) => {
      state.logIn.loading = false;
      state.loggedIn = true;
      state.user = { ...action.payload.user, groupName: action.payload.groupName };
    });
    builder.addCase(logIn.pending, state => {
      state.logIn.loading = true;
    });
    builder.addCase(logIn.rejected, (state, action) => {
      state.logIn.loading = false;
      state.logIn.error = action.error;
      state.loggedIn = false;
    });
    builder.addCase(refresh.fulfilled, (state, action) => {
      state.refresh.loading = false;
      state.loggedIn = true;
      state.user = { ...action.payload.user, groupName: action.payload.groupName };
    });
    builder.addCase(refresh.pending, state => {
      state.refresh.loading = true;
    });
    builder.addCase(refresh.rejected, (state, action) => {
      state.refresh.loading = false;
      state.refresh.error = action.error;
    });
    builder.addCase(logOut.fulfilled, state => {
      state.logOut.loading = false;
      state.loggedIn = false;
      state.user = initialState.user;
    });
    builder.addCase(logOut.pending, state => {
      state.logOut.loading = true;
    });
    builder.addCase(logOut.rejected, (state, action) => {
      state.logOut.loading = false;
      if (action.error.code === '401') {
        state.loggedIn = false;
      }
      state.logOut.error = action.error;
    });
  },
});

export const { setLoggedIn } = slice.actions;
export const authSlice = slice.reducer;
