import { Menu } from 'antd';
import { MenuProps } from 'antd';
import { FC, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { css } from '@emotion/react';

import { Route } from '../../../shared/models/route';
import { getCurrentRouteKey } from '../../utils/routes';

type Props = {
  navigationRoutes: Route[];
};

export const SideNavigation: FC<Props> = memo(({ navigationRoutes }) => {
  const { t } = useTranslation();
  const { push: routerPush } = useHistory();
  const { pathname } = useLocation();

  const defaultKeys = useMemo<{ selectedKeys: string[]; openKeys: string[] }>(() => {
    const currentRouteKey = getCurrentRouteKey(pathname, navigationRoutes);

    if (currentRouteKey != null) {
      return {
        selectedKeys: [currentRouteKey.key],
        openKeys: currentRouteKey.parentKey != null ? [currentRouteKey.parentKey] : [],
      };
    }

    return {
      selectedKeys: [navigationRoutes[0].path],
      openKeys: [],
    };
  }, [navigationRoutes, pathname]);

  const handleMenuItemClick: MenuProps['onClick'] = useCallback(
    ({ key }) => {
      routerPush(key.toString());
    },
    [routerPush]
  );

  function getItem(route: Route, parentPath?: string) {
    return {
      key: parentPath != null ? `${parentPath}${route.path}` : route.path,
      icon: route.icon && <img className="menu-icons" src={route.icon} alt={'Menu Icon'} />,
      label: t(route.nameTranslationKey),
      children:
        route.children?.length > 0 ? route.children?.map(childrenRoute => getItem(childrenRoute, route.path)) : null,
    } as MenuProps['items'][number];
  }

  const items: MenuProps['items'] = navigationRoutes
    .filter(route => route.displayInSideNavigation === true)
    .map(route => getItem(route));

  return (
    <Menu
      mode="inline"
      css={menuStyles}
      selectedKeys={defaultKeys.selectedKeys}
      defaultOpenKeys={defaultKeys.openKeys}
      onClick={handleMenuItemClick}
      items={items}
    />
  );
});

const menuStyles = css`
  min-height: calc(100% - 20px);
  border-right: 0;

  img.menu-icons {
    height: 16px;
  }
`;
