import { useQuery } from 'react-query';
import { Policy as BePolicy, FindAllPolicyTemplatesResponse } from '@pccr-ifc/pccr-ifc';
import { useTranslation } from 'react-i18next';

import { CommonFindAllQueryParams, QueryRequest } from '../../../shared/models/api/request';
import { Policy } from '../../../shared/models/policy';
import { deleteEmptyArrayObjProps } from '../../../shared/utils/object';
import { PoliciesFilters } from '../../models/policy';
import { convertSearchResponse } from '../converters/policy-converters';
import { usePolicyService } from '../services/use-policy-service';
import { ResourceSearchResult } from '../../../shared/models/api/response';
import { getResourceListQueryKeyRoot, getSingleResourceQueryKey } from '../../../shared/utils/resource-query-key';
import { displayErrors } from '../../../shared/utils/error';

export enum PolicyQueryKey {
  TEMPLATES = 'templates',
}

export function useGetPolicyQuery(id: number) {
  const policyService = usePolicyService();
  const { t } = useTranslation();

  return useQuery<BePolicy, unknown, Policy>(
    getSingleResourceQueryKey('POLICY', id),
    async () => {
      const response = await policyService.getPolicy(id);
      return response.data;
    },
    {
      enabled: id != null,
      onError: error => displayErrors(error, t),
    }
  );
}

export function useGetPolicyTemplateQuery(enabled) {
  const policyService = usePolicyService();
  const { t } = useTranslation();

  return useQuery<FindAllPolicyTemplatesResponse, unknown>(
    PolicyQueryKey.TEMPLATES,
    async () => {
      const response = await policyService.getTemplates();
      return response.data;
    },
    {
      enabled,
      onError: error => displayErrors(error, t),
    }
  );
}

export function useFindPoliciesQuery({
  page,
  pageSize,
  searchValue,
  filters,
  order,
  attributes = ['id', 'method', 'name', 'path', 'request', 'createdAt', 'updatedAt'],
}: CommonFindAllQueryParams<Policy, PoliciesFilters>) {
  const policyService = usePolicyService();

  return useQuery<ResourceSearchResult<Policy>, unknown>(
    [getResourceListQueryKeyRoot('POLICY'), { page, pageSize }, searchValue, filters, order, attributes],
    async () => {
      const request: QueryRequest<Policy, PoliciesFilters> = {
        numberOfResults: pageSize,
        startRow: (page - 1) * pageSize,
        attributes,
      };

      if (order?.length > 0) {
        request.order = order;
      }

      if (searchValue) {
        request.search = searchValue;
      }

      if (filters && Object.values(filters).some(val => val != null)) {
        request.filters = deleteEmptyArrayObjProps(filters);
      }

      const response = await policyService.findPolicies(request);
      return convertSearchResponse(response.data);
    }
  );
}
