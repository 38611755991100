import { Button, Form, Input, message, Space, Typography } from 'antd';
import { FC, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowLeftOutlined, ArrowRightOutlined, LockOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { unwrapResult } from '@reduxjs/toolkit';

import { useAuthService } from '../../../../shared/data/services/use-auth-service';
import { changePasswordLoadingSelector } from '../../../../shared/data/store/selectors/auth-selectors';
import { changePassword } from '../../../../shared/data/store/thunks/auth-thunks';
import { Feature } from '../../../../shared/models/features';
import { translationNamespace } from '../../../constants/translation-resources';
import { convertFormValuesToChangePasswordRequest } from '../../../data/converters/auth-converters';
import { ForgotPasswordContext, ForgotPasswordFormField, ForgotPasswordFormValues } from '../forgot-password-context';
import { displayErrors } from '../../../../shared/utils/error';
import { userPasswordRulesWithoutUsernameRule } from '../../../../shared/utils/form-rules';
import { showOnlyOneError } from '../../../../shared/styles/form-styles';

const { Title, Text } = Typography;

type ChangeFormValues = Pick<
  ForgotPasswordFormValues,
  ForgotPasswordFormField.PASSWORD | ForgotPasswordFormField.CONFIRM_PASSWORD
>;

export const Change: FC = () => {
  const { t } = useTranslation(translationNamespace);
  const [form] = Form.useForm();
  const { previousStep, setValues, nextStep, token } = useContext(ForgotPasswordContext);
  const dispatch = useDispatch();
  const authService = useAuthService();
  const requestLoading = useSelector(changePasswordLoadingSelector);

  const submit = useCallback(
    (values: ChangeFormValues) => {
      setValues(values);
      dispatch(
        changePassword({ request: convertFormValuesToChangePasswordRequest(values, token), service: authService })
      )
        .then(unwrapResult)
        .then(() => {
          message.success(t('forgotPasswordView.steps.change.changeSuccessMessage'));
          nextStep();
        })
        .catch(error => displayErrors(error, t));
    },
    [authService, dispatch, nextStep, setValues, t, token]
  );

  return (
    <Space direction="vertical">
      <Title level={2}>{t('forgotPasswordView.steps.change.title')}</Title>
      <Text type="secondary">{t('forgotPasswordView.steps.change.description')}</Text>
      <Form layout="vertical" form={form} onFinish={submit} requiredMark={false}>
        <Form.Item
          name={ForgotPasswordFormField.PASSWORD}
          label={t(`${Feature.SHARED}:password.label`)}
          rules={userPasswordRulesWithoutUsernameRule(t)}
          css={showOnlyOneError}
        >
          <Input.Password prefix={<LockOutlined />} placeholder={t(`${Feature.SHARED}:password.placeholder`)} />
        </Form.Item>
        <Form.Item
          name={ForgotPasswordFormField.CONFIRM_PASSWORD}
          label={t('shared.confirmPassword.label')}
          dependencies={[ForgotPasswordFormField.PASSWORD]}
          rules={[
            { required: true },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue(ForgotPasswordFormField.PASSWORD) === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('shared.confirmPassword.error')));
              },
            }),
          ]}
        >
          <Input.Password prefix={<LockOutlined />} placeholder={t('shared.confirmPassword.placeholder')} />
        </Form.Item>
        <ButtonGroup>
          <ButtonNoPaddingLeft type="link" htmlType="button" icon={<ArrowLeftOutlined />} onClick={previousStep}>
            {t('shared.back')}
          </ButtonNoPaddingLeft>
          <Form.Item>
            <StyledButton type="primary" htmlType="submit" icon={<ArrowRightOutlined />} loading={requestLoading}>
              {t('shared.continue')}
            </StyledButton>
          </Form.Item>
        </ButtonGroup>
      </Form>
    </Space>
  );
};

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const StyledButton = styled(Button)`
  margin-top: 16px;
`;

const ButtonNoPaddingLeft = styled(StyledButton)`
  padding-left: 0;
`;
