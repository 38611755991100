import { Typography } from 'antd';
import { getHours } from 'date-fns';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useInterval } from 'react-use';
import styled from '@emotion/styled';

import { permissionSelector, userSelector } from '../../../shared/data/store/selectors/auth-selectors';
import { formatTime } from '../../../shared/utils/date';
import { getLanguageFromI18nLanguage } from '../../../shared/utils/language';
import { translationNamespace } from '../../constants/translation-resources';
import { UserName } from '../../../shared/components/user-name';
import { NavigationTab } from '../../components/navigation-tabs';
import { Feature } from '../../../shared/models/features';
import reportImage from './../../assets/images/tile_reports.jpg';
import documentsImage from './../../assets/images/tile_documents.jpg';
import usersImage from './../../assets/images/tile_releases.jpg';
import profileImage from './../../assets/images/tile_users.jpg';
import { AppResourcePaths } from '../../../shared/constants/app-resources';
import { colors, fontSize } from '../../../shared/constants/styles';

export const HomepageView: React.FC = () => {
  const { t, i18n } = useTranslation(translationNamespace);
  const user = useSelector(userSelector);
  const [date, setDate] = useState(new Date());
  const permissions = useSelector(permissionSelector);

  const getWelcomeMessage = () => {
    const hours = getHours(date);
    const message: ReactNode[] = [];

    if (hours >= 5 && hours < 12) {
      message.push(t('homepage.goodMorning'));
    } else if (hours >= 12 && hours < 16) {
      message.push(t('homepage.goodAfternoon'));
    } else {
      message.push(t('homepage.goodEvening'));
    }

    if (user) {
      message.push(', ', <UserName key="user" user={user} />, '!');
    }

    return message;
  };

  useInterval(() => {
    setDate(new Date());
  }, 1000);

  const navigationCards = [
    {
      path: AppResourcePaths.reports,
      title: `${Feature.SHARED}:report.labelPlural`,
      authorized: permissions.REPORT.READ,
      backgroundImage: reportImage,
    },
    {
      path: AppResourcePaths.documents,
      title: `${Feature.APPROVAL}:navigation.approval`,
      authorized: permissions.BATCH.READ,
      backgroundImage: documentsImage,
    },
    {
      path: AppResourcePaths.users,
      title: `${Feature.ACCESS}:shared.users`,
      authorized: permissions.USER.READ,
      backgroundImage: usersImage,
    },
  ].filter(card => card.authorized);

  return (
    <Container>
      <GreatingWrapper>
        <TypographyTime>{formatTime(date, getLanguageFromI18nLanguage(i18n.language))}</TypographyTime>
        <TypographyWelcome>{getWelcomeMessage()}</TypographyWelcome>
      </GreatingWrapper>
      {navigationCards.length > 0 &&
        navigationCards.map(({ path, title, backgroundImage }) => (
          <NavigationTab key={path} path={path} title={t(title)} backgroundImage={backgroundImage} />
        ))}
      <NavigationTab path={'/auth/profile'} title={t(`${Feature.SHARED}:profile`)} backgroundImage={profileImage} />
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px;
  align-items: center;
  justify-content: center;
`;

const GreatingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 215px;
  background-color: ${colors.grey};

  span {
    padding: 0 5px;
    text-align: center;
    color: ${colors.primary};
    font-weight: bold;
  }
`;

const TypographyTime = styled(Typography.Text)`
  font-size: ${fontSize.THREE};
  line-height: 64px;
`;

const TypographyWelcome = styled(Typography.Text)`
  font-size: ${fontSize.ONE_POINT_TWO};
  line-height: 28px;
`;
