import { Step } from '../../../models/step';
import { Details } from './details';
import { Finish } from './finish';
import { Intro } from './intro';
import { Verification } from './verification';

export const steps: Step[] = [
  {
    key: 'intro',
    nameTranslationKey: 'registerView.steps.intro.label',
    component: Intro,
  },
  {
    key: 'details',
    nameTranslationKey: 'registerView.steps.details.label',
    component: Details,
  },
  {
    key: 'verification',
    nameTranslationKey: 'registerView.steps.verification.label',
    component: Verification,
  },
  {
    key: 'finish',
    nameTranslationKey: 'registerView.steps.finish.label',
    component: Finish,
    tokenStep: true,
  },
];
