import { AllowedRoutesResponse, DefaultRolesResponse } from '@pccr-ifc/pccr-ifc';

import { Method } from '../../../shared/models/method';
import { UserAllowedRoutes } from '../../models/allowed-required-routes';
import { defaultRolesNamesToAppResourceMap } from '../../constants/default-roles-maps';
import { AppResources } from '../../../shared/constants/app-resources';
import { DefaultRole } from '../../models/default-role';

const excludedRoutes = ['/user/edit/own', '/role/default'];

export function convertGetUserAllowedRoutesResponse(response: AllowedRoutesResponse): UserAllowedRoutes {
  return Object.fromEntries(
    Object.entries(response).reduce((acc, [path, methodsObj]) => {
      if (!excludedRoutes.includes(path)) {
        acc.push([path, new Set(Object.keys(methodsObj).map(method => method.toUpperCase() as Method))]);
      }
      return acc;
    }, [])
  );
}

export function convertDefaultRolesResponse(defaultRolesResponse: DefaultRolesResponse): DefaultRole[] {
  const defaultRoles = defaultRolesResponse.roles.map(defaultRole => ({
    name: defaultRolesNamesToAppResourceMap[defaultRole.name],
    CREATE: defaultRole.create,
    READ: defaultRole.read,
    UPDATE: defaultRole.update,
    DELETE: defaultRole.delete,
  }));

  const sortedDefaultRoles = Object.values(AppResources).reduce((acc, resource) => {
    const role = defaultRoles.find(role => role.name === resource);
    if (role !== undefined) {
      acc.push(role);
    }
    return acc;
  }, [] as DefaultRole[]);

  return sortedDefaultRoles;
}
