import axios from 'axios';
import { ReportDefinition as BeDefinition, CheckDefinitionResponse } from '@pccr-ifc/pccr-ifc';

import { BASE_PATH } from '../../../shared/data/services/use-definition-service';
import { Config } from '../../../shared/models/config';
import { useService } from '../../../shared/hooks/use-service';

function createDefinitionService(config: Config) {
  const createDefinition = async (request: { definition: Omit<BeDefinition, 'id' | 'status'> }) => {
    const response = await axios.post<{ definition: BeDefinition }>(`${config.API_URL}${BASE_PATH}/add`, request, {
      withCredentials: true,
    });
    return response.data.definition;
  };

  const updateDefinition = async ({ id, ...request }: Partial<BeDefinition>) => {
    const response = await axios.patch<{ definition: BeDefinition }>(
      `${config.API_URL}${BASE_PATH}/${id}`,
      { definition: request },
      {
        withCredentials: true,
      }
    );
    return response.data.definition;
  };

  const deleteDefinition = (id: number) =>
    axios.delete<void>(`${config.API_URL}${BASE_PATH}/${id}`, {
      withCredentials: true,
    });

  const checkDefinition = (request: { definition: Omit<BeDefinition, 'id' | 'status'> }) =>
    axios.post<CheckDefinitionResponse>(`${config.API_URL}${BASE_PATH}/check`, request, {
      withCredentials: true,
    });

  return {
    createDefinition,
    updateDefinition,
    deleteDefinition,
    checkDefinition,
  };
}

export const useDefinitionService = () => useService(createDefinitionService);

export type DefinitionService = ReturnType<typeof useDefinitionService>;
