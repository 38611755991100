import { Button, ColProps, Form, Input, InputNumber, Select, Space } from 'antd';
import { NamePath } from 'rc-field-form/lib/interface';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce, useMap } from 'react-use';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { useFindDefinitionsQuery, useGetDefinitionQuery } from '../../../shared/data/queries/definition-queries';
import { QueryParamType } from '../../../shared/models/query-param';
import { translationNamespace } from '../../constants/translation-resources';
import { mapQueryParamTypeToComponent, mapValueTypeToQueryParamType } from '../../utils/query-params-mappers';
import { redundantSpacebarsRule } from '../../utils/form-rules';
import { QueryParamTypes } from '../../constants/query-param';
import { showOnlyOneError } from '../../styles/form-styles';
import { ResourceSelect } from '../resource-select';
import { Job } from '../../../automation/models/job';

export enum GenerateReportFormField {
  NAME = 'name',
  DEFINITION_ID = 'definitionId',
  DAYS_TO_EXPIRE = 'daysToExpire',
  SQL_PARAMS = 'sqlParams',
  SQL_PARAM_KEY = 'key',
  SQL_PARAM_TYPE = 'type',
  SQL_PARAM_VALUE = 'value',
}

export interface GenerateReportFormSQLParamsValue<T = unknown> {
  [GenerateReportFormField.SQL_PARAM_KEY]: string;
  [GenerateReportFormField.SQL_PARAM_TYPE]: QueryParamType;
  [GenerateReportFormField.SQL_PARAM_VALUE]: T;
}

export interface GenerateReportFormValues {
  [GenerateReportFormField.NAME]: string;
  [GenerateReportFormField.DEFINITION_ID]: number;
  [GenerateReportFormField.DAYS_TO_EXPIRE]: number;
  [GenerateReportFormField.SQL_PARAMS]: GenerateReportFormSQLParamsValue[];
}

interface Props {
  namePath?: NamePath;
  setFieldsValue: <T extends Record<string | number, unknown>>(values: T) => void;
  customWrapperCol?: ColProps;
  job?: Job;
}

const defaultDaysToExpire = 60;

export const GenerateReportFormFields: React.FC<Props> = ({ namePath, setFieldsValue, customWrapperCol, job }) => {
  const { t } = useTranslation(translationNamespace);
  const [definitionsSearchValue, setDefinitionsSearchValue] = useState<string>();
  const [definitionsDebouncedSearchValue, setDefinitionsDebouncedSearchValue] = useState<string>();
  const { data: definitionsData, isLoading: definitionsLoading } = useFindDefinitionsQuery({
    page: 1,
    pageSize: 25,
    searchValue: definitionsDebouncedSearchValue,
    attributes: ['id', 'name', 'status'],
  });
  const [selectedDefinitionId, setSelectedDefinitionId] = useState<number>();
  const { data: definition, isLoading: definitionLoading } = useGetDefinitionQuery(selectedDefinitionId);
  const [paramsTypes, { set: setParamsTypes, remove: removeParamsTypes }] = useMap<Record<number, QueryParamType>>({});
  const { data: currentDefinitionData, isLoading: currentDefinitionLoading } = useFindDefinitionsQuery(
    {
      page: 1,
      pageSize: 1,
      attributes: ['id', 'name', 'status'],
      filters: { id: [job?.request.body.report.definitionId] },
    },
    job?.request.body.report.definitionId != null
  );

  const basePath = useMemo(() => {
    if (namePath == null) {
      return null;
    }

    if (Array.isArray(namePath)) {
      return namePath;
    }

    return [namePath];
  }, [namePath]);

  const renderParamValueFieldByType = useCallback(
    (fieldKey: number) => {
      return mapQueryParamTypeToComponent(paramsTypes[fieldKey], t);
    },
    [paramsTypes, t]
  );

  const getFormFieldName = useCallback(
    (name: NamePath) => {
      if (basePath == null) {
        return name;
      }

      if (Array.isArray(name)) {
        return [...basePath, ...name];
      }

      return [...basePath, name];
    },
    [basePath]
  );

  function expireTimeParser(value: string) {
    return value.replaceAll(',', '.');
  }

  const applyDefinitionsSearch = (value: string) => {
    setDefinitionsSearchValue(value);
    setDefinitionsDebouncedSearchValue(value);
  };

  const selectDefinition = (definitionId: number) => {
    setSelectedDefinitionId(definitionId);
  };

  const setParamType = (fieldKey: number, value: QueryParamType) => {
    removeParamsTypes(fieldKey);
    setParamsTypes(fieldKey, value);
  };

  useDebounce(
    () => {
      setDefinitionsDebouncedSearchValue(definitionsSearchValue);
    },
    3000,
    [definitionsSearchValue]
  );

  useEffect(() => {
    if (definition == null) {
      return;
    }

    setFieldsValue({
      [GenerateReportFormField.SQL_PARAMS]: definition.sqlParams
        ? Object.entries(definition.sqlParams).map(([key, value]) => {
            return {
              [GenerateReportFormField.SQL_PARAM_KEY]: key,
              [GenerateReportFormField.SQL_PARAM_TYPE]: mapValueTypeToQueryParamType(value),
              [GenerateReportFormField.SQL_PARAM_VALUE]: value,
            };
          })
        : [],
    });
  }, [definition, setFieldsValue]);

  return (
    <>
      <Form.Item
        label={t('report.name')}
        name={getFormFieldName(GenerateReportFormField.NAME)}
        rules={[{ required: true, max: 180 }, redundantSpacebarsRule(t)]}
        css={fullWidthStyles}
      >
        <Input placeholder={t('report.placeholder')} />
      </Form.Item>
      <Form.Item
        label={t('definition.label')}
        name={getFormFieldName(GenerateReportFormField.DEFINITION_ID)}
        rules={[{ required: true }]}
      >
        <ResourceSelect
          allowClear
          showSearch
          filterOption={false}
          placeholder={t('definition.select')}
          loading={definitionsLoading || currentDefinitionLoading}
          onSearch={applyDefinitionsSearch}
          onSelect={selectDefinition}
          resources={definitionsData?.resources}
          currentResource={currentDefinitionData?.resources[0]}
          resourceSearchValue={definitionsSearchValue}
        />
      </Form.Item>
      <Form.Item
        label={t('report.daysToExpire')}
        name={getFormFieldName(GenerateReportFormField.DAYS_TO_EXPIRE)}
        initialValue={defaultDaysToExpire}
        rules={[{ required: true, type: 'number', min: 1 }]}
        css={showOnlyOneError}
      >
        <InputNumber parser={expireTimeParser} />
      </Form.Item>
      <Form.Item css={noMarginBottomStyles} wrapperCol={customWrapperCol}>
        <Form.List name={getFormFieldName(GenerateReportFormField.SQL_PARAMS)}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...field }) => (
                <StyledSpace key={key} align="baseline">
                  <Form.Item
                    {...field}
                    name={[name, GenerateReportFormField.SQL_PARAM_KEY]}
                    rules={[{ required: true, type: 'string' }]}
                    wrapperCol={{ span: 24 }}
                    messageVariables={{ label: t('queryParams.key') }}
                  >
                    <Input placeholder={t('queryParams.key')} />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    // name={[name, GenerateReportFormField.SQL_PARAM_TYPE]}
                    // rules={[{ required: true }]}
                    wrapperCol={{ span: 24 }}
                    messageVariables={{ label: t('type') }}
                  >
                    <Select
                      showSearch
                      allowClear
                      placeholder={t('type')}
                      onSelect={(value: QueryParamType) => setParamType(key, value)}
                      disabled
                    >
                      {Object.values(QueryParamTypes).map((queryParamType, index) => (
                        <Select.Option key={index} value={queryParamType}>
                          {queryParamType}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[name, GenerateReportFormField.SQL_PARAM_VALUE]}
                    rules={[{ required: true }]}
                    wrapperCol={{ span: 24 }}
                    messageVariables={{ label: t('value') }}
                  >
                    {renderParamValueFieldByType(key)}
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </StyledSpace>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} loading={definitionLoading} block icon={<PlusOutlined />}>
                  {t('queryParams.add')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
    </>
  );
};

const fullWidthStyles = css`
  width: 100%;
`;

const noMarginBottomStyles = css`
  margin-bottom: 0;
`;

const marginBottomStyles = css`
  margin-bottom: 8px;
`;

const StyledSpace = styled(Space)`
  display: flex;

  ${marginBottomStyles}

  > div:not(:last-of-type) {
    width: 100%;
  }
`;
