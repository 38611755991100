import { UserRequiredRoute } from '../../core/models/allowed-required-routes';
import { Methods } from '../../shared/constants/method';

const generateReportPath = '/report/data/generate';
export const generateReportRequiredRoute: UserRequiredRoute = {
  path: generateReportPath,
  method: Methods.POST,
};

const listReportsPath = '/report/data/findall';
export const listReportsRequiredRoute: UserRequiredRoute = {
  path: listReportsPath,
  method: Methods.POST,
};

const deleteReportPath = '/report/data/\\d+';
export const deleteReportRequiredRoute: UserRequiredRoute = {
  path: deleteReportPath,
  method: Methods.DELETE,
};

const createPresetPath = '/report/preset/add';
export const createPresetRequiredRoute: UserRequiredRoute = {
  path: createPresetPath,
  method: Methods.POST,
};

const listPresetsPath = '/report/preset/findall';
export const listPresetsRequiredRoute: UserRequiredRoute = {
  path: listPresetsPath,
  method: Methods.POST,
};

const updatePresetPath = '/report/preset/\\d+';
export const updatePresetRequiredRoute: UserRequiredRoute = {
  path: updatePresetPath,
  method: Methods.PATCH,
};

const deletePresetPath = '/report/preset/\\d+';
export const deletePresetRequiredRoute: UserRequiredRoute = {
  path: deletePresetPath,
  method: Methods.DELETE,
};

const createDefinitionPath = '/report/definition/add';
export const createDefinitionRequiredRoute: UserRequiredRoute = {
  path: createDefinitionPath,
  method: Methods.POST,
};

const listDefinitionsPath = '/report/definition/findall';
export const listDefinitionsRequiredRoute: UserRequiredRoute = {
  path: listDefinitionsPath,
  method: Methods.POST,
};

const updateDefinitionPath = '/report/definition/\\d+';
export const updateDefinitionRequiredRoute: UserRequiredRoute = {
  path: updateDefinitionPath,
  method: Methods.PATCH,
};

const deleteDefinitionPath = '/report/definition/\\d+';
export const deleteDefinitionRequiredRoute: UserRequiredRoute = {
  path: deleteDefinitionPath,
  method: Methods.DELETE,
};

const createViewPath = '/report/view/add';
export const createViewRequiredRoute: UserRequiredRoute = {
  path: createViewPath,
  method: Methods.POST,
};

const listViewsPath = '/report/view/findall';
export const listViewsRequiredRoute: UserRequiredRoute = {
  path: listViewsPath,
  method: Methods.POST,
};

const createSourcePath = '/report/source/add';
export const createSourceRequiredRoute: UserRequiredRoute = {
  path: createSourcePath,
  method: Methods.POST,
};

const listSourcesPath = '/report/source/findall';
export const listSourcesRequiredRoute: UserRequiredRoute = {
  path: listSourcesPath,
  method: Methods.POST,
};

const updateSourcePath = '/report/source/\\d+';
export const updateSourceRequiredRoute: UserRequiredRoute = {
  path: updateSourcePath,
  method: Methods.PATCH,
};

const deleteSourcePath = '/report/source/\\d+';
export const deleteSourceRequiredRoute: UserRequiredRoute = {
  path: deleteSourcePath,
  method: Methods.DELETE,
};
