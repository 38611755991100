import { GuardFunction } from 'react-router-guards';
import { changeLayout } from '../../shared/data/store';
import { GuardedRouteMeta } from '../../shared/models/route';

export const layoutGuard: GuardFunction = (to, from, next) => {
  const { layout, dispatch }: GuardedRouteMeta = to.meta;
  if (layout) {
    dispatch(changeLayout(layout));
  }
  next();
};
