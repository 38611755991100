import styled from '@emotion/styled';
import { Button, Card, Typography } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getLanguageFromI18nLanguage } from '../../../shared/utils/language';
import { formatNumber } from '../../../shared/utils/number';
import { translationNamespace } from '../../../shared/constants/translation-resources';

interface Props {
  title: string;
  subtitle?: string;
  count: number;
  loading: boolean;
  action?: () => void;
}

const { Text } = Typography;

export const TenantStatsCard: FC<Props> = ({ title, subtitle, count, loading, action }) => {
  const { t, i18n } = useTranslation(translationNamespace);
  const lang = getLanguageFromI18nLanguage(i18n.language);

  return (
    <Card
      loading={loading}
      title={title}
      extra={
        <Button type="link" onClick={action} disabled={isNaN(count) || count === 0}>
          {t('view')}
        </Button>
      }
    >
      <BlockText type="secondary">{subtitle}</BlockText>
      <LargeBlockText>{formatNumber(isNaN(count) ? 0 : count, lang)}</LargeBlockText>
    </Card>
  );
};

const BlockText = styled(Text)`
  display: block;
`;

const LargeBlockText = styled(BlockText)`
  font-size: 24px;
`;
