import { resources as accessResources } from '../../access/constants/translation-resources';
import { resources as approvalResources } from '../../approval/constants/translation-resources';
import { resources as authResources } from '../../auth/constants/translation-resources';
import { resources as logsResources } from '../../logs/constants/translation-resources';
import { resources as automationResources } from '../../automation/constants/translation-resources';
import { resources as reportsResources } from '../../reports/constants/translation-resources';
import { resources as sharedResources } from '../../shared/constants/translation-resources';
import { Language } from '../../shared/models/language';
import { LanguagesResources } from '../../shared/models/language-resources';
import { resources as coreResources } from '../constants/translation-resources';

export const resources: LanguagesResources = {
  [Language.EnglishGB]: {
    ...coreResources[Language.EnglishGB],
    ...authResources[Language.EnglishGB],
    ...accessResources[Language.EnglishGB],
    ...approvalResources[Language.EnglishGB],
    ...logsResources[Language.EnglishGB],
    ...automationResources[Language.EnglishGB],
    ...reportsResources[Language.EnglishGB],
    ...sharedResources[Language.EnglishGB],
  },
  [Language.German]: {
    ...coreResources[Language.German],
    ...authResources[Language.German],
    ...accessResources[Language.German],
    ...approvalResources[Language.German],
    ...logsResources[Language.German],
    ...automationResources[Language.German],
    ...reportsResources[Language.German],
    ...sharedResources[Language.German],
  },
};
