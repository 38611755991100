import { Button, Col, Form, FormInstance, Row, Select } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';

import { Feature } from '../../../shared/models/features';
import { translationNamespace } from '../../constants/translation-resources';
import { DefinitionStatus } from '../../../shared/models/definition';
import { DefinitionStatuses } from '../../../shared/constants/definition';
import { RangePicker } from '../../../shared/components/date-picker/date-picker';

enum DefinitionsTableFilter {
  NAME = 'name',
  STATUS = 'status',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
}

export interface DefinitionsTableFilters {
  [DefinitionsTableFilter.NAME]: string[];
  [DefinitionsTableFilter.STATUS]: DefinitionStatus[];
  [DefinitionsTableFilter.CREATED_AT]: [Date?, Date?];
  [DefinitionsTableFilter.UPDATED_AT]: [Date?, Date?];
}

interface DefinitionsTableFiltersFormProps {
  onApply?: (filters: DefinitionsTableFilters) => void;
  form: FormInstance<DefinitionsTableFilters>;
}

export const DefinitionsTableFiltersForm: FC<DefinitionsTableFiltersFormProps> = ({ onApply, form }) => {
  const { t } = useTranslation(translationNamespace);

  const onClear = () => {
    form.resetFields();
    onApply?.(form.getFieldsValue());
  };

  return (
    <Form form={form} layout="vertical" onFinish={onApply}>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item label={t(`${Feature.SHARED}:name`)} name={DefinitionsTableFilter.NAME}>
            <Select suffixIcon={null} size="large" mode="tags" allowClear open={false} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t(`${Feature.SHARED}:status`)} name={DefinitionsTableFilter.STATUS}>
            <Select size="large" mode="multiple" allowClear>
              {Object.values(DefinitionStatuses).map((definitionStatus, i) => (
                <Select.Option key={i} value={definitionStatus}>
                  {t(`shared.status.${definitionStatus.toLowerCase()}`)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t(`${Feature.SHARED}:createdAt`)} name={DefinitionsTableFilter.CREATED_AT}>
            <RangePicker css={fullWidthStyles} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label={t(`${Feature.SHARED}:updatedAt`)} name={DefinitionsTableFilter.UPDATED_AT}>
            <RangePicker css={fullWidthStyles} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <Button type="primary" htmlType="submit" size="large" icon={<CheckOutlined />}>
            {t(`${Feature.SHARED}:apply`)}
          </Button>
        </Col>
        <Col>
          <Button htmlType="button" size="large" onClick={onClear}>
            {t(`${Feature.SHARED}:clear`)}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const fullWidthStyles = css`
  width: 100%;
  border-radius: 0;
`;
