import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { User as BeUser } from '@pccr-ifc/pccr-ifc';
import { useTranslation } from 'react-i18next';

import { UserProfilePasswordChangeFields, UserProfileUpsert } from '../../models/user-profile';
import { convertToUpsertRequest } from '../converters/user-profile-converters';
import { useUserProfileService } from '../services/use-user-profile-service';
import { displayErrors } from '../../../shared/utils/error';

export function useUpdateUserProfileMutation() {
  const userProfileService = useUserProfileService();
  const { t } = useTranslation();

  return useMutation<BeUser, unknown, UserProfileUpsert>(
    user => {
      const request = convertToUpsertRequest(user);
      return userProfileService.updateUserProfile(request);
    },
    {
      onError(error) {
        displayErrors(error, t);
      },
    }
  );
}

export function useUserProfileChangePasswordMutation() {
  const userProfileService = useUserProfileService();
  const { t } = useTranslation();

  return useMutation<AxiosResponse<void>, unknown, UserProfilePasswordChangeFields>(
    data => userProfileService.userProfileChangePassword(data),
    {
      onError(error) {
        displayErrors(error, t);
      },
    }
  );
}
