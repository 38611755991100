import axios from 'axios';
import { FindAllRolesResponse, Role as BeRole, RoleWithIncludes, FindAllRolesRequest } from '@pccr-ifc/pccr-ifc';

import { Config } from '../../../shared/models/config';
import { useService } from '../../../shared/hooks/use-service';

function createRoleService(config: Config) {
  const BASE_PATH = '/role';

  const findRoles = (request: FindAllRolesRequest) =>
    axios.post<FindAllRolesResponse>(`${config.API_URL}${BASE_PATH}/findAll`, request, {
      withCredentials: true,
    });

  const getRole = (id: number) =>
    axios.get<RoleWithIncludes>(`${config.API_URL}${BASE_PATH}/${id}`, {
      withCredentials: true,
    });

  const createRole = (role: Omit<BeRole, 'id'>) =>
    axios.post<RoleWithIncludes>(
      `${config.API_URL}${BASE_PATH}/add`,
      { role },
      {
        withCredentials: true,
      }
    );

  const updateRole = ({ id, ...request }: Partial<BeRole>) =>
    axios.patch<RoleWithIncludes>(
      `${config.API_URL}${BASE_PATH}/${id}`,
      { role: request },
      {
        withCredentials: true,
      }
    );

  const deleteRole = (id: number) =>
    axios.delete<void>(`${config.API_URL}${BASE_PATH}/${id}`, {
      withCredentials: true,
    });

  return { findRoles, getRole, createRole, updateRole, deleteRole };
}

export const useRoleService = () => useService(createRoleService);
