import { Button, ButtonProps } from 'antd';
import styled from '@emotion/styled';

import { colors } from '../../constants/styles';

interface FixedButtonProps extends ButtonProps {
  iconSrc?: string;
}

export const FixedButton = ({ iconSrc, icon, ...prop }: FixedButtonProps) => {
  return <StyledButton {...prop} icon={iconSrc ? <img className={'icon'} src={iconSrc} alt={'Icon'} /> : icon} />;
};

export const StyledButton = styled(Button)`
  background-color: ${colors.orange};
  position: fixed;
  z-index: 1;
  top: 71px;
  right: 24px;

  img.icon {
    width: 13px;
    height: 13px;
  }

  &:hover {
    background-color: ${colors.hoverOrange} !important;
  }
`;
