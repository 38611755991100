import {
  addDays,
  addMonths,
  addYears,
  endOfMonth,
  format as formatDate,
  getDate,
  getDay,
  getHours,
  getMinutes,
  getMonth,
  getSeconds,
  getWeek,
  getYear,
  isAfter,
  isValid,
  parse as parseDate,
  setDate,
  setHours,
  setMinutes,
  setMonth,
  setSeconds,
  setYear,
  startOfWeek,
} from 'date-fns';
import enGB from 'date-fns/locale/en-GB';
import de from 'date-fns/locale/de';
import { GenerateConfig } from 'rc-picker/lib/generate';
import { Language } from '../models/language';

const defaultDateFnsLocale: Locale = enGB;

const antdDateFnsLocaleMap: Record<string, Locale> = {
  en_GB: enGB,
  de_DE: de,
};

export const i18nDateFnsLocaleMap: Record<Language, Locale> = {
  [Language.EnglishGB]: enGB,
  [Language.German]: de,
};

const dealLocal = (str: string) => antdDateFnsLocaleMap[str] ?? defaultDateFnsLocale;

const localeParse = (format: string) => {
  return format
    .replace(/Y/g, 'y')
    .replace(/D/g, 'd')
    .replace(/gggg/, 'yyyy')
    .replace(/g/g, 'G')
    .replace(/([Ww])o/g, 'wo');
};

export const dateFnsGenerateConfig: GenerateConfig<Date> = {
  getNow: () => {
    return new Date();
  },
  getFixedDate: string => {
    return new Date(string);
  },
  getEndDate: date => {
    return endOfMonth(date);
  },
  getWeekDay: date => {
    return getDay(date);
  },
  getYear: date => {
    return getYear(date);
  },
  getMonth: date => {
    return getMonth(date);
  },
  getDate: date => {
    return getDate(date);
  },
  getHour: date => {
    return getHours(date);
  },
  getMinute: date => {
    return getMinutes(date);
  },
  getSecond: date => {
    return getSeconds(date);
  },
  addYear: (date, diff) => {
    return addYears(date, diff);
  },
  addMonth: (date, diff) => {
    return addMonths(date, diff);
  },
  addDate: (date, diff) => {
    return addDays(date, diff);
  },
  setYear: (date, year) => {
    return setYear(date, year);
  },
  setMonth: (date, month) => {
    return setMonth(date, month);
  },
  setDate: (date, num) => {
    return setDate(date, num);
  },
  setHour: (date, hour) => {
    return setHours(date, hour);
  },
  setMinute: (date, minute) => {
    return setMinutes(date, minute);
  },
  setSecond: (date, second) => {
    return setSeconds(date, second);
  },
  isAfter: (date1, date2) => {
    return isAfter(date1, date2);
  },
  isValidate: date => {
    return isValid(date);
  },
  locale: {
    getWeekFirstDay: locale => {
      const clone = dealLocal(locale);
      return clone.options?.weekStartsOn;
    },
    getWeekFirstDate: (locale, date) => {
      return startOfWeek(date, {
        locale: dealLocal(locale),
      });
    },
    getWeek: (locale, date) => {
      return getWeek(date, {
        locale: dealLocal(locale),
      });
    },
    getShortWeekDays: locale => {
      const clone = dealLocal(locale);
      return Array.from({
        length: 7,
      }).map((_, i) => {
        return clone.localize?.day(i, {
          width: 'short',
        });
      });
    },
    getShortMonths: locale => {
      const clone = dealLocal(locale);
      return Array.from({
        length: 12,
      }).map((_, i) => {
        return clone.localize?.month(i, {
          width: 'abbreviated',
        });
      });
    },
    format: (locale, date, _format) => {
      if (!isValid(date)) {
        return null;
      }

      return formatDate(date, localeParse(_format), {
        locale: dealLocal(locale),
      });
    },
    parse: (locale, text, formats) => {
      for (let i = 0; i < formats.length; i += 1) {
        const format = localeParse(formats[i]);
        const formatText = text;
        const date = parseDate(formatText, format, new Date(), {
          locale: dealLocal(locale),
        });

        if (isValid(date)) {
          return date;
        }
      }

      return null;
    },
  },
};
