import { coreRoutes } from './core-routes';
import { accessRoutes } from '../../access/routes';
import { authRoutes } from '../../auth/routes';
import { automationRoutes } from '../../automation/routes';
import { logsRoutes } from '../../logs/routes';
import { reportRoutes } from '../../reports/routes';
import { approvalRoutes } from '../../approval/routes';
import { Layout } from '../../shared/models/layout';
import { Route } from '../../shared/models/route';
import { NotFoundView } from '../views/not-found';

export const appRoutes: Route[] = [
  coreRoutes,
  reportRoutes,
  automationRoutes,
  accessRoutes,
  logsRoutes,
  authRoutes,
  approvalRoutes,
  {
    path: '*',
    view: NotFoundView,
    meta: { loggedIn: false, layout: Layout.FULL_PAGE },
  },
];
