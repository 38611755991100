import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { Preset as BePreset } from '@pccr-ifc/pccr-ifc';
import { useTranslation } from 'react-i18next';

import { PickSomeRestPartial } from '../../../shared/utils/types';
import { Preset, PresetUpsert } from '../../models/preset';
import { usePresetService } from '../services/use-preset-service';
import {
  onCreateResourceMutationSuccess,
  onDeleteResourceMutationSuccess,
  onUpdateResourceMutationSuccess,
} from '../../../shared/utils/mutation';
import { displayErrors } from '../../../shared/utils/error';

export function useCreatePresetMutation() {
  const presetService = usePresetService();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<BePreset, unknown, Omit<PresetUpsert, 'id'>>(preset => presetService.createPreset({ preset }), {
    onSuccess(response) {
      onCreateResourceMutationSuccess(queryClient, 'PRESET', response);
    },
    onError(error) {
      displayErrors(error, t);
    },
  });
}

export function useUpdatePresetMutation() {
  const presetService = usePresetService();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<BePreset, unknown, PickSomeRestPartial<PresetUpsert, 'id'>>(
    preset => presetService.updatePreset(preset),
    {
      onSuccess(response, vars) {
        onUpdateResourceMutationSuccess(queryClient, 'PRESET', response, vars.id);
      },
      onError(error) {
        displayErrors(error, t);
      },
    }
  );
}

export function useDeletePresetMutation() {
  const presetService = usePresetService();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse<void>, unknown, Preset['id']>(id => presetService.deletePreset(id), {
    onSuccess(_response, id) {
      onDeleteResourceMutationSuccess(queryClient, 'PRESET', id);
    },
    onError(error) {
      displayErrors(error, t);
    },
  });
}
