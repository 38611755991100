import { createAsyncThunk } from '@reduxjs/toolkit';

import { Feature } from '../../../../shared/models/features';
import { RequestWithService } from '../../../../shared/models/request';
import { SourceUpsert } from '../../../models/source';
import { SourceService } from '../../services/use-source-service';

export const checkSource = createAsyncThunk<void, RequestWithService<Omit<SourceUpsert, 'id'>, SourceService>>(
  `${Feature.REPORTS}/source/check`,
  async ({ request: source, service }, { rejectWithValue }) => {
    try {
      await service.checkSource({ source });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
