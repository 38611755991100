import { Feature } from '../../shared/models/features';
import { Language } from '../../shared/models/language';
import { LanguagesResources } from '../../shared/models/language-resources';
import deDE from '../assets/locales/de-DE/translation.json';
import enGB from '../assets/locales/en-GB/translation.json';

export const translationNamespace = Feature.AUTOMATION;

export const resources: LanguagesResources = {
  [Language.EnglishGB]: {
    [translationNamespace]: enGB,
  },
  [Language.German]: {
    [translationNamespace]: deDE,
  },
};
