import { Route } from '../../shared/models/route';
import { JobNewView } from '../views/list/job-new';
import { JobSingleView } from '../views/list/job-single';
import { JobsListView } from '../views/list/jobs-list';
import automationIcon from '../assets/images/menu-icon/icon_automation.svg';

export const automationRoutes: Route = {
  path: '/automation',
  nameTranslationKey: 'automation:navigation.root',
  displayInSideNavigation: true,
  icon: automationIcon,
  meta: {
    requiredPermission: ['JOB', 'READ'],
  },
  children: [
    {
      path: '',
      view: JobsListView,
      displayInSideNavigation: false,
    },
    {
      path: '/new',
      nameTranslationKey: 'shared:new',
      view: JobNewView,
      displayInSideNavigation: false,
    },
    {
      path: '/:id',
      view: JobSingleView,
      displayInSideNavigation: false,
      meta: {
        isResourcePath: true,
      },
    },
  ],
};
