import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';

import { logOut } from '../../shared/data/store/thunks/auth-thunks';
import { translationNamespace } from '../constants/translation-resources';
import { displayErrors } from '../../shared/utils/error';
import { useAuthService } from '../../shared/data/services/use-auth-service';

export function useLogOut() {
  const { t } = useTranslation(translationNamespace);
  const authService = useAuthService();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { push: routerPush } = useHistory();

  return async () => {
    try {
      const response = await dispatch(logOut({ service: authService }));
      unwrapResult(response);
      queryClient.clear();
      routerPush('/auth/login');
    } catch (error) {
      displayErrors(error, t);
    }
  };
}
