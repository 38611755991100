import { TagProps } from 'antd';
import { JobStatus } from '../models/job';

export const getJobStatusTagColor = (status: JobStatus): TagProps['color'] => {
  switch (status) {
    case 'CREATED':
      return 'yellow';
    case 'RUNNING':
      return 'blue';
    case 'FAILED':
      return 'red';
    case 'SUCCESS':
      return 'green';

    default:
      return 'default';
  }
};
