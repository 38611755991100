import { TableColumnType, Tag } from 'antd';
import { TFunction } from 'react-i18next';
import { LinkedName } from '../../../shared/components/linked-name';
import { Feature } from '../../../shared/models/features';
import { Language } from '../../../shared/models/language';
import { Policy } from '../../../shared/models/policy';
import { formatISODate } from '../../../shared/utils/date';
import { getMethodTagColor } from '../../utils/color-mappers';
import { Actions } from './actions';
import { AppResources } from '../../../shared/constants/app-resources';
import { PermissionsState } from '../../../shared/data/store';

export const getColumns = (
  t: TFunction<Feature.ACCESS>,
  language: Language,
  permissions: PermissionsState
): TableColumnType<Policy>[] => {
  return [
    {
      title: t(`${Feature.SHARED}:name`),
      dataIndex: 'name',
      render: (name: Policy['name'], policy: Policy) => <LinkedName name={name} to={`policies/${policy.id}`} />,
      sorter: true,
    },
    {
      title: t(`${Feature.SHARED}:method`),
      dataIndex: 'method',
      render: (method: Policy['method']) =>
        method != null ? (
          <Tag color={getMethodTagColor(method)}>{t(`${Feature.SHARED}:methods.${method.toLowerCase()}`)}</Tag>
        ) : (
          ''
        ),
      sorter: true,
      width: 120,
    },
    {
      title: t(`${Feature.SHARED}:path`),
      dataIndex: 'path',
      sorter: true,
      onCell: () => ({ style: { wordBreak: 'break-all' } }),
    },
    {
      title: t(`${Feature.SHARED}:request`),
      dataIndex: 'request',
      sorter: true,
      onCell: () => ({ style: { wordBreak: 'break-all' } }),
    },
    {
      title: t(`${Feature.SHARED}:createdAt`),
      dataIndex: 'createdAt',
      render: (createdAt: Policy['createdAt']) => formatISODate(createdAt, language),
      sorter: true,
      width: 130,
      defaultSortOrder: 'descend',
    },
    {
      title: t(`${Feature.SHARED}:updatedAt`),
      dataIndex: 'updatedAt',
      render: (updatedAt: Policy['updatedAt']) => formatISODate(updatedAt, language),
      sorter: true,
      width: 130,
    },
    {
      key: 'action',
      align: 'right',
      render: (_, policy) => <Actions policy={policy} />,
      width: 130,
      hidden: !permissions[AppResources.POLICY]['UPDATE'] && !permissions[AppResources.POLICY]['DELETE'],
    },
  ];
};
