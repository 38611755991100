import { Tag, TagProps } from 'antd';

import { colors } from '../../constants/styles';
import styled from '@emotion/styled';

interface CustomTagProps extends TagProps {
  color?: string;
  icon?: string;
}

interface StyledTagProps {
  hasIcon: boolean;
}

export const CustomTag = ({ color, icon, ...props }: CustomTagProps) => {
  return (
    <StylesTag
      hasIcon={icon != null}
      style={{
        backgroundColor: `${color}`,
        backgroundImage: icon != null && `url(${icon})`,
      }}
      {...props}
    />
  );
};

const StylesTag = styled(Tag)<StyledTagProps>`
  padding-right: ${props => (props.hasIcon ? '36px' : '24px')};
  padding-left: ${props => (props.hasIcon ? '8px' : '24px')};
  margin-inline-end: 0;
  min-width: ${props => props.hasIcon && '107px'};
  color: ${colors.white};
  background-position: ${props => props.hasIcon && 'center right 8px'};
  background-repeat: ${props => props.hasIcon && 'no-repeat'};
  background-size: ${props => props.hasIcon && '16px'};
`;
