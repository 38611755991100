import { createSlice, SerializedError } from '@reduxjs/toolkit';

import { Feature } from '../../../../shared/models/features';
import { checkDefinition } from '../thunks/definition-thunks';

interface DefinitionState {
  check: {
    loading: boolean;
    error: SerializedError;
  };
}

const initialState: DefinitionState = {
  check: {
    loading: false,
    error: null,
  },
};

const slice = createSlice({
  name: `${Feature.REPORTS}/definition`,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(checkDefinition.fulfilled, state => {
      state.check.loading = false;
    });
    builder.addCase(checkDefinition.pending, state => {
      state.check.loading = true;
    });
    builder.addCase(checkDefinition.rejected, (state, action) => {
      state.check.loading = false;
      state.check.error = action.error;
    });
  },
});

export const definitionSlice = slice.reducer;
