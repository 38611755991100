export const singleResourceQueryKeyRoots = {
  REPORT: 'report',
  PRESET: 'preset',
  DEFINITION: 'definition',
  VIEW: 'view',
  SOURCE: 'source',
  JOB: 'job',
  TENANT: 'tenant',
  USER: 'user',
  POLICY: 'policy',
  ROLE: 'role',
  LOG: 'log',
} as const;

export const resourceListQueryKeyRoots = {
  REPORT: 'reports',
  PRESET: 'presets',
  DEFINITION: 'definitions',
  VIEW: 'views',
  SOURCE: 'sources',
  JOB: 'jobs',
  TENANT: 'tenants',
  USER: 'users',
  POLICY: 'policies',
  ROLE: 'roles',
  LOG: 'logs',
  DOCUMENT: 'documents',
  BATCH: 'batches',
} as const;
