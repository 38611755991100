import { Button, Col, Form, FormInstance, Row, Select } from 'antd';
import { Rule } from 'rc-field-form/lib/interface';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';

import { Feature } from '../../../shared/models/features';
import { translationNamespace } from '../../constants/translation-resources';
import { cronValidator } from '../../utils/cron';
import { JobStatuses } from '../../constants/job';
import { JobStatus } from '../../models/job';
import { RangePicker } from '../../../shared/components/date-picker/date-picker';

enum JobsTableFilter {
  NAME = 'name',
  CRON = 'cron',
  ENABLED = 'enabled',
  STATUS = 'status',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
}

export interface JobsTableFilters {
  [JobsTableFilter.NAME]: string[];
  [JobsTableFilter.CRON]: string[];
  [JobsTableFilter.ENABLED]: boolean;
  [JobsTableFilter.STATUS]: JobStatus[];
  [JobsTableFilter.CREATED_AT]: [Date?, Date?];
  [JobsTableFilter.UPDATED_AT]: [Date?, Date?];
}

interface JobsTableFiltersFormProps {
  onApply?: (filters: JobsTableFilters) => void;
  form: FormInstance<JobsTableFilters>;
}

export const JobsTableFiltersForm: FC<JobsTableFiltersFormProps> = ({ onApply, form }) => {
  const { t } = useTranslation(translationNamespace);

  const onClear = () => {
    form.resetFields();
    onApply?.(form.getFieldsValue());
  };

  const cronValdiator: Rule = useMemo(() => {
    return { validator: (...args) => cronValidator(t, ...args) };
  }, [t]);

  return (
    <Form form={form} layout="vertical" onFinish={onApply}>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item label={t('jobs.list.jobsTable.name')} name={JobsTableFilter.NAME}>
            <Select suffixIcon={null} size="large" mode="tags" allowClear open={false} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t('jobs.shared.cron.label')} name={JobsTableFilter.CRON} rules={[cronValdiator]}>
            <Select suffixIcon={null} size="large" mode="tags" allowClear open={false} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label={t('shared.enabled')} name={JobsTableFilter.ENABLED}>
            <Select allowClear size="large">
              <Select.Option value={true}>{t(`${Feature.SHARED}:yes`)}</Select.Option>
              <Select.Option value={false}>{t(`${Feature.SHARED}:no`)}</Select.Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label={t(`${Feature.SHARED}:status`)} name={JobsTableFilter.STATUS}>
            <Select size="large" mode="multiple" allowClear>
              {Object.values(JobStatuses).map((jobStatus, i) => (
                <Select.Option key={i} value={jobStatus}>
                  {t(`jobs.shared.status.${jobStatus.toLowerCase()}`)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label={t(`${Feature.SHARED}:createdAt`)} name={JobsTableFilter.CREATED_AT}>
            <RangePicker css={fullWidthStyles} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label={t(`${Feature.SHARED}:updatedAt`)} name={JobsTableFilter.UPDATED_AT}>
            <RangePicker css={fullWidthStyles} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <Button type="primary" htmlType="submit" size="large" icon={<CheckOutlined />}>
            {t(`${Feature.SHARED}:apply`)}
          </Button>
        </Col>
        <Col>
          <Button htmlType="button" size="large" onClick={onClear}>
            {t(`${Feature.SHARED}:clear`)}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const fullWidthStyles = css`
  width: 100%;
  border-radius: 0;
`;
