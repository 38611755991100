import { FindAllRolesResponse, RoleWithIncludes } from '@pccr-ifc/pccr-ifc';

import { Role } from '../../../shared/models/role';
import { convertDateRangeToISO } from '../../../shared/utils/date';
import { RoleUpsertFormValues } from '../../components/role-upsert-form';
import { RolesTableFilters } from '../../components/roles-table';
import { RolesFilters, RoleUpsert } from '../../models/role';
import { ResourceSearchResult } from '../../../shared/models/api/response';
import { convertTenant } from './tenant-converters';
import { Policy } from '../../../shared/models/policy';

export function convertRole(beRole: RoleWithIncludes): Role {
  return {
    id: beRole.id,
    name: beRole.name,
    isDefault: beRole.isDefault,
    policies: beRole.policies as Policy[],
    scopeGroups: beRole.scopeGroups?.map(convertTenant),
    createdAt: beRole.createdAt as string,
    updatedAt: beRole.updatedAt as string,
    createdBy: beRole.createdBy,
    updatedBy: beRole.updatedBy,
  };
}

export function convertSearchResponse(response: FindAllRolesResponse): ResourceSearchResult<Role> {
  return {
    total: response.total,
    resources: response.roles.map(convertRole),
  };
}

export function convertFormValuesToRole(values: Partial<RoleUpsertFormValues>): Omit<RoleUpsert, 'id'> {
  return {
    name: values.name,
    policies: values.policies,
    scopeGroups: values.tenants,
  };
}

export function convertTableFiltersToFilters(tableFilters: RolesTableFilters): RolesFilters {
  const rolesFilters: RolesFilters = { name: tableFilters.name };

  if (tableFilters.createdAt != null) {
    rolesFilters.createdAt = convertDateRangeToISO(tableFilters.createdAt);
  }
  if (tableFilters.updatedAt != null) {
    rolesFilters.updatedAt = convertDateRangeToISO(tableFilters.updatedAt);
  }

  return rolesFilters;
}
