import axios from 'axios';
import { AllowedRoutesResponse, DefaultRolesResponse } from '@pccr-ifc/pccr-ifc';

import { Config } from '../../../shared/models/config';
import { useService } from '../../../shared/hooks/use-service';

function createCoreService(config: Config) {
  const BASE_PATH = '/api';

  const getUserAllowedRoutes = () =>
    axios.get<AllowedRoutesResponse>(`${config.API_URL}${BASE_PATH}/allowedroutes`, {
      withCredentials: true,
    });

  const getDefaultRoles = () =>
    axios.get<DefaultRolesResponse>(`${config.API_URL}/role/default`, {
      withCredentials: true,
    });

  return { getUserAllowedRoutes, getDefaultRoles };
}

export const useCoreService = () => useService(createCoreService);
