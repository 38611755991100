import { Input, InputNumber, Radio } from 'antd';
import { TFunction } from 'react-i18next';
import { css } from '@emotion/react';

import { DatePicker } from '../../shared/components/date-picker';
import { Feature } from '../../shared/models/features';
import { QueryParamType } from '../models/query-param';
import { QueryParamTypes } from '../constants/query-param';

export function mapQueryParamTypeToComponent(
  queryParamType: QueryParamType,
  t: TFunction<Feature.SHARED> | TFunction<Feature[]> | TFunction<(Feature.REPORTS | Feature.SHARED)[]>
) {
  const placeholder = t(`${Feature.SHARED}:value`);

  switch (queryParamType) {
    case QueryParamTypes.NUMBER:
      return <InputNumber placeholder={placeholder} css={maxWidthStyles} />;
    case QueryParamTypes.BOOLEAN:
      return (
        <Radio.Group>
          <Radio value={true}>{t(`${Feature.SHARED}:true`)}</Radio>
          <Radio value={false}>{t(`${Feature.SHARED}:false`)}</Radio>
        </Radio.Group>
      );
    case QueryParamTypes.JSON:
      return <Input.TextArea autoSize placeholder={placeholder} css={verticalAlignMiddleStyles} />;
    case QueryParamTypes.DATE:
      return <DatePicker placeholder={placeholder} css={maxWidthStyles} />;
    case QueryParamTypes.DATETIME:
      return <DatePicker showTime placeholder={placeholder} css={maxWidthStyles} />;
    case QueryParamTypes.STRING:
    default:
      return <Input placeholder={placeholder} />;
  }
}

export function mapValueTypeToQueryParamType(value: unknown): QueryParamType {
  const valueType = typeof value;

  switch (valueType) {
    case 'number':
    case 'bigint':
      return QueryParamTypes.NUMBER;
    case 'boolean':
      return QueryParamTypes.BOOLEAN;
    default:
      return QueryParamTypes.STRING;
  }
}

const maxWidthStyles = css`
  width: 100%;
`;

const verticalAlignMiddleStyles = css`
  vertical-align: middle;
`;
