import { FC, useMemo } from 'react';

import { ServicesContainerContext } from './services-container-context';
import { Config } from '../../models/config';

type Props = {
  config: Config;
};

export const ServicesContainerProvider: FC<Props> = ({ config, children }) => {
  const getService = useMemo(() => {
    const container = new Map<Function | symbol, unknown>();
    return <T,>(token: symbol, createService: (config: Config) => T) => {
      let service = container.get(token) as T;
      if (service === undefined) {
        service = createService(config);
        container.set(token, service);
      }
      return service;
    };
  }, [config]);
  return <ServicesContainerContext.Provider value={getService}>{children}</ServicesContainerContext.Provider>;
};
