import { Dialect } from '../models/dialect';

export function findParamsInSqlQuery(sqlQuery: string): string[] {
  const foundSqlQueryParams = sqlQuery.match(/%\(\w+\)s/g);
  const sqlQueryParams =
    foundSqlQueryParams != null
      ? foundSqlQueryParams.filter(value => value != null).map(value => value.slice(2, -2))
      : [];

  return sqlQueryParams;
}

export function createSqlStatement(tableName: string, columns: string[], dialect: Dialect) {
  switch (dialect) {
    case 'mysql':
      return `SELECT ${columns?.length > 0 ? `${columns.join(', ')} ` : ''}FROM ${tableName}`;
    case 'mssql':
      return `SELECT ${columns?.length > 0 ? `[${columns.join('], [')}] ` : ''}FROM [${tableName}]`;
    case 'postgres':
      return `SELECT ${columns?.length > 0 ? `"${columns.join('", "')}" ` : ''}FROM "${tableName}"`;

    default:
      throw new Error(`${dialect} is not supported dialect`);
  }
}
