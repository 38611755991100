import { Button, Card, Col, Form, message, Row, Space } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { CheckOutlined } from '@ant-design/icons';

import { Feature } from '../../../shared/models/features';
import { PresetUpsertForm, PresetUpsertFormValues } from '../../components/preset-upsert-form';
import { translationNamespace } from '../../constants/translation-resources';
import { useCreatePresetMutation } from '../../data/mutations/preset-mutations';
import { convertFormValuesToPreset } from '../../data/converters/preset-converters';
import { AppResourcePaths } from '../../../shared/constants/app-resources';

export const PresetNewView: FC = () => {
  const { t } = useTranslation(translationNamespace);
  const { push: routerPush } = useHistory();
  const [form] = Form.useForm<PresetUpsertFormValues>();
  const mutation = useCreatePresetMutation();

  const submit = (values: PresetUpsertFormValues) => {
    const newPreset = convertFormValuesToPreset(values);

    mutation.mutate(newPreset, {
      onSuccess: () => {
        message.success(t('presets.shared.createSuccessMessage'));
        routerPush(`${AppResourcePaths.presets}`);
      },
    });
  };

  const triggerSubmit = () => {
    form.submit();
  };

  return (
    <Row gutter={[16, 24]}>
      <Col span={24}>
        <Card title={t(`${Feature.SHARED}:details`)}>
          <PresetUpsertForm form={form} onSubmit={submit} />
        </Card>
      </Col>
      <Col>
        <Space>
          <Button type="primary" htmlType="submit" size="large" icon={<CheckOutlined />} onClick={triggerSubmit}>
            {t(`${Feature.SHARED}:create`)}
          </Button>
          <Link to="../presets">
            <Button htmlType="button" size="large">
              {t(`${Feature.SHARED}:cancel`)}
            </Button>
          </Link>
        </Space>
      </Col>
    </Row>
  );
};
