import { createSlice, SerializedError } from '@reduxjs/toolkit';
import { Feature } from '../../../../shared/models/features';
import { checkSource } from '../thunks/source-thunks';

interface SourceState {
  check: {
    loading: boolean;
    error: SerializedError;
  };
}

const initialState: SourceState = {
  check: {
    loading: false,
    error: null,
  },
};

const slice = createSlice({
  name: `${Feature.REPORTS}/source`,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(checkSource.fulfilled, state => {
      state.check.loading = false;
    });
    builder.addCase(checkSource.pending, state => {
      state.check.loading = true;
    });
    builder.addCase(checkSource.rejected, (state, action) => {
      state.check.loading = false;
      state.check.error = action.error;
    });
  },
});

export const sourceSlice = slice.reducer;
