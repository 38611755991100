import { Button, Dropdown, message } from 'antd';
import { MenuProps } from 'antd';
import { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import { useToggle } from 'react-use';

import { ConfirmationModal } from '../../../shared/components/confirmation-modal';
import { Feature } from '../../../shared/models/features';
import { translationNamespace } from '../../constants/translation-resources';
import { useDeleteReportMutation } from '../../data/mutations/report-mutations';
import { Report } from '../../models/report';
import { colors } from '../../../shared/constants/styles';
import { permissionSelector } from '../../../shared/data/store/selectors/auth-selectors';
import { AppResources } from '../../../shared/constants/app-resources';

interface ActionsProps {
  report: Report;
  disabled: boolean;
}

export const Actions: FC<ActionsProps> = ({ report, disabled }) => {
  const { t } = useTranslation(translationNamespace);
  const deleteMutation = useDeleteReportMutation();
  const permissions = useSelector(permissionSelector);
  const [showConfirmDeleteModal, toggleShowConfirmDeleteModal] = useToggle(false);

  const remove = useCallback(() => {
    if (report.uuid == null) {
      return;
    }

    deleteMutation.mutate(report.uuid, {
      onSuccess: () => {
        message.success(t('reports.shared.deleteSuccessMessage'));
        toggleShowConfirmDeleteModal();
      },
    });
  }, [deleteMutation, t, report.uuid, toggleShowConfirmDeleteModal]);

  const items = useMemo<MenuProps['items']>(() => {
    const menuItems: MenuProps['items'] = [
      {
        key: 'details',
        label: <Link to={`list/${report.uuid}`}>{t(`${Feature.SHARED}:details`)}</Link>,
      },
    ];
    if (permissions[AppResources.REPORT]['DELETE']) {
      menuItems.push({
        key: 'remove',
        onClick: () => toggleShowConfirmDeleteModal(),
        label: t(`${Feature.SHARED}:remove`),
      });
    }

    return menuItems;
  }, [permissions, report.uuid, t, toggleShowConfirmDeleteModal]);

  return (
    <>
      <Dropdown menu={{ items }} disabled={disabled} trigger={['click']}>
        <Button type="link" style={{ color: colors.skyBlue }} icon={<MenuOutlined />}>
          {t(`${Feature.SHARED}:actions`)}
        </Button>
      </Dropdown>
      <ConfirmationModal
        confirmLoading={deleteMutation.isLoading}
        open={showConfirmDeleteModal}
        title={t(`${Feature.SHARED}:areYouSure`)}
        onCancel={toggleShowConfirmDeleteModal}
        onOk={remove}
        content={t(`${Feature.SHARED}:confirmDeleteMsg`, {
          resourceName: report.name,
          buttonTitle: t(`${Feature.SHARED}:confirm`),
        })}
      />
    </>
  );
};
