import { TableProps } from 'antd';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getLanguageFromI18nLanguage } from '../../../shared/utils/language';
import { translationNamespace } from '../../constants/translation-resources';
import { Report } from '../../models/report';
import { getColumns } from './columns';
import { ReportStatuses } from '../../constants/report';
import { CustomTable } from '../../../shared/components/table';

export const ReportsTable: FC<Omit<TableProps<Report>, 'columns' | 'rowKey'>> = props => {
  const { t, i18n } = useTranslation(translationNamespace);
  const columns = useMemo(() => getColumns(t, getLanguageFromI18nLanguage(i18n.language)), [i18n.language, t]);

  return (
    <CustomTable<Report>
      onRow={data => ({
        style: data.status !== ReportStatuses.FINISHED && { opacity: 0.5 },
      })}
      columns={columns}
      rowKey={report => report.uuid}
      {...props}
    />
  );
};
