import { FindAllSchedulesResponse, SchedulerAttributes } from '@pccr-ifc/pccr-ifc';

import {
  convertFormValuesToGenerateReport,
  convertGenerateReportToFormValues,
} from '../../../shared/data/converters/report-converters';
import { convertDateRangeToISO } from '../../../shared/utils/date';
import { isNonEmptyObject } from '../../../shared/utils/object';
import { isGenerateReportFormValues } from '../../../shared/utils/types';
import { JobUpsertFormRequestValues, JobUpsertFormValues } from '../../components/job-upsert-form';
import { JobsTableFilters } from '../../components/jobs-table';
import { generateReportTemplate } from '../../constants/job-task-templates';
import { JobTasks } from '../../constants/job-tasks';
import { Job, JobRequest, JobsFilters } from '../../models/job';
import { JobTask } from '../../models/job-task';
import { isJobStatus } from '../../utils/types';
import { ResourceSearchResult } from '../../../shared/models/api/response';

export function convertJob(schedule: SchedulerAttributes): Job {
  return {
    id: schedule.id,
    name: schedule.name,
    cron: schedule.cron,
    status: isJobStatus(schedule.status) ? schedule.status : null,
    executionStart:
      schedule.executionStart && !isNaN(Number(schedule.executionStart)) && Number(schedule.executionStart),
    executionDuration:
      schedule.executionDuration && !isNaN(Number(schedule.executionDuration)) && Number(schedule.executionDuration),
    errors: schedule.errors,
    request: schedule.request,
    enabled: schedule.enabled,
    overwritable: schedule.overwritable,
    createdAt: typeof schedule.createdAt === 'string' ? schedule.createdAt : null,
    updatedAt: typeof schedule.updatedAt === 'string' ? schedule.updatedAt : null,
    createdBy: schedule.createdBy,
    updatedBy: schedule.updatedBy,
  };
}

export function convertSearchResponse(response: FindAllSchedulesResponse): ResourceSearchResult<Job> {
  return {
    total: response.total,
    resources: response.schedules?.map(schedule => convertJob(schedule)),
  };
}

export function convertFormValuesToJobRequestBody(values: Record<PropertyKey, unknown>, task: JobTask) {
  switch (task) {
    case JobTasks.GENERATE_REPORT:
      if (isGenerateReportFormValues(values)) {
        return { report: convertFormValuesToGenerateReport(values) };
      }
      return null;
    default:
      return null;
  }
}

export function convertFormValuesToJobRequest(values: JobUpsertFormRequestValues, task: JobTask): JobRequest {
  if (!isNonEmptyObject(values)) {
    return null;
  }

  const request: JobRequest = {
    service: values.service,
    path: values.path,
    method: values.method,
    headers: values.headers,
  };

  if (isNonEmptyObject(values.body)) {
    request.body = convertFormValuesToJobRequestBody(values.body, task);
  }

  return request;
}

export function convertFormValuesToJob(values: Partial<JobUpsertFormValues>): Omit<Job, 'id'> {
  const job: Omit<Job, 'id'> = {
    name: values.name,
    enabled: values.enabled,
    cron: values.cron,
    overwritable: values.overwritable,
  };

  if (isNonEmptyObject(values.request)) {
    job.request = convertFormValuesToJobRequest(values.request, values.task);
  }

  return job;
}

export function convertJobRequestToTask(jobRequest: JobRequest): JobTask {
  if (jobRequest == null) {
    return null;
  }

  if (jobRequest.path === generateReportTemplate.path && jobRequest.method === generateReportTemplate.method) {
    return JobTasks.GENERATE_REPORT;
  }

  return null;
}

export function convertJobRequestToFormValues(jobRequest: JobRequest, task: JobTask): JobUpsertFormRequestValues {
  if (task == null || jobRequest == null) {
    return null;
  }

  const formValues: JobUpsertFormRequestValues = {
    service: jobRequest.service,
    path: jobRequest.path,
    method: jobRequest.method,
    headers: jobRequest.headers,
    body: {},
  };

  switch (task) {
    case JobTasks.GENERATE_REPORT:
      formValues.body = convertGenerateReportToFormValues(jobRequest.body?.report);
      break;
    default:
      break;
  }

  return formValues;
}

export function convertTableFiltersToFilters(tableFilters: JobsTableFilters): JobsFilters {
  const sourcesFilters: JobsFilters = {
    name: tableFilters.name,
    cron: tableFilters.cron,
    status: tableFilters.status,
    enabled: tableFilters.enabled,
  };

  if (tableFilters.createdAt != null) {
    sourcesFilters.createdAt = convertDateRangeToISO(tableFilters.createdAt);
  }
  if (tableFilters.updatedAt != null) {
    sourcesFilters.updatedAt = convertDateRangeToISO(tableFilters.updatedAt);
  }

  return sourcesFilters;
}
