export const DefinitionSourceTypes = {
  VIEWS: 'views',
  TABLES: 'tables',
} as const;

export const DefinitionStatuses = {
  VALID: 'VALID',
  INVALID: 'INVALID',
} as const;

export const DefinitionTypes = {
  SQL: 'SQL',
} as const;
