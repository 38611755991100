import { Button, Col, Form, FormInstance, Row, Select } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { endOfDay, startOfDay } from 'date-fns';

import { Feature } from '../../../shared/models/features';
import { translationNamespace } from '../../constants/translation-resources';
import { RangePicker } from '../../../shared/components/date-picker/date-picker';
import { DataColumn } from '../../models/data-column';

export type ReportDataTableFilters = Record<string, unknown>;

interface ReportDataTableFiltersFormProps {
  onApply?: (filters: ReportDataTableFilters) => void;
  attributes?: DataColumn[];
  form?: FormInstance<ReportDataTableFilters>;
}

const defaultStartDate = startOfDay(new Date());
const defaultEndDate = endOfDay(new Date());

export const ReportDataTableFiltersForm: FC<ReportDataTableFiltersFormProps> = ({ onApply, attributes = [], form }) => {
  const { t } = useTranslation(translationNamespace);

  const onClear = () => {
    form.resetFields();
    onApply?.(form.getFieldsValue());
  };

  function returnFilterFormItemBasedOnAttribute(attribute: DataColumn) {
    switch (attribute.type) {
      case 'date':
        return (
          <Form.Item label={attribute.name} name={attribute.name}>
            <RangePicker
              showTime={{
                format: 'HH:mm',
                defaultValue: [defaultStartDate, defaultEndDate],
              }}
              css={fullWidthStyles}
            />
          </Form.Item>
        );
      case 'boolean':
        return (
          <Form.Item label={attribute.name} name={attribute.name}>
            <Select allowClear size="large">
              <Select.Option value={true}>{t(`${Feature.SHARED}:true`)}</Select.Option>
              <Select.Option value={false}>{t(`${Feature.SHARED}:false`)}</Select.Option>
            </Select>
          </Form.Item>
        );
      case 'number':
        return (
          <Form.Item
            label={attribute.name}
            name={attribute.name}
            rules={[
              () => ({
                validator(_, value) {
                  if (!value || value.every(tag => /^[\d.,]+$/.test(tag))) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t(`${Feature.SHARED}:provideCorrectNumber`)));
                },
              }),
            ]}
          >
            <Select size="large" mode="tags" allowClear open={false} suffixIcon={null} />
          </Form.Item>
        );

      default:
        return (
          <Form.Item label={attribute.name} name={attribute.name}>
            <Select size="large" mode="tags" allowClear open={false} suffixIcon={null} />
          </Form.Item>
        );
    }
  }

  return (
    <Form form={form} layout="vertical" onFinish={onApply}>
      <Row gutter={16}>
        {attributes.map(attr => (
          <Col span={attr.type === 'date' ? 9 : 6}>{returnFilterFormItemBasedOnAttribute(attr)}</Col>
        ))}
      </Row>
      <Row gutter={16}>
        <Col>
          <Button type="primary" htmlType="submit" size="large" icon={<CheckOutlined />}>
            {t(`${Feature.SHARED}:apply`)}
          </Button>
        </Col>
        <Col>
          <Button htmlType="button" size="large" onClick={onClear}>
            {t(`${Feature.SHARED}:clear`)}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const fullWidthStyles = css`
  width: 100%;
  border-radius: 0;
`;
