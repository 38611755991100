import { ReactNode } from 'react';
import { TFunction } from 'react-i18next';
import { Route } from '../../../shared/models/route';

export type Item = {
  path: string;
  icon?: ReactNode;
  title: string;
  isLinked: boolean;
};

export const getBreadcrumbItems = (resourceName: string, routes: Route[], pathname: string, t: TFunction) => {
  const items: Item[] = [];

  let path = '';
  let children = routes;
  let remainingPath = pathname;
  while (remainingPath) {
    const route =
      // eslint-disable-next-line no-loop-func
      children.find(r => r.path && !r.meta?.isResourcePath && matchPathSegments(remainingPath, r.path)) ??
      children.find(r => r.meta?.isResourcePath);
    if (!route) break;

    const segmentCount = route.path.split('/').length - 1;
    [path, remainingPath] = advancePath(path, remainingPath, segmentCount);

    const definingChild = route.children?.find(child => child.path === '');
    const nameTranslationKey = route.nameTranslationKey ?? definingChild?.nameTranslationKey ?? null;
    const title = route.meta?.isResourcePath ? resourceName : t(nameTranslationKey);
    const icon = route.icon ?? definingChild?.icon ?? null;
    const isLinked = route.view != null || definingChild?.view != null;

    items.push({ path, icon, title, isLinked });

    children = [...(route.children ?? []), ...(definingChild?.children ?? [])];
  }
  return items;
};

const matchPathSegments = (pathname: string, routePath: string): boolean => {
  const res =
    pathname.startsWith(routePath) && (routePath.length === pathname.length || pathname[routePath.length] === '/');
  return res;
};

const advancePath = (matched: string, remaining: string, segmentCount: number): [string, string] => {
  let segmentsMatched = 0;
  let position = 0;
  while (position < remaining.length && segmentsMatched < segmentCount) {
    if (remaining[++position] === '/') segmentsMatched++;
  }

  return [matched + remaining.slice(0, position), remaining.slice(position)];
};
