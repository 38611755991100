import { Checkbox, Descriptions, Divider, Drawer, DrawerProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { useToggle } from 'react-use';

import { Feature } from '../../../shared/models/features';
import { formatISODate } from '../../../shared/utils/date';
import { getLanguageFromI18nLanguage } from '../../../shared/utils/language';
import { translationNamespace } from '../../constants/translation-resources';
import { useLogsQueryForTimeline } from '../../../logs/data/queries/log-queries';
import { LogsTimeline } from '../../../shared/components/logs-timeline';
import { UserName } from '../../../shared/components/user-name';
import { Tenant } from '../../../shared/models/tenant';
import { useGetTenantQuery } from '../../data/queries/tenant-queries';
import { LogsFilters } from '../../../logs/models/log';

interface TenantDetailsDrawerProps extends DrawerProps {
  tenant: Tenant;
  parentGroupId?: number;
}

export const TenantDetailsDrawer = ({ tenant, parentGroupId, ...props }: TenantDetailsDrawerProps) => {
  const { t, i18n } = useTranslation(translationNamespace);
  const { data: parentTenantData } = useGetTenantQuery(parentGroupId, props.open);
  const [includeGetLogs, toggleIncludeGetLogs] = useToggle(false);
  const filters: LogsFilters = {
    entityType: 'tenant',
    resourceId: [tenant.id],
    method: includeGetLogs ? undefined : ['PATCH', 'POST', 'PUT', 'DELETE'],
  };
  const logQueryResult = useLogsQueryForTimeline(filters, { enabled: props.open });
  return (
    <Drawer {...props} title={t('users.single.details')} width={400} placement="right" destroyOnClose>
      <Descriptions column={1} bordered css={withMarginBottom}>
        <Descriptions.Item label={t(`${Feature.SHARED}:name`)}>{tenant.name}</Descriptions.Item>
        <Descriptions.Item label={t('tenants.shared.parentName')}>{parentTenantData?.name}</Descriptions.Item>
        <Descriptions.Item label={t('tenants.shared.customerId')}>{tenant.customerId}</Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:createdAt`)}>
          {tenant.createdAt ? formatISODate(tenant.createdAt, getLanguageFromI18nLanguage(i18n.language)) : ''}
        </Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:createdBy`)}>
          <UserName userId={logQueryResult.isLoading ? undefined : tenant.createdBy} />
        </Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:updatedAt`)}>
          {tenant.updatedAt ? formatISODate(tenant.updatedAt, getLanguageFromI18nLanguage(i18n.language)) : ''}
        </Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:updatedBy`)}>
          <UserName userId={logQueryResult.isLoading ? undefined : tenant.updatedBy} />
        </Descriptions.Item>
      </Descriptions>

      <Divider>{t(`${Feature.SHARED}:log`)}</Divider>
      <Checkbox checked={includeGetLogs} onChange={toggleIncludeGetLogs} css={withMarginBottom}>
        {t(`${Feature.SHARED}:includeGetLogs`)}
      </Checkbox>
      <LogsTimeline queryResult={logQueryResult} />
    </Drawer>
  );
};

const withMarginBottom = css`
  margin-bottom: 32px;
`;
