import { QueryClient } from 'react-query';

import { getLogsQueryKeyForTimeline } from '../../logs/data/queries/log-queries';
import { AppResource } from '../models/app-resource';
import { getResourceListQueryKeyRoot, getSingleResourceQueryKey, getSingleUserQueryKey } from './resource-query-key';

export function onCreateResourceMutationSuccess<B extends { id: number }>(
  queryClient: QueryClient,
  resourceType: AppResource,
  responseData: B
) {
  if (resourceType === 'USER') {
    queryClient.setQueryData(getSingleUserQueryKey(responseData.id, 'full'), responseData);
  } else {
    queryClient.setQueryData(getSingleResourceQueryKey(resourceType, responseData.id), responseData);
  }

  queryClient.invalidateQueries(getResourceListQueryKeyRoot(resourceType));
}

export function onUpdateResourceMutationSuccess<B extends { id: number }>(
  queryClient: QueryClient,
  resourceType: AppResource,
  responseData: B,
  resourceId: number | string
) {
  if (resourceType === 'USER') {
    queryClient.setQueryData(getSingleUserQueryKey(resourceId, 'full'), responseData);
    queryClient.invalidateQueries(getSingleUserQueryKey(resourceId, 'name'));
  } else {
    queryClient.setQueryData(getSingleResourceQueryKey(resourceType, resourceId), responseData);
  }

  queryClient.invalidateQueries(getResourceListQueryKeyRoot(resourceType));
  queryClient.invalidateQueries(getLogsQueryKeyForTimeline(resourceType, resourceId));
}

export function onDeleteResourceMutationSuccess(
  queryClient: QueryClient,
  resourceType: AppResource,
  resourceId: number | string
) {
  if (resourceType === 'USER') {
    queryClient.removeQueries(getSingleUserQueryKey(resourceId, 'full'));
    queryClient.removeQueries(getSingleUserQueryKey(resourceId, 'name'));
  } else {
    queryClient.removeQueries(getSingleResourceQueryKey(resourceType, resourceId));
  }

  queryClient.invalidateQueries(getResourceListQueryKeyRoot(resourceType));
}
