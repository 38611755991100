import { Form, FormInstance, Input, Select } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

import { Feature } from '../../../shared/models/features';
import { Method } from '../../../shared/models/method';
import { translationNamespace } from '../../constants/translation-resources';
import { redundantSpacebarsRule } from '../../../shared/utils/form-rules';
import { PolicyPathTooltip } from './policy-path-tooltip';
import { Methods } from '../../../shared/constants/method';

export enum PolicyUpsertFormField {
  NAME = 'name',
  METHOD = 'method',
  PATH = 'path',
  REQUEST = 'request',
}

export interface PolicyUpsertFormValues {
  [PolicyUpsertFormField.NAME]: string;
  [PolicyUpsertFormField.METHOD]?: Method;
  [PolicyUpsertFormField.PATH]?: string;
  [PolicyUpsertFormField.REQUEST]?: string;
}

interface Props {
  form: FormInstance<PolicyUpsertFormValues>;
  onSubmit?: (values: PolicyUpsertFormValues) => void;
}

export const PolicyUpsertForm: FC<Props> = ({ form, onSubmit }) => {
  const { t } = useTranslation(translationNamespace);

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      layout="horizontal"
      labelCol={{ lg: 4, xl: 4 }}
      wrapperCol={{ lg: 16, xl: 10 }}
      requiredMark={false}
    >
      <Form.Item
        label={t(`${Feature.SHARED}:name`)}
        name={PolicyUpsertFormField.NAME}
        rules={[{ required: true, max: 180 }, redundantSpacebarsRule(t)]}
      >
        <Input placeholder={t('policies.upsert.placeholder')} />
      </Form.Item>

      <Form.Item
        label={t(`${Feature.SHARED}:path`)}
        name={PolicyUpsertFormField.PATH}
        tooltip={<PolicyPathTooltip />}
        rules={[{ type: 'regexp' }, { required: true, max: 180 }]}
      >
        <Input placeholder={t('policies.upsert.placeholderPath')} />
      </Form.Item>

      <Form.Item label={t(`${Feature.SHARED}:method`)} name={PolicyUpsertFormField.METHOD} rules={[{ required: true }]}>
        <Select allowClear placeholder={t('policies.upsert.selectMethod')}>
          {Object.values(Methods).map((method, i) => (
            <Select.Option key={i} value={method}>
              {t(`${Feature.SHARED}:methods.${method.toLocaleLowerCase()}`)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label={t(`${Feature.SHARED}:request`)}
        name={PolicyUpsertFormField.REQUEST}
        css={marginBottomStyles}
        rules={[{ max: 180 }]}
      >
        <Input placeholder={t('policies.upsert.placeholderRequest')} />
      </Form.Item>
    </Form>
  );
};

const marginBottomStyles = css`
  margin-bottom: 52px;
`;
