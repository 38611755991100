import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';

import { ConditionalWrapper } from '../../../shared/components/conditional-wrapper';
import { loggedInSelector } from '../../../shared/data/store/selectors/auth-selectors';
import { useBreakpoints } from '../../../shared/hooks/use-breakpoints';
import { Feature } from '../../../shared/models/features';
import { Logo } from '../logo';

interface HeaderLogoProps {
  simple?: boolean;
  inverted?: boolean;
}

const wrapWithLink = (children: React.ReactNode) => {
  return <Link to="/">{children}</Link>;
};

export const HeaderLogo: FC<HeaderLogoProps> = ({ simple = false, inverted = false }) => {
  const { md } = useBreakpoints();
  const loggedIn = useSelector(loggedInSelector);

  return (
    <LogoContainer simple={simple} hideName={!simple && md}>
      <ConditionalWrapper condition={loggedIn} wrapper={wrapWithLink}>
        <Logo inverted={inverted} />
      </ConditionalWrapper>
    </LogoContainer>
  );
};

const richLogoContainerStyles = (theme: Theme) => css`
  margin-right: 8px;
  min-width: ${theme[Feature.CORE].layouts.sideNavigation.sider.width - 24 - 8}px;
  justify-content: flex-start;
`;

const hideNameLogoContainerStyles = css`
  height: 100%;
  margin-right: 8px;
  min-width: auto;
`;

const LogoContainer = styled.div<{ simple?: boolean; hideName?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => !props.simple && richLogoContainerStyles(props.theme)}
  ${props => props.hideName && hideNameLogoContainerStyles}
`;
