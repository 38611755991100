import { TableColumnType } from 'antd';
import { TFunction } from 'react-i18next';

import { LinkedName } from '../../../shared/components/linked-name';
import { Feature } from '../../../shared/models/features';
import { Language } from '../../../shared/models/language';
import { formatISODate, formatTimeRemainingToNow } from '../../../shared/utils/date';
import { formatSeconds } from '../../../shared/utils/number';
import { Report } from '../../models/report';
import { Actions } from './actions';
import { i18nDateFnsLocaleMap } from '../../../shared/constants/date-fns';
import { ReportStatuses } from '../../constants/report';
import { CustomTag } from '../../../shared/components/tag';
import { getReportStatusCustomTagColor } from '../../utils/color-mappers';

import iconStarted from '../../assets/images/statuses/icon_started.svg';
import iconFinished from '../../assets/images/statuses/icon_finished.svg';
import iconFailed from '../../assets/images/statuses/icon_failed.svg';
import iconCancelled from '../../assets/images/statuses/icon_cancelled.svg';
import iconQueued from '../../assets/images/statuses/icon_queued.svg';
import iconError from '../../assets/images/statuses/icon_error.svg';
import iconDeleting from '../../assets/images/statuses/icon_deleting.svg';

const ICONS = {
  [ReportStatuses.STARTED]: iconStarted,
  [ReportStatuses.FINISHED]: iconFinished,
  [ReportStatuses.FAILED]: iconFailed,
  [ReportStatuses.CANCELLED]: iconCancelled,
  [ReportStatuses.QUEUED]: iconQueued,
  [ReportStatuses.ERROR]: iconError,
  [ReportStatuses.DELETING]: iconDeleting,
};

export const getColumns = (t: TFunction<Feature.REPORTS>, language: Language): TableColumnType<Report>[] => {
  return [
    {
      title: t(`${Feature.SHARED}:name`),
      dataIndex: 'name',
      render: (name: Report['name'], report: Report) => (
        <LinkedName disabled={report.status !== ReportStatuses.FINISHED} name={name} to={`list/${report.uuid}`} />
      ),
      sorter: true,
      width: 160,
    },
    {
      title: t(`${Feature.SHARED}:definition.label`),
      dataIndex: 'definition',
      render: (definition: Report['definition']) => definition.name,
      width: 200,
    },
    {
      title: t('reports.shared.executionTime'),
      dataIndex: 'executionTime',
      render: (executionTime: Report['executionTime']) =>
        executionTime != null ? formatSeconds(executionTime, language) : '',
      sorter: true,
      width: 160,
    },
    {
      title: t(`${Feature.SHARED}:status`),
      dataIndex: 'status',
      render: (status: Report['status']) => (
        <CustomTag color={getReportStatusCustomTagColor(status)} icon={ICONS[status]}>
          {t(`reports.shared.reportStatus.${status.toLowerCase()}`)}
        </CustomTag>
      ),
      sorter: true,
      width: 130,
    },
    {
      title: t('reports.shared.expiresIn'),
      dataIndex: 'expireDate',
      render: (expireDate: Report['expireDate']) =>
        expireDate == null
          ? ''
          : formatTimeRemainingToNow(new Date(expireDate), i18nDateFnsLocaleMap[language], t('reports.shared.expired')),
      sorter: true,
      width: 140,
    },
    {
      title: t(`${Feature.SHARED}:createdAt`),
      dataIndex: 'createdAt',
      render: (createdAt: Report['createdAt']) => formatISODate(createdAt, language),
      sorter: true,
      width: 130,
      defaultSortOrder: 'descend',
    },
    {
      title: t(`${Feature.SHARED}:updatedAt`),
      dataIndex: 'updatedAt',
      render: (updatedAt: Report['updatedAt']) => formatISODate(updatedAt, language),
      sorter: true,
      width: 130,
    },
    {
      key: 'action',
      align: 'right',
      render: (_, report) => <Actions disabled={report.status !== ReportStatuses.FINISHED} report={report} />,
      width: 110,
    },
  ];
};
