import { Checkbox, Descriptions, Divider, Drawer, DrawerProps, Tag } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { useToggle } from 'react-use';

import { Feature } from '../../../shared/models/features';
import { formatISODate } from '../../../shared/utils/date';
import { getLanguageFromI18nLanguage } from '../../../shared/utils/language';
import { translationNamespace } from '../../constants/translation-resources';
import { User } from '../../../shared/models/user';
import { useLogsQueryForTimeline } from '../../../logs/data/queries/log-queries';
import { LogsTimeline } from '../../../shared/components/logs-timeline';
import { UserName } from '../../../shared/components/user-name';
import { getUserStatusTagColor } from '../../utils/color-mappers';
import { LogsFilters } from '../../../logs/models/log';

interface UserDetailsDrawerProps extends DrawerProps {
  user: User;
}

export const UserDetailsDrawer = ({ user, ...props }: UserDetailsDrawerProps) => {
  const { t, i18n } = useTranslation(translationNamespace);
  const [includeGetLogs, toggleIncludeGetLogs] = useToggle(false);
  const filters: LogsFilters = {
    resourceId: [user.id],
    entityType: 'user',
    method: includeGetLogs ? undefined : ['PATCH', 'POST', 'PUT', 'DELETE'],
  };
  const logQueryResult = useLogsQueryForTimeline(filters, { enabled: props.open });

  const roles = useMemo(() => {
    return user.roles?.length > 0 ? (
      user.roles.map((role, index) => (
        <Tag css={whiteSpaceNormal} key={index}>
          {role.name}
        </Tag>
      ))
    ) : user.isAdmin ? (
      <Tag css={whiteSpaceNormal}>{t('shared.superAdmin')}</Tag>
    ) : (
      ''
    );
  }, [user, t]);

  const groups = useMemo(() => {
    return user.groups?.length > 0
      ? user.groups.map((group, index) => (
          <Tag css={whiteSpaceNormal} key={index}>
            {group.name}
          </Tag>
        ))
      : '';
  }, [user]);

  return (
    <Drawer {...props} title={t('users.single.details')} width={400} placement="right" destroyOnClose>
      <Descriptions column={1} bordered css={withMarginBottom}>
        <Descriptions.Item label={t(`${Feature.SHARED}:firstName.label`)}>{user.firstName}</Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:middleName.label`)}>{user.middleName}</Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:lastName.label`)}>{user.lastName}</Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:username.label`)}>{user.username}</Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:email.label`)}>{user.email}</Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:mobilePhone.label`)}>{user.mobilePhone}</Descriptions.Item>
        <Descriptions.Item contentStyle={preWrapStyles} label={t(`${Feature.SHARED}:description.label`)}>
          {user.description}
        </Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:tenant.plural`)}>{groups}</Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:role.plural`)}>{roles}</Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:status`)}>
          <Tag color={getUserStatusTagColor(user.status)}>{t(`users.shared.status.${user.status.toLowerCase()}`)}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:createdAt`)}>
          {user.createdAt ? formatISODate(user.createdAt, getLanguageFromI18nLanguage(i18n.language)) : ''}
        </Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:createdBy`)}>
          <UserName userId={logQueryResult.isLoading ? undefined : user.createdBy} />
        </Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:updatedAt`)}>
          {user.updatedAt ? formatISODate(user.updatedAt, getLanguageFromI18nLanguage(i18n.language)) : ''}
        </Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:updatedBy`)}>
          <UserName userId={logQueryResult.isLoading ? undefined : user.updatedBy} />
        </Descriptions.Item>
      </Descriptions>

      <Divider>{t(`${Feature.SHARED}:log`)}</Divider>
      <Checkbox checked={includeGetLogs} onChange={toggleIncludeGetLogs} css={withMarginBottom}>
        {t(`${Feature.SHARED}:includeGetLogs`)}
      </Checkbox>
      <LogsTimeline queryResult={logQueryResult} />
    </Drawer>
  );
};

const withMarginBottom = css`
  margin-bottom: 32px;
`;

const whiteSpaceNormal = css`
  white-space: normal;
`;

const preWrapStyles: React.CSSProperties = {
  whiteSpace: 'pre-wrap',
};
