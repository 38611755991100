import { FindAllDefinitionsResponse } from '@pccr-ifc/pccr-ifc';

import { Definition } from '../../../shared/models/definition';
import { ResourceSearchResult } from '../../models/api/response';

export function convertSearchResponse(response: FindAllDefinitionsResponse): ResourceSearchResult<Definition> {
  return {
    total: response.total,
    resources: response.definitions as Definition[],
  };
}
