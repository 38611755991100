import { useQuery } from 'react-query';
import { RoleWithIncludes as BeRole } from '@pccr-ifc/pccr-ifc';
import { useTranslation } from 'react-i18next';

import { CommonFindAllQueryParams, QueryRequest } from '../../../shared/models/api/request';
import { Role } from '../../../shared/models/role';
import { deleteEmptyArrayObjProps } from '../../../shared/utils/object';
import { RoleSearchIncludes } from '../../models/api/role';
import { RolesFilters } from '../../models/role';
import { convertRole, convertSearchResponse } from '../converters/role-converters';
import { useRoleService } from '../services/use-role-service';
import { ResourceSearchResult } from '../../../shared/models/api/response';
import { getResourceListQueryKeyRoot, getSingleResourceQueryKey } from '../../../shared/utils/resource-query-key';
import { displayErrors } from '../../../shared/utils/error';

export function useGetRoleQuery(id: number) {
  const roleService = useRoleService();
  const { t } = useTranslation();

  return useQuery<BeRole, unknown, Role>(
    getSingleResourceQueryKey('ROLE', id),
    async () => {
      const response = await roleService.getRole(id);
      return response.data;
    },
    {
      enabled: id != null,
      select: convertRole,
      onError: error => displayErrors(error, t),
    }
  );
}

export function useFindRolesQuery(
  {
    page,
    pageSize,
    searchValue,
    filters,
    order,
    attributes = ['id', 'name', 'createdAt', 'updatedAt'],
  }: CommonFindAllQueryParams<Role, RolesFilters>,
  includes?: RoleSearchIncludes,
  enabled = true
) {
  const roleService = useRoleService();

  return useQuery<ResourceSearchResult<Role>, unknown>(
    [getResourceListQueryKeyRoot('ROLE'), { page, pageSize }, searchValue, filters, order, includes, attributes],
    async () => {
      const request: QueryRequest<Role, RolesFilters, RoleSearchIncludes> = {
        numberOfResults: pageSize,
        startRow: (page - 1) * pageSize,
        attributes,
      };

      if (order?.length > 0) {
        request.order = order;
      }

      if (searchValue) {
        request.search = searchValue;
      }

      if (includes != null) {
        request.includes = includes;
      }

      if (filters && Object.values(filters).some(val => val != null)) {
        request.filters = deleteEmptyArrayObjProps(filters);
      }

      const response = await roleService.findRoles(request);
      return convertSearchResponse(response.data);
    },
    { enabled }
  );
}
