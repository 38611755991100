import { Select, SelectProps } from 'antd';

interface ResourceSelectOption {
  id: number;
  name?: string;
  username?: string; // username for User as it doesn't have 'name' prop
  status?: string;
}

interface ResourceSelectProps extends SelectProps {
  resources: ResourceSelectOption[];
  currentResource: ResourceSelectOption;
  resourceSearchValue: string;
  optionDisabledFn?: (resource: ResourceSelectOption) => boolean;
}

function optionDisabledWhenInvalid(resource: ResourceSelectOption): boolean {
  return resource.status === 'INVALID';
}

function generateResourceSelectOptions(
  resources: ResourceSelectOption[],
  currentResource: ResourceSelectOption,
  resourceSearchValue: string
) {
  if (
    resources != null &&
    currentResource != null &&
    resources.find(resource => resource.id === currentResource.id) === undefined &&
    (resourceSearchValue == null || resourceSearchValue === '')
  ) {
    resources = [currentResource, ...resources];
  }
  return resources ?? [];
}

export const ResourceSelect = ({
  resources,
  currentResource,
  resourceSearchValue,
  optionDisabledFn = optionDisabledWhenInvalid,
  ...props
}: ResourceSelectProps) => {
  return (
    <Select {...props}>
      {generateResourceSelectOptions(resources, currentResource, resourceSearchValue).map((resource, index) => (
        <Select.Option disabled={optionDisabledFn(resource)} key={index} value={resource.id}>
          {resource.name ?? resource.username}
        </Select.Option>
      ))}
    </Select>
  );
};
