import { Button, Card, Col, Dropdown, Form, Input, message, Row, Space } from 'antd';
import { MenuProps } from 'antd';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { useToggle } from 'react-use';

import { ConfirmationModal } from '../../../shared/components/confirmation-modal';
import { setResourceName } from '../../../shared/data/store/slices/nav-slice';
import { CommonQueryParamKey } from '../../../shared/models/common-query-params';
import { Feature } from '../../../shared/models/features';
import { ResourceParams } from '../../../shared/models/resource-params';
import { Tenant } from '../../../shared/models/tenant';
import { User } from '../../../shared/models/user';
import {
  AssignTenantSubtenantFormValues,
  AssignTenantSubtenantModal,
} from '../../components/assign-tenant-subtenant-modal';
import { AssignTenantUserFormValues, AssignTenantUserModal } from '../../components/assign-tenant-user-modal';
import { EditTenantFormValues, EditTenantModal } from '../../components/edit-tenant-modal';
import { TenantSubtenantActions } from '../../components/tenant-subtenant-actions';
import { TenantUserActions } from '../../components/tenant-user-actions';
import { TenantsTable } from '../../components/tenants-table';
import { UsersTable } from '../../components/users-table';
import { translationNamespace } from '../../constants/translation-resources';
import {
  useDeleteAllFromTenantMutation,
  useDeleteTenantMutation,
  useReassignAllFromTenantMutation,
  useUpdateTenantMutation,
} from '../../data/mutations/tenant-mutations';
import { useGetTenantQuery, useGetTenantStatsQuery } from '../../data/queries/tenant-queries';
import { NewResourceQueryParamKey } from '../../models/params';
import { TenantStatsCard } from '../../components/tenant-stats-card/tenant-stats-card';
import { TenantDetailsDrawer } from '../../components/tenant-details-drawer';
import {
  ReassignAllFromTenantFormValues,
  ReassignAllFromTenantModal,
} from '../../components/reassign-all-from-tenant-modal';
import { countTenantStats } from '../../utils/tenant-utils';
import { TenantStatsModal } from '../../components/tenant-stats-modal';
import { TenantStats } from '../../models/tenant';
import { AppResourcePaths, AppResources } from '../../../shared/constants/app-resources';
import { tenantStatsCardTypes } from '../../components/tenant-stats-card/tenants-stats-card-types';
import { returnChangedObjectProperties } from '../../../shared/utils/object';
import { TenantUpsertFormField } from '../../components/tenant-upsert-form';
import { convertFormValuesToTenantUpsert } from '../../data/converters/tenant-converters';
import { permissionSelector } from '../../../shared/data/store/selectors/auth-selectors';

const { Search } = Input;

export const TenantSingleView: FC = () => {
  const { t } = useTranslation(translationNamespace);
  const dispatch = useDispatch();
  const location = useLocation();
  const { push: routerPush } = useHistory();
  const { id } = useParams<ResourceParams>();
  const parsedId = Number(id);
  const { data: tenant, isLoading, isSuccess } = useGetTenantQuery(parsedId);
  const [form] = Form.useForm<EditTenantFormValues>();
  const updateMutation = useUpdateTenantMutation();
  const deleteMutation = useDeleteTenantMutation();
  const deleteAllFromTenantMutation = useDeleteAllFromTenantMutation();
  const reassignAllFromTenantMutation = useReassignAllFromTenantMutation();
  const [showDetails, toggleShowDetails] = useToggle(false);
  const [searchValue, setSearchValue] = useState<string>();
  const [showAddUserModal, toggleShowAddUserModal] = useToggle(false);
  const [showAddSubtenantModal, toggleShowAddSubtenantModal] = useToggle(false);
  const [showEditTenantModal, toggleShowEditTenantModal] = useToggle(false);
  const [showConfirmDeleteModal, toggleShowConfirmDeleteModal] = useToggle(false);
  const [showConfirmDeleteAllFromTenantModal, toggleShowConfirmDeleteAllFromTenantModal] = useToggle(false);
  const [showReassignAllFromTenantModal, toggleShowReassignAllFromTenantModal] = useToggle(false);
  const [tenantStatsModalType, setTenantStatsModalType] = useState<
    undefined | keyof Omit<TenantStats, 'parentGroupId'>
  >();
  const { data: tenantStats, isLoading: statsLoading } = useGetTenantStatsQuery(parsedId);
  const initialTenantValues = useRef<EditTenantFormValues>();
  const permissions = useSelector(permissionSelector);

  const areRequestsLoading =
    isLoading ||
    updateMutation.isLoading ||
    deleteMutation.isLoading ||
    deleteAllFromTenantMutation.isLoading ||
    reassignAllFromTenantMutation.isLoading;

  const users = useMemo(() => {
    if (searchValue) {
      return tenant?.users.filter(user =>
        Object.values(user).some(value => {
          if (typeof value === 'string') {
            return value.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase());
          }

          return false;
        })
      );
    }

    return tenant?.users;
  }, [searchValue, tenant?.users]);

  const applySearch = (value: string) => {
    setSearchValue(value);
  };

  const navigateToUserInvitation = () => {
    toggleShowAddUserModal();
    routerPush(
      `${AppResourcePaths.users}/new?${CommonQueryParamKey.PREV}=${encodeURIComponent(location.pathname)}&${
        NewResourceQueryParamKey.TENANT
      }=${encodeURIComponent(tenant?.id ?? '')}`
    );
  };

  const navigateToTenantAdd = () => {
    toggleShowAddSubtenantModal();
    routerPush(
      `${AppResourcePaths.tenants}/new?${CommonQueryParamKey.PREV}=${encodeURIComponent(location.pathname)}&${
        NewResourceQueryParamKey.TENANT
      }=${encodeURIComponent(tenant?.id ?? '')}`
    );
  };

  const remove = () => {
    if (tenant.id == null) {
      return;
    }

    if (countTenantStats(tenantStats) > 0) {
      message.error(t('tenants.single.deleteWarning'));
      return;
    }

    deleteMutation.mutate(tenant.id, {
      onSuccess: () => {
        message.success(t('tenants.shared.deleteSuccessMessage'));
        routerPush(AppResourcePaths.tenants);
      },
    });
  };

  const deleteAllFromTenant = () => {
    if (tenant.id == null) {
      return;
    }

    if (tenantStats != null && countTenantStats(tenantStats) === 0) {
      message.error(t('tenants.single.deleteAllFromTenant.nothingToDelete'));
      return;
    }

    deleteAllFromTenantMutation.mutate(tenant.id, {
      onSuccess: () => {
        message.success(t('tenants.single.deleteAllFromTenant.success'));
        toggleShowConfirmDeleteAllFromTenantModal();
      },
    });
  };

  const edit = (values: EditTenantFormValues) => {
    const changedProperties = returnChangedObjectProperties(initialTenantValues.current, values);

    if (!changedProperties) {
      message.warning(t(`${Feature.SHARED}:nothingToUpdate`));
      return;
    }

    updateMutation.mutate(
      {
        id: tenant.id,
        ...convertFormValuesToTenantUpsert(changedProperties),
      },
      {
        onSuccess: () => {
          toggleShowEditTenantModal();
          message.success(t('tenants.shared.updateSuccessMessage'));
        },
      }
    );
  };

  const reassignAllFromTenant = (values: ReassignAllFromTenantFormValues) => {
    if (tenant.id == null || values.newTenantId === tenant.id) {
      toggleShowReassignAllFromTenantModal();
      return;
    }

    if (tenantStats != null && countTenantStats(tenantStats) === 0) {
      message.error(t('tenants.single.reassignAllFromTenant.nothingToReassign'));
      return;
    }

    reassignAllFromTenantMutation.mutate(
      { currentTenantId: tenant.id, newTenantId: values.newTenantId },
      {
        onSuccess: () => {
          toggleShowReassignAllFromTenantModal();
          message.success(t('tenants.single.reassignAllFromTenant.success'));
        },
      }
    );
  };

  const addUsers = (values: AssignTenantUserFormValues, resetForm: () => void) => {
    if (tenant.id == null) {
      return;
    }

    const currentUserIds = tenant.users?.map(user => user.id) ?? [];
    const users = [...currentUserIds, ...values.users];

    if (users.length > 0 && users.some(el => !!el)) {
      updateMutation.mutate(
        { id: tenant.id, users },
        {
          onSuccess: () => {
            message.success(t('tenants.shared.updateSuccessMessage'));
            toggleShowAddUserModal();
            resetForm();
          },
        }
      );
    }
  };

  const removeUser = (user: User) => {
    if (tenant.id == null) {
      return;
    }

    updateMutation.mutate(
      { id: tenant.id, users: [...tenant.users.filter(usr => usr.id !== user.id).map(usr => usr.id)] },
      {
        onSuccess: () => {
          message.success(t('tenants.shared.updateSuccessMessage'));
        },
      }
    );
  };

  const addSubtenants = (values: AssignTenantSubtenantFormValues, resetForm: () => void) => {
    if (tenant.id == null) {
      return;
    }

    const currentGroupIds = tenant.groups?.map(group => group.id) ?? [];
    const groups = [...currentGroupIds, ...values.subtenants];

    if (groups.length > 0 && groups.some(el => !!el)) {
      updateMutation.mutate(
        { id: tenant.id, groups },
        {
          onSuccess: () => {
            message.success(t('tenants.shared.updateSuccessMessage'));
            toggleShowAddSubtenantModal();
            resetForm();
          },
        }
      );
    }
  };

  const removeSubtenant = (subtenant: Tenant) => {
    if (tenant.id == null) {
      return;
    }

    updateMutation.mutate(
      { id: tenant.id, groups: [...tenant.groups.filter(group => group.id !== subtenant.id).map(group => group.id)] },
      {
        onSuccess: () => {
          message.success(t('tenants.shared.updateSuccessMessage'));
        },
      }
    );
  };

  const items = useMemo<MenuProps['items']>(() => {
    const menuItems: MenuProps['items'] = [];

    if (permissions[AppResources.TENANT]['UPDATE']) {
      menuItems.push({
        key: 'edit',
        onClick: () => toggleShowEditTenantModal(),
        label: t(`${Feature.SHARED}:edit`),
      });
      menuItems.push({
        key: 'reassignAllFromTenant',
        onClick: () => toggleShowReassignAllFromTenantModal(),
        label: t('tenants.single.reassignAllFromTenant.action'),
      });
    }
    if (permissions[AppResources.TENANT]['DELETE']) {
      menuItems.push({
        key: 'deleteAllFromTenant',
        onClick: () => toggleShowConfirmDeleteAllFromTenantModal(),
        label: t('tenants.single.deleteAllFromTenant.action'),
      });
      menuItems.push({
        key: 'remove',
        onClick: () => toggleShowConfirmDeleteModal(),
        label: t(`${Feature.SHARED}:remove`),
      });
    }

    return menuItems;
  }, [
    permissions,
    t,
    toggleShowEditTenantModal,
    toggleShowReassignAllFromTenantModal,
    toggleShowConfirmDeleteAllFromTenantModal,
    toggleShowConfirmDeleteModal,
  ]);

  useEffect(() => {
    if (tenant) {
      const tenantUpsertFormFieldValues = {
        [TenantUpsertFormField.NAME]: tenant.name,
        [TenantUpsertFormField.CUSTOMER_ID]: tenant.customerId,
      };

      form.setFieldsValue(tenantUpsertFormFieldValues);
      initialTenantValues.current = tenantUpsertFormFieldValues;
    }
  }, [form, tenant]);

  useEffect(() => {
    dispatch(setResourceName(tenant?.name));
  }, [dispatch, tenant?.name]);

  return (
    <Row gutter={[16, 24]}>
      <StyledCol span={24}>
        <Space>
          <Button size="large" onClick={toggleShowDetails} loading={areRequestsLoading} disabled={!isSuccess}>
            {t(`${Feature.SHARED}:details`)}
          </Button>
          {items.length > 0 && (
            <Dropdown menu={{ items }} trigger={['click']} disabled={!tenant}>
              <Button size="large" loading={areRequestsLoading} disabled={!isSuccess}>
                {t(`${Feature.SHARED}:actions`)} <DownOutlined />
              </Button>
            </Dropdown>
          )}
        </Space>
      </StyledCol>

      {tenantStatsCardTypes.map(cardType => (
        <Col span={8} key={cardType}>
          <TenantStatsCard
            title={t(`tenants.single.${cardType}.label`)}
            subtitle={t(`tenants.single.${cardType}.description`)}
            count={tenantStats?.[cardType]?.length}
            loading={statsLoading}
            action={() => setTenantStatsModalType(cardType)}
          />
        </Col>
      ))}

      <Col span={24}>
        <Card
          title={t('tenants.single.usersTable.title')}
          extra={
            permissions.TENANT.UPDATE && (
              <Button disabled={tenant === undefined} type="link" onClick={toggleShowAddUserModal}>
                {t('tenants.shared.addUser')}
              </Button>
            )
          }
        >
          <Row gutter={[16, 24]}>
            <Col span={24}>
              <StyledSearch
                placeholder={t(`${Feature.SHARED}:search`)}
                onSearch={applySearch}
                allowClear
                size="large"
              />
            </Col>
            <Col span={24}>
              <UsersTable
                dataSource={users}
                loading={isLoading}
                customActions={user => <TenantUserActions user={user} onRemove={removeUser} />}
                sortingEnabled={false}
                scroll={{ x: 2000 }}
              />
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={24}>
        <Card
          title={t('tenants.single.subtenantsTable.title')}
          extra={
            permissions.TENANT.UPDATE && (
              <Button disabled={tenant === undefined} type="link" onClick={toggleShowAddSubtenantModal}>
                {t('tenants.single.addSubtenant')}
              </Button>
            )
          }
        >
          <TenantsTable
            dataSource={tenant?.groups}
            loading={isLoading}
            customActions={subtenant => <TenantSubtenantActions tenant={subtenant} onRemove={removeSubtenant} />}
            sortingEnabled={false}
            scroll={{ x: 650 }}
          />
        </Card>
      </Col>

      {tenant != null && (
        <TenantDetailsDrawer
          tenant={tenant}
          parentGroupId={tenantStats?.parentGroupId}
          open={showDetails}
          onClose={toggleShowDetails}
        />
      )}
      <AssignTenantUserModal
        open={showAddUserModal}
        onInvite={navigateToUserInvitation}
        onCancel={toggleShowAddUserModal}
        onSubmit={addUsers}
        confirmLoading={updateMutation.isLoading}
      />
      <AssignTenantSubtenantModal
        tenantId={parsedId}
        open={showAddSubtenantModal}
        onInvite={navigateToTenantAdd}
        onCancel={toggleShowAddSubtenantModal}
        onSubmit={addSubtenants}
        confirmLoading={updateMutation.isLoading}
      />
      <EditTenantModal
        tenant={tenant}
        open={showEditTenantModal}
        onCancel={toggleShowEditTenantModal}
        onSubmit={edit}
        confirmLoading={updateMutation.isLoading}
      />
      <ReassignAllFromTenantModal
        currentTenant={tenant}
        open={showReassignAllFromTenantModal}
        onCancel={toggleShowReassignAllFromTenantModal}
        onSubmit={reassignAllFromTenant}
        confirmLoading={reassignAllFromTenantMutation.isLoading}
      />
      <ConfirmationModal
        open={showConfirmDeleteModal}
        title={t(`${Feature.SHARED}:areYouSure`)}
        onCancel={toggleShowConfirmDeleteModal}
        onOk={remove}
        content={t(`${Feature.SHARED}:confirmDeleteMsg`, {
          resourceName: tenant?.name,
          buttonTitle: t(`${Feature.SHARED}:confirm`),
        })}
      />
      <ConfirmationModal
        open={showConfirmDeleteAllFromTenantModal}
        title={t(`${Feature.SHARED}:areYouSure`)}
        onCancel={toggleShowConfirmDeleteAllFromTenantModal}
        onOk={deleteAllFromTenant}
        content={t('tenants.single.deleteAllFromTenant.info', {
          tenantName: tenant?.name,
        })}
      />
      <TenantStatsModal
        open={tenantStatsModalType !== undefined}
        onCancel={() => setTenantStatsModalType(undefined)}
        type={tenantStatsModalType}
        stats={tenantStats}
      />
    </Row>
  );
};

const StyledSearch = styled(Search)`
  width: 100%;
  max-width: 320px;
`;

const StyledCol = styled(Col)`
  display: flex;
  justify-content: end;
`;
