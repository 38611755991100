import { FindAllPresetsResponse } from '@pccr-ifc/pccr-ifc';

import { convertDateRangeToISO } from '../../../shared/utils/date';
import { PresetUpsertFormValues } from '../../components/preset-upsert-form';
import { PresetsTableFilters } from '../../components/presets-table';
import { Preset, PresetsFilters } from '../../models/preset';
import { ResourceSearchResult } from '../../../shared/models/api/response';

export function convertSearchResponse(response: FindAllPresetsResponse): ResourceSearchResult<Preset> {
  return {
    total: response.total,
    resources: response.presets as Preset[],
  };
}

function convertFormValuesToPreset(values: PresetUpsertFormValues): Omit<Preset, 'id'>;
function convertFormValuesToPreset(values: Partial<PresetUpsertFormValues>): Partial<Omit<Preset, 'id'>>;
function convertFormValuesToPreset(values: Partial<PresetUpsertFormValues>): Partial<Omit<Preset, 'id'>> {
  const preset: Partial<Omit<Preset, 'id'>> = {
    name: values.name,
    definitionId: values.definition,
  };

  if (values.reportColumns !== undefined) {
    preset.query = { attributes: values.reportColumns };
  }

  if (values.search?.length > 0) {
    preset.query.search = values.search;
  }

  if (values.filters?.length > 0) {
    preset.query.filters = values.filters.reduce((map, { column, value }) => {
      map = {
        ...map,
        [column]: value,
      };
      return map;
    }, {});
  }

  return preset;
}
export { convertFormValuesToPreset };

export function convertTableFiltersToFilters(tableFilters: PresetsTableFilters): PresetsFilters {
  const presetsFilters: PresetsFilters = { name: tableFilters.name, definitionName: tableFilters.definitionName };

  if (tableFilters.createdAt != null) {
    presetsFilters.createdAt = convertDateRangeToISO(tableFilters.createdAt);
  }
  if (tableFilters.updatedAt != null) {
    presetsFilters.updatedAt = convertDateRangeToISO(tableFilters.updatedAt);
  }

  return presetsFilters;
}
