import { Button, Card, Col, Form, message, Row, Space, Tooltip } from 'antd';
import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { CheckOutlined, DeleteOutlined, QuestionOutlined } from '@ant-design/icons';
import { unwrapResult } from '@reduxjs/toolkit';
import { useToggle } from 'react-use';
import styled from '@emotion/styled';

import { ConfirmationModal } from '../../../shared/components/confirmation-modal';
import { setResourceName } from '../../../shared/data/store/slices/nav-slice';
import { Feature } from '../../../shared/models/features';
import { ResourceParams } from '../../../shared/models/resource-params';
import { SourceDetailsDrawer } from '../../components/source-details-drawer';
import { SourceUpsertForm, SourceUpsertFormField, SourceUpsertFormValues } from '../../components/source-upsert-form';
import { translationNamespace } from '../../constants/translation-resources';
import { convertFormValuesToSource } from '../../data/converters/source-converters';
import { useDeleteSourceMutation, useUpdateSourceMutation } from '../../data/mutations/source-mutations';
import { useGetSourceQuery } from '../../data/queries/source-queries';
import { checkSource } from '../../data/store/thunks/source-thunks';
import { useSourceService } from '../../data/services/use-source-service';
import { AppResourcePaths } from '../../../shared/constants/app-resources';
import { returnChangedObjectProperties } from '../../../shared/utils/object';
import { useGetTenantQuery } from '../../../access/data/queries/tenant-queries';
import { displayErrors } from '../../../shared/utils/error';
import { permissionSelector } from '../../../shared/data/store/selectors/auth-selectors';

export const SourceSingleView: FC = () => {
  const { t } = useTranslation(translationNamespace);
  const dispatch = useDispatch();
  const { push: routerPush } = useHistory();
  const { id } = useParams<ResourceParams>();
  const [form] = Form.useForm<SourceUpsertFormValues>();
  const [showDetails, toggleShowDetails] = useToggle(false);
  const [showConfirmDeleteModal, toggleShowConfirmDeleteModal] = useToggle(false);
  const parsedId = Number(id);
  const { data: sourceRes, isSuccess, isLoading, isFetching } = useGetSourceQuery(parsedId);
  const { data: tenant, isLoading: sourceTenantLoading } = useGetTenantQuery(sourceRes?.source.groupId);
  const updateMutation = useUpdateSourceMutation();
  const deleteMutation = useDeleteSourceMutation();
  const sourceService = useSourceService();
  const initialSourceValues = useRef<Omit<SourceUpsertFormValues, SourceUpsertFormField.PASSWORD>>();
  const permissions = useSelector(permissionSelector);

  const areRequestsLoading = isLoading || updateMutation.isLoading || deleteMutation.isLoading;

  const submit = (values: SourceUpsertFormValues) => {
    if (sourceRes?.source.id == null) {
      return;
    }

    const changedProperties = returnChangedObjectProperties(initialSourceValues.current, values);

    if (!changedProperties) {
      message.warning(t(`${Feature.SHARED}:nothingToUpdate`));
      return;
    }

    const source = {
      id: sourceRes.source.id,
      ...convertFormValuesToSource(changedProperties),
    };

    updateMutation.mutate(source, {
      onSuccess: () => {
        message.success(t('sources.shared.updateSuccessMessage'));
      },
    });
  };

  const remove = () => {
    if (sourceRes?.source.id == null) {
      return;
    }

    deleteMutation.mutate(sourceRes.source.id, {
      onSuccess: () => {
        message.success(t('sources.shared.deleteSuccessMessage'));
        routerPush(AppResourcePaths.sources);
      },
    });
  };

  const triggerSubmit = () => {
    form.submit();
  };

  const check = () => {
    const newSource = convertFormValuesToSource(form.getFieldsValue());

    dispatch(checkSource({ request: newSource, service: sourceService }))
      .then(unwrapResult)
      .then(() => {
        message.success(t('shared.check.successful'));
      })
      .catch(error => displayErrors(error, t));
  };

  useEffect(() => {
    const source = sourceRes?.source;

    if (source) {
      const sourceUpsertFormFieldValues = {
        [SourceUpsertFormField.NAME]: source.name,
        [SourceUpsertFormField.DIALECT]: source.dialect,
        [SourceUpsertFormField.HOST]: source.host,
        [SourceUpsertFormField.PORT]: source.port,
        [SourceUpsertFormField.DATABASE_NAME]: source.databasename,
        [SourceUpsertFormField.USERNAME]: source.username,
        [SourceUpsertFormField.PASSWORD]: '',
        [SourceUpsertFormField.TENANT]: tenant == null ? null : source.groupId,
      };

      form.setFieldsValue(sourceUpsertFormFieldValues);
      initialSourceValues.current = sourceUpsertFormFieldValues;
    }
  }, [form, sourceRes, tenant]);

  useEffect(() => {
    dispatch(setResourceName(sourceRes?.source.name));
  }, [dispatch, sourceRes?.source.name]);

  return (
    <Row justify="space-between" gutter={[16, 24]}>
      <StyledCol span={24}>
        <Space>
          <Button size="large" onClick={toggleShowDetails} loading={areRequestsLoading} disabled={!isSuccess}>
            {t(`${Feature.SHARED}:details`)}
          </Button>
          {permissions.SOURCE.DELETE && (
            <Button
              size="large"
              onClick={toggleShowConfirmDeleteModal}
              loading={areRequestsLoading}
              disabled={sourceRes?.source == null}
              icon={<DeleteOutlined />}
              danger
            />
          )}
        </Space>
      </StyledCol>

      <Col span={24}>
        <Card title={t(`${Feature.SHARED}:details`)}>
          <SourceUpsertForm
            source={sourceRes?.source}
            form={form}
            onSubmit={submit}
            sourceTenantLoading={sourceTenantLoading}
          />
        </Card>
      </Col>

      <Col>
        <Space>
          {permissions.SOURCE.UPDATE && (
            <Tooltip title={t('shared.check.tooltip.source.checkFirst')}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                icon={<CheckOutlined />}
                loading={areRequestsLoading || isFetching}
                onClick={triggerSubmit}
                disabled={sourceRes?.source == null}
              >
                {t(`${Feature.SHARED}:save`)}
              </Button>
            </Tooltip>
          )}

          <Tooltip title={t('shared.check.tooltip.source.providePassword')}>
            <Button type="dashed" size="large" icon={<QuestionOutlined />} onClick={check}>
              {t('shared.check.label')}
            </Button>
          </Tooltip>

          <Link to={AppResourcePaths.sources}>
            <Button htmlType="button" size="large">
              {t(`${Feature.SHARED}:cancel`)}
            </Button>
          </Link>
        </Space>
      </Col>
      {sourceRes?.source && (
        <SourceDetailsDrawer source={sourceRes?.source} open={showDetails} onClose={toggleShowDetails} />
      )}
      <ConfirmationModal
        open={showConfirmDeleteModal}
        title={t(`${Feature.SHARED}:areYouSure`)}
        onCancel={toggleShowConfirmDeleteModal}
        onOk={remove}
        content={t(`${Feature.SHARED}:confirmDeleteMsg`, {
          resourceName: sourceRes?.source.name,
          buttonTitle: t(`${Feature.SHARED}:confirm`),
        })}
      />
    </Row>
  );
};

const StyledCol = styled(Col)`
  display: flex;
  justify-content: end;
`;
