import { TableColumnType, Tag } from 'antd';
import { TFunction } from 'react-i18next';

import { LinkedName } from '../../../shared/components/linked-name';
import { Feature } from '../../../shared/models/features';
import { Language } from '../../../shared/models/language';
import { User } from '../../../shared/models/user';
import { formatISODate } from '../../../shared/utils/date';
import { getUserStatusTagColor } from '../../utils/color-mappers';
import { RolesCell } from '../roles-cell';
import { Actions as DefaultActions } from './actions';
import { AppResourcePaths, AppResources } from '../../../shared/constants/app-resources';
import { PermissionsState } from '../../../shared/data/store';

export const getColumns = (
  t: TFunction<Feature.ACCESS>,
  language: Language,
  customActions: (user: User) => React.ReactElement = (user: User) => <DefaultActions user={user} />,
  sortingEnabled = true,
  permissions: PermissionsState
): TableColumnType<User>[] => {
  return [
    {
      title: t(`${Feature.SHARED}:username.label`),
      dataIndex: 'username',
      render: (username: User['username'], user: User) => (
        <LinkedName name={username} to={`${AppResourcePaths.users}/${user.id}`} />
      ),
      sorter: sortingEnabled,
    },
    {
      title: t(`${Feature.SHARED}:email.label`),
      dataIndex: 'email',
      render: (email: User['email'], user: User) => (
        <LinkedName name={email} to={`${AppResourcePaths.users}/${user.id}`} />
      ),
      sorter: sortingEnabled,
    },
    {
      title: t(`${Feature.SHARED}:role.plural`),
      dataIndex: 'roles',
      render: (roles: User['roles'], user: User) => <RolesCell roles={roles} isAdmin={user.isAdmin} />,
    },
    {
      title: t(`${Feature.SHARED}:firstName.label`),
      dataIndex: 'firstName',
      sorter: sortingEnabled,
      width: 150,
    },
    {
      title: t(`${Feature.SHARED}:lastName.label`),
      dataIndex: 'lastName',
      sorter: sortingEnabled,
      width: 170,
    },
    {
      title: t(`${Feature.SHARED}:mobilePhone.label`),
      dataIndex: 'mobilePhone',
      sorter: sortingEnabled,
      width: 120,
    },
    {
      title: t(`${Feature.SHARED}:status`),
      dataIndex: 'status',
      render: (status: User['status']) => (
        <Tag color={getUserStatusTagColor(status)}>{t(`users.shared.status.${status.toLowerCase()}`)}</Tag>
      ),
      sorter: true,
      width: 140,
    },
    {
      title: t(`${Feature.SHARED}:createdAt`),
      dataIndex: 'createdAt',
      render: (createdAt: User['createdAt']) => formatISODate(createdAt, language),
      sorter: true,
      width: 130,
      defaultSortOrder: 'descend',
    },
    {
      title: t(`${Feature.SHARED}:updatedAt`),
      dataIndex: 'updatedAt',
      render: (updatedAt: User['updatedAt']) => formatISODate(updatedAt, language),
      sorter: true,
      width: 130,
    },
    {
      key: 'action',
      align: 'right',
      render: (_, user) => customActions(user),
      width: 130,
      hidden: permissions[AppResources.TENANT]['UPDATE']
        ? false
        : !permissions[AppResources.USER]['UPDATE'] && !permissions[AppResources.USER]['DELETE'],
    },
  ];
};
