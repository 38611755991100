import { GuardFunction } from 'react-router-guards';
import { unwrapResult } from '@reduxjs/toolkit';
import { refresh } from '../../shared/data/store/thunks/auth-thunks';
import { CommonQueryParamKey } from '../../shared/models/common-query-params';
import { GuardedRouteMeta } from '../../shared/models/route';

export const loggedInGuard: GuardFunction = (to, from, next) => {
  const { loggedIn, getState, dispatch, authService, queryClient }: GuardedRouteMeta = to.meta;
  if (loggedIn) {
    if (getState?.().shared.auth.loggedIn) {
      return next();
    }

    return dispatch(refresh({ service: authService }))
      .then(unwrapResult)
      .then(() => next())
      .catch(() => {
        queryClient?.clear();
        next.redirect(`/auth/login?${CommonQueryParamKey.PREV}=${encodeURIComponent(to.location.pathname)}`);
      });
  } else {
    next();
  }
};
