import { Feature } from '../../shared/models/features';
import { Layout } from '../../shared/models/layout';
import { Route } from '../../shared/models/route';
import { ForgotPasswordView } from '../views/forgot-password/forgot-password-view';
import { LoginView } from '../views/login';
import { RegisterView } from '../views/register';
import { UserProfileView } from '../views/user-profile';
import userIcon from '../assets/images/icon_user.svg';

export const authRoutes: Route = {
  path: '/auth',
  meta: { loggedIn: false, layout: Layout.FULL_PAGE },
  children: [
    {
      path: '/login',
      view: LoginView,
    },
    {
      path: '/register',
      view: RegisterView,
    },
    {
      path: '/forgot-password',
      view: ForgotPasswordView,
    },
    {
      path: '/profile',
      view: UserProfileView,
      meta: { loggedIn: true, layout: Layout.SIDE_NAVIGATION },
      icon: userIcon,
      nameTranslationKey: `${Feature.SHARED}:profile`,
    },
  ],
};
