import { Config, AntdConfig } from '@react-awesome-query-builder/antd';

export const defaultQueryBuilderConfig: Config = {
  ...AntdConfig,
  fields: {
    temporary: {
      type: 'text',
    },
  },
};
