import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import {
  BatchesActionRequest,
  BatchesActionResponse,
  SetFixedDateRequest,
  SetFixedDateResponse,
} from '@pccr-ifc/pccr-ifc';
import { useTranslation } from 'react-i18next';

import { useApprovalService } from '../services/use-approval-service';
import { getResourceListQueryKeyRoot } from '../../../shared/utils/resource-query-key';
import { displayErrors } from '../../../shared/utils/error';

export function useHandleBatchActionMutation() {
  const approvalService = useApprovalService();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse<BatchesActionResponse>, unknown, BatchesActionRequest>(
    data => approvalService.handleBatchAction(data),
    {
      onSuccess() {
        queryClient.invalidateQueries(getResourceListQueryKeyRoot('BATCH'));
      },
      onError(error) {
        displayErrors(error, t);
      },
    }
  );
}
export function useSetFixDateMutation() {
  const approvalService = useApprovalService();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse<SetFixedDateResponse>, unknown, SetFixedDateRequest>(
    data => approvalService.setFixDate(data),
    {
      onSuccess() {
        queryClient.invalidateQueries(getResourceListQueryKeyRoot('BATCH'));
      },
      onError(error) {
        displayErrors(error, t);
      },
    }
  );
}
