import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { translationNamespace } from '../../constants/translation-resources';

export const PolicyPathTooltip: React.FC = () => {
  const { t } = useTranslation(translationNamespace);
  return (
    <>
      <div>{t('policies.upsert.pathTooltip.title')}</div>
      <a href="https://regexr.com/" hrefLang="en" target="_blank" rel="noreferrer">
        {t('policies.upsert.pathTooltip.checkDocumentation')}
      </a>
      <StyledText>{t('policies.upsert.pathTooltip.constantExample')}</StyledText>
      <pre>/report/preset/findall</pre>
      <StyledText>{t('policies.upsert.pathTooltip.dynamicExample')}</StyledText>
      <pre>/user/\d+</pre>
    </>
  );
};

const StyledText = styled.div`
  margin-top: 10px;
`;
