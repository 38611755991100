export const colors = {
  primary: '#134d71',
  white: '#ffffff',
  black: '#000000',
  grey: '#eeeeee',
  failedGrey: '#595959',
  red: '#b55f03',
  darkGrey: '#999999',
  hoverGray: '#d4d6d4',
  backgroundGrey: '#f7f7f7',
  blueHover: '#238acb',
  orange: '#b55f03',
  hoverOrange: '#e37f00',
  green: '#6CA885',
  skyBlue: '#0C7AB8',
  turquoise: '#007059',
  turquoiseHover: '#00C9A0',
  ping: '#85406B',
  pingHover: '#f573c0',
};

export const fontSize = {
  ONE: '1rem',
  ONE_POINT_TWO: '1.2rem',
  ONE_POINT_FOUR: '1.4rem',
  ONE_POINT_FIVE: '1.5rem',
  THREE: '3rem',
};

export const border = (colorName: keyof typeof colors, wPx = 1, style = 'solid') =>
  `${wPx}px ${style} ${colors[colorName]}`;
