import { ReportTheme } from '../../shared/models/theme';

export const reportsDefaultTheme: ReportTheme = {
  exportModal: {
    icon: {
      color: '#1890ff',
    },
  },
  queryBuilder: {
    groupContainer: {
      border: {
        color: '#d9d9d9',
      },
    },
  },
};
