import { createAsyncThunk } from '@reduxjs/toolkit';

import { Feature } from '../../../../shared/models/features';
import { RequestWithService } from '../../../../shared/models/request';
import { Report } from '../../../models/report';
import { ReportService } from '../../services/use-report-service';

export const checkReportSize = createAsyncThunk<
  number,
  RequestWithService<Pick<Report, 'definitionId' | 'sqlParams'>, ReportService>
>(`${Feature.REPORTS}/report/checkSize`, async ({ request: report, service }, { rejectWithValue }) => {
  try {
    const response = await service.checkReportSize(report);
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});
