import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useIdle, useInterval } from 'react-use';
import { unwrapResult } from '@reduxjs/toolkit';

import { translationNamespace } from '../../constants/translation-resources';
import { secondsToMMSS } from '../../utils/time';
import { useAuthService } from '../../../shared/data/services/use-auth-service';
import { loggedInSelector } from '../../../shared/data/store/selectors/auth-selectors';
import { useGetSessionDataQuery } from '../../data/queries/session-queries';
import { refresh } from '../../../shared/data/store/thunks/auth-thunks';
import { displayErrors } from '../../../shared/utils/error';
import { useLogOut } from '../../hooks/use-log-out';

const FIVE_MINUTES_IN_S = 5 * 60;
const TEN_MINUTES_IN_S = FIVE_MINUTES_IN_S * 2;

const lastRefreshTimeStorageKey = 'lastRefreshTime';
const lastActivityTimeStorageKey = 'lastActivityTime';

const updateStorageTime = (key: string): number => {
  const now = new Date().getTime();
  localStorage.setItem(key, now.toString());
  return now;
};

export function updateLastRefreshTime() {
  updateStorageTime(lastRefreshTimeStorageKey);
}

const getTimeFromStorage = (key: string): number => {
  const value = localStorage.getItem(key);
  if (value != null) {
    return parseInt(value);
  } else {
    return new Date().getTime();
  }
};

export const AutomaticLogout = (): null => {
  const { t } = useTranslation(translationNamespace);
  const authService = useAuthService();
  const dispatch = useDispatch();
  const loggedIn = useSelector(loggedInSelector);
  const { data } = useGetSessionDataQuery(loggedIn);
  const sessionDurationInMinutes = data != null ? data.refreshDurationMinutes : 30;
  const [timeToLogout, setSecondsToLogout] = useState(sessionDurationInMinutes * 60);
  const isIdle = useIdle(1000);
  const logOut = useLogOut();

  const logoutWarningKey = 'logoutWarning';

  const refreshSession = async () => {
    try {
      const response = await dispatch(refresh({ service: authService }));
      unwrapResult(response);
    } catch (error) {
      displayErrors(error, t);
    }
  };

  const resetLogoutTimer = () => {
    setSecondsToLogout(sessionDurationInMinutes * 60);
  };

  useInterval(
    () => {
      const now = new Date().getTime();

      const lastActivityTime = isIdle
        ? getTimeFromStorage(lastActivityTimeStorageKey)
        : updateStorageTime(lastActivityTimeStorageKey);
      const secondsToLogoutDueToInactive = Math.round(
        (sessionDurationInMinutes * 60e3 + lastActivityTime - now) / 1000
      );

      if (secondsToLogoutDueToInactive <= 0) {
        resetLogoutTimer();
        logOut();
      } else if (secondsToLogoutDueToInactive <= FIVE_MINUTES_IN_S) {
        setSecondsToLogout(secondsToLogoutDueToInactive);
      } else {
        resetLogoutTimer();
      }

      const lastRefeshTime = getTimeFromStorage(lastRefreshTimeStorageKey);
      const timeFromLastRefresh = Math.round((now - lastRefeshTime) / 1000);
      if (timeFromLastRefresh > TEN_MINUTES_IN_S) {
        refreshSession();
      }
    },
    loggedIn && timeToLogout > 0 ? 1000 : null
  );

  useEffect(() => {
    if (loggedIn) {
      localStorage.setItem(lastActivityTimeStorageKey, new Date().getTime().toString());
    }
  }, [loggedIn]);

  useEffect(() => {
    if (timeToLogout <= FIVE_MINUTES_IN_S) {
      notification.warning({
        key: logoutWarningKey,
        message: `${t('autoLogout.logoutIn')} ${secondsToMMSS(timeToLogout) ?? '00:00'}`,
        description: t('autoLogout.description'),
        duration: 0,
      });
    } else {
      notification.destroy(logoutWarningKey);
    }
  }, [t, timeToLogout]);

  return null;
};
