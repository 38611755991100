import { useQuery } from 'react-query';
import { UserWithIncludes as BeUser } from '@pccr-ifc/pccr-ifc';
import { useTranslation } from 'react-i18next';

import { CommonFindAllQueryParams, QueryRequest } from '../../../shared/models/api/request';
import { User } from '../../../shared/models/user';
import { UserSearchIncludes } from '../../models/api/user';
import { UserIdentifierAttribute, UsersFilters } from '../../models/user';
import { convertFilters, convertSearchResponse, convertUser } from '../converters/user-converters';
import { useUserService } from '../services/use-user-service';
import { ResourceSearchResult } from '../../../shared/models/api/response';
import { getResourceListQueryKeyRoot, getSingleUserQueryKey } from '../../../shared/utils/resource-query-key';
import { displayErrors } from '../../../shared/utils/error';

const USER_NAME_STALE_TIME = 300e3;

export function useGetUserQuery(id: number | undefined) {
  const userService = useUserService();
  const { t } = useTranslation();

  return useQuery<BeUser, unknown, User>(getSingleUserQueryKey(id, 'full'), () => userService.fetchUserById(id), {
    enabled: id != null,
    select: convertUser,
    onError: error => displayErrors(error, t),
  });
}

export function useGetUserNameQuery(id: number | undefined) {
  const userService = useUserService();

  return useQuery<Pick<BeUser, UserIdentifierAttribute>, unknown>(
    getSingleUserQueryKey(id, 'name'),
    () => userService.fetchUserName(id),
    {
      staleTime: USER_NAME_STALE_TIME,
      enabled: id != null,
    }
  );
}

export function useFindUsersQuery(
  {
    page,
    pageSize,
    searchValue,
    filters,
    order,
    attributes = [
      'id',
      'username',
      'email',
      'firstName',
      'lastName',
      'mobilePhone',
      'status',
      'createdAt',
      'updatedAt',
    ],
  }: CommonFindAllQueryParams<Omit<User, 'password' | 'groupName'>, UsersFilters>,

  includes?: UserSearchIncludes,
  enabled = true
) {
  const userService = useUserService();

  return useQuery<ResourceSearchResult<User>, unknown>(
    [getResourceListQueryKeyRoot('USER'), { page, pageSize }, searchValue, filters, order, includes, attributes],
    async () => {
      const request: QueryRequest<Omit<User, 'password' | 'groupName'>, UsersFilters, UserSearchIncludes> = {
        numberOfResults: pageSize,
        startRow: (page - 1) * pageSize,
        attributes,
      };

      if (order?.length > 0) {
        request.order = order;
      }

      if (searchValue) {
        request.search = searchValue;
      }

      if (includes != null) {
        request.includes = includes;
      }

      if (filters) {
        const { rolesNames, ...filtersWithoutRoles } = filters;

        if (rolesNames?.length > 0) {
          request.includes.roles.names = rolesNames;
        }

        const convertedFilters = convertFilters(filtersWithoutRoles);

        if (convertedFilters != null) {
          request.filters = convertedFilters;
        }
      }

      const response = await userService.findUsers(request);
      return convertSearchResponse(response.data);
    },
    { enabled }
  );
}
