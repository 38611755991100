import { Checkbox, Descriptions, Divider, Drawer, DrawerProps, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { useToggle } from 'react-use';

import { Policy } from '../../../shared/models/policy';
import { formatISODate } from '../../../shared/utils/date';
import { getLanguageFromI18nLanguage } from '../../../shared/utils/language';
import { translationNamespace } from '../../constants/translation-resources';
import { Feature } from '../../../shared/models/features';
import { useLogsQueryForTimeline } from '../../../logs/data/queries/log-queries';
import { LogsTimeline } from '../../../shared/components/logs-timeline';
import { UserName } from '../../../shared/components/user-name';
import { LogsFilters } from '../../../logs/models/log';
import { getMethodTagColor } from '../../utils/color-mappers';

interface PolicyDetailsDrawerProps extends DrawerProps {
  policy: Policy;
}

export const PolicyDetailsDrawer = ({ policy, ...props }: PolicyDetailsDrawerProps): JSX.Element => {
  const { t, i18n } = useTranslation(translationNamespace);
  const [includeGetLogs, toggleIncludeGetLogs] = useToggle(false);
  const filters: LogsFilters = {
    entityType: 'policy',
    resourceId: [policy.id],
    method: includeGetLogs ? undefined : ['PATCH', 'POST', 'PUT', 'DELETE'],
  };
  const logQueryResult = useLogsQueryForTimeline(filters, { enabled: props.open });

  return (
    <Drawer {...props} title={t('policies.single.details')} width={400} placement="right" destroyOnClose>
      <Descriptions column={1} bordered css={withMarginBottom}>
        <Descriptions.Item label={t(`${Feature.SHARED}:name`)}>{policy.name}</Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:path`)}>{policy.path}</Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:method`)}>
          <Tag color={getMethodTagColor(policy.method)}>
            {policy.method != null && t(`${Feature.SHARED}:methods.${policy.method.toLowerCase()}`)}
          </Tag>
        </Descriptions.Item>

        <Descriptions.Item label={t(`${Feature.SHARED}:request`)}>{policy.request}</Descriptions.Item>

        <Descriptions.Item label={t(`${Feature.SHARED}:createdAt`)}>
          {policy.createdAt ? formatISODate(policy.createdAt, getLanguageFromI18nLanguage(i18n.language)) : ''}
        </Descriptions.Item>

        <Descriptions.Item label={t(`${Feature.SHARED}:createdBy`)}>
          <UserName userId={logQueryResult.isLoading ? undefined : policy.createdBy} />
        </Descriptions.Item>

        <Descriptions.Item label={t(`${Feature.SHARED}:updatedAt`)}>
          {policy.updatedAt ? formatISODate(policy.updatedAt, getLanguageFromI18nLanguage(i18n.language)) : ''}
        </Descriptions.Item>

        <Descriptions.Item label={t(`${Feature.SHARED}:updatedBy`)}>
          <UserName userId={logQueryResult.isLoading ? undefined : policy.updatedBy} />
        </Descriptions.Item>
      </Descriptions>

      <Divider>{t(`${Feature.SHARED}:log`)}</Divider>
      <Checkbox checked={includeGetLogs} onChange={toggleIncludeGetLogs} css={withMarginBottom}>
        {t(`${Feature.SHARED}:includeGetLogs`)}
      </Checkbox>
      <LogsTimeline queryResult={logQueryResult} />
    </Drawer>
  );
};

const withMarginBottom = css`
  margin-bottom: 32px;
`;
