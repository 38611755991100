import { Button, Space, Typography } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { translationNamespace } from '../../../constants/translation-resources';

const { Title, Text } = Typography;

export const Finish: FC = () => {
  const { t } = useTranslation(translationNamespace);

  return (
    <StyledSpace direction="vertical">
      <Title level={2}>{t('forgotPasswordView.steps.finish.title')}</Title>
      <Text type="secondary">{t('forgotPasswordView.steps.finish.description')}</Text>
      <ButtonGroup>
        <Link to="/auth/login">
          <StyledButton type="primary" icon={<ArrowRightOutlined />}>
            {t('shared.login')}
          </StyledButton>
        </Link>
      </ButtonGroup>
    </StyledSpace>
  );
};

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

const StyledButton = styled(Button)`
  margin-top: 16px;
`;

const StyledSpace = styled(Space)`
  width: 100%;
`;
