import { FindAllPoliciesResponse, PolicyTemplate } from '@pccr-ifc/pccr-ifc';

import { Policy } from '../../../shared/models/policy';
import { convertDateRangeToISO } from '../../../shared/utils/date';
import { PoliciesTableFilters } from '../../components/policies-table';
import { PoliciesFilters } from '../../models/policy';
import { ResourceSearchResult } from '../../../shared/models/api/response';
import { PolicyUpsert } from '../../models/policy';
import { GeneratePolicyFormValues } from '../../components/generate-policy-modal/generate-policy-modal';
import { isRequestArrayPattern } from '../../utils/policy-utils';

export function convertSearchResponse(response: FindAllPoliciesResponse): ResourceSearchResult<Policy> {
  return {
    total: response.total,
    resources: response.policies as Policy[],
  };
}

export function convertTableFiltersToFilters(tableFilters: PoliciesTableFilters): PoliciesFilters {
  const policiesFilters: PoliciesFilters = {
    name: tableFilters.name,
    method: tableFilters.method,
    path: tableFilters.path,
  };

  if (tableFilters.createdAt != null) {
    policiesFilters.createdAt = convertDateRangeToISO(tableFilters.createdAt);
  }
  if (tableFilters.updatedAt != null) {
    policiesFilters.updatedAt = convertDateRangeToISO(tableFilters.updatedAt);
  }

  return policiesFilters;
}

export function convertValuesToNewPolicy(
  formValues: GeneratePolicyFormValues,
  template: PolicyTemplate
): Omit<PolicyUpsert, 'id'> {
  const requestValue = isRequestArrayPattern(template) ? `${[...formValues.request]}` : `${formValues.request[0]}`;

  const regex = /\{\*\}/;
  const request = template.request.replace(regex, requestValue);

  const newPolicy = {
    name: formValues.name,
    method: template.method,
    path: template.path,
    request,
  };

  return newPolicy;
}
