import { TableColumnType } from 'antd';
import { TFunction } from 'react-i18next';

import { LinkedName } from '../../../shared/components/linked-name';
import { Definition } from '../../../shared/models/definition';
import { Feature } from '../../../shared/models/features';
import { Language } from '../../../shared/models/language';
import { formatISODate } from '../../../shared/utils/date';
import { getDefinitionStatusTagColor } from '../../utils/color-mappers';
import { Actions } from './actions';
import { CustomTag } from '../../../shared/components/tag';
import { DefinitionStatuses } from '../../../shared/constants/definition';
import iconValid from '../../assets/images/icon_done.svg';
import iconInvalid from '../../assets/images/icon_invalid.svg';
import { AppResources } from '../../../shared/constants/app-resources';
import { PermissionsState } from '../../../shared/data/store';

export const getColumns = (
  t: TFunction<Feature.REPORTS>,
  language: Language,
  permissions: PermissionsState
): TableColumnType<Definition>[] => {
  return [
    {
      title: t(`${Feature.SHARED}:name`),
      dataIndex: 'name',
      render: (name: Definition['name'], definition: Definition) => (
        <LinkedName name={name} to={`definitions/${definition.id}`} />
      ),
      sorter: true,
      width: 350,
    },
    {
      title: t(`${Feature.SHARED}:tenant.label`),
      dataIndex: 'groupName',
      width: 350,
    },
    {
      title: t(`${Feature.SHARED}:status`),
      dataIndex: 'status',
      render: (status: Definition['status']) => {
        const icon = status === DefinitionStatuses.VALID ? iconValid : iconInvalid;
        return status ? (
          <CustomTag color={getDefinitionStatusTagColor(status)} icon={icon}>
            {t(`shared.status.${status.toLowerCase()}`)}
          </CustomTag>
        ) : (
          ''
        );
      },
      sorter: true,
      width: 120,
    },
    {
      title: t(`${Feature.SHARED}:createdAt`),
      dataIndex: 'createdAt',
      render: (createdAt: Definition['createdAt']) => formatISODate(createdAt, language),
      sorter: true,
      width: 130,
      defaultSortOrder: 'descend',
    },
    {
      title: t(`${Feature.SHARED}:updatedAt`),
      dataIndex: 'updatedAt',
      render: (updatedAt: Definition['updatedAt']) => formatISODate(updatedAt, language),
      sorter: true,
      width: 130,
    },
    {
      key: 'action',
      align: 'right',
      render: (_, definition) => <Actions definition={definition} />,
      width: 130,
      hidden: !permissions[AppResources.DEFINITION]['UPDATE'] && !permissions[AppResources.DEFINITION]['DELETE'],
    },
  ];
};
