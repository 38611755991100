import { Modal, ModalProps, Space } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Feature } from '../../../shared/models/features';
import { translationNamespace } from '../../constants/translation-resources';

type Props = ModalProps & {
  content?: string;
};

export const ConfirmationModal: FC<Props> = ({ title, content, children, ...props }) => {
  const { t } = useTranslation(translationNamespace);

  return (
    <StyledModal
      {...props}
      centered
      title={
        <Space size="middle" align="start">
          <StyledExclamationCircleOutlined />
          {title}
        </Space>
      }
      okText={t(`${Feature.SHARED}:confirm`)}
      cancelText={t(`${Feature.SHARED}:cancel`)}
      width={400}
    >
      {content && <StyledParagraph>{content}</StyledParagraph>}
      {children}
    </StyledModal>
  );
};

const StyledExclamationCircleOutlined = styled(ExclamationCircleOutlined)`
  font-size: 22px;
  color: ${props => props.theme.shared.confirmationModal.icon.color};
`;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    margin-left: 38px;
  }

  .ant-modal-header,
  .ant-modal-footer {
    border: 0;
  }
`;

const StyledParagraph = styled.p`
  margin-bottom: 0;
`;
