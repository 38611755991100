import { DefinitionsFilters, DefinitionUpsert } from '../../../shared/models/definition';
import { convertDateRangeToISO } from '../../../shared/utils/date';
import { DefinitionUpsertFormField, DefinitionUpsertFormValues } from '../../components/definition-upsert-form';
import { DefinitionsTableFilters } from '../../components/definitions-table';

function convertFormValuesToDefinition(values: DefinitionUpsertFormValues): Omit<DefinitionUpsert, 'id'>;
function convertFormValuesToDefinition(
  values: Partial<DefinitionUpsertFormValues>
): Partial<Omit<DefinitionUpsert, 'id'>>;
function convertFormValuesToDefinition(
  values: Partial<DefinitionUpsertFormValues>
): Partial<Omit<DefinitionUpsert, 'id'>> {
  const definition: Omit<DefinitionUpsert, 'id'> = {
    name: values.name,
    type: values.type,
    groupId: values.tenant,
    sourceId: values.source,
    sql: values.sql,
  };

  if (values.sqlParams?.length > 0) {
    const sqlParams = values.sqlParams.reduce((map, param) => {
      const key = param?.[DefinitionUpsertFormField.SQL_PARAM_KEY];
      const value = param?.[DefinitionUpsertFormField.SQL_PARAM_VALUE];

      if (key != null) {
        map = {
          ...map,
          [key]: value,
        };
      }

      return map;
    }, {});

    definition.sqlParams = sqlParams;
  }

  return definition;
}
export { convertFormValuesToDefinition };

export function convertTableFiltersToFilters(tableFilters: DefinitionsTableFilters): DefinitionsFilters {
  const definitionFilters: DefinitionsFilters = { name: tableFilters.name, status: tableFilters.status };

  if (tableFilters.createdAt != null) {
    definitionFilters.createdAt = convertDateRangeToISO(tableFilters.createdAt);
  }
  if (tableFilters.updatedAt != null) {
    definitionFilters.updatedAt = convertDateRangeToISO(tableFilters.updatedAt);
  }

  return definitionFilters;
}
