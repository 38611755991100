import { TableColumnType } from 'antd';
import { TFunction } from 'react-i18next';

import { LinkedName } from '../../../shared/components/linked-name';
import { Feature } from '../../../shared/models/features';
import { Language } from '../../../shared/models/language';
import { formatISODate } from '../../../shared/utils/date';
import { Source } from '../../models/source';
import { getSourceStatusTagColor, getDialectTagColor } from '../../utils/color-mappers';
import { Actions } from './actions';
import { CustomTag } from '../../../shared/components/tag';
import { SourceStatuses } from '../../constants/source';
import iconValid from '../../assets/images/icon_done.svg';
import iconInvalid from '../../assets/images/icon_invalid.svg';
import { Dialects } from '../../constants/dialect';
import mssqlIcon from '../../assets/images/dialects/icon_ms_sql.svg';
import mysqlIcon from '../../assets/images/dialects/icon_my_sql.svg';
import postgresIcon from '../../assets/images/dialects/icon_postgre_sql.svg';
import { AppResources } from '../../../shared/constants/app-resources';
import { PermissionsState } from '../../../shared/data/store';

const DIALECT_NAMES = {
  mssql: 'MS SQL',
  mysql: 'MySQL',
  postgres: 'PostgreSQL',
} as const;

export const getColumns = (
  t: TFunction<Feature.REPORTS>,
  language: Language,
  permissions: PermissionsState
): TableColumnType<Source>[] => {
  return [
    {
      title: t(`${Feature.SHARED}:name`),
      dataIndex: 'name',
      render: (name: Source['name'], source: Source) => <LinkedName name={name} to={`sources/${source.id}`} />,
      sorter: true,
    },
    {
      title: t('sources.shared.dialect'),
      dataIndex: 'dialect',
      render: (dialect: Source['dialect']) => {
        const icon = dialect === Dialects.MSSQL ? mssqlIcon : dialect === Dialects.MYSQL ? mysqlIcon : postgresIcon;

        return dialect ? (
          <CustomTag color={getDialectTagColor(dialect)} icon={icon}>
            {DIALECT_NAMES[dialect]}
          </CustomTag>
        ) : (
          ''
        );
      },
      sorter: true,
      width: 120,
    },
    {
      title: t('sources.shared.address.column'),
      dataIndex: 'host',
      key: 'address',
      render: (host: Source['host'], source: Source) => `${host}:${source.port}`,
      sorter: true,
    },
    {
      title: t('sources.shared.databasename.label'),
      dataIndex: 'databasename',
      sorter: true,
      width: 140,
    },
    {
      title: t(`${Feature.SHARED}:status`),
      dataIndex: 'status',
      render: (status: Source['status']) => {
        const icon = status === SourceStatuses.VALID ? iconValid : iconInvalid;
        return status ? (
          <CustomTag color={getSourceStatusTagColor(status)} icon={icon}>
            {t(`shared.status.${status.toLowerCase()}`)}
          </CustomTag>
        ) : (
          ''
        );
      },
      sorter: true,
      width: 120,
    },
    {
      title: t(`${Feature.SHARED}:createdAt`),
      dataIndex: 'createdAt',
      render: (createdAt: Source['createdAt']) => formatISODate(createdAt, language),
      sorter: true,
      width: 130,
      defaultSortOrder: 'descend',
    },
    {
      title: t(`${Feature.SHARED}:updatedAt`),
      dataIndex: 'updatedAt',
      render: (updatedAt: Source['updatedAt']) => formatISODate(updatedAt, language),
      sorter: true,
      width: 130,
    },
    {
      key: 'action',
      align: 'right',
      render: (_, source) => <Actions source={source} />,
      width: 130,
      hidden: !permissions[AppResources.SOURCE]['UPDATE'] && !permissions[AppResources.SOURCE]['DELETE'],
    },
  ];
};
