import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { Policy as BePolicy } from '@pccr-ifc/pccr-ifc';
import { useTranslation } from 'react-i18next';

import { PickSomeRestPartial } from '../../../shared/utils/types';
import { usePolicyService } from '../services/use-policy-service';
import { PolicyUpsert } from '../../models/policy';
import { Policy } from '../../../shared/models/policy';
import {
  onCreateResourceMutationSuccess,
  onDeleteResourceMutationSuccess,
  onUpdateResourceMutationSuccess,
} from '../../../shared/utils/mutation';
import { displayErrors } from '../../../shared/utils/error';

export function useCreatePolicyMutation() {
  const policyService = usePolicyService();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse<BePolicy>, unknown, Omit<PolicyUpsert, 'id'>>(
    policy => policyService.createPolicy({ policy }),
    {
      onSuccess(response) {
        onCreateResourceMutationSuccess(queryClient, 'POLICY', response.data);
      },
      onError(error) {
        displayErrors(error, t);
      },
    }
  );
}

export function useUpdatePolicyMutation() {
  const policyService = usePolicyService();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse<BePolicy>, unknown, PickSomeRestPartial<PolicyUpsert, 'id'>>(
    policy => policyService.updatePolicy(policy),
    {
      onSuccess(response, vars) {
        onUpdateResourceMutationSuccess(queryClient, 'POLICY', response.data, vars.id);
      },
      onError(error) {
        displayErrors(error, t);
      },
    }
  );
}

export function useDeletePolicyMutation() {
  const policyService = usePolicyService();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse<void>, unknown, Policy['id']>(id => policyService.deletePolicy(id), {
    onSuccess(_response, id) {
      onDeleteResourceMutationSuccess(queryClient, 'POLICY', id);
    },
    onError(error) {
      displayErrors(error, t);
    },
  });
}
