import { ThemeConfig } from 'antd';
import { ComponentStyleConfig } from 'antd/es/config-provider/context';

import { colors } from '../../shared/constants/styles';

export const configProviderTheme: ThemeConfig = {
  token: {
    colorBgContainer: colors.white,
    colorPrimaryBg: colors.grey,
    colorPrimary: colors.primary,
    borderRadius: 0,
  },
  components: {
    Table: {
      cellPaddingBlockMD: 0,
      rowHoverBg: colors.hoverGray,
      //uncomment it after a confirmation of new design
      // headerColor: colors.white,
      // headerBg: colors.primary,
      // headerSortHoverBg: colors.skyBlue,
      // headerSortActiveBg: colors.skyBlue,
      // headerSplitColor: colors.white,
    },
    Input: {
      activeBorderColor: colors.primary,
      hoverBorderColor: colors.primary,
      activeShadow: 'none',
    },
  },
};

export const configProviderTypography: ComponentStyleConfig = {
  style: {
    fontFamily: "'Roboto', 'Open Sans', 'Helvetica Neue', 'Lucida Grande', Helvetica, Arial, sans-serif",
  },
};
