import { TableColumnType } from 'antd';
import { TFunction } from 'react-i18next';

import { Feature } from '../../../shared/models/features';
import { Language } from '../../../shared/models/language';
import { Log } from '../../../shared/models/log';
import { formatISODate } from '../../../shared/utils/date';

export const getColumns = (t: TFunction<Feature.LOGS>, language: Language): TableColumnType<Log>[] => {
  return [
    {
      title: t(`${Feature.SHARED}:path`),
      dataIndex: 'path',
      sorter: true,
    },
    {
      title: t(`${Feature.SHARED}:method`),
      dataIndex: 'method',
      sorter: true,
    },
    {
      title: t('list.logsTable.userId'),
      dataIndex: 'userId',
      sorter: true,
    },
    {
      title: t('list.logsTable.resourceId'),
      dataIndex: 'resourceId',
      sorter: true,
    },
    {
      title: t(`${Feature.SHARED}:createdAt`),
      dataIndex: 'createdAt',
      render: (createdAt: Log['createdAt']) => formatISODate(createdAt, language),
      sorter: true,
      width: 130,
      defaultSortOrder: 'descend',
    },
  ];
};
