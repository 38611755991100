import { reportsDefaultTheme } from '../../reports/themes/reports-default-theme';
import { Feature } from '../../shared/models/features';
import { AppTheme } from '../../shared/models/theme';
import { sharedDefaultTheme } from '../../shared/themes/shared-default-theme';
import { coreDefaultTheme } from './core-default-theme';

export const defaultTheme: AppTheme = {
  [Feature.CORE]: coreDefaultTheme,
  [Feature.REPORTS]: reportsDefaultTheme,
  [Feature.SHARED]: sharedDefaultTheme,
};
