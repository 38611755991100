import { TableColumnType, Tag } from 'antd';
import { TFunction } from 'react-i18next';

import { LinkedName } from '../../../shared/components/linked-name';
import { Feature } from '../../../shared/models/features';
import { Language } from '../../../shared/models/language';
import { formatISODate } from '../../../shared/utils/date';
import { Job } from '../../models/job';
import { getJobStatusTagColor } from '../../utils/color-mappers';
import { formatCron } from '../../utils/cron';
import { Actions } from './actions';
import { AppResources } from '../../../shared/constants/app-resources';
import { PermissionsState } from '../../../shared/data/store';

export const getColumns = (
  t: TFunction<Feature.AUTOMATION>,
  language: Language,
  permissions: PermissionsState
): TableColumnType<Job>[] => {
  return [
    {
      title: t('jobs.list.jobsTable.name'),
      dataIndex: 'name',
      render: (name: Job['name'], job: Job) => <LinkedName name={name} to={`automation/${job.id}`} />,
      sorter: true,
    },
    {
      title: t('jobs.shared.schedule'),
      dataIndex: 'cron',
      render: (cron: Job['cron']) => formatCron(cron, language),
    },
    {
      title: t('jobs.shared.cron.label'),
      dataIndex: 'cron',
      width: 140,
    },
    {
      title: t('shared.enabled'),
      dataIndex: 'enabled',
      render: (enabled: Job['enabled']) => (
        <Tag color={enabled === true ? 'green' : 'red'}>{t(`${Feature.SHARED}:${enabled ? `yes` : `no`}`)}</Tag>
      ),
      sorter: true,
      width: 100,
    },
    {
      title: t(`${Feature.SHARED}:status`),
      dataIndex: 'status',
      render: (status: Job['status']) =>
        status != null && (
          <Tag color={getJobStatusTagColor(status)}>{t(`jobs.shared.status.${status.toLowerCase()}`)}</Tag>
        ),
      sorter: true,
      width: 110,
    },
    {
      title: t(`${Feature.SHARED}:createdAt`),
      dataIndex: 'createdAt',
      render: (createdAt: Job['createdAt']) => formatISODate(createdAt, language),
      sorter: true,
      width: 130,
      defaultSortOrder: 'descend',
    },
    {
      title: t(`${Feature.SHARED}:updatedAt`),
      dataIndex: 'updatedAt',
      render: (updatedAt: Job['updatedAt']) => formatISODate(updatedAt, language),
      sorter: true,
      width: 130,
    },
    {
      key: 'action',
      align: 'right',
      render: (_, job) => <Actions job={job} />,
      width: 130,
      hidden: !permissions[AppResources.JOB]['UPDATE'] && !permissions[AppResources.JOB]['DELETE'],
    },
  ];
};
