import { TFunction } from 'i18next';
import { ValidateMessages } from 'rc-field-form/lib/interface';
import { Language } from '../../shared/models/language';
import { i18nAntdLocaleMap } from '../constants/languages';

export const getAntdLocale = (language: string) => {
  switch (language) {
    case Language.EnglishGB:
      return i18nAntdLocaleMap[Language.EnglishGB];
    case Language.German:
      return i18nAntdLocaleMap[Language.German];
    default:
      return i18nAntdLocaleMap[Language.EnglishGB];
  }
};

export const getAntdValidateMessages = (t: TFunction): ValidateMessages => {
  return {
    default: t('validateMessages.default'),
    required: t('validateMessages.required'),
    enum: t('validateMessages.enum'),
    whitespace: t('validateMessages.whitespace'),
    date: {
      format: t('validateMessages.date.format'),
      parse: t('validateMessages.date.parse'),
      invalid: t('validateMessages.date.invalid'),
    },
    types: {
      string: t('validateMessages.types.common'),
      method: t('validateMessages.types.common'),
      array: t('validateMessages.types.common'),
      object: t('validateMessages.types.common'),
      number: t('validateMessages.types.common'),
      date: t('validateMessages.types.common'),
      boolean: t('validateMessages.types.common'),
      integer: t('validateMessages.types.common'),
      float: t('validateMessages.types.common'),
      regexp: t('validateMessages.types.common'),
      email: t('validateMessages.types.common'),
      url: t('validateMessages.types.common'),
      hex: t('validateMessages.types.common'),
    },
    string: {
      len: t('validateMessages.string.len'),
      min: t('validateMessages.string.min'),
      max: t('validateMessages.string.max'),
      range: t('validateMessages.string.range'),
    },
    number: {
      len: t('validateMessages.number.len'),
      min: t('validateMessages.number.min'),
      max: t('validateMessages.number.max'),
      range: t('validateMessages.number.range'),
    },
    array: {
      len: t('validateMessages.array.len'),
      min: t('validateMessages.array.min'),
      max: t('validateMessages.array.max'),
      range: t('validateMessages.array.range'),
    },
    pattern: {
      mismatch: t('validateMessages.pattern.mismatch'),
    },
  };
};
