import { Checkbox, Descriptions, Divider, Drawer, DrawerProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { useToggle } from 'react-use';

import { formatISODate } from '../../../shared/utils/date';
import { getLanguageFromI18nLanguage } from '../../../shared/utils/language';
import { translationNamespace } from '../../constants/translation-resources';
import { Preset } from '../../models/preset';
import { Feature } from '../../../shared/models/features';
import { useLogsQueryForTimeline } from '../../../logs/data/queries/log-queries';
import { LogsTimeline } from '../../../shared/components/logs-timeline';
import { UserName } from '../../../shared/components/user-name';
import { LogsFilters } from '../../../logs/models/log';
import { useGetDefinitionQuery } from '../../../shared/data/queries/definition-queries';

interface PresetDetailsDrawerProps extends DrawerProps {
  preset: Preset;
}

export const PresetDetailsDrawer = ({ preset, ...props }: PresetDetailsDrawerProps) => {
  const { t, i18n } = useTranslation(translationNamespace);
  const [includeGetLogs, toggleIncludeGetLogs] = useToggle(false);
  const filters: LogsFilters = {
    resourceId: [preset.id],
    entityType: 'preset',
    method: includeGetLogs ? undefined : ['PATCH', 'POST', 'PUT', 'DELETE'],
  };
  const logQueryResult = useLogsQueryForTimeline(filters, { enabled: props.open });

  const { data: definition } = useGetDefinitionQuery(preset?.definitionId);

  return (
    <Drawer {...props} title={t(`${Feature.SHARED}:details`)} width={400} placement="right" destroyOnClose>
      <Descriptions column={1} bordered css={withMarginBottom}>
        <Descriptions.Item label={t(`${Feature.SHARED}:name`)}>{preset.name}</Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:definition.label`)}>{definition?.name ?? ''}</Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:createdAt`)}>
          {preset.createdAt ? formatISODate(preset.createdAt, getLanguageFromI18nLanguage(i18n.language)) : ''}
        </Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:createdBy`)}>
          <UserName userId={logQueryResult.isLoading ? undefined : preset.createdBy} />
        </Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:updatedAt`)}>
          {preset.updatedAt ? formatISODate(preset.updatedAt, getLanguageFromI18nLanguage(i18n.language)) : ''}
        </Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:updatedBy`)}>
          <UserName userId={logQueryResult.isLoading ? undefined : preset.createdBy} />
        </Descriptions.Item>
      </Descriptions>
      <Divider>{t(`${Feature.SHARED}:log`)}</Divider>
      <Checkbox checked={includeGetLogs} onChange={toggleIncludeGetLogs} css={withMarginBottom}>
        {t(`${Feature.SHARED}:includeGetLogs`)}
      </Checkbox>
      <LogsTimeline queryResult={logQueryResult} />
    </Drawer>
  );
};

const withMarginBottom = css`
  margin-bottom: 32px;
`;
