import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { ExportReportResponse, GenerateReportResponse } from '@pccr-ifc/pccr-ifc';
import { useTranslation } from 'react-i18next';

import { Report } from '../../models/report';
import { useReportService } from '../services/use-report-service';
import { ExportedReportFile } from '../../models/export';
import { onDeleteResourceMutationSuccess } from '../../../shared/utils/mutation';
import { GenerateReportData } from '../../../shared/models/api/report';
import { getResourceListQueryKeyRoot, getSingleResourceQueryKey } from '../../../shared/utils/resource-query-key';
import { ExportReportRequest } from '../../models/api/export';
import { displayErrors } from '../../../shared/utils/error';

export function useGenerateReportMutation() {
  const reportService = useReportService();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse<GenerateReportResponse>, unknown, GenerateReportData>(
    data => reportService.generateReport({ report: data }),
    {
      onSuccess() {
        queryClient.invalidateQueries(getResourceListQueryKeyRoot('REPORT'));
      },
      onError(error) {
        displayErrors(error, t);
      },
    }
  );
}

export function useDeleteReportMutation() {
  const reportService = useReportService();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse<void>, unknown, Report['uuid']>(uuid => reportService.deleteReport(uuid), {
    onSuccess(_response, uuid) {
      onDeleteResourceMutationSuccess(queryClient, 'REPORT', uuid);
    },
    onError(error) {
      displayErrors(error, t);
    },
  });
}

export function useExportReportMutation() {
  const reportService = useReportService();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse<ExportReportResponse>, unknown, { uuid: string; request: ExportReportRequest }>(
    ({ uuid, request }) => reportService.exportReport(uuid, request),
    {
      onSuccess(_response, vars) {
        queryClient.invalidateQueries(getSingleResourceQueryKey('REPORT', vars.uuid));
      },
      onError(error) {
        displayErrors(error, t);
      },
    }
  );
}

export function useDownloadReportFileMutation() {
  const reportService = useReportService();
  const { t } = useTranslation();

  return useMutation<void, unknown, number>(
    async fileId => {
      const { data } = await reportService.getPresignedUrl(fileId);
      window.open(data.url, '_blank', 'noreferrer');
    },
    {
      onError(error) {
        displayErrors(error, t);
      },
    }
  );
}

export function useDeleteReportFileMutation(uuid) {
  const reportService = useReportService();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse<void>, unknown, { fileId: ExportedReportFile['id'] }>(
    ({ fileId }) => reportService.deleteReportFile(fileId),
    {
      onSuccess() {
        queryClient.invalidateQueries(getSingleResourceQueryKey('REPORT', uuid));
      },
      onError(error) {
        displayErrors(error, t);
      },
    }
  );
}
