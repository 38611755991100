import { createContext, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '../../../shared/hooks/use-query';
import { AuthQueryParamKey } from '../../models/params';
import { steps } from './steps';

interface ForgotPasswordContextProviderProps {
  currentStep: number;
  setCurrentStep: (step: number) => void;
}

export enum ForgotPasswordFormField {
  EMAIL = 'email',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirmPassword',
}

export interface ForgotPasswordFormValues {
  [ForgotPasswordFormField.EMAIL]?: string;
  [ForgotPasswordFormField.PASSWORD]?: string;
  [ForgotPasswordFormField.CONFIRM_PASSWORD]?: string;
}

interface ForgotPasswordContextType {
  currentStep: number;
  token: string;
  nextStep: () => void;
  previousStep: () => void;
  setValues: (values: ForgotPasswordFormValues) => void;
  formValues: ForgotPasswordFormValues;
}

export const ForgotPasswordContext = createContext<ForgotPasswordContextType>({
  currentStep: 0,
  token: null,
  nextStep: () => {},
  previousStep: () => {},
  setValues: () => {},
  formValues: null,
});

export const ForgotPasswordContextProvider: FC<ForgotPasswordContextProviderProps> = ({
  children,
  currentStep,
  setCurrentStep,
}) => {
  const [formValues, setFormValues] = useState<ForgotPasswordFormValues>(null);
  const [tokenStepSet, setTokenStepSet] = useState(false);
  const query = useQuery();
  const token = query.get(AuthQueryParamKey.TOKEN);

  const nextStep = useCallback(() => {
    const next = currentStep + 1;
    if (next < steps.length) {
      setCurrentStep(currentStep + 1);
    }
  }, [currentStep, setCurrentStep]);

  const previousStep = useCallback(() => {
    const prev = currentStep - 1;
    if (prev >= 0) {
      setCurrentStep(prev);
    }
  }, [currentStep, setCurrentStep]);

  const setValues = useCallback((values: ForgotPasswordFormValues) => {
    if (values != null) {
      setFormValues(formVals => {
        return formVals != null ? { ...formVals, ...values } : values;
      });
    }
  }, []);

  const value = useMemo(() => {
    return { currentStep, nextStep, previousStep, setValues, token, formValues };
  }, [currentStep, nextStep, previousStep, setValues, token, formValues]);

  useEffect(() => {
    if (!tokenStepSet && token?.length > 0) {
      const tokenStepIndex = steps.findIndex(step => {
        return step.tokenStep === true;
      });
      setCurrentStep(tokenStepIndex > -1 ? tokenStepIndex : steps.length - 1);
      setTokenStepSet(true);
    }
  }, [setCurrentStep, token?.length, tokenStepSet]);

  return <ForgotPasswordContext.Provider value={value}>{children}</ForgotPasswordContext.Provider>;
};
