export const AppResources = {
  REPORT: 'REPORT',
  PRESET: 'PRESET',
  DEFINITION: 'DEFINITION',
  VIEW: 'VIEW',
  SOURCE: 'SOURCE',
  JOB: 'JOB',
  TENANT: 'TENANT',
  USER: 'USER',
  POLICY: 'POLICY',
  ROLE: 'ROLE',
  LOG: 'LOG',
  DOCUMENT: 'DOCUMENT',
  BATCH: 'BATCH',
} as const;

export const AppResourcePaths = {
  reports: '/reports/list',
  presets: '/reports/presets',
  definitions: '/reports/definitions',
  views: '/reports/views',
  sources: '/reports/sources',
  jobs: '/automation',
  tenants: '/access/tenants',
  users: '/access/users',
  policies: '/access/policies',
  roles: '/access/roles',
  logs: '/logs',
  documents: '/documents',
} as const;

export const AllowedRoutesPaths = {
  REPORT: '/report/data/',
  PRESET: '/report/preset/',
  DEFINITION: '/report/definition/',
  VIEW: '/report/view/',
  SOURCE: '/report/source/',
  JOB: '/schedule/',
  TENANT: '/group/',
  USER: '/user/',
  POLICY: '/policy/',
  ROLE: '/role/',
  LOG: '/history/',
  BATCH: '/approval/',
} as const;
