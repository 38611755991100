import { Button, Card, Col, Form, message, Row, Space } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { CheckOutlined } from '@ant-design/icons';

import { useQuery } from '../../../shared/hooks/use-query';
import { CommonQueryParamKey } from '../../../shared/models/common-query-params';
import { Feature } from '../../../shared/models/features';
import { RoleUpsertForm, RoleUpsertFormValues } from '../../components/role-upsert-form';
import { translationNamespace } from '../../constants/translation-resources';
import { convertFormValuesToRole } from '../../data/converters/role-converters';
import { useCreateRoleMutation } from '../../data/mutations/role-mutations';
import { AppResourcePaths } from '../../../shared/constants/app-resources';

export const RoleNewView: FC = () => {
  const { t } = useTranslation(translationNamespace);
  const { push: routerPush } = useHistory();
  const [form] = Form.useForm<RoleUpsertFormValues>();
  const query = useQuery();
  const mutation = useCreateRoleMutation();

  const parsedPrev = query.get(CommonQueryParamKey.PREV);
  const prevUrl = parsedPrev ?? AppResourcePaths.roles;

  const submit = (values: RoleUpsertFormValues) => {
    const newRole = convertFormValuesToRole(values);

    mutation.mutate(newRole, {
      onSuccess: () => {
        message.success(t('roles.shared.createSuccessMessage'));
        routerPush(parsedPrev ?? `${AppResourcePaths.roles}`);
      },
    });
  };

  const triggerSubmit = () => {
    form.submit();
  };

  return (
    <Row gutter={[16, 24]}>
      <Col span={24}>
        <Card title={t(`${Feature.SHARED}:details`)}>
          <RoleUpsertForm form={form} onSubmit={submit} />
        </Card>
      </Col>
      <Col>
        <Space>
          <Button type="primary" htmlType="submit" size="large" icon={<CheckOutlined />} onClick={triggerSubmit}>
            {t(`${Feature.SHARED}:create`)}
          </Button>
          <Link to={prevUrl}>
            <Button htmlType="button" size="large">
              {t(`${Feature.SHARED}:cancel`)}
            </Button>
          </Link>
        </Space>
      </Col>
    </Row>
  );
};
