import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { RefreshToken } from '@pccr-ifc/pccr-ifc';

import { useSessionService } from '../services/use-session-service';
import { displayErrors } from '../../../shared/utils/error';

export enum SessionQueryKey {
  VALIDATE = 'validate',
}

export function useGetSessionDataQuery(loggedIn: boolean) {
  const sessionService = useSessionService();
  const { t } = useTranslation();

  return useQuery<RefreshToken, unknown>(
    SessionQueryKey.VALIDATE,
    async () => {
      const response = await sessionService.validate();
      return response.data.refreshToken;
    },
    { refetchOnWindowFocus: false, enabled: loggedIn, onError: error => displayErrors(error, t) }
  );
}
