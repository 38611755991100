import { MaxMediaQueryType, MinMediaQueryType } from '../models/media-queries';
import { maxAvailableBreakpoints, minAvailableBreakpoints } from './breakpoints';

const minMediaQuery = {} as MinMediaQueryType;
const maxMediaQuery = {} as MaxMediaQueryType;

(Object.keys(minAvailableBreakpoints) as Array<keyof MinMediaQueryType>).forEach(
  breakpointKey => (minMediaQuery[breakpointKey] = `@media (min-width: ${minAvailableBreakpoints[breakpointKey]}px)`)
);

(Object.keys(maxAvailableBreakpoints) as Array<keyof MaxMediaQueryType>).forEach(
  breakpointKey => (maxMediaQuery[breakpointKey] = `@media (max-width: ${maxAvailableBreakpoints[breakpointKey]}px)`)
);

export { minMediaQuery, maxMediaQuery };
