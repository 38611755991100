import { Checkbox, Descriptions, Divider, Drawer, DrawerProps, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { useToggle } from 'react-use';

import { useGetTenantQuery } from '../../../access/data/queries/tenant-queries';
import { useLogsQueryForTimeline } from '../../../logs/data/queries/log-queries';
import { LogsTimeline } from '../../../shared/components/logs-timeline';
import { UserName } from '../../../shared/components/user-name';
import { Feature } from '../../../shared/models/features';
import { formatISODate } from '../../../shared/utils/date';
import { getLanguageFromI18nLanguage } from '../../../shared/utils/language';
import { translationNamespace } from '../../constants/translation-resources';
import { getSourceStatusTagColor } from '../../utils/color-mappers';
import { Source } from '../../models/source';
import { LogsFilters } from '../../../logs/models/log';

interface SourceDetailsDrawerProps extends DrawerProps {
  source: Source;
}

export const SourceDetailsDrawer = ({ source, ...props }: SourceDetailsDrawerProps) => {
  const { t, i18n } = useTranslation(translationNamespace);
  const { data: tenant } = useGetTenantQuery(source.groupId, props.open);
  const [includeGetLogs, toggleIncludeGetLogs] = useToggle(false);
  const filters: LogsFilters = {
    entityType: 'source',
    resourceId: [source.id],
    method: includeGetLogs ? undefined : ['PATCH', 'POST', 'PUT', 'DELETE'],
  };
  const logQueryResult = useLogsQueryForTimeline(filters, { enabled: props.open });

  return (
    <Drawer {...props} title={t(`${Feature.SHARED}:details`)} width={400} placement="right" destroyOnClose>
      <Descriptions column={1} bordered css={withMarginBottom}>
        <Descriptions.Item label={t(`${Feature.SHARED}:name`)}>{source.name}</Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:tenant.label`)}>{tenant?.name ?? ''}</Descriptions.Item>
        <Descriptions.Item label={t('sources.shared.dialect')}>{source.dialect}</Descriptions.Item>
        <Descriptions.Item label={t('sources.shared.address.label')}>
          {source.host}:{source.port}
        </Descriptions.Item>
        <Descriptions.Item label={t('sources.shared.databasename.label')}>{source.databasename}</Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:username.label`)}>{source.username}</Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:status`)}>
          {source.status ? (
            <Tag color={getSourceStatusTagColor(source.status)}>
              {t(`shared.status.${source.status.toLowerCase()}`)}
            </Tag>
          ) : (
            ''
          )}
        </Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:error`)}>{source.error}</Descriptions.Item>
        <Descriptions.Item label={t(`${Feature.SHARED}:createdAt`)}>
          {source.createdAt ? formatISODate(source.createdAt, getLanguageFromI18nLanguage(i18n.language)) : ''}
        </Descriptions.Item>

        <Descriptions.Item label={t(`${Feature.SHARED}:createdBy`)}>
          <UserName userId={logQueryResult.isLoading ? undefined : source.createdBy} />
        </Descriptions.Item>

        <Descriptions.Item label={t(`${Feature.SHARED}:updatedAt`)}>
          {source.updatedAt ? formatISODate(source.updatedAt, getLanguageFromI18nLanguage(i18n.language)) : ''}
        </Descriptions.Item>

        <Descriptions.Item label={t(`${Feature.SHARED}:updatedBy`)}>
          <UserName userId={logQueryResult.isLoading ? undefined : source.updatedBy} />
        </Descriptions.Item>
      </Descriptions>

      <Divider>{t(`${Feature.SHARED}:log`)}</Divider>
      <Checkbox checked={includeGetLogs} onChange={toggleIncludeGetLogs} css={withMarginBottom}>
        {t(`${Feature.SHARED}:includeGetLogs`)}
      </Checkbox>
      <LogsTimeline queryResult={logQueryResult} />
    </Drawer>
  );
};

const withMarginBottom = css`
  margin-bottom: 32px;
`;
