import { createContext, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '../../../shared/hooks/use-query';
import { AuthQueryParamKey } from '../../models/params';
import { steps } from './steps';

interface RegisterContextProviderProps {
  currentStep: number;
  setCurrentStep: (step: number) => void;
}

export enum RegisterFormField {
  USERNAME = 'username',
  EMAIL = 'email',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirmPassword',
  FIRST_NAME = 'firstName',
  MIDDLE_NAME = 'middleName',
  LAST_NAME = 'lastName',
  MOBILE_PHONE = 'mobilePhone',
}

export interface RegisterFormValues {
  [RegisterFormField.USERNAME]?: string;
  [RegisterFormField.EMAIL]?: string;
  [RegisterFormField.PASSWORD]?: string;
  [RegisterFormField.CONFIRM_PASSWORD]?: string;
  [RegisterFormField.FIRST_NAME]?: string;
  [RegisterFormField.MIDDLE_NAME]?: string;
  [RegisterFormField.LAST_NAME]?: string;
  [RegisterFormField.MOBILE_PHONE]?: string;
}

interface RegisterContextType {
  currentStep: number;
  token: string;
  nextStep: () => void;
  previousStep: () => void;
  setValues: (values: RegisterFormValues) => void;
  formValues: RegisterFormValues;
}

export const RegisterContext = createContext<RegisterContextType>({
  currentStep: 0,
  token: null,
  nextStep: () => {},
  previousStep: () => {},
  setValues: () => {},
  formValues: null,
});

export const RegisterContextProvider: FC<RegisterContextProviderProps> = ({
  children,
  currentStep,
  setCurrentStep,
}) => {
  const [formValues, setFormValues] = useState<RegisterFormValues>(null);
  const [tokenStepSet, setTokenStepSet] = useState(false);
  const query = useQuery();
  const token = query.get(AuthQueryParamKey.TOKEN);

  const nextStep = useCallback(() => {
    const next = currentStep + 1;
    if (next < steps.length) {
      setCurrentStep(currentStep + 1);
    }
  }, [currentStep, setCurrentStep]);

  const previousStep = useCallback(() => {
    const prev = currentStep - 1;
    if (prev >= 0) {
      setCurrentStep(prev);
    }
  }, [currentStep, setCurrentStep]);

  const setValues = useCallback((values: RegisterFormValues) => {
    if (values != null) {
      setFormValues(formVals => {
        return formVals != null ? { ...formVals, ...values } : values;
      });
    }
  }, []);

  const value = useMemo(() => {
    return { currentStep, nextStep, previousStep, setValues, token, formValues };
  }, [currentStep, nextStep, previousStep, setValues, token, formValues]);

  useEffect(() => {
    if (!tokenStepSet && token?.length > 0) {
      const tokenStepIndex = steps.findIndex(step => {
        return step.tokenStep === true;
      });
      setCurrentStep(tokenStepIndex > -1 ? tokenStepIndex : steps.length - 1);
      setTokenStepSet(true);
    }
  }, [setCurrentStep, token?.length, tokenStepSet]);

  return <RegisterContext.Provider value={value}>{children}</RegisterContext.Provider>;
};
