import deDE from 'antd/lib/locale/de_DE';
import enGB from 'antd/lib/locale/en_GB';
import { Language } from '../../shared/models/language';

export const i18nAntdLocaleMap = {
  [Language.EnglishGB]: enGB,
  [Language.German]: deDE,
};

export const supportedLanguages: Language[] = [Language.EnglishGB, Language.German];
