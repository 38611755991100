import { Typography } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { translationNamespace } from '../../constants/translation-resources';
import { LoginForm } from './login-form';

const { Title } = Typography;

export const LoginView: FC = () => {
  const { t } = useTranslation(translationNamespace);

  return (
    <Container>
      <Title level={2}>{t('loginView.title')}</Title>
      <LoginForm />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 0 24px;
`;
