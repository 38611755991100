import { Drawer, DrawerProps, message } from 'antd';
import { FC, useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';

import { translationNamespace } from '../../constants/translation-resources';
import { ExportedReportFile } from '../../models/export';
import { ConfirmationModal } from '../../../shared/components/confirmation-modal';
import { FileDataBlock } from './file-data-block';
import { Feature } from '../../../shared/models/features';
import { useDeleteReportFileMutation } from '../../data/mutations/report-mutations';

interface ReportFilesDrawerProps extends DrawerProps {
  files: ExportedReportFile[];
  uuid: string;
}

export const ReportFilesDrawer: FC<ReportFilesDrawerProps> = ({ files, uuid, ...props }) => {
  const { t, i18n } = useTranslation(translationNamespace);
  const [showDeletionModal, toggleShowDeletionModal] = useToggle(false);
  const [selectedFile, setSelectedFile] = useState<Pick<ExportedReportFile, 'id' | 'name'>>();
  const deleteMutation = useDeleteReportFileMutation(uuid);

  const selectFile = (file: ExportedReportFile) => {
    setSelectedFile({ id: file.id, name: file.name });
    toggleShowDeletionModal();
  };

  const confirm = () => {
    deleteMutation.mutate(
      { fileId: selectedFile.id },
      {
        onSuccess: () => {
          message.success(t('reports.single.files.file.deleteSuccessMessage'));
          toggleShowDeletionModal(false);
        },
      }
    );
  };

  return (
    <>
      <Drawer
        {...props}
        title={t('reports.single.files.label')}
        width={400}
        css={drawerStyles}
        placement="right"
        closable
      >
        <ul css={unorderedListStyle}>
          {files.map(file => (
            <li key={file.id}>
              <FileDataBlock file={file} t={t} i18nLanguage={i18n.language} handleDeleteModal={selectFile} />
            </li>
          ))}
        </ul>
      </Drawer>

      <ConfirmationModal
        open={showDeletionModal}
        title={t(`${Feature.SHARED}:areYouSure`)}
        onCancel={toggleShowDeletionModal}
        onOk={confirm}
        content={t('reports.single.files.file.confirmDeleteMessage', {
          resourceName: selectedFile?.name,
          buttonTitle: t(`${Feature.SHARED}:confirm`),
        })}
      />
    </>
  );
};

const drawerStyles = css`
  .ant-drawer-content {
    overflow: hidden;
  }
`;

const unorderedListStyle = css`
  list-style: none;
  position: relative;
  padding: 0 26px;

  li:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #fff;
    border: 2px solid #1890ff;
    border-radius: 5px;
    left: 0;
    margin-top: 6px;
  }
`;
