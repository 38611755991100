import { TFunction } from 'react-i18next';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/react';
import { Button, Space } from 'antd';
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';

import { Feature } from '../../../shared/models/features';
import { ExportedReportFile, ExportStatus } from '../../models/export';
import { formatISODate } from '../../../shared/utils/date';
import { ExportStatuses } from '../../constants/export';
import { getLanguageFromI18nLanguage } from '../../../shared/utils/language';
import { useDownloadReportFileMutation } from '../../data/mutations/report-mutations';
import { permissionSelector } from '../../../shared/data/store/selectors/auth-selectors';

interface FileDataBlockProps {
  file: ExportedReportFile;
  t: TFunction<(Feature.REPORTS | Feature.SHARED)[]>;
  i18nLanguage: string;
  handleDeleteModal: (file: ExportedReportFile) => void;
}

const statusIconMap = (status: ExportStatus) => {
  switch (status) {
    case 'FAILED':
      return <CloseCircleOutlined style={{ color: '#ff4d4f' }} />;
    case 'FINISHED':
      return <CheckCircleOutlined style={{ color: '#52c41a' }} />;
    case 'STARTED':
      return <ClockCircleOutlined style={{ color: '#2020ff' }} />;

    default:
      return null;
  }
};

export const FileDataBlock: FC<FileDataBlockProps> = ({ file, t, i18nLanguage, handleDeleteModal }) => {
  const downloadMutation = useDownloadReportFileMutation();
  const permissions = useSelector(permissionSelector);

  const handleDownloadClick = () => {
    downloadMutation.mutate(file.id);
  };

  return (
    <>
      <div>
        <span css={boldTextStyle}>{t(`${Feature.SHARED}:type`)}:</span> {file.type}
      </div>
      <div>
        <span css={boldTextStyle}>{t(`${Feature.SHARED}:date`)}: </span>
        {formatISODate(file.createdAt, getLanguageFromI18nLanguage(i18nLanguage))}
      </div>
      <div>
        <span css={boldTextStyle}>{t(`${Feature.SHARED}:name`)}:</span> {file.name}
      </div>
      <div>
        <span css={boldTextStyle}>{t(`${Feature.SHARED}:status`)}:</span> {statusIconMap(file.status)}
      </div>
      {file.error ? (
        <div>
          <span css={boldTextStyle}>{t(`${Feature.SHARED}:error`)}:</span> {file.error}
        </div>
      ) : null}
      <StyledSpace>
        {!file.error ? (
          <Button
            disabled={file.status !== ExportStatuses.FINISHED}
            css={buttonStyles}
            onClick={handleDownloadClick}
            type="link"
            loading={downloadMutation.isLoading}
          >
            {t(`${Feature.SHARED}:download`)}
          </Button>
        ) : null}
        {permissions.REPORT.DELETE && (
          <Button css={buttonStyles} type="link" danger onClick={() => handleDeleteModal(file)}>
            {t(`${Feature.SHARED}:delete`)}
          </Button>
        )}
      </StyledSpace>
    </>
  );
};

const boldTextStyle = css`
  font-weight: bold;
`;

const buttonStyles = css`
  font-weight: bold;
  padding: 4px 0;
`;

const StyledSpace = styled(Space)`
  margin-bottom: 20px;
`;
