import axios from 'axios';
import {
  CreateUserGroupRequest,
  FindAllGroupsRequest,
  FindAllGroupsResponse,
  GroupStatistics,
  UserGroupWithIncludes,
} from '@pccr-ifc/pccr-ifc';

import { Config } from '../../../shared/models/config';
import { useService } from '../../../shared/hooks/use-service';

function createTenantService(config: Config) {
  const BASE_PATH = '/group';
  const STATS_PATH = '/stats';

  const findTenants = (request: FindAllGroupsRequest) =>
    axios.post<FindAllGroupsResponse>(`${config.API_URL}${BASE_PATH}/findAll`, request, {
      withCredentials: true,
    });

  const getTenant = (id: number) =>
    axios.get<UserGroupWithIncludes>(`${config.API_URL}${BASE_PATH}/${id}`, {
      withCredentials: true,
    });

  const createTenant = (request: CreateUserGroupRequest) =>
    axios.post<UserGroupWithIncludes>(`${config.API_URL}${BASE_PATH}/add`, request, {
      withCredentials: true,
    });

  const updateTenant = (request: { group: Partial<CreateUserGroupRequest['group']> }, id: number) =>
    axios.patch<UserGroupWithIncludes>(`${config.API_URL}${BASE_PATH}/${id}`, request, {
      withCredentials: true,
    });

  const deleteTenant = (id: number) =>
    axios.delete<void>(`${config.API_URL}${BASE_PATH}/${id}`, {
      withCredentials: true,
    });

  const getTenantStats = (id: number) =>
    axios.get<GroupStatistics>(`${config.API_URL}${STATS_PATH}/group/${id}`, {
      withCredentials: true,
    });

  const reassignAllFromTenant = (request: { newGroupId: number }, id: number) =>
    axios.patch<void>(`${config.API_URL}${BASE_PATH}/reassignAllFromGroup/${id}`, request, {
      withCredentials: true,
    });

  const deleteAllFromTenant = (id: number) =>
    axios.delete<void>(`${config.API_URL}${BASE_PATH}/deleteAllFromGroup/${id}`, {
      withCredentials: true,
    });

  return {
    findTenants,
    getTenant,
    createTenant,
    updateTenant,
    deleteTenant,
    getTenantStats,
    reassignAllFromTenant,
    deleteAllFromTenant,
  };
}

export const useTenantService = () => useService(createTenantService);
