import { Button, Dropdown, message } from 'antd';
import { MenuProps } from 'antd';
import { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import { useToggle } from 'react-use';

import { ConfirmationModal } from '../../../shared/components/confirmation-modal';
import { Feature } from '../../../shared/models/features';
import { Tenant } from '../../../shared/models/tenant';
import { translationNamespace } from '../../constants/translation-resources';
import { useDeleteTenantMutation } from '../../data/mutations/tenant-mutations';
import { permissionSelector } from '../../../shared/data/store/selectors/auth-selectors';
import { AppResources } from '../../../shared/constants/app-resources';

export interface ActionsProps {
  tenant: Tenant;
}

export const Actions: FC<ActionsProps> = ({ tenant }) => {
  const { t } = useTranslation(translationNamespace);
  const deleteMutation = useDeleteTenantMutation();
  const permissions = useSelector(permissionSelector);
  const [showConfirmDeleteModal, toggleShowConfirmDeleteModal] = useToggle(false);

  const remove = useCallback(() => {
    if (tenant.id == null) {
      return;
    }

    deleteMutation.mutate(tenant.id, {
      onSuccess: () => {
        message.success(t('tenants.shared.deleteSuccessMessage'));
        toggleShowConfirmDeleteModal();
      },
    });
  }, [deleteMutation, t, tenant.id, toggleShowConfirmDeleteModal]);

  const items = useMemo<MenuProps['items']>(() => {
    const menuItems: MenuProps['items'] = [];

    if (permissions[AppResources.TENANT]['UPDATE']) {
      menuItems.push({
        key: 'edit',
        label: <Link to={`tenants/${tenant.id}`}>{t(`${Feature.SHARED}:edit`)}</Link>,
      });
    }
    if (permissions[AppResources.TENANT]['DELETE']) {
      menuItems.push({
        key: 'remove',
        onClick: () => toggleShowConfirmDeleteModal(),
        label: t(`${Feature.SHARED}:remove`),
        disabled: deleteMutation.isLoading,
      });
    }

    return menuItems;
  }, [permissions, tenant.id, t, toggleShowConfirmDeleteModal, deleteMutation.isLoading]);

  return (
    <>
      <Dropdown menu={{ items }} trigger={['click']}>
        <Button type="link" icon={<MenuOutlined />}>
          {t(`${Feature.SHARED}:actions`)}
        </Button>
      </Dropdown>
      <ConfirmationModal
        open={showConfirmDeleteModal}
        title={t(`${Feature.SHARED}:areYouSure`)}
        onCancel={toggleShowConfirmDeleteModal}
        onOk={remove}
        content={t(`${Feature.SHARED}:confirmDeleteMsg`, {
          resourceName: tenant.name,
          buttonTitle: t(`${Feature.SHARED}:confirm`),
        })}
      />
    </>
  );
};
