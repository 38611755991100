import { Button, Card, Col, Form, message, Row, Space } from 'antd';
import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { useToggle } from 'react-use';
import styled from '@emotion/styled';

import { ConfirmationModal } from '../../../shared/components/confirmation-modal';
import { setResourceName } from '../../../shared/data/store/slices/nav-slice';
import { Feature } from '../../../shared/models/features';
import { ResourceParams } from '../../../shared/models/resource-params';
import { PickSomeRestPartial } from '../../../shared/utils/types';
import { JobDetailsDrawer } from '../../components/job-details-drawer';
import { JobUpsertForm, JobUpsertFormField, JobUpsertFormValues } from '../../components/job-upsert-form';
import { translationNamespace } from '../../constants/translation-resources';
import {
  convertFormValuesToJob,
  convertJobRequestToFormValues,
  convertJobRequestToTask,
} from '../../data/converters/job-converters';
import { useDeleteJobMutation, useUpdateJobMutation } from '../../data/mutations/job-mutations';
import { useGetJobQuery } from '../../data/queries/job-queries';
import { Job } from '../../models/job';
import { AppResourcePaths } from '../../../shared/constants/app-resources';
import { returnChangedObjectProperties } from '../../../shared/utils/object';
import { permissionSelector } from '../../../shared/data/store/selectors/auth-selectors';

export const JobSingleView: FC = () => {
  const { t } = useTranslation(translationNamespace);
  const dispatch = useDispatch();
  const { push: routerPush } = useHistory();
  const { id } = useParams<ResourceParams>();
  const [form] = Form.useForm<JobUpsertFormValues>();
  const [showDetails, toggleShowDetails] = useToggle(false);
  const [showConfirmDeleteModal, toggleShowConfirmDeleteModal] = useToggle(false);
  const parsedId = Number(id);
  const { data: job, isSuccess, isLoading, isFetching } = useGetJobQuery(parsedId);
  const updateMutation = useUpdateJobMutation();
  const deleteMutation = useDeleteJobMutation();
  const initialJobValues = useRef<JobUpsertFormValues>();
  const permissions = useSelector(permissionSelector);

  const areRequestsLoading = isLoading || updateMutation.isLoading || deleteMutation.isLoading;

  const submit = (values: JobUpsertFormValues) => {
    if (job?.id == null) {
      return;
    }

    const changedProperties = returnChangedObjectProperties(initialJobValues.current, values);

    if (!changedProperties) {
      message.warning(t(`${Feature.SHARED}:nothingToUpdate`));
      return;
    }

    if ('request' in changedProperties) {
      changedProperties.request = values.request;
      changedProperties.task = values.task;
    }

    const updatedJob: PickSomeRestPartial<Job, 'id'> = {
      id: job.id,
      ...convertFormValuesToJob(changedProperties),
    };

    updateMutation.mutate(updatedJob, {
      onSuccess: () => {
        message.success(t('jobs.shared.updateSuccessMessage'));
      },
    });
  };

  const remove = () => {
    if (job?.id == null) {
      return;
    }

    deleteMutation.mutate(job.id, {
      onSuccess: () => {
        message.success(t('jobs.shared.deleteSuccessMessage'));
        routerPush(AppResourcePaths.jobs);
      },
    });
  };

  const triggerSubmit = () => {
    form.submit();
  };

  useEffect(() => {
    if (job) {
      const task = convertJobRequestToTask(job.request);
      const jobUpsertFormFieldValues = {
        [JobUpsertFormField.NAME]: job.name,
        [JobUpsertFormField.CRON]: job.cron,
        [JobUpsertFormField.ENABLED]: job.enabled,
        [JobUpsertFormField.OVERWRITABLE]: job.overwritable,
        [JobUpsertFormField.TASK]: task,
        [JobUpsertFormField.REQUEST]: convertJobRequestToFormValues(job.request, task),
      };

      form.setFieldsValue(jobUpsertFormFieldValues);
      initialJobValues.current = jobUpsertFormFieldValues;
    }
  }, [form, job]);

  useEffect(() => {
    dispatch(setResourceName(job?.name));
  }, [dispatch, job?.name]);

  return (
    <Row justify="space-between" gutter={[16, 24]}>
      <StyledCol span={24}>
        <Space>
          <Button size="large" onClick={toggleShowDetails} loading={areRequestsLoading} disabled={!isSuccess}>
            {t(`${Feature.SHARED}:details`)}
          </Button>
          {permissions.JOB.DELETE && (
            <Button
              size="large"
              onClick={toggleShowConfirmDeleteModal}
              loading={areRequestsLoading}
              disabled={job == null}
              icon={<DeleteOutlined />}
              danger
            />
          )}
        </Space>
      </StyledCol>
      <Col span={24}>
        <Card title={t(`${Feature.SHARED}:details`)}>
          <JobUpsertForm job={job} form={form} onSubmit={submit} />
        </Card>
      </Col>
      <Col>
        <Space>
          {permissions.JOB.UPDATE && (
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              icon={<CheckOutlined />}
              loading={areRequestsLoading || isFetching}
              onClick={triggerSubmit}
              disabled={job == null}
            >
              {t(`${Feature.SHARED}:save`)}
            </Button>
          )}
          <Link to={AppResourcePaths.jobs}>
            <Button htmlType="button" size="large">
              {t(`${Feature.SHARED}:cancel`)}
            </Button>
          </Link>
        </Space>
      </Col>
      {job && <JobDetailsDrawer job={job} open={showDetails} onClose={toggleShowDetails} />}
      <ConfirmationModal
        open={showConfirmDeleteModal}
        title={t(`${Feature.SHARED}:areYouSure`)}
        onCancel={toggleShowConfirmDeleteModal}
        onOk={remove}
        content={t(`${Feature.SHARED}:confirmDeleteMsg`, {
          resourceName: job?.name,
          buttonTitle: t(`${Feature.SHARED}:confirm`),
        })}
      />
    </Row>
  );
};

const StyledCol = styled(Col)`
  display: flex;
  justify-content: end;
`;
