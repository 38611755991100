import generatePicker from 'antd/lib/date-picker/generatePicker';
import { useTranslation } from 'react-i18next';
import { RangePickerProps } from 'antd/es/date-picker/generatePicker';

import { dateFnsGenerateConfig } from '../../constants/date-fns';

export const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);

function getDateFormat(i18nLanguage: string, includeTime: boolean) {
  let format: string;

  if (i18nLanguage === 'de-DE') {
    format = 'dd.MM.Y';
  } else {
    format = 'dd/MM/Y';
  }
  if (includeTime) format = `${format} HH:mm`;

  return format;
}

export const RangePicker = (props: RangePickerProps<Date>) => {
  const { i18n } = useTranslation();

  const includeTime = 'showTime' in props;

  return (
    <DatePicker.RangePicker
      format={getDateFormat(i18n.language, includeTime)}
      allowEmpty={[true, true]}
      size="large"
      {...props}
    />
  );
};
