import { createAsyncThunk } from '@reduxjs/toolkit';

import { Feature } from '../../../../shared/models/features';
import { RequestWithService } from '../../../../shared/models/request';
import { DefinitionCheckResult } from '../../../models/api/definition';
import { DefinitionUpsert } from '../../../../shared/models/definition';
import { DefinitionService } from '../../services/use-definition-service';

export const checkDefinition = createAsyncThunk<
  DefinitionCheckResult,
  RequestWithService<Omit<DefinitionUpsert, 'id'>, DefinitionService>
>(`${Feature.REPORTS}/definition/check`, async ({ request: definition, service }, { rejectWithValue }) => {
  try {
    await service.checkDefinition({ definition });
  } catch (error) {
    return rejectWithValue(error);
  }
});
