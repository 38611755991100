import { createSlice, SerializedError } from '@reduxjs/toolkit';

import { Feature } from '../../../../shared/models/features';
import { checkReportSize } from '../thunks/report-thunks';

interface ReportState {
  checkSize: {
    loading: boolean;
    error: SerializedError;
  };
  generate: {
    loading: boolean;
    error: SerializedError;
  };
  export: {
    loading: boolean;
    error: SerializedError;
  };
}

const initialState: ReportState = {
  checkSize: {
    loading: false,
    error: null,
  },
  generate: {
    loading: false,
    error: null,
  },
  export: {
    loading: false,
    error: null,
  },
};

const slice = createSlice({
  name: `${Feature.REPORTS}/report`,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(checkReportSize.fulfilled, state => {
      state.checkSize.loading = false;
    });
    builder.addCase(checkReportSize.pending, state => {
      state.checkSize.loading = true;
    });
    builder.addCase(checkReportSize.rejected, (state, action) => {
      state.checkSize.loading = false;
      state.checkSize.error = action.error;
    });
  },
});

export const reportSlice = slice.reducer;
