import { FindAllHistoryResponse } from '@pccr-ifc/pccr-ifc';

import { Log } from '../../../shared/models/log';
import { convertDateRangeToISO } from '../../../shared/utils/date';
import { LogsTableFilters } from '../../components/logs-table';
import { LogsFilters } from '../../models/log';
import { ResourceSearchResult } from '../../../shared/models/api/response';

export function convertSearchResponse(response: FindAllHistoryResponse): ResourceSearchResult<Log> {
  return {
    total: response.count,
    resources: response.rows as Log[],
  };
}

export function convertTableFiltersToFilters(tableFilters: LogsTableFilters): LogsFilters {
  const presetsFilters: LogsFilters = {
    path: tableFilters.path,
    method: tableFilters.method,
    userId: tableFilters.userId,
    resourceId: tableFilters.resourceId,
  };

  if (tableFilters.createdAt != null) {
    presetsFilters.createdAt = convertDateRangeToISO(tableFilters.createdAt);
  }

  return presetsFilters;
}
