import { Rule } from 'antd/es/form';
import { TFunction } from 'react-i18next';

import { Feature } from '../../shared/models/features';

export function definitionNameRule(t: TFunction<Feature[] | Feature>): Rule {
  return {
    validator: (_, value) => {
      if (typeof value === 'string' && /[.']/.test(value)) {
        return Promise.reject(new Error(t('definitions.upsert.name.validationError')));
      }
      return Promise.resolve();
    },
  };
}
