import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { ReportDefinition as BeDefinition } from '@pccr-ifc/pccr-ifc';
import { useTranslation } from 'react-i18next';

import { PickSomeRestPartial } from '../../../shared/utils/types';
import { Definition, DefinitionUpsert } from '../../../shared/models/definition';
import { useDefinitionService } from '../services/use-definition-service';
import {
  onCreateResourceMutationSuccess,
  onDeleteResourceMutationSuccess,
  onUpdateResourceMutationSuccess,
} from '../../../shared/utils/mutation';
import { displayErrors } from '../../../shared/utils/error';

export function useCreateDefinitionMutation() {
  const definitionService = useDefinitionService();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<BeDefinition, unknown, Omit<DefinitionUpsert, 'id'>>(
    definition => {
      const request = { definition };
      return definitionService.createDefinition(request);
    },
    {
      onSuccess(response) {
        onCreateResourceMutationSuccess(queryClient, 'DEFINITION', response);
      },
      onError(error) {
        displayErrors(error, t);
      },
    }
  );
}

export function useUpdateDefinitionMutation() {
  const definitionService = useDefinitionService();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<BeDefinition, unknown, PickSomeRestPartial<DefinitionUpsert, 'id'>>(
    definition => definitionService.updateDefinition(definition),
    {
      onSuccess(response, vars) {
        onUpdateResourceMutationSuccess(queryClient, 'DEFINITION', response, vars.id);
      },
      onError(error) {
        displayErrors(error, t);
      },
    }
  );
}

export function useDeleteDefinitionMutation() {
  const definitionService = useDefinitionService();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation<AxiosResponse<void>, unknown, Definition['id']>(id => definitionService.deleteDefinition(id), {
    onSuccess(_response, id) {
      onDeleteResourceMutationSuccess(queryClient, 'DEFINITION', id);
    },
    onError(error) {
      displayErrors(error, t);
    },
  });
}
