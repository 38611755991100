import { Button, Card, Col, Form, message, Row, Space } from 'antd';
import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { useToggle } from 'react-use';
import styled from '@emotion/styled';

import { ConfirmationModal } from '../../../shared/components/confirmation-modal';
import { setResourceName } from '../../../shared/data/store/slices/nav-slice';
import { Feature } from '../../../shared/models/features';
import { ResourceParams } from '../../../shared/models/resource-params';
import { PickSomeRestPartial } from '../../../shared/utils/types';
import { RoleDetailsDrawer } from '../../components/role-details-drawer';
import { RoleUpsertForm, RoleUpsertFormValues } from '../../components/role-upsert-form';
import { translationNamespace } from '../../constants/translation-resources';
import { convertFormValuesToRole } from '../../data/converters/role-converters';
import { useDeleteRoleMutation, useUpdateRoleMutation } from '../../data/mutations/role-mutations';
import { useGetRoleQuery } from '../../data/queries/role-queries';
import { RoleUpsert } from '../../models/role';
import { AppResourcePaths } from '../../../shared/constants/app-resources';
import { returnChangedObjectProperties } from '../../../shared/utils/object';

import { permissionSelector } from '../../../shared/data/store/selectors/auth-selectors';

export const RoleSingleView: FC = () => {
  const { t } = useTranslation(translationNamespace);
  const dispatch = useDispatch();
  const { push: routerPush } = useHistory();
  const { id } = useParams<ResourceParams>();
  const [form] = Form.useForm<RoleUpsertFormValues>();
  const [showDetails, toggleShowDetails] = useToggle(false);
  const [showConfirmDeleteModal, toggleShowConfirmDeleteModal] = useToggle(false);
  const parsedId = Number(id);
  const { data: role, isSuccess, isLoading, isFetching } = useGetRoleQuery(parsedId);
  const updateMutation = useUpdateRoleMutation();
  const deleteMutation = useDeleteRoleMutation();
  const initialRoleValues = useRef<RoleUpsertFormValues>();
  const permissions = useSelector(permissionSelector);

  const areRequestsLoading = isLoading || updateMutation.isLoading || deleteMutation.isLoading;

  const submit = (values: RoleUpsertFormValues) => {
    if (role?.id == null) {
      return;
    }

    const changedProperties = returnChangedObjectProperties(initialRoleValues.current, values);

    if (!changedProperties) {
      message.warning(t(`${Feature.SHARED}:nothingToUpdate`));
      return;
    }

    const updatedRole: PickSomeRestPartial<RoleUpsert, 'id'> = {
      id: role.id,
      ...convertFormValuesToRole(changedProperties),
    };

    updateMutation.mutate(updatedRole, {
      onSuccess: () => {
        message.success(t('roles.shared.updateSuccessMessage'));
      },
    });
  };

  const remove = () => {
    if (role.id == null) {
      return;
    }

    deleteMutation.mutate(role.id, {
      onSuccess: () => {
        message.success(t('roles.shared.deleteSuccessMessage'));
        routerPush(AppResourcePaths.roles);
      },
    });
  };

  const triggerSubmit = () => {
    form.submit();
  };

  useEffect(() => {
    if (role) {
      const roleUpsertFormFieldValues = {
        name: role.name,
        policies: role.policies?.map(policy => policy.id),
        tenants: role.scopeGroups?.map(tenant => tenant.id),
      };

      form.setFieldsValue(roleUpsertFormFieldValues);
      initialRoleValues.current = roleUpsertFormFieldValues;
    }
  }, [role, form]);

  useEffect(() => {
    dispatch(setResourceName(role?.name));
  }, [dispatch, role?.name]);

  return (
    <Row justify="space-between" gutter={[16, 24]}>
      <StyledCol span={24}>
        <Space>
          <Button size="large" onClick={toggleShowDetails} loading={areRequestsLoading} disabled={!isSuccess}>
            {t(`${Feature.SHARED}:details`)}
          </Button>
          {permissions.ROLE.DELETE && (
            <Button
              size="large"
              onClick={toggleShowConfirmDeleteModal}
              loading={areRequestsLoading}
              disabled={role == null}
              icon={<DeleteOutlined />}
              danger
            />
          )}
        </Space>
      </StyledCol>
      <Col span={24}>
        <Card title={t(`${Feature.SHARED}:details`)}>
          <RoleUpsertForm form={form} onSubmit={submit} role={role} />
        </Card>
      </Col>

      <Col>
        <Space>
          {permissions.ROLE.UPDATE && (
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              icon={<CheckOutlined />}
              onClick={triggerSubmit}
              loading={areRequestsLoading || isFetching}
              disabled={!isSuccess}
            >
              {t(`${Feature.SHARED}:save`)}
            </Button>
          )}
          <Link to={AppResourcePaths.roles}>
            <Button htmlType="button" size="large">
              {t(`${Feature.SHARED}:cancel`)}
            </Button>
          </Link>
        </Space>
      </Col>

      {role && <RoleDetailsDrawer role={role} open={showDetails} onClose={toggleShowDetails} />}

      <ConfirmationModal
        open={showConfirmDeleteModal}
        title={t(`${Feature.SHARED}:areYouSure`)}
        onCancel={toggleShowConfirmDeleteModal}
        onOk={remove}
        content={t(`${Feature.SHARED}:confirmDeleteMsg`, {
          resourceName: role?.name,
          buttonTitle: t(`${Feature.SHARED}:confirm`),
        })}
      />
    </Row>
  );
};

const StyledCol = styled(Col)`
  display: flex;
  justify-content: end;
`;
