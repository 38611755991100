import { combineReducers } from '@reduxjs/toolkit';

import { reportsReducer } from '../../../reports/data/store';
import { sharedReducer } from '../../../shared/data/store';
import { Feature } from '../../../shared/models/features';

export const rootReducer = combineReducers({
  [Feature.REPORTS]: reportsReducer,
  [Feature.SHARED]: sharedReducer,
});
