import { Button, Form, Input, Modal, ModalProps, Space } from 'antd';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Feature } from '../../../shared/models/features';
import { translationNamespace } from '../../constants/translation-resources';
import { ExportType } from '../../models/export';
import { redundantSpacebarsRule } from '../../../shared/utils/form-rules';
import { compressableExportTypes } from '../../constants/export';

enum ExportModalFormField {
  FILENAME = 'filename',
}

interface ExportModalFormValues {
  [ExportModalFormField.FILENAME]: string;
}

interface ExportModalProps extends Omit<ModalProps, 'title' | 'footer' | 'okText' | 'cancelText'> {
  reportName: string;
  exportType: ExportType;
  dataFiltered?: boolean;
  onExport?: (exportType: ExportType, filtered: boolean, compression: boolean, filename: string) => void;
}

export const ExportModal: FC<ExportModalProps> = ({
  exportType,
  dataFiltered = false,
  onExport,
  onCancel,
  reportName,
  open,
  ...props
}) => {
  const [form] = Form.useForm<ExportModalFormValues>();
  const { t } = useTranslation(translationNamespace);
  const [exportFiltered, setExportFiltered] = useState<boolean>(null);
  const [filename, setFilename] = useState<string>();
  const [showAdditionalInfoStep, setShowAdditionalInfoStep] = useState(true);
  const inputRef = useRef(null);

  const showFilteredStep = dataFiltered && exportFiltered === null;
  const isCompressable = compressableExportTypes.includes(exportType);

  const exportData = useCallback(
    (filtered: boolean, compression: boolean, initFilename?: string) => {
      onExport?.(exportType, filtered, compression, initFilename == null ? filename : initFilename);
      setExportFiltered(null);
      setShowAdditionalInfoStep(true);

      setTimeout(() => {
        form.resetFields();
      }, 100);
    },
    [exportType, filename, form, onExport]
  );

  const handleFiltered = useCallback(
    (expFiltered: boolean) => {
      if (!compressableExportTypes.includes(exportType)) {
        exportData(expFiltered, false);
        return;
      }

      setExportFiltered(expFiltered);
    },
    [exportData, exportType]
  );

  const submit = ({ filename }: ExportModalFormValues) => {
    setFilename(filename);

    if (!isCompressable && !dataFiltered) {
      exportData(false, false, filename);
      return;
    }

    setShowAdditionalInfoStep(false);
  };

  const cancel = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onCancel(e);
      setShowAdditionalInfoStep(true);
      setExportFiltered(null);
    },
    [onCancel]
  );

  const filenameButtons = useMemo(() => {
    return [
      <Button key="cancel" type="primary" onClick={cancel} ghost>
        {t(`${Feature.SHARED}:cancel`)}
      </Button>,
      <Button key="submit" htmlType="submit" type="primary" onClick={form.submit}>
        {t(`${Feature.SHARED}:confirm`)}
      </Button>,
    ];
  }, [cancel, form, t]);

  const filteredButtons = useMemo(() => {
    return [
      <Button key="filtered" type="primary" onClick={() => handleFiltered(true)} ghost>
        {t('reports.single.export.filteredReport')}
      </Button>,
      <Button key="full" type="primary" onClick={() => handleFiltered(false)}>
        {t('reports.single.export.fullReport')}
      </Button>,
    ];
  }, [handleFiltered, t]);

  const compressionButtons = useMemo(() => {
    const expFiltered = exportFiltered ?? false;
    return [
      <Button key="uncompressed" type="primary" onClick={() => exportData(expFiltered, false)} ghost>
        {t(`${Feature.SHARED}:no`)}
      </Button>,
      <Button key="compressed" type="primary" onClick={() => exportData(expFiltered, true)}>
        {t(`${Feature.SHARED}:yes`)}
      </Button>,
    ];
  }, [exportFiltered, t, exportData]);

  useEffect(() => {
    setFilename(reportName);
  }, [reportName]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        inputRef.current.focus();
      });
    }
  }, [open]);

  return (
    <StyledModal
      {...props}
      centered
      open={open}
      title={
        <Space size="middle" align="start">
          <StyledInfoCircleOutlined />
          {exportType ? t('reports.single.export.exportTo', { type: exportType }) : ''}
        </Space>
      }
      width={400}
      footer={showAdditionalInfoStep ? filenameButtons : showFilteredStep ? filteredButtons : compressionButtons}
      onCancel={cancel}
    >
      <StyledParagraph>
        {t(
          `reports.single.export.description.${
            showAdditionalInfoStep ? 'provideDetails' : showFilteredStep ? 'filtered' : 'compression'
          }`
        )}
      </StyledParagraph>

      {showAdditionalInfoStep && (
        <Form form={form} initialValues={{ filename: reportName }} onFinish={submit}>
          <Form.Item
            css={formItemStyles}
            name={ExportModalFormField.FILENAME}
            label={t(`${Feature.SHARED}:name`)}
            rules={[{ required: true, max: 180 }, redundantSpacebarsRule(t)]}
          >
            <Input ref={inputRef} />
          </Form.Item>
        </Form>
      )}
    </StyledModal>
  );
};

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  font-size: 22px;
  color: ${props => props.theme.reports.exportModal.icon.color};
`;

const StyledModal = styled(Modal)`
  .ant-modal-header {
    padding: 32px 32px 0;
  }

  .ant-modal-body {
    padding: 8px 32px;
    margin-left: 38px;
  }

  .ant-modal-footer {
    padding: 16px 32px;
  }

  .ant-modal-header,
  .ant-modal-footer {
    border: 0;
  }
`;

const StyledParagraph = styled.p`
  margin-bottom: 0;
`;

const formItemStyles = css`
  margin-top: 20px;
  margin-bottom: 0;
`;
