import { Button, message, Space, Typography } from 'antd';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowLeftOutlined, LoadingOutlined, SendOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { unwrapResult } from '@reduxjs/toolkit';

import { useAuthService } from '../../../../shared/data/services/use-auth-service';
import { verifyEmailLoadingSelector } from '../../../../shared/data/store/selectors/auth-selectors';
import { resendEmail } from '../../../../shared/data/store/thunks/auth-thunks';
import { translationNamespace } from '../../../constants/translation-resources';
import { RegisterContext, RegisterFormField } from '../register-context';
import { displayErrors } from '../../../../shared/utils/error';

const { Title, Text } = Typography;

export const Verification: FC = () => {
  const { t } = useTranslation(translationNamespace);
  const { previousStep, formValues } = useContext(RegisterContext);
  const [timer, setTimer] = useState(59);
  const dispatch = useDispatch();
  const authService = useAuthService();
  const requestLoading = useSelector(verifyEmailLoadingSelector);

  const resend = useCallback(() => {
    if (formValues[RegisterFormField.EMAIL]?.length > 0) {
      dispatch(resendEmail({ request: formValues[RegisterFormField.EMAIL], service: authService }))
        .then(unwrapResult)
        .then(() => {
          message.success(t('registerView.steps.verification.resendSuccessMessage'));
        })
        .catch(error => displayErrors(error, t));
    }
  }, [authService, dispatch, formValues, t]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(currentTimer => {
        const newTimer = currentTimer - 1;

        if (newTimer < 0) {
          clearInterval(interval);
          return 0;
        }

        return newTimer;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Space direction="vertical">
      <Title level={2}>{t('registerView.steps.verification.title')}</Title>
      <Text type="secondary">{t('registerView.steps.verification.description')}</Text>
      <ButtonGroup>
        <ButtonNoPaddingLeft disabled type="link" htmlType="button" icon={<ArrowLeftOutlined />} onClick={previousStep}>
          {t('shared.back')}
        </ButtonNoPaddingLeft>
        <StyledButton
          type="primary"
          icon={timer > 0 ? <LoadingOutlined /> : <SendOutlined />}
          loading={requestLoading}
          disabled={timer > 0}
          onClick={resend}
        >
          {t('registerView.steps.verification.resend')}
          {timer > 0 && ` ${timer}`}
        </StyledButton>
      </ButtonGroup>
    </Space>
  );
};

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const StyledButton = styled(Button)`
  margin-top: 16px;
`;

const ButtonNoPaddingLeft = styled(StyledButton)`
  padding-left: 0;
`;
