import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Feature } from '../../../models/features';
import { AppResource } from '../../../models/app-resource';
import { CrudOperation } from '../../../models/crud-operation';

type OperationState = {
  CREATE?: boolean;
  READ: boolean;
  UPDATE?: boolean;
  DELETE?: boolean;
};

export type PermissionsState = Partial<Record<AppResource, OperationState>>;

const initialState: PermissionsState = {
  REPORT: {
    CREATE: false,
    READ: false,
    UPDATE: false,
    DELETE: false,
  },
  PRESET: {
    CREATE: false,
    READ: false,
    UPDATE: false,
    DELETE: false,
  },
  DEFINITION: {
    CREATE: false,
    READ: false,
    UPDATE: false,
    DELETE: false,
  },
  VIEW: {
    READ: false,
  },
  SOURCE: {
    CREATE: false,
    READ: false,
    UPDATE: false,
    DELETE: false,
  },
  JOB: {
    CREATE: false,
    READ: false,
    UPDATE: false,
    DELETE: false,
  },
  TENANT: {
    CREATE: false,
    READ: false,
    UPDATE: false,
    DELETE: false,
  },
  USER: {
    CREATE: false,
    READ: false,
    UPDATE: false,
    DELETE: false,
  },
  POLICY: {
    CREATE: false,
    READ: false,
    UPDATE: false,
    DELETE: false,
  },
  ROLE: {
    CREATE: false,
    READ: false,
    UPDATE: false,
    DELETE: false,
  },
  LOG: {
    READ: false,
  },
  BATCH: {
    READ: false,
    UPDATE: false,
  },
};

const slice = createSlice({
  name: `${Feature.SHARED}/permission`,
  initialState,
  reducers: {
    setPermission(state, action: PayloadAction<{ resource: AppResource; operation: CrudOperation }>) {
      const { resource, operation } = action.payload;
      if (state[resource]?.[operation] !== undefined) {
        state[resource][operation] = true;
      } else {
        throw new Error(`The ${operation} permission doesn't exist on ${resource} resource`);
      }
    },
  },
});

export const { setPermission } = slice.actions;
export const permissionSlice = slice.reducer;
