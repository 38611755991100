import { FC, useMemo } from 'react';
import { compileTimeConfig } from '../../constants/config';
import { Config, RuntimeConfig } from '../../models/config';
import { ConfigContext } from './config-context';
import { ServicesContainerProvider } from '../services-container';

type Props = {
  runtimeConfig?: RuntimeConfig;
};

export const ConfigProvider: FC<Props> = ({ children, runtimeConfig }) => {
  const config = useMemo(() => {
    let cfg: Config = { ...compileTimeConfig };

    if (runtimeConfig) {
      cfg = { ...cfg, ...runtimeConfig };
    }

    cfg = {
      ...cfg,
      ORIGINAL_API_URL: cfg.API_URL,
      API_URL:
        cfg.NODE_ENV === 'production'
          ? cfg.API_URL
          : `${cfg.SSR ? 'https' : 'http'}://localhost:${cfg.SSR ? cfg.SERVER_PORT : cfg.CLIENT_PORT}/api`,
    };

    return cfg;
  }, [runtimeConfig]);

  return (
    <ConfigContext.Provider value={config}>
      <ServicesContainerProvider config={config}>{children}</ServicesContainerProvider>
    </ConfigContext.Provider>
  );
};
