import { Layout } from 'antd';
import { FC } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { useBreakpoints } from '../../../shared/hooks/use-breakpoints';
import { Feature } from '../../../shared/models/features';
import { FooterContent } from '../footer-content';
import { HeaderLogo } from '../header-content';
import { LanguageSelector } from '../language-selector';

const { Content, Footer, Sider } = Layout;

export const FullPageLayout: FC = ({ children }) => {
  const theme = useTheme();
  const { md } = useBreakpoints();

  return (
    <StyledLayout>
      <MainArea>
        <StyledHeader>
          <HeaderLogo inverted simple />
          <LanguageSelectorWrapper>
            <LanguageSelector isFullPage />
          </LanguageSelectorWrapper>
        </StyledHeader>
        <Content>{children}</Content>
        <StyledFooter>
          <FooterContent />
        </StyledFooter>
      </MainArea>
      <StyledSider width={md ? 0 : `${theme[Feature.CORE].layouts.fullPage.sider.width}%`} />
    </StyledLayout>
  );
};

const StyledLayout = styled(Layout)`
  min-height: 100vh;
`;

const StyledHeader = styled.header`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  align-items: center;
`;

const LanguageSelectorWrapper = styled.div`
  padding: 12px 24px;
`;

const MainArea = styled(Layout)`
  background-color: ${props => props.theme[Feature.CORE].layouts.fullPage.mainArea.bgColor};
  box-shadow: ${props => props.theme[Feature.CORE].layouts.fullPage.mainArea.boxShadow};
`;

const StyledFooter = styled(Footer)`
  background-color: ${props => props.theme[Feature.CORE].layouts.fullPage.footer.bgColor};
`;

const StyledSider = styled(Sider)`
  background-color: ${props => props.theme[Feature.CORE].layouts.fullPage.sider.bgColor} !important;
`;
