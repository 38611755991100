import cron from 'cron-validate';
import cronstrue from 'cronstrue/i18n';
import { Feature } from '../../shared/models/features';
import { Language } from '../../shared/models/language';
import { LocalizedValidator } from '../../shared/models/validator';
import { isArrayOfStrings } from '../../shared/utils/types';

export function isValidCRON(value: string): boolean {
  return value != null && cron(value, { preset: 'npm-node-cron' }).isValid();
}

export function convertLanguageToCronstrueLocale(language: Language) {
  switch (language) {
    case Language.German:
      return 'de';
    case Language.EnglishGB:
    default:
      return 'en';
  }
}

export function formatCron(cron: string, language = Language.EnglishGB) {
  return cronstrue.toString(cron, {
    locale: convertLanguageToCronstrueLocale(language),
    dayOfWeekStartIndexZero: true,
    monthStartIndexZero: true,
    use24HourTimeFormat: true,
  });
}

export const cronValidator: LocalizedValidator<Feature.AUTOMATION> = (t, _rule, value) => {
  if (
    value == null ||
    (typeof value === 'string' && isValidCRON(value)) ||
    (isArrayOfStrings(value) && value.every(val => isValidCRON(val)))
  ) {
    return Promise.resolve();
  }

  return Promise.reject(new Error(t(`${Feature.AUTOMATION}:shared.cronError`)));
};
