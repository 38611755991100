import { ResourceSearchResult } from '../../../shared/models/api/response';
import { convertDateRangeToISO } from '../../../shared/utils/date';
import { cleanObject } from '../../../shared/utils/object';
import { AnyApiResponse } from '../../../shared/utils/types';
import { ViewUpsertFormValues } from '../../components/view-upsert-form';
import { ViewsTableFilters } from '../../components/views-table';
import { View, ViewsFilters, ViewUpsert } from '../../models/view';

export function convertSearchResponse(response: AnyApiResponse): ResourceSearchResult<View> {
  return {
    total: response.total,
    resources: response.views,
  };
}

export function convertTableFiltersToFilters(tableFilters: ViewsTableFilters): ViewsFilters {
  const viewsFilters: ViewsFilters = {
    name: tableFilters.name,
    viewName: tableFilters.viewName,
    schemaName: tableFilters.schemaName,
    status: tableFilters.status,
  };

  if (tableFilters.createdAt != null) {
    viewsFilters.createdAt = convertDateRangeToISO(tableFilters.createdAt);
  }
  if (tableFilters.updatedAt != null) {
    viewsFilters.updatedAt = convertDateRangeToISO(tableFilters.updatedAt);
  }

  return viewsFilters;
}

export function convertToUpsertRequest(view: Partial<ViewUpsert>): AnyApiResponse {
  const request: AnyApiResponse = {
    view: {
      name: view.name,
      sourceId: view.sourceId,
      groupId: view.groupId,
      viewName: view.viewName,
      schemaName: view.schemaName,
      selectStatement: view.selectStatement,
    },
  };

  return { ...request, view: cleanObject(request.view) };
}

export function convertFormValuesToView(values: Partial<ViewUpsertFormValues>): ViewUpsert {
  return {
    name: values.name,
    sourceId: values.source,
    groupId: values.tenant,
    viewName: values.viewName,
    schemaName: values.schemaName,
    selectStatement: values.sql,
  };
}
