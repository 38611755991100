import { TableProps } from 'antd';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getLanguageFromI18nLanguage } from '../../../shared/utils/language';
import { translationNamespace } from '../../constants/translation-resources';
import { Preset } from '../../models/preset';
import { getColumns } from './columns';
import { CustomTable } from '../../../shared/components/table';
import { permissionSelector } from '../../../shared/data/store/selectors/auth-selectors';

export const PresetsTable: FC<Omit<TableProps<Preset>, 'columns' | 'rowKey'>> = props => {
  const { t, i18n } = useTranslation(translationNamespace);
  const permissions = useSelector(permissionSelector);

  const columns = useMemo(
    () => getColumns(t, getLanguageFromI18nLanguage(i18n.language), permissions),
    [i18n.language, t, permissions]
  );

  return <CustomTable<Preset> columns={columns} rowKey={preset => preset.id} {...props} />;
};
