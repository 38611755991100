import { ArrayElement, Values } from '../../shared/utils/types';

export const DocumentActions = {
  APPROVED_QUANTITY: 'approved_quantity',
  APPROVED_LAYOUT: 'approved_layout',
  APPROVED_BOTH: 'approved',
  REJECTED: 'rejected',
  WAITING: 'waiting',
} as const;

export type DocumentAction = Values<typeof DocumentActions>;

//TODO: this should be on IFC repo
export const DocumentFiltersTypes = ['string', 'dateRange', 'integerRange'] as const;
export type DocumentFiltersType = ArrayElement<typeof DocumentFiltersTypes> | string[];
