import '@react-awesome-query-builder/antd/css/compact_styles.css';
import { Alert, Divider, Empty, Form, FormInstance, Input, Select, Tabs } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  Builder,
  BuilderProps,
  Config,
  ImmutableTree,
  JsonGroup,
  Query,
  Utils as QbUtils,
} from '@react-awesome-query-builder/antd';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import { css } from '@emotion/react';

import { useFindTenantsQuery } from '../../../access/data/queries/tenant-queries';
import { Feature } from '../../../shared/models/features';
import { defaultQueryBuilderConfig } from '../../constants/query-builder-config';
import { translationNamespace } from '../../constants/translation-resources';
import { useFindSourcesQuery, useGetSourceQuery } from '../../data/queries/source-queries';
import { View } from '../../models/view';
import { redundantSpacebarsRule } from '../../../shared/utils/form-rules';
import { SourceStatuses } from '../../constants/source';

const { TextArea } = Input;

export enum DataQueryTabKey {
  QUERY_BUILDER = '1',
  RAW_SQL = '2',
}

export enum ViewUpsertFormField {
  NAME = 'name',
  SOURCE = 'source',
  TENANT = 'tenant',
  VIEW_NAME = 'viewName',
  SCHEMA_NAME = 'schemaName',
  SQL = 'sql',
}

export interface ViewUpsertFormValues {
  [ViewUpsertFormField.NAME]: string;
  [ViewUpsertFormField.SOURCE]: number;
  [ViewUpsertFormField.TENANT]?: number;
  [ViewUpsertFormField.VIEW_NAME]?: string;
  [ViewUpsertFormField.SCHEMA_NAME]?: string;
  [ViewUpsertFormField.SQL]: string;
}

interface Props {
  form: FormInstance<ViewUpsertFormValues>;
  onSubmit?: (values: ViewUpsertFormValues) => void;
  initialValues?: Partial<ViewUpsertFormValues>;
  view?: View;
  viewTenantLoading?: boolean;
}

const initialQueryBuilderValue: JsonGroup = { id: QbUtils.uuid(), type: 'group' };

export const ViewUpsertForm: FC<Props> = ({ form, onSubmit, initialValues, view, viewTenantLoading }) => {
  const { t } = useTranslation(translationNamespace);
  const [queryBuilderConfig, setQueryBuilderConfig] = useState(defaultQueryBuilderConfig);
  const [queryBuilderTree, setQueryBuilderTree] = useState(() =>
    QbUtils.checkTree(QbUtils.loadTree(initialQueryBuilderValue), queryBuilderConfig)
  );
  const [tenantsSearchValue, setTenantsSearchValue] = useState<string>();
  const [tenantsDebouncedSearchValue, setTenantsDebouncedSearchValue] = useState<string>();
  const { data: tenantsData, isLoading: tenantsLoading } = useFindTenantsQuery({
    page: 1,
    pageSize: 25,
    searchValue: tenantsDebouncedSearchValue,
    attributes: ['id', 'name'],
  });
  const [sourcesSearchValue, setSourcesSearchValue] = useState<string>();
  const [sourcesDebouncedSearchValue, setSourcesDebouncedSearchValue] = useState<string>();
  const { data: sourcesData, isLoading: sourcesLoading } = useFindSourcesQuery({
    page: 1,
    pageSize: 25,
    searchValue: sourcesDebouncedSearchValue,
    attributes: ['id', 'name', 'status'],
  });
  const [selectedSourceId, setSelectedSourceId] = useState<number>();
  const { data: selectedSourceData, isLoading: selectedSourceLoading } = useGetSourceQuery(selectedSourceId);
  const [activeTab, setActiveTab] = useState<DataQueryTabKey>(DataQueryTabKey.RAW_SQL);

  const tenantSelectOptions = useMemo(() => {
    return tenantsData?.resources.map((tenant, index) => (
      <Select.Option key={index} value={tenant.id}>
        {tenant.name}
      </Select.Option>
    ));
  }, [tenantsData?.resources]);

  const sourceSelectOptions = useMemo(() => {
    return sourcesData?.resources
      .filter(source => source.status === SourceStatuses.VALID)
      .map((source, index) => (
        <Select.Option key={index} value={source.id}>
          {source.name}
        </Select.Option>
      ));
  }, [sourcesData?.resources]);

  const schemaSelectOptions = useMemo(() => {
    return selectedSourceData?.schema_list.map((schema, index) => (
      <Select.Option key={index} value={schema}>
        {schema}
      </Select.Option>
    ));
  }, [selectedSourceData]);

  const setDataSource = (id: number) => {
    setSelectedSourceId(id);
  };

  const applySourcesSearch = (value: string) => {
    setSourcesSearchValue(value);
    setSourcesDebouncedSearchValue(value);
  };

  const applyTenantsSearch = (value: string) => {
    setTenantsSearchValue(value);
    setTenantsDebouncedSearchValue(value);
  };

  const clearTenantSearchValue = () => {
    setTenantsSearchValue('');
    setTenantsDebouncedSearchValue('');
  };

  const renderQueryBuilder = (props: BuilderProps) => {
    return (
      <div className="query-builder-container" style={{ padding: '10px' }}>
        <div className="query-builder qb-lite" css={noMarginStyles}>
          <Builder {...props} />
        </div>
      </div>
    );
  };

  const setQueryBuilderState = (value: ImmutableTree, config: Config) => {
    setQueryBuilderTree(value);
    setQueryBuilderConfig(config);
  };

  const switchTab = (activeKey: DataQueryTabKey) => {
    setActiveTab(activeKey);
  };

  const viewItems = [
    {
      label: t('shared.dataQuery.tabs.query'),
      key: DataQueryTabKey.QUERY_BUILDER,
      disabled: true,
      children: (
        <Form.Item wrapperCol={{ span: 24 }} extra={t('shared.dataQuery.queryBuilderHelp')}>
          <Query
            {...queryBuilderConfig}
            value={queryBuilderTree}
            onChange={setQueryBuilderState}
            renderBuilder={renderQueryBuilder}
          />
        </Form.Item>
      ),
    },
    {
      label: t('shared.dataQuery.tabs.rawSql'),
      key: DataQueryTabKey.RAW_SQL,
      children: (
        <Form.Item wrapperCol={{ span: 20, xl: { span: 14 } }}>
          <Alert message={t('shared.dataQuery.advancedModeWarning')} type="warning" css={marginBottomStyles} />
          <Form.Item
            name={ViewUpsertFormField.SQL}
            messageVariables={{ label: t('shared.sql.label') }}
            rules={[{ required: true }]}
          >
            <TextArea
              disabled={view != null}
              autoSize
              allowClear
              placeholder={t('shared.sql.placeholder')}
              contentEditable={false}
            />
          </Form.Item>
        </Form.Item>
      ),
    },
  ];

  useDebounce(
    () => {
      setTenantsDebouncedSearchValue(tenantsSearchValue);
    },
    3000,
    [tenantsSearchValue]
  );

  useDebounce(
    () => {
      setSourcesDebouncedSearchValue(sourcesSearchValue);
    },
    3000,
    [sourcesSearchValue]
  );

  useEffect(() => {
    if (view != null) {
      setDataSource(view.sourceId);
    }
  }, [view]);

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      layout="horizontal"
      labelCol={{ lg: 4, xl: 4 }}
      wrapperCol={{ lg: 16, xl: 10 }}
      requiredMark={false}
      initialValues={initialValues}
    >
      <Form.Item
        label={t(`${Feature.SHARED}:name`)}
        name={ViewUpsertFormField.NAME}
        rules={[{ required: true, max: 180 }, redundantSpacebarsRule(t)]}
      >
        <Input placeholder={t('views.upsert.name.placeholder')} />
      </Form.Item>
      <Form.Item label={t(`${Feature.SHARED}:tenant.label`)} name={ViewUpsertFormField.TENANT}>
        <Select
          allowClear={view == null}
          showSearch
          filterOption={false}
          placeholder={t('shared.selectTenant')}
          loading={tenantsLoading || viewTenantLoading}
          onSearch={applyTenantsSearch}
          onSelect={clearTenantSearchValue}
        >
          {tenantSelectOptions}
        </Select>
      </Form.Item>
      <Form.Item label={t('shared.source.label')} name={ViewUpsertFormField.SOURCE} rules={[{ required: true }]}>
        <Select
          disabled={view != null}
          allowClear
          showSearch
          filterOption={false}
          placeholder={t('shared.source.placeholder')}
          loading={sourcesLoading}
          onSearch={applySourcesSearch}
          onSelect={setDataSource}
        >
          {sourceSelectOptions}
        </Select>
      </Form.Item>
      <Form.Item
        label={t('views.shared.viewName.label')}
        name={ViewUpsertFormField.VIEW_NAME}
        rules={[{ required: true, max: 128 }, redundantSpacebarsRule(t)]}
      >
        <Input disabled={view != null} placeholder={t('views.upsert.viewName.placeholder')} />
      </Form.Item>
      <Form.Item
        label={t('views.shared.schemaName.label')}
        name={ViewUpsertFormField.SCHEMA_NAME}
        rules={[redundantSpacebarsRule(t)]}
      >
        <Select
          notFoundContent={<Empty description={t('shared.source.noSourceSelected')} />}
          placeholder={t('views.upsert.schemaName.placeholder')}
          loading={selectedSourceLoading}
          disabled={view != null}
        >
          {schemaSelectOptions}
        </Select>
      </Form.Item>
      <Divider orientation="left">{t('shared.dataQuery.title')}</Divider>
      <Tabs activeKey={activeTab} onTabClick={switchTab} type="card" items={viewItems} />
    </Form>
  );
};

const noMarginStyles = css`
  margin: 0;
`;

const marginBottomStyles = css`
  margin-bottom: 8px;
`;
