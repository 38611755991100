import { Button, Col, Form, Modal, ModalProps, Row, Select, Space } from 'antd';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import { CheckOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

import { Feature } from '../../../shared/models/features';
import { translationNamespace } from '../../constants/translation-resources';
import { useFindTenantsQuery } from '../../data/queries/tenant-queries';
import { noDuplicatesRule } from '../../../shared/utils/form-rules';
import { permissionSelector } from '../../../shared/data/store/selectors/auth-selectors';

enum AssignTenantSubtenantFormField {
  SUBTENANTS = 'subtenants',
}

export interface AssignTenantSubtenantFormValues {
  [AssignTenantSubtenantFormField.SUBTENANTS]: number[];
}

interface AssignTenantSubtenantModalProps extends ModalProps {
  onSubmit?: (values: AssignTenantSubtenantFormValues, resetForm: () => void) => void;
  onInvite?: () => void;
  tenantId: number;
}

const tenantIdsInitialValue: string[] = [''];

export const AssignTenantSubtenantModal: FC<AssignTenantSubtenantModalProps> = ({
  onSubmit,
  onInvite,
  onOk,
  onCancel,
  okButtonProps,
  tenantId,
  ...props
}) => {
  const permissions = useSelector(permissionSelector);
  const { t } = useTranslation(translationNamespace);
  const [form] = Form.useForm<AssignTenantSubtenantFormValues>();
  const [searchValue, setSearchValue] = useState<string>();
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>();
  const { data, isLoading } = useFindTenantsQuery(
    {
      page: 1,
      pageSize: 25,
      searchValue: debouncedSearchValue,
      attributes: ['id', 'name'],
    },
    props.open
  );

  const subtenantSelectOptions = useMemo(() => {
    return data?.resources?.reduce((acc, group, index) => {
      group.id !== tenantId &&
        acc.push(
          <Select.Option key={index} value={group.id}>
            {group.name}
          </Select.Option>
        );

      return acc;
    }, new Array<React.ReactNode>());
  }, [data?.resources, tenantId]);

  const confirmButtonProps: ModalProps['okButtonProps'] = {
    ...okButtonProps,
    icon: <CheckOutlined />,
    type: 'primary',
    htmlType: 'submit',
  };

  const clearSearchValue = () => {
    setSearchValue('');
    setDebouncedSearchValue('');
  };

  const triggerSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    form.submit();
    clearSearchValue();
    onOk?.(event);
  };

  const submit = (values: AssignTenantSubtenantFormValues) => {
    onSubmit?.(values, form.resetFields);
  };

  const handleInvite = () => {
    onInvite?.();
  };

  const applySearch = (value: string) => {
    setSearchValue(value);
    setDebouncedSearchValue(value);
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    form.resetFields();
    clearSearchValue();
    onCancel(e);
  };

  useDebounce(
    () => {
      setDebouncedSearchValue(searchValue);
    },
    3000,
    [searchValue]
  );

  return (
    <Modal
      {...props}
      centered
      title={t('tenants.single.assignSubtenantModal.title')}
      okText={t(`${Feature.SHARED}:confirm`)}
      cancelText={t(`${Feature.SHARED}:cancel`)}
      okButtonProps={confirmButtonProps}
      onOk={triggerSubmit}
      onCancel={handleCancel}
      footer={
        <Space>
          <Button htmlType="button" onClick={handleCancel}>
            {t(`${Feature.SHARED}:cancel`)}
          </Button>
          {permissions.TENANT.CREATE && (
            <Button htmlType="button" onClick={handleInvite}>
              {t('tenants.single.assignSubtenantModal.addNew')}
            </Button>
          )}
          <Button {...confirmButtonProps} onClick={triggerSubmit}>
            {t(`${Feature.SHARED}:confirm`)}
          </Button>
        </Space>
      }
    >
      <Form form={form} layout="horizontal" requiredMark={false} onFinish={submit}>
        <Form.List name={AssignTenantSubtenantFormField.SUBTENANTS} initialValue={tenantIdsInitialValue}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item key={field.key}>
                  <Row gutter={[16, 24]} align="middle">
                    <Col span={22}>
                      <Form.Item
                        {...field}
                        label={t(`${Feature.SHARED}:name`)}
                        name={field.name}
                        rules={[
                          { required: true },
                          noDuplicatesRule(
                            form,
                            AssignTenantSubtenantFormField.SUBTENANTS,
                            t('tenants.single.assignSubtenantModal.duplicateWarning')
                          ),
                        ]}
                        css={noMarginBottomStyles}
                      >
                        <Select
                          showSearch
                          filterOption={false}
                          loading={isLoading}
                          onSearch={applySearch}
                          onSelect={clearSearchValue}
                        >
                          {subtenantSelectOptions}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={2} css={removeIconStyles}>
                      {index !== 0 && fields.length > 1 && (
                        <MinusCircleOutlined
                          onClick={() => {
                            remove(field.name);
                            setTimeout(() => {
                              form.validateFields();
                            }, 0);
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                </Form.Item>
              ))}
              <Form.Item>
                <StyledButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  {t('tenants.single.addSubtenant')}
                </StyledButton>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

const noMarginBottomStyles = css`
  margin-bottom: 0;
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 180px;
`;

const removeIconStyles = css`
  padding: 9px 8px;
  display: flex;
  align-self: flex-start;
`;
