import axios from 'axios';
import { FindAllSchedulesRequest, FindAllSchedulesResponse, SchedulerAttributes } from '@pccr-ifc/pccr-ifc';

import { Config } from '../../../shared/models/config';
import { useService } from '../../../shared/hooks/use-service';

function createJobService(config: Config) {
  const BASE_PATH = '/schedule';

  const findJobs = (request: FindAllSchedulesRequest) =>
    axios.post<FindAllSchedulesResponse>(`${config.API_URL}${BASE_PATH}/findAll`, request, {
      withCredentials: true,
    });

  const getJob = async (id: number) => {
    const response = await axios.get<{ schedule: SchedulerAttributes }>(`${config.API_URL}${BASE_PATH}/${id}`, {
      withCredentials: true,
    });
    return response.data.schedule;
  };

  const createJob = async (request: { schedule: Omit<SchedulerAttributes, 'id'> }) => {
    const response = await axios.post<{ schedule: SchedulerAttributes }>(`${config.API_URL}${BASE_PATH}/add`, request, {
      withCredentials: true,
    });
    return response.data.schedule;
  };

  const updateJob = async ({ id, ...request }: Partial<SchedulerAttributes>) => {
    const response = await axios.patch<{ schedule: SchedulerAttributes }>(
      `${config.API_URL}${BASE_PATH}/${id}`,
      { schedule: request },
      {
        withCredentials: true,
      }
    );
    return response.data.schedule;
  };

  const deleteJob = (id: number) =>
    axios.delete<void>(`${config.API_URL}${BASE_PATH}/${id}`, {
      withCredentials: true,
    });

  return { findJobs, getJob, createJob, updateJob, deleteJob };
}

export const useJobService = () => useService(createJobService);
