import { Button, Card, Col, Dropdown, Form, message, Row, Space } from 'antd';
import { MenuProps } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { CheckOutlined, DownOutlined } from '@ant-design/icons';
import { useToggle } from 'react-use';

import { ConfirmationModal } from '../../../shared/components/confirmation-modal';
import { setResourceName } from '../../../shared/data/store/slices/nav-slice';
import { Feature } from '../../../shared/models/features';
import { ResourceParams } from '../../../shared/models/resource-params';
import { PickSomeRestPartial } from '../../../shared/utils/types';
import { ViewDetailsDrawer } from '../../components/view-details-drawer';
import { ViewUpsertForm, ViewUpsertFormField, ViewUpsertFormValues } from '../../components/view-upsert-form';
import { translationNamespace } from '../../constants/translation-resources';
import { convertFormValuesToView } from '../../data/converters/view-converters';
import { useDeleteViewMutation, useUpdateViewMutation } from '../../data/mutations/view-mutations';
import { useGetViewQuery } from '../../data/queries/view-queries';
import { View } from '../../models/view';
import { AppResourcePaths } from '../../../shared/constants/app-resources';
import { displayErrors } from '../../../shared/utils/error';
import { useGetTenantQuery } from '../../../access/data/queries/tenant-queries';
import { returnChangedObjectProperties } from '../../../shared/utils/object';

export const ViewSingleView: FC = () => {
  const { t } = useTranslation(translationNamespace);
  const dispatch = useDispatch();
  const { push: routerPush } = useHistory();
  const { id } = useParams<ResourceParams>();
  const [form] = Form.useForm<ViewUpsertFormValues>();
  const [showDetails, toggleShowDetails] = useToggle(false);
  const [showConfirmDeleteModal, toggleShowConfirmDeleteModal] = useToggle(false);
  const parsedId = Number(id);
  const { data: view, isSuccess, isLoading, isFetching } = useGetViewQuery(parsedId);
  const { data: tenant, isLoading: viewTenantLoading } = useGetTenantQuery(view?.groupId);
  const updateMutation = useUpdateViewMutation();
  const deleteMutation = useDeleteViewMutation();
  const [initialFieldsValue, setInitialFieldsValue] = useState<ViewUpsertFormValues>();

  const areRequestsLoading = isLoading || updateMutation.isLoading || deleteMutation.isLoading;

  const submit = (values: ViewUpsertFormValues) => {
    if (view?.id == null) {
      return;
    }

    const changedProperties = returnChangedObjectProperties(initialFieldsValue, values);

    if (changedProperties == null) {
      message.warning(t(`${Feature.SHARED}:nothingToUpdate`));
      return;
    }

    const newView: PickSomeRestPartial<View, 'id'> = {
      id: view.id,
      ...convertFormValuesToView(changedProperties),
    };

    updateMutation.mutate(newView, {
      onSuccess: () => {
        message.success(t('views.shared.updateSuccessMessage'));
      },
      onError: error => displayErrors(error, t),
    });
  };

  const remove = () => {
    if (view.id == null) {
      return;
    }

    deleteMutation.mutate(
      { id: view.id },
      {
        onSuccess: () => {
          message.success(t('views.shared.deleteSuccessMessage'));
          routerPush(AppResourcePaths.views);
        },
        onError: error => displayErrors(error, t),
      }
    );
  };

  const triggerSubmit = () => {
    form.submit();
  };

  const items: MenuProps['items'] = [
    {
      key: 'remove',
      onClick: () => toggleShowConfirmDeleteModal(),
      label: t(`${Feature.SHARED}:remove`),
    },
  ];

  useEffect(() => {
    if (view) {
      const fieldsValue = {
        [ViewUpsertFormField.NAME]: view.name,
        [ViewUpsertFormField.TENANT]: tenant == null ? null : view.groupId,
        [ViewUpsertFormField.SOURCE]: view.sourceId,
        [ViewUpsertFormField.VIEW_NAME]: view.viewName,
        [ViewUpsertFormField.SCHEMA_NAME]: view.schemaName,
        [ViewUpsertFormField.SQL]: view.selectStatement,
      };

      form.setFieldsValue(fieldsValue);
      setInitialFieldsValue(fieldsValue);
    }
  }, [form, tenant, view]);

  useEffect(() => {
    dispatch(setResourceName(view?.name));
  }, [dispatch, view?.name]);

  return (
    <Row justify="space-between" gutter={[16, 24]}>
      <Col />
      <Col>
        <Space>
          <Button size="large" onClick={toggleShowDetails} loading={areRequestsLoading} disabled={!isSuccess}>
            {t(`${Feature.SHARED}:details`)}
          </Button>
          <Dropdown menu={{ items }} trigger={['click']} disabled={!view}>
            <Button size="large" loading={areRequestsLoading} disabled={!isSuccess}>
              {t(`${Feature.SHARED}:actions`)} <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      </Col>
      <Col span={24}>
        <Card title={t(`${Feature.SHARED}:details`)}>
          <ViewUpsertForm view={view} form={form} onSubmit={submit} viewTenantLoading={viewTenantLoading} />
        </Card>
      </Col>
      <Col>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            icon={<CheckOutlined />}
            loading={areRequestsLoading || isFetching}
            onClick={triggerSubmit}
            disabled={!view}
          >
            {t(`${Feature.SHARED}:save`)}
          </Button>
          <Link to={AppResourcePaths.views}>
            <Button htmlType="button" size="large">
              {t(`${Feature.SHARED}:cancel`)}
            </Button>
          </Link>
        </Space>
      </Col>
      {view && <ViewDetailsDrawer view={view} open={showDetails} onClose={toggleShowDetails} />}
      <ConfirmationModal
        open={showConfirmDeleteModal}
        title={t(`${Feature.SHARED}:areYouSure`)}
        onCancel={toggleShowConfirmDeleteModal}
        onOk={remove}
        content={t(`${Feature.SHARED}:confirmDeleteMsg`, {
          resourceName: view?.name,
          buttonTitle: t(`${Feature.SHARED}:confirm`),
        })}
      />
    </Row>
  );
};
