import { Button, Card, Col, Form, Input, Row, Space } from 'antd';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { useDebounce, useToggle } from 'react-use';
import { DownOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';

import { useTableOptions } from '../../../shared/hooks/use-data-options';
import { Feature } from '../../../shared/models/features';
import { ViewsTable, ViewsTableFilters, ViewsTableFiltersForm } from '../../components/views-table';
import { translationNamespace } from '../../constants/translation-resources';
import { convertTableFiltersToFilters } from '../../data/converters/view-converters';
import { useFindViewsQuery } from '../../data/queries/view-queries';
import { View, ViewsFilters } from '../../models/view';
import { Orders } from '../../../shared/constants/order';
import { useGetUserAllowedRoutesQuery } from '../../../core/data/queries/core-queries';
import { isUserAuthorized } from '../../../shared/utils/allowed-routes';
import { createViewRequiredRoute } from '../../constants/required-routes';

const { Search } = Input;

export const ViewsListView: FC = () => {
  const { t } = useTranslation(translationNamespace);
  const [filtersForm] = Form.useForm<ViewsTableFilters>();
  const { page, setPage, pageSize, order, handleTableChange } = useTableOptions<View>([['createdAt', Orders.DESC]]);
  const [showFilters, toggleShowFilters] = useToggle(false);
  const [filters, setFilters] = useState<ViewsFilters>(null);
  const [searchValue, setSearchValue] = useState<string>();
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>();
  const { data, isLoading } = useFindViewsQuery({ page, pageSize, searchValue: debouncedSearchValue, filters, order });
  const { url } = useRouteMatch();
  const { data: userAllowedRoutes } = useGetUserAllowedRoutesQuery();

  const applyFilters = (filters: ViewsTableFilters) => {
    const viewsFilters = convertTableFiltersToFilters(filters);

    setSearchValue(null);
    setDebouncedSearchValue(null);
    setFilters(viewsFilters);
    setPage(1);
  };

  const applySearch = (value: string) => {
    setFilters(null);
    filtersForm.resetFields();
    setSearchValue(value);
    setDebouncedSearchValue(value);
    setPage(1);
  };

  useDebounce(
    () => {
      if (searchValue != null) {
        setFilters(null);
        filtersForm.resetFields();
        setPage(1);
      }
      setDebouncedSearchValue(searchValue);
    },
    3000,
    [searchValue]
  );

  return (
    <Row justify="space-between" gutter={[16, 24]}>
      <Col>
        <Space>
          <StyledSearch
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            placeholder={t(`${Feature.SHARED}:search`)}
            onSearch={applySearch}
            allowClear
            size="large"
          />
          <Button type="link" onClick={toggleShowFilters}>
            {t(`${Feature.SHARED}:filters`)} <StyledDownOutlined rotate={showFilters ? 180 : 0} />
          </Button>
        </Space>
      </Col>
      <Col>
        <Link to={`${url}/new`}>
          <Button type="primary" size="large" hidden={!isUserAuthorized(createViewRequiredRoute, userAllowedRoutes)}>
            {t('views.list.new')}
          </Button>
        </Link>
      </Col>
      {showFilters && (
        <Col span={24}>
          <Card title={t(`${Feature.SHARED}:filters`)}>
            <ViewsTableFiltersForm form={filtersForm} onApply={applyFilters} />
          </Card>
        </Col>
      )}
      <Col span={24}>
        <Card>
          <ViewsTable
            dataSource={data?.resources}
            pagination={{ total: data?.total, current: page, pageSize, showSizeChanger: data?.total > 10 }}
            loading={isLoading}
            onChange={handleTableChange}
            scroll={{ x: 1300 }}
          />
        </Card>
      </Col>
    </Row>
  );
};

const StyledSearch = styled(Search)`
  width: 100%;
  max-width: 320px;
`;

const StyledDownOutlined = styled(DownOutlined)`
  > svg {
    transition: transform 0.2s;
  }
`;
