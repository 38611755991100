import { Table, TableProps } from 'antd';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { translationNamespace } from '../../constants/translation-resources';
import { getColumns } from './columns';
import { Batch } from '../../models/batch';
import { Order } from '../../../shared/models/order';
import { useApprovalService } from '../../data/services/use-approval-service';
import { getLanguageFromI18nLanguage } from '../../../shared/utils/language';

export const BatchesTable: FC<
  Omit<TableProps<Batch>, 'columns' | 'rowKey'> & {
    defaultOrder: [keyof Batch, Order];
  }
> = ({ defaultOrder, ...props }) => {
  const { t, i18n } = useTranslation(translationNamespace);
  const approvalService = useApprovalService();

  const openPdf = useCallback(
    (pdfLink: string) => {
      const url = approvalService.getPdfUrl(pdfLink);

      const win = window.open('', pdfLink, 'location=0');
      win.document.title = pdfLink;

      const iframe = win.document.createElement('iframe');
      iframe.src = url;
      iframe.style.width = '100%';
      iframe.style.height = '100%';
      iframe.style.border = '0';
      win.document.body.appendChild(iframe);
    },
    [approvalService]
  );

  const columns = useMemo(
    () => getColumns(t, getLanguageFromI18nLanguage(i18n.language), defaultOrder, openPdf),
    [t, i18n.language, defaultOrder, openPdf]
  );

  return <Table<Batch> columns={columns} rowKey={batch => batch.wId} {...props} />;
};
