import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { GenericDefinitionResponse as BeDefinition } from '@pccr-ifc/pccr-ifc';

import { CommonFindAllQueryParams, QueryRequest } from '../../../shared/models/api/request';
import { Definition, DefinitionsFilters, DefinitionWithoutColumnTypes } from '../../../shared/models/definition';
import { deleteEmptyArrayObjProps } from '../../../shared/utils/object';
import { convertSearchResponse } from '../converters/definition-converters';
import { useDefinitionSharedService } from '../services/use-definition-service';
import { ResourceSearchResult } from '../../models/api/response';
import { getResourceListQueryKeyRoot, getSingleResourceQueryKey } from '../../utils/resource-query-key';
import { displayErrors } from '../../utils/error';

export function useGetDefinitionQuery(id: number, enabled = true) {
  const definitionService = useDefinitionSharedService();
  const { t } = useTranslation();

  return useQuery<BeDefinition, unknown, Definition>(
    getSingleResourceQueryKey('DEFINITION', id),
    () => definitionService.getDefinition(id),
    {
      onError: error => displayErrors(error, t),
      enabled: id != null && enabled,
      select: data => data as Definition,
    }
  );
}

export function useFindDefinitionsQuery(
  {
    page,
    pageSize,
    searchValue,
    filters,
    order,
    attributes = ['id', 'name', 'type', 'status', 'sourceId', 'groupId', 'groupName', 'updatedAt', 'createdAt'],
  }: CommonFindAllQueryParams<DefinitionWithoutColumnTypes, DefinitionsFilters>,
  enabled = true
) {
  const definitionService = useDefinitionSharedService();

  return useQuery<ResourceSearchResult<Definition>, unknown>(
    [getResourceListQueryKeyRoot('DEFINITION'), { page, pageSize }, searchValue, filters, order, attributes],
    async () => {
      const request: QueryRequest<DefinitionWithoutColumnTypes, DefinitionsFilters> = {
        limit: pageSize,
        offset: (page - 1) * pageSize,
        attributes,
      };

      if (order?.length > 0) {
        request.order = order;
      }

      if (searchValue) {
        request.search = searchValue;
      }

      if (filters && Object.values(filters).some(val => val != null)) {
        request.filters = deleteEmptyArrayObjProps(filters);
      }

      const response = await definitionService.findDefinitions(request);
      return convertSearchResponse(response.data);
    },
    { enabled }
  );
}
