import {
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult,
  useInfiniteQuery,
  useQuery,
} from 'react-query';

import {
  CommonFindAllInfiniteQueryParams,
  CommonFindAllQueryParams,
  QueryRequest,
} from '../../../shared/models/api/request';
import { EntityType, Log } from '../../../shared/models/log';
import { deleteEmptyArrayObjProps } from '../../../shared/utils/object';
import { LogsFilters } from '../../models/log';
import { convertSearchResponse } from '../converters/log-converters';
import { useLogService } from '../services/use-log-service';
import { ResourceSearchResult } from '../../../shared/models/api/response';
import { MultiColumnOrder } from '../../../shared/utils/types';
import { Orders } from '../../../shared/constants/order';
import { AppResource } from '../../../shared/models/app-resource';
import { resourceToEntityMap } from '../../../shared/constants/log';
import { getResourceListQueryKeyRoot } from '../../../shared/utils/resource-query-key';
import { ResourceListQueryKeyRoot } from '../../../shared/models/resource-query-key';

export enum LogQueryKey {
  LOGS_TIMELINE = 'logsTimeline',
}

type LogFindAllQueryParams = CommonFindAllInfiniteQueryParams<Log, LogsFilters>;

type LogsQueryKey = [
  LogQueryKey | ResourceListQueryKeyRoot,
  { page: number; limit: number },
  LogFindAllQueryParams['searchValue'],
  LogFindAllQueryParams['filters'],
  LogFindAllQueryParams['order'],
  LogFindAllQueryParams['attributes']
];

type TimelineLogsQueryKey = [LogQueryKey, EntityType, number | string, LogFindAllQueryParams['filters']?];

type LogData = ResourceSearchResult<Log>;

async function logQueryFn(
  logService: ReturnType<typeof useLogService>,
  limit: number,
  offset: number,
  order: LogFindAllQueryParams['order'],
  attributes: LogFindAllQueryParams['attributes'],
  filters: LogFindAllQueryParams['filters'],
  searchValue?: LogFindAllQueryParams['searchValue']
) {
  const request: QueryRequest<Log, LogsFilters> = { limit, offset, attributes };

  if (order?.length > 0) {
    request.order = order;
  }

  if (searchValue) {
    request.search = searchValue;
  }

  if (filters && Object.values(filters).some(val => val != null)) {
    request.filters = deleteEmptyArrayObjProps(filters);
  }

  const response = await logService.findLogs(request);
  return convertSearchResponse(response.data);
}

export function useFindLogsQuery(
  {
    page,
    pageSize,
    searchValue,
    filters,
    order,
    attributes = ['id', 'path', 'method', 'userId', 'body', 'resourceId', 'createdAt'],
  }: CommonFindAllQueryParams<Log, LogsFilters>,
  queryOptions?: Omit<UseQueryOptions<LogData, unknown, LogData, LogsQueryKey>, 'queryKey' | 'queryFn'>
): UseQueryResult<LogData, unknown> {
  const logService = useLogService();

  return useQuery<LogData, unknown>(
    [getResourceListQueryKeyRoot('LOG'), { pageSize, page }, searchValue, filters, order, attributes],
    () => logQueryFn(logService, pageSize, (page - 1) * pageSize, order, attributes, filters, searchValue),
    queryOptions
  );
}

const TIMELINE_PAGE_SIZE = 5;
const TIMELINE_ORDER: MultiColumnOrder<Log> = [['createdAt', Orders.DESC]];
const TIMELINE_ATTRIBUTES: (keyof Log)[] = ['path', 'method', 'userId', 'body', 'createdAt'];

export function useLogsQueryForTimeline(
  filters: LogsFilters,
  queryOptions?: Omit<
    UseInfiniteQueryOptions<LogData, unknown, LogData, LogData, TimelineLogsQueryKey>,
    'queryKey' | 'queryFn'
  >
): UseInfiniteQueryResult<LogData, unknown> {
  const logService = useLogService();
  const {
    entityType,
    resourceId: [resourceId],
    ...otherFilters
  } = filters;
  return useInfiniteQuery<LogData, unknown, LogData, TimelineLogsQueryKey>(
    [LogQueryKey.LOGS_TIMELINE, entityType, resourceId, otherFilters],
    ({ pageParam }) => {
      return logQueryFn(logService, TIMELINE_PAGE_SIZE, pageParam, TIMELINE_ORDER, TIMELINE_ATTRIBUTES, filters);
    },
    {
      refetchOnWindowFocus: true,
      ...queryOptions,
      getNextPageParam: (lastPage, allPages) => {
        const fetchedCount = allPages.reduce((count, page) => count + page.resources.length, 0);
        if (fetchedCount >= lastPage.total) return undefined;
        return fetchedCount;
      },
    }
  );
}

export function getLogsQueryKeyForTimeline(
  resourceType: AppResource,
  resourceId: number | string
): TimelineLogsQueryKey {
  return [LogQueryKey.LOGS_TIMELINE, resourceToEntityMap[resourceType], resourceId];
}
