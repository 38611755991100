import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import { CommonFindAllQueryParams, QueryRequest } from '../../../shared/models/api/request';
import { Order } from '../../../shared/models/order';
import { deleteEmptyArrayObjProps } from '../../../shared/utils/object';
import { ReportDataSearchResults } from '../../models/api/report';
import { Report, ReportData, ReportDataFilters, ReportSearchResult, ReportsFilters } from '../../models/report';
import { convertSearchResponse } from '../converters/report-converters';
import { useReportService } from '../services/use-report-service';
import { getResourceListQueryKeyRoot, getSingleResourceQueryKey } from '../../../shared/utils/resource-query-key';
import { displayErrors } from '../../../shared/utils/error';

export enum ReportQueryKey {
  REPORT_DATA = 'reportData',
}

export function useGetReportQuery(uuid: string) {
  const reportService = useReportService();
  const { t } = useTranslation();

  return useQuery<Report, unknown>(
    getSingleResourceQueryKey('REPORT', uuid),
    async () => {
      const response = await reportService.getReport(uuid);
      return response as Report;
    },
    {
      enabled: uuid != null,
      onError: error => displayErrors(error, t),
    }
  );
}

export function useFindReportsQuery(
  {
    page,
    pageSize,
    searchValue,
    filters,
    order,
    attributes = ['uuid', 'name', 'definitionId', 'executionTime', 'status', 'expireDate', 'createdAt', 'updatedAt'],
  }: CommonFindAllQueryParams<Report, ReportsFilters>,
  enabled = true
) {
  const reportService = useReportService();

  return useQuery<ReportSearchResult, unknown>(
    [getResourceListQueryKeyRoot('REPORT'), { page, pageSize }, searchValue, filters, order, attributes],
    async () => {
      const request: QueryRequest<Report, ReportsFilters> = {
        limit: pageSize,
        offset: (page - 1) * pageSize,
        attributes,
      };

      if (order?.length > 0) {
        request.order = order;
      }

      if (searchValue) {
        request.search = searchValue;
      }

      if (filters && Object.values(filters).some(val => val != null)) {
        request.filters = deleteEmptyArrayObjProps(filters);
      }

      const response = await reportService.findReports(request);
      return convertSearchResponse(response.data);
    },
    { enabled }
  );
}

export function useFindReportDataQuery(
  uuid: string,
  page = 1,
  pageSize = 10,
  searchValue?: string,
  filters?: ReportDataFilters,
  order?: [keyof ReportData, Order][],
  attributes?: (keyof ReportData)[]
) {
  const reportService = useReportService();

  return useQuery<ReportDataSearchResults, unknown>(
    [ReportQueryKey.REPORT_DATA, uuid, { page, pageSize }, searchValue, filters, order, attributes],
    async () => {
      const request: QueryRequest<ReportData, ReportDataFilters> = {
        limit: pageSize,
        offset: (page - 1) * pageSize,
        attributes,
      };

      if (order?.length > 0) {
        request.order = order;
      }

      if (searchValue) {
        request.search = searchValue;
      }

      if (filters && Object.values(filters).some(val => val != null)) {
        request.filters = deleteEmptyArrayObjProps(filters);
      }

      const response = await reportService.getReportDetails(uuid, request);
      return response.data as ReportDataSearchResults;
    },
    {
      enabled: uuid != null && attributes?.length > 0,
      refetchInterval: data => (data?.report.executionTime == null ? 30 * 1000 : false),
      refetchOnWindowFocus: false,
    }
  );
}
