import { ExportType } from '../models/export';

export const ExportTypes = {
  JSON: 'JSON',
  PDF: 'PDF',
  CSV: 'CSV',
  XLSX: 'XLSX',
} as const;

export const compressableExportTypes: ExportType[] = [ExportTypes.JSON, ExportTypes.CSV];

export const ExportStatuses = {
  STARTED: 'STARTED',
  FAILED: 'FAILED',
  FINISHED: 'FINISHED',
} as const;
