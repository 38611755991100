import { TableColumnType } from 'antd';
import { TFunction } from 'react-i18next';

import { LinkedName } from '../../../shared/components/linked-name';
import { Feature } from '../../../shared/models/features';
import { Language } from '../../../shared/models/language';
import { formatISODate } from '../../../shared/utils/date';
import { Preset } from '../../models/preset';
import { Actions } from './actions';
import { AppResources } from '../../../shared/constants/app-resources';
import { PermissionsState } from '../../../shared/data/store';

export const getColumns = (
  t: TFunction<Feature.REPORTS>,
  language: Language,
  permissions: PermissionsState
): TableColumnType<Preset>[] => {
  return [
    {
      title: t(`${Feature.SHARED}:name`),
      dataIndex: 'name',
      render: (name: Preset['name'], preset: Preset) => <LinkedName name={name} to={`presets/${preset.id}`} />,
      sorter: true,
    },
    {
      title: t(`${Feature.SHARED}:definition.label`),
      dataIndex: 'definition',
      render: (definition: Preset['definition']) => definition?.name,
    },
    {
      title: t(`${Feature.SHARED}:createdAt`),
      dataIndex: 'createdAt',
      render: (createdAt: Preset['createdAt']) => formatISODate(createdAt, language),
      sorter: true,
      width: 130,
      defaultSortOrder: 'descend',
    },
    {
      title: t(`${Feature.SHARED}:updatedAt`),
      dataIndex: 'updatedAt',
      render: (updatedAt: Preset['updatedAt']) => formatISODate(updatedAt, language),
      sorter: true,
      width: 130,
    },
    {
      key: 'action',
      align: 'right',
      render: (_, preset) => <Actions preset={preset} />,
      width: 130,
      hidden: !permissions[AppResources.PRESET]['UPDATE'] && !permissions[AppResources.PRESET]['DELETE'],
    },
  ];
};
