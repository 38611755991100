import { GuardFunction } from 'react-router-guards';
import { setResourceName } from '../../shared/data/store/slices/nav-slice';
import { GuardedRouteMeta } from '../../shared/models/route';

export const resourceNameGuard: GuardFunction = (to, from, next) => {
  const { isResourcePath, dispatch }: GuardedRouteMeta = to.meta;
  if (!isResourcePath) {
    dispatch(setResourceName(''));
  }
  next();
};
