import { css } from '@emotion/react';
import { Col, Divider, Modal, ModalProps, Row, Space, message } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useToggle } from 'react-use';
import { getDay, isBefore, parseISO } from 'date-fns';

import { translationNamespace } from '../../constants/translation-resources';
import { DatePicker } from '../../../shared/components/date-picker';
import { useSetFixDateMutation } from '../../data/mutations/batch-mutations';
import { Batch } from '../../models/batch';
import { ConfirmationModal } from '../../../shared/components/confirmation-modal';
import { Feature } from '../../../shared/models/features';

interface SetFixDateModalProps extends ModalProps {
  batches: Batch[];
  toggleModal: (nextValue?: false) => void;
}

export const SetFixDateModal = ({ batches, toggleModal, ...props }: SetFixDateModalProps) => {
  const { t, i18n } = useTranslation(translationNamespace);
  const setFixDateMutation = useSetFixDateMutation();
  const [isCallendarOpen, toggleCallendarOpen] = useToggle(false);
  const [date, setDate] = useState<string>();
  const [isConfirmationModalOpen, toggleConfirmationModalOpen] = useToggle(false);

  function goToConfirmationModal() {
    toggleModal();
    toggleConfirmationModalOpen();
  }

  function setFixDate() {
    setFixDateMutation.mutate(
      { wIds: batches.map(batch => batch.wId), fixDate: date },
      {
        onSuccess: () => {
          message.success(t('batches.fixDate.success'));
          toggleConfirmationModalOpen();
        },
      }
    );
  }

  function disableTodayAndWeekends(date: Date): boolean {
    const today = new Date().toISOString().slice(0, 10);
    const dateFromCallendar = date.toISOString().slice(0, 10);
    const isWeekend = getDay(parseISO(dateFromCallendar)) === 0 || getDay(parseISO(dateFromCallendar)) === 6;

    if (today === dateFromCallendar || isBefore(parseISO(dateFromCallendar), parseISO(today)) || isWeekend) {
      return true;
    } else return false;
  }

  return (
    <>
      <ConfirmationModal
        title={t(`${Feature.SHARED}:areYouSure`)}
        open={isConfirmationModalOpen}
        onCancel={toggleConfirmationModalOpen}
        onOk={setFixDate}
        confirmLoading={setFixDateMutation.isLoading}
        content={t('batches.fixDate.confirm', { date, count: batches?.length })}
      />

      {!isConfirmationModalOpen && (
        <Modal
          title={
            <Space size="middle" align="start">
              <StyledInfoCircleOutlined />
              {t('batches.fixDate.set')}
            </Space>
          }
          afterOpenChange={toggleCallendarOpen}
          centered
          onOk={goToConfirmationModal}
          css={modalStyles}
          destroyOnClose
          okButtonProps={{ disabled: date == null || date?.length === 0 }}
          {...props}
        >
          <Row gutter={24}>
            <Col span={15}>
              <DatePicker
                size={'large'}
                format={i18n.language === 'en-GB' ? 'dd/MM/Y' : 'dd.MM.Y'}
                onChange={(_, dateString) => setDate(dateString)}
                open={isCallendarOpen}
                showToday={false}
                disabledDate={disableTodayAndWeekends}
              />
            </Col>
            <Col span={9}>
              <h3>{t('batches.fixDate.selectedBatches', { count: batches.length })}</h3>
              <div css={batchesListStyles}>
                {batches.map(batch => (
                  <>
                    <Divider css={dividerStyles} />
                    <div>{batch.fileName}</div>
                  </>
                ))}
              </div>
            </Col>
          </Row>
        </Modal>
      )}
    </>
  );
};

const modalStyles = css`
  .ant-modal-header {
    margin-bottom: 20px;
  }

  .ant-modal-body {
    height: 310px;
  }
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  font-size: 22px;
  color: ${props => props.theme.reports.exportModal.icon.color};
`;

const dividerStyles = css`
  margin: 5px 0;
`;

const batchesListStyles = css`
  height: 260px;
  overflow-y: auto;
`;
