import { resourceListQueryKeyRoots, singleResourceQueryKeyRoots } from '../constants/resource-query-key';
import { AppResource } from '../models/app-resource';
import {
  ResourceListQueryKeyRoot,
  SingleResourceQueryKeyRoot,
  UserQueryKeyVariant,
} from '../models/resource-query-key';

export function getSingleResourceQueryKey(
  resource: Exclude<AppResource, 'USER'>,
  id: number | string
): [SingleResourceQueryKeyRoot, number | string] {
  return [singleResourceQueryKeyRoots[resource], id];
}

export function getSingleUserQueryKey(id: number | string, variant: UserQueryKeyVariant) {
  return [singleResourceQueryKeyRoots.USER, id, variant];
}

export function getResourceListQueryKeyRoot(resource: AppResource): ResourceListQueryKeyRoot {
  return resourceListQueryKeyRoots[resource];
}
