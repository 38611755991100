import { Card } from 'antd';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

import { fontSize, colors } from '../../../shared/constants/styles';

export const NavigationTab = ({ path, title, backgroundImage }) => {
  return (
    <Link to={path} style={{ height: '100%' }}>
      <StyledCard hoverable style={{ ['--background-image' as string]: `url(${backgroundImage})` }}>
        {title}
      </StyledCard>
    </Link>
  );
};

const StyledCard = styled(Card)`
  position: relative;
  overflow: hidden;
  height: 215px;
  color: ${colors.white};
  font-size: ${fontSize.ONE};
  font-weight: bold;
  background-color: ${colors.primary};
  border-radius: inherit;

  .ant-card-body {
    position: absolute;
    font-size: ${fontSize.ONE_POINT_FOUR};
    left: 50%;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(1px);
    background-image: var(--background-image);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 200%;
    transition: transform 0.5s ease;
  }

  :hover::before {
    transform: scale(1.1);
  }
`;
