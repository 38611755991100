import { useContext } from 'react';
import { BreakpointContext } from '../components/breakpoint';

export function useBreakpoints() {
  const context = useContext(BreakpointContext);

  if (context == null) {
    throw new Error('BreakpointContext is not available, please wrap your component with context provider');
  }

  return context;
}
