import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { translationNamespace } from '../../constants/translation-resources';

export const ReportFiltersTooltip = () => {
  const { t } = useTranslation(translationNamespace);

  return (
    <>
      <StyledText>1. {t('reports.single.filtersTooltip.beforeTerm')}</StyledText>
      <StyledText>2. {t('reports.single.filtersTooltip.afterTerm')}</StyledText>
      <div>3. {t('reports.single.filtersTooltip.beforeAndAfter')}</div>
    </>
  );
};

const StyledText = styled.div`
  margin-bottom: 10px;
`;
