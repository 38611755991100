import { useQuery } from 'react-query';

import { CommonFindAllQueryParams, QueryRequest } from '../../../shared/models/api/request';
import { deleteEmptyArrayObjProps } from '../../../shared/utils/object';
import { View, ViewsFilters } from '../../models/view';
import { convertSearchResponse } from '../converters/view-converters';
import { useViewService } from '../services/use-view-service';
import { ResourceSearchResult } from '../../../shared/models/api/response';
import { getResourceListQueryKeyRoot, getSingleResourceQueryKey } from '../../../shared/utils/resource-query-key';

export function useGetViewQuery(id?: number) {
  const viewService = useViewService();

  return useQuery<View, unknown>(
    getSingleResourceQueryKey('VIEW', id),
    async () => {
      const response = await viewService.getView(id);
      return response.data.view;
    },
    {
      enabled: id != null,
    }
  );
}

export function useFindViewsQuery(
  {
    page,
    pageSize,
    searchValue,
    filters,
    order,
    attributes = ['id', 'name', 'viewName', 'schemaName', 'status', 'createdAt', 'updatedAt'],
  }: CommonFindAllQueryParams<View, ViewsFilters>,
  enabled = true
) {
  const viewService = useViewService();

  return useQuery<ResourceSearchResult<View>, unknown>(
    [getResourceListQueryKeyRoot('VIEW'), { page, pageSize }, searchValue, filters, order, attributes],
    async () => {
      const request: QueryRequest<View, ViewsFilters> = {
        limit: pageSize,
        offset: (page - 1) * pageSize,
        attributes,
      };

      if (order?.length > 0) {
        request.order = order;
      }

      if (searchValue) {
        request.search = searchValue;
      }

      if (filters && Object.values(filters).some(val => val != null)) {
        request.filters = deleteEmptyArrayObjProps(filters);
      }

      const response = await viewService.findViews(request);
      return convertSearchResponse(response.data);
    },
    { enabled }
  );
}
