import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { AnyApiResponse, PickSomeRestPartial } from '../../../shared/utils/types';
import { View, ViewUpsert } from '../../models/view';
import { convertToUpsertRequest } from '../converters/view-converters';
import { useViewService } from '../services/use-view-service';

export function useCreateViewMutation() {
  const viewService = useViewService();

  return useMutation<AxiosResponse<AnyApiResponse>, unknown, ViewUpsert>(view => {
    const request = convertToUpsertRequest(view);
    return viewService.createView(request);
  });
}

export function useUpdateViewMutation() {
  const viewService = useViewService();

  return useMutation<AxiosResponse<AnyApiResponse>, unknown, PickSomeRestPartial<View, 'id'>>(view => {
    const request = convertToUpsertRequest(view);
    return viewService.updateView(request, view.id);
  });
}

export function useDeleteViewMutation() {
  const viewService = useViewService();

  return useMutation<AxiosResponse<AnyApiResponse>, unknown, Pick<View, 'id'>>(view => {
    return viewService.deleteView(view.id);
  });
}
