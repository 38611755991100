import { Config, FieldOrGroup, Fields } from '@react-awesome-query-builder/antd';
import { TableColumnType } from 'antd';
import styled from '@emotion/styled';
import { GenericType as BeGenericType } from '@pccr-ifc/pccr-ifc/lib/cjs/definition/GenericDefinitionResponse';
import { format, parseISO } from 'date-fns';

import { defaultQueryBuilderConfig } from '../constants/query-builder-config';
import { ReportData } from '../models/report';
import { SourceTableInfo } from '../models/source';
import { Language } from '../../shared/models/language';
import { formatISODate } from '../../shared/utils/date';
import { DataColumn } from '../models/data-column';
import { i18nDateFnsLocaleMap } from '../../shared/constants/date-fns';

export function convertSourceInfoColumnToQueryBuilderField(column: DataColumn): FieldOrGroup {
  let type: FieldOrGroup['type'] = null;

  switch (column.type) {
    case 'number':
      type = 'number';
      break;

    case 'string':
      type = 'text';
      break;

    case 'boolean':
      type = 'boolean';
      break;

    case 'date':
      type = 'date';
      break;

    case 'array':
    case 'buffer':
    case 'object':
      throw new Error(`data_type: ${column.type} needs implementation!`);

    default:
      throw new Error(`Unsupported column data_type: ${column.type}`);
  }

  return {
    type,
  };
}

export function convertSourceInfoTableToQueryBuilderConfig(table: SourceTableInfo): Config {
  let cfg: Config = defaultQueryBuilderConfig;

  if (table?.columns?.length === 0) {
    return cfg;
  }

  cfg = {
    ...cfg,
    fields: table.columns.reduce((acc, column) => {
      acc = {
        ...acc,
        [column.name]: convertSourceInfoColumnToQueryBuilderField(column),
      };
      return acc;
    }, {} as Fields),
  };

  return cfg;
}

function isDatetime(isoDate: string): boolean {
  return /\d{4}.\d{2}.\d{2}.\d{2}.\d{2}.\d{2}/.test(isoDate);
}

function extractDateFromDatetime(datetime: string): string {
  return datetime.match(/\d{4}.\d{2}.\d{2}/)?.[0];
}

function renderReportDataValues(value, columnType: BeGenericType, language: Language) {
  if (value == null) return '';

  switch (columnType) {
    case 'date':
      if (isDatetime(value)) {
        return formatISODate(value, language);
      } else if (extractDateFromDatetime(value) != null) {
        return format(parseISO(value), 'P', { locale: i18nDateFnsLocaleMap[language] });
      } else {
        throw new Error(`Invalid date format`);
      }
    case 'object':
      return JSON.stringify(value);
    default:
      return String(value);
  }
}

export function generateTableColumns(columns: DataColumn[], language: Language): TableColumnType<ReportData>[] {
  return (
    columns?.map(({ name, type }) => ({
      title: name,
      dataIndex: name,
      sorter: true,
      render: value => <ReportDataCell>{renderReportDataValues(value, type, language)}</ReportDataCell>,
    })) ?? []
  );
}

const ReportDataCell = styled.div`
  overflow-wrap: anywhere;
`;
