import { MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { useLogOut } from '../../../auth/hooks/use-log-out';
import { translationNamespace } from '../../constants/translation-resources';
import { DropdownMenu, StyledActionButton } from '../dropdown-menu';
import { userSelector } from '../../../shared/data/store/selectors/auth-selectors';
import userIcon from '../../assets/images/icon_user.svg';
import userProfile from '../../assets/images/icon_profile.svg';
import userLogout from '../../assets/images/icon_logout.svg';
import { Environment } from '../../../shared/constants/environment';
import { colors } from '../../../shared/constants/styles';

export const UserActionsButton = ({ env }: { env: Environment }) => {
  const logOut = useLogOut();
  const { t } = useTranslation(translationNamespace);
  const { push: routerPush } = useHistory();
  const user = useSelector(userSelector);

  const goToUserProfile = () => {
    routerPush('/auth/profile');
  };

  const items: MenuProps['items'] = [
    {
      key: 'user-profile',
      onClick: goToUserProfile,
      label: t('userActions.myProfile'),
      icon: <img src={userProfile} alt="User Icon" />,
    },
    {
      key: 'logout',
      onClick: logOut,
      label: t('userActions.logOut'),
      icon: <img src={userLogout} alt="Logout Icon" />,
    },
  ];

  return (
    <DropdownMenu items={{ items }}>
      <ActionButton env={env}>
        <StyledImage src={userIcon} alt={'User icon'} id="user-icon" />
        {user?.username}
      </ActionButton>
    </DropdownMenu>
  );
};

export const StyledImage = styled.img`
  margin-right: 8px;
  width: 19px;
`;

const ActionButton = styled(StyledActionButton)<{ env: Environment }>`
  &:hover {
    background-color: ${props =>
      props.env === 'development' ? colors.pingHover : props.env === 'test' ? colors.turquoiseHover : colors.blueHover};
  }
`;
