import { Button, Divider, Form, FormInstance, Input, Select, Space } from 'antd';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Role } from '../../../shared/models/role';
import { translationNamespace } from '../../constants/translation-resources';
import { useFindPoliciesQuery } from '../../data/queries/policy-queries';
import { useFindTenantsQuery } from '../../data/queries/tenant-queries';
import { Feature } from '../../../shared/models/features';
import { Policy } from '../../../shared/models/policy';
import { Tenant } from '../../../shared/models/tenant';
import { noDuplicatesRule, redundantSpacebarsRule } from '../../../shared/utils/form-rules';

export enum RoleUpsertFormField {
  NAME = 'name',
  POLICIES = 'policies',
  TENANTS = 'tenants',
}
export interface RoleUpsertFormValues {
  [RoleUpsertFormField.NAME]: string;
  [RoleUpsertFormField.POLICIES]: number[];
  [RoleUpsertFormField.TENANTS]: number[];
}

interface Props {
  form: FormInstance<RoleUpsertFormValues>;
  onSubmit?: (values: RoleUpsertFormValues) => void;
  role?: Role;
}

export const RoleUpsertForm: FC<Props> = ({ form, onSubmit, role }) => {
  const { t } = useTranslation(translationNamespace);
  const [policySearchValue, setPolicySearchValue] = useState<string>();
  const [policiesDebouncedSearchValue, setPoliciesDebouncedSearchValue] = useState<string>();
  const { data: policiesData, isLoading: policiesLoading } = useFindPoliciesQuery({
    page: 1,
    pageSize: 25,
    searchValue: policiesDebouncedSearchValue,
    attributes: ['id', 'name'],
  });

  const [tenantSearchValue, setTenantSearchValue] = useState<string>();
  const [tenantDebouncedSearchValue, setTenantsDebouncedSearchValue] = useState<string>();
  const { data: tenantsData, isLoading: tenantsLoading } = useFindTenantsQuery({
    page: 1,
    pageSize: 25,
    searchValue: tenantDebouncedSearchValue,
    attributes: ['id', 'name'],
  });

  const policySelectOptions = useMemo(() => {
    let options: Policy[] = [];

    if (role?.policies?.length > 0 && !policiesDebouncedSearchValue) {
      options = role.policies;

      if (policiesData?.resources?.length > 0) {
        options = options.concat(
          policiesData.resources.filter(policy => !role.policies.some(rolePolicy => rolePolicy.id === policy.id))
        );
      }
    } else {
      options = policiesData?.resources;
    }

    return options?.map(policy => (
      <Select.Option key={policy.id} value={policy.id}>
        {policy.name}
      </Select.Option>
    ));
  }, [policiesData?.resources, policiesDebouncedSearchValue, role?.policies]);

  const tenantSelectOptions = useMemo(() => {
    let options: Tenant[] = [];

    if (role?.scopeGroups?.length > 0 && !tenantDebouncedSearchValue) {
      options = role.scopeGroups;

      if (tenantsData?.resources?.length > 0) {
        options = options.concat(
          tenantsData.resources.filter(tenant => !role.scopeGroups.some(roleTenant => roleTenant.id === tenant.id))
        );
      }
    } else {
      options = tenantsData?.resources;
    }

    return options?.map(tenant => (
      <Select.Option key={tenant.id} value={tenant.id}>
        {tenant.name}
      </Select.Option>
    ));
  }, [tenantsData?.resources, tenantDebouncedSearchValue, role?.scopeGroups]);

  const applyPoliciesSearch = (value: string) => {
    setPolicySearchValue(value);
    setPoliciesDebouncedSearchValue(value);
  };

  const clearPolicySearchValue = () => {
    setPolicySearchValue('');
    setPoliciesDebouncedSearchValue('');
  };

  const applyTenantsSearch = (value: string) => {
    setTenantSearchValue(value);
    setTenantsDebouncedSearchValue(value);
  };

  const clearTenantSearchValue = () => {
    setTenantSearchValue('');
    setTenantsDebouncedSearchValue('');
  };

  useDebounce(
    () => {
      setPoliciesDebouncedSearchValue(policySearchValue);
    },
    3000,
    [policySearchValue]
  );

  useDebounce(
    () => {
      setTenantsDebouncedSearchValue(tenantSearchValue);
    },
    3000,
    [tenantSearchValue]
  );

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      layout="horizontal"
      labelCol={{ lg: 4, xl: 4 }}
      wrapperCol={{ lg: 16, xl: 10 }}
      requiredMark={false}
    >
      <Form.Item
        label={t(`${Feature.SHARED}:name`)}
        name={RoleUpsertFormField.NAME}
        rules={[{ required: true, max: 180 }, redundantSpacebarsRule(t)]}
        css={marginBottomStyles}
      >
        <Input placeholder={t('roles.upsert.rolePlaceholder')} />
      </Form.Item>
      <Divider orientation="left">{t(`${Feature.SHARED}:policies`)}</Divider>
      <Form.List name={RoleUpsertFormField.POLICIES}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(field => (
              <Form.Item key={field.key} label={t('roles.upsert.policy.label')} css={noMarginBottomStyles}>
                <StyledSpace align="baseline">
                  <Form.Item
                    {...field}
                    name={field.name}
                    messageVariables={{ label: t('roles.upsert.policy.label') }}
                    rules={[
                      { required: true },
                      noDuplicatesRule(form, RoleUpsertFormField.POLICIES, t('roles.upsert.policy.duplicateWarning')),
                    ]}
                  >
                    <Select
                      showSearch
                      filterOption={false}
                      placeholder={t('roles.upsert.policy.label')}
                      loading={policiesLoading}
                      onSearch={applyPoliciesSearch}
                      onSelect={clearPolicySearchValue}
                    >
                      {policySelectOptions}
                    </Select>
                  </Form.Item>
                  <MinusCircleOutlined
                    onClick={() => {
                      remove(field.name);
                      setTimeout(() => {
                        form.validateFields();
                      }, 0);
                    }}
                  />
                </StyledSpace>
              </Form.Item>
            ))}
            <Form.Item>
              <StyledButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                {t('roles.upsert.policy.add')}
              </StyledButton>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Divider orientation="left">{t('roles.upsert.scope')}</Divider>
      <Form.List name={RoleUpsertFormField.TENANTS}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(field => (
              <Form.Item key={field.key} label={t(`${Feature.SHARED}:tenant.label`)} css={noMarginBottomStyles}>
                <StyledSpace align="baseline">
                  <Form.Item
                    {...field}
                    name={field.name}
                    messageVariables={{ label: t(`${Feature.SHARED}:tenant.label`) }}
                    rules={[
                      { required: true },
                      noDuplicatesRule(form, RoleUpsertFormField.TENANTS, t('roles.upsert.tenant.duplicateWarning')),
                    ]}
                  >
                    <Select
                      showSearch
                      filterOption={false}
                      placeholder={t(`${Feature.SHARED}:tenant.placeholder`)}
                      loading={tenantsLoading}
                      onSearch={applyTenantsSearch}
                      onSelect={clearTenantSearchValue}
                    >
                      {tenantSelectOptions}
                    </Select>
                  </Form.Item>
                  <MinusCircleOutlined
                    onClick={() => {
                      remove(field.name);
                      setTimeout(() => {
                        form.validateFields();
                      }, 0);
                    }}
                  />
                </StyledSpace>
              </Form.Item>
            ))}
            <Form.Item>
              <StyledButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                {t('roles.upsert.tenant.add')}
              </StyledButton>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};

const marginBottomStyles = css`
  margin-bottom: 52px;
`;

const noMarginBottomStyles = css`
  margin-bottom: 0;
`;

const StyledSpace = styled(Space)`
  display: flex;

  > div:first-of-type {
    width: calc(100% - 25px);
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 180px;
`;
