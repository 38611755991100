import { Alert, Button, Card, Col, Form, message, Row, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import {
  UserProfileUpsertForm,
  UserProfileUpsertFormField,
  UserProfileUpsertFormValues,
} from './user-profile-upsert-form';
import { userSelector } from '../../../shared/data/store/selectors/auth-selectors';
import { Feature } from '../../../shared/models/features';
import { translationNamespace } from '../../constants/translation-resources';
import {
  useUserProfileChangePasswordMutation,
  useUpdateUserProfileMutation,
} from '../../data/mutations/user-profile-mutations';
import { refresh } from '../../../shared/data/store/thunks/auth-thunks';
import { useAuthService } from '../../../shared/data/services/use-auth-service';
import {
  UserProfilePasswordChangeForm,
  UserProfilePasswordChangeFormValues,
} from './user-profile-password-change-form';
import { setLoggedIn } from '../../../shared/data/store';
import { UserStatuses } from '../../../shared/constants/user';
import { returnChangedObjectProperties } from '../../../shared/utils/object';
import { UserName } from '../../../shared/components/user-name';

export const UserProfileView = () => {
  const { t } = useTranslation(translationNamespace);
  const [passwordChangeForm] = Form.useForm<UserProfilePasswordChangeFormValues>();
  const [updateForm] = Form.useForm<UserProfileUpsertFormValues>();
  const user = useSelector(userSelector);
  const updateMutation = useUpdateUserProfileMutation();
  const changePasswordMutation = useUserProfileChangePasswordMutation();
  const authService = useAuthService();
  const dispatch = useDispatch();
  const { push: routerPush } = useHistory();
  const queryClient = useQueryClient();
  const initialProfileValues = useRef<UserProfileUpsertFormValues>();

  const submitUserProfile = (values: UserProfileUpsertFormValues) => {
    const changedProperties = returnChangedObjectProperties(initialProfileValues.current, values);

    if (!changedProperties) {
      message.warning(t(`${Feature.SHARED}:nothingToUpdate`));
      return;
    }

    updateMutation.mutate(changedProperties, {
      onSuccess: () => {
        message.success(t('userProfile.updateSuccessMessage'));
        dispatch(refresh({ service: authService }));
      },
    });
  };

  const submitPasswordChange = (values: UserProfilePasswordChangeFormValues) => {
    changePasswordMutation.mutate(values, {
      onSuccess: () => {
        message.success(t('userProfile.changePassword.success'));
        dispatch(setLoggedIn(false));
        routerPush('/auth/login');
        queryClient.clear();
      },
    });
  };

  const triggerProfileUpdateSubmit = () => {
    if (user.status === UserStatuses.APPROVED) updateForm.submit();
    else message.error(t('userProfile.cantUpdate'));
  };

  const triggerPasswordChangeSubmit = () => {
    passwordChangeForm.submit();
  };

  useEffect(() => {
    if (user) {
      const fieldsValue: UserProfileUpsertFormValues = {
        [UserProfileUpsertFormField.EMAIL]: user.email,
        [UserProfileUpsertFormField.USERNAME]: user.username,
        [UserProfileUpsertFormField.FIRST_NAME]: user.firstName,
        [UserProfileUpsertFormField.MIDDLE_NAME]: user.middleName,
        [UserProfileUpsertFormField.LAST_NAME]: user.lastName,
        [UserProfileUpsertFormField.DESCRIPTION]: user.description,
        [UserProfileUpsertFormField.MOBILE_PHONE]: user.mobilePhone,
        [UserProfileUpsertFormField.ROLES]: user.roles?.map(role => role.name),
        [UserProfileUpsertFormField.TENANTS]: user.groups?.map(group => group.name),
      };

      updateForm.setFieldsValue(fieldsValue);
      initialProfileValues.current = fieldsValue;
    }
  }, [updateForm, user]);

  return (
    <Row justify="space-between" gutter={[16, 24]}>
      <Col span={24}>
        {user?.status === UserStatuses.PASSWORD_REQUIRED && (
          <Alert showIcon message={t('userProfile.changePassword.warning')} type="warning" />
        )}
      </Col>

      <Col span={24}>
        <Card title={user != null ? <UserName user={user} /> : t('defaultUserIdentifier')}>
          <UserProfileUpsertForm user={user} form={updateForm} onSubmit={submitUserProfile} />
        </Card>
      </Col>
      <Col>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            icon={<CheckOutlined />}
            onClick={triggerProfileUpdateSubmit}
          >
            {t(`${Feature.SHARED}:save`)}
          </Button>
        </Space>
      </Col>

      {(user?.status === UserStatuses.APPROVED || user?.status === UserStatuses?.PASSWORD_REQUIRED) && (
        <>
          <Col span={24}>
            <Card title={t('userProfile.changePassword.label')}>
              <UserProfilePasswordChangeForm form={passwordChangeForm} onSubmit={submitPasswordChange} />
            </Card>
          </Col>
          <Col>
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                icon={<CheckOutlined />}
                onClick={triggerPasswordChangeSubmit}
              >
                {t(`${Feature.SHARED}:save`)}
              </Button>
            </Space>
          </Col>
        </>
      )}
    </Row>
  );
};
