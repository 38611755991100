import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Layout as LayoutModel } from '../../../shared/models/layout';
import { FullPageLayout } from './full-page-layout';
import { SideNavigationLayout } from './side-navigation-layout';

const availableLayouts: Record<LayoutModel, FC> = {
  [LayoutModel.FULL_PAGE]: FullPageLayout,
  [LayoutModel.SIDE_NAVIGATION]: SideNavigationLayout,
};

export const Layout: FC = ({ children }) => {
  const currentLayout = useSelector(state => state.shared.layout.currentLayout);
  const LayoutComponent = availableLayouts[currentLayout];

  return <LayoutComponent>{children}</LayoutComponent>;
};
