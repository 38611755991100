import '@react-awesome-query-builder/antd/css/compact_styles.css';
import {
  Alert,
  Button,
  ColProps,
  Divider,
  Empty,
  Form,
  FormInstance,
  Input,
  Radio,
  Select,
  Space,
  Tabs,
  Typography,
} from 'antd';
import { SelectValue } from 'antd/lib/select';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Builder,
  BuilderProps,
  Config,
  ImmutableTree,
  JsonGroup,
  Query,
  Utils as QbUtils,
} from '@react-awesome-query-builder/antd';
import { useTranslation } from 'react-i18next';
import { useDebounce, useList, useMap } from 'react-use';
import { LinkOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { NamePath, StoreValue } from 'antd/es/form/interface';

import { useFindTenantsQuery } from '../../../access/data/queries/tenant-queries';
import { Definition, DefinitionType } from '../../../shared/models/definition';
import { QueryParamType } from '../../../shared/models/query-param';
import { mapQueryParamTypeToComponent } from '../../../shared/utils/query-params-mappers';
import { isArrayOfStrings } from '../../../shared/utils/types';
import { defaultQueryBuilderConfig } from '../../constants/query-builder-config';
import { translationNamespace } from '../../constants/translation-resources';
import { useFindSourcesQuery, useGetSourceQuery } from '../../data/queries/source-queries';
import { convertSourceInfoTableToQueryBuilderConfig } from '../../utils/data-converters';
import { Feature } from '../../../shared/models/features';
import { redundantSpacebarsRule } from '../../../shared/utils/form-rules';
import { createSqlStatement, findParamsInSqlQuery } from '../../utils/sql';
import { DefinitionSourceTypes, DefinitionTypes } from '../../../shared/constants/definition';
import { ResourceSelect } from '../../../shared/components/resource-select';
import { definitionNameRule } from '../../constants/form-rules';

const { Text } = Typography;
const { TextArea } = Input;

enum DataQueryTabKey {
  QUERY_BUILDER = '1',
  RAW_SQL = '2',
}

export enum DefinitionUpsertFormField {
  NAME = 'name',
  TYPE = 'type',
  SQL_PARAMS = 'sqlParams',
  SQL_PARAM_KEY = 'key',
  SQL_PARAM_TYPE = 'type',
  SQL_PARAM_VALUE = 'value',
  SQL = 'sql',
  SOURCE = 'source',
  TENANT = 'tenant',
}

interface DefinitionUpsertFormSQLParamsValue {
  [DefinitionUpsertFormField.SQL_PARAM_KEY]: string;
  [DefinitionUpsertFormField.SQL_PARAM_VALUE]: string;
}

export interface DefinitionUpsertFormValues {
  [DefinitionUpsertFormField.NAME]: string;
  [DefinitionUpsertFormField.TYPE]: DefinitionType;
  [DefinitionUpsertFormField.SOURCE]: number;
  [DefinitionUpsertFormField.TENANT]: number;
  [DefinitionUpsertFormField.SQL_PARAMS]: DefinitionUpsertFormSQLParamsValue[];
  [DefinitionUpsertFormField.SQL]: string;
}

interface Props {
  form: FormInstance<DefinitionUpsertFormValues>;
  onSubmit?: (values: DefinitionUpsertFormValues) => void;
  definition?: Definition;
}

const initialQueryBuilderValue: JsonGroup = { id: QbUtils.uuid(), type: 'group' };

export const DefinitionUpsertForm: FC<Props> = ({ form, onSubmit, definition }) => {
  const { t } = useTranslation(translationNamespace);
  const [queryBuilderConfig, setQueryBuilderConfig] = useState(defaultQueryBuilderConfig);
  const [queryBuilderTree, setQueryBuilderTree] = useState(() =>
    QbUtils.checkTree(QbUtils.loadTree(initialQueryBuilderValue), queryBuilderConfig)
  );
  const [tenantsSearchValue, setTenantsSearchValue] = useState<string>();
  const [tenantsDebouncedSearchValue, setTenantsDebouncedSearchValue] = useState<string>();
  const { data: tenantsData, isLoading: tenantsLoading } = useFindTenantsQuery({
    page: 1,
    pageSize: 25,
    searchValue: tenantsDebouncedSearchValue,
    attributes: ['id', 'name'],
  });
  const { data: currentTenantData, isLoading: currentTenantLoading } = useFindTenantsQuery(
    {
      page: 1,
      pageSize: 1,
      attributes: ['id', 'name'],
      filters: { id: [definition?.groupId] },
    },
    definition?.groupId != null
  );
  const [sourcesSearchValue, setSourcesSearchValue] = useState<string>();
  const [sourcesDebouncedSearchValue, setSourcesDebouncedSearchValue] = useState<string>();
  const { data: sourcesData, isLoading: sourcesLoading } = useFindSourcesQuery({
    page: 1,
    pageSize: 25,
    searchValue: sourcesDebouncedSearchValue,
    attributes: ['id', 'name', 'status'],
  });
  const { data: currentSourceData, isLoading: currentSourceLoading } = useFindSourcesQuery(
    {
      page: 1,
      pageSize: 1,
      attributes: ['id', 'name', 'status'],
      filters: { id: [definition?.sourceId] },
    },
    definition?.sourceId != null
  );
  const isNewDefinition = definition === undefined;
  const [selectedSourceId, setSelectedSourceId] = useState<number>();
  const { data: selectedSourceRes, isLoading: selectedSourceLoading } = useGetSourceQuery(
    selectedSourceId,
    isNewDefinition
  );
  const [selectedTables, { reset: resetSelectedTables, set: setSelectedTables }] = useList<string>([]);
  const [
    selectedColumns,
    { set: setSelectedTableColumns, remove: removeSelectedTableColumns, reset: resetSelectedColumns },
  ] = useMap<Record<string, string[]>>({});
  const [activeTab, setActiveTab] = useState<DataQueryTabKey>(DataQueryTabKey.QUERY_BUILDER);
  const [paramsTypes, { set: setParamsTypes, remove: removeParamsTypes }] = useMap<Record<number, QueryParamType>>({});
  const [sqlParamsWarnings, setSqlParamsWarnings] = useState<[string, string]>([null, null]);

  const advancedMode = selectedTables.length !== 1;

  const simpleSQLSelectStatement = useMemo(() => {
    if (selectedSourceRes == null || advancedMode) {
      return '';
    }
    const selectedTable = selectedTables[0];
    const selectedTableColumns = selectedColumns[selectedTable];

    return createSqlStatement(selectedTable, selectedTableColumns, selectedSourceRes.source.dialect);
  }, [advancedMode, selectedColumns, selectedSourceRes, selectedTables]);

  const showDataQuery = useMemo(
    () => !isNewDefinition || (selectedTables.length > 0 && Object.values(selectedColumns).some(val => val.length > 0)),
    [selectedTables.length, selectedColumns, isNewDefinition]
  );

  const tableSelectOptions = useMemo(() => {
    return selectedSourceRes?.tables_info?.map((table, index) => (
      <Select.Option key={index} value={table.name}>
        {table.name}
      </Select.Option>
    ));
  }, [selectedSourceRes]);

  const applyDataSourceSearch = (value: string) => {
    setSourcesSearchValue(value);
    setSourcesDebouncedSearchValue(value);
  };

  const setDataSource = (id: number) => {
    setSelectedSourceId(id);
    resetSelectedColumns();
    resetSelectedTables();
    setSourcesSearchValue('');
    setSourcesDebouncedSearchValue('');
  };

  const applyTenantsSearch = (value: string) => {
    setTenantsSearchValue(value);
    setTenantsDebouncedSearchValue(value);
  };

  const clearTenantsSearch = () => {
    setTenantsSearchValue('');
    setTenantsDebouncedSearchValue('');
  };

  function sqlParamsValidator(_rule, sqlQuery: string, getFieldValue: (name: NamePath) => StoreValue) {
    const sqlStatementParams = findParamsInSqlQuery(sqlQuery);

    const declaredParams: string[] =
      getFieldValue(DefinitionUpsertFormField.SQL_PARAMS)
        ?.filter(param => param != null)
        .map(param => param.key) ?? [];

    if (!declaredParams.every(param => sqlStatementParams.includes(param))) {
      setSqlParamsWarnings(prev => [t('definitions.upsert.unusedParamsWarning'), prev[1]]);
    } else {
      setSqlParamsWarnings(prev => [null, prev[1]]);
    }

    if (!sqlStatementParams.every(param => declaredParams.includes(param))) {
      setSqlParamsWarnings(prev => [prev[0], t('definitions.upsert.undeclaredParamsWarning')]);
    } else {
      setSqlParamsWarnings(prev => [prev[0], null]);
    }

    return Promise.resolve();
  }

  const renderQueryBuilder = useCallback(
    (props: BuilderProps) => {
      return (
        <div className="query-builder-container" style={{ padding: '10px' }}>
          <div className="query-builder qb-lite" css={noMarginStyles}>
            {simpleSQLSelectStatement.length > 0 && (
              <Form.Item css={noMarginBottomStyles}>
                <Text code>{simpleSQLSelectStatement}</Text>
              </Form.Item>
            )}
            <Builder {...props} />
          </div>
        </div>
      );
    },
    [simpleSQLSelectStatement]
  );

  const setQueryBuilderState = (value: ImmutableTree, config: Config) => {
    setQueryBuilderTree(value);
    setQueryBuilderConfig(config);
  };

  const setTables = useCallback(
    (tables: string[]) => {
      setSelectedTables(tables);

      Object.keys(selectedColumns)
        .filter(table => !tables.includes(table))
        .forEach(table => removeSelectedTableColumns(table));
    },
    [removeSelectedTableColumns, selectedColumns, setSelectedTables]
  );

  const switchTab = (activeKey: DataQueryTabKey) => {
    setActiveTab(activeKey);
  };

  const setParamType = (fieldKey: number, value: QueryParamType) => {
    removeParamsTypes(fieldKey);
    setParamsTypes(fieldKey, value);
  };

  const renderParamValueFieldByType = useCallback(
    (fieldKey: number) => {
      return mapQueryParamTypeToComponent(paramsTypes[fieldKey], t);
    },
    [paramsTypes, t]
  );

  const setColumns = useCallback(
    (value: SelectValue, table: string) => {
      if (isArrayOfStrings(value)) {
        setSelectedTableColumns(table, value);
      }
    },
    [setSelectedTableColumns]
  );

  const columnFormItems = useMemo(() => {
    return selectedTables.length > 0 ? (
      selectedTables.map((table, tableIndex) => (
        <Form.Item
          key={tableIndex}
          label={<Text code>{table}</Text>}
          messageVariables={{ label: t('definitions.upsert.columns.label', table) }}
          rules={[{ required: true }]}
        >
          <Select
            value={selectedColumns[table]}
            showSearch
            allowClear
            mode="multiple"
            onChange={value => setColumns(value, table)}
            placeholder={t('definitions.upsert.columns.placeholder')}
          >
            {selectedSourceRes?.tables_info
              ?.find(tbl => tbl.name === table)
              ?.columns?.map((column, columnIndex) => (
                <Select.Option key={columnIndex} value={column.name}>
                  {column.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      ))
    ) : (
      <Form.Item wrapperCol={customWrapperCol}>
        <Empty description={t('definitions.upsert.noTables')} />
      </Form.Item>
    );
  }, [selectedColumns, selectedSourceRes?.tables_info, selectedTables, setColumns, t]);

  const tabsItems = [
    {
      label: t('shared.dataQuery.tabs.query'),
      key: DataQueryTabKey.QUERY_BUILDER,
      disabled: advancedMode,
      children: showDataQuery ? (
        <Form.Item wrapperCol={{ span: 24 }} extra={t('shared.dataQuery.queryBuilderHelp')}>
          <Query
            {...queryBuilderConfig}
            value={queryBuilderTree}
            onChange={setQueryBuilderState}
            renderBuilder={renderQueryBuilder}
          />
        </Form.Item>
      ) : (
        <Form.Item wrapperCol={customWrapperCol}>
          <Empty description={t('definitions.upsert.noTablesOrColumns')} />
        </Form.Item>
      ),
    },
    {
      label: t('shared.dataQuery.tabs.rawSql'),
      key: DataQueryTabKey.RAW_SQL,
      children: (
        <Form.Item wrapperCol={{ span: 20, xl: { span: 14 } }}>
          {advancedMode && isNewDefinition && (
            <Alert message={t('shared.dataQuery.advancedModeWarning')} type="warning" css={marginBottomStyles} />
          )}
          <Form.Item
            name={DefinitionUpsertFormField.SQL}
            messageVariables={{ label: t('shared.sql.label') }}
            dependencies={[DefinitionUpsertFormField.SQL_PARAMS]}
            rules={[
              { required: true },
              ({ getFieldValue }) => ({
                validator: (rule, value) => sqlParamsValidator(rule, value, getFieldValue),
              }),
            ]}
            extra={
              <>
                {!advancedMode && <div>{t('shared.dataQuery.sqlQueryHelp.line1')}</div>}
                <div>
                  {t('shared.dataQuery.sqlQueryHelp.line2')}
                  <a
                    href="https://github.com/alexei/sprintf.js#named-arguments"
                    hrefLang="en"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('shared.dataQuery.sqlQueryHelp.linkLabel')} <LinkOutlined />
                  </a>
                </div>
              </>
            }
          >
            <TextArea disabled={!isNewDefinition} autoSize allowClear placeholder={t('shared.sql.placeholder')} />
          </Form.Item>
        </Form.Item>
      ),
    },
  ];

  useDebounce(
    () => {
      setTenantsDebouncedSearchValue(tenantsSearchValue);
    },
    3000,
    [tenantsSearchValue]
  );

  useDebounce(
    () => {
      setSourcesDebouncedSearchValue(sourcesSearchValue);
    },
    3000,
    [sourcesSearchValue]
  );

  useEffect(() => {
    if (simpleSQLSelectStatement.length === 0) {
      return;
    }
    const whereClause = QbUtils.sqlFormat(queryBuilderTree, queryBuilderConfig);

    form.setFieldValue(
      DefinitionUpsertFormField.SQL,
      `${simpleSQLSelectStatement}${whereClause ? ` WHERE ${whereClause}` : ''};`
    );
  }, [form, queryBuilderConfig, queryBuilderTree, simpleSQLSelectStatement]);

  useEffect(() => {
    if (advancedMode) {
      setActiveTab(DataQueryTabKey.RAW_SQL);
    } else {
      setActiveTab(DataQueryTabKey.QUERY_BUILDER);
    }
  }, [advancedMode]);

  useEffect(() => {
    let selectedTable = selectedSourceRes?.tables_info?.find(table => table.name === selectedTables[0]);

    if (selectedTable != null && Object.keys(selectedColumns).length > 0) {
      selectedTable = {
        ...selectedTable,
        columns:
          selectedTable.columns?.filter(column => selectedColumns[selectedTable.name]?.includes(column.name)) ?? [],
      };

      setQueryBuilderConfig(convertSourceInfoTableToQueryBuilderConfig(selectedTable));
    }
  }, [selectedColumns, selectedSourceRes, selectedTables]);

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      layout="horizontal"
      labelCol={{ lg: 4, xl: 4 }}
      wrapperCol={{ lg: 16, xl: 10 }}
      requiredMark={false}
    >
      <Form.Item
        label={t(`${Feature.SHARED}:name`)}
        name={DefinitionUpsertFormField.NAME}
        rules={[{ required: true, max: 180 }, redundantSpacebarsRule(t), definitionNameRule(t)]}
      >
        <Input placeholder={t('definitions.upsert.name.placeholder')} />
      </Form.Item>

      <Form.Item
        label={t(`${Feature.SHARED}:type`)}
        name={DefinitionUpsertFormField.TYPE}
        rules={[{ required: true }]}
        initialValue={DefinitionTypes.SQL}
      >
        <Radio.Group>
          {Object.values(DefinitionTypes).map((definitionType, index) => (
            <Radio key={index} value={definitionType}>
              {definitionType}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Form.Item label={t(`${Feature.SHARED}:tenant.label`)} name={DefinitionUpsertFormField.TENANT}>
        <ResourceSelect
          allowClear={isNewDefinition}
          showSearch
          filterOption={false}
          placeholder={t('shared.selectTenant')}
          loading={tenantsLoading || currentTenantLoading}
          onSearch={applyTenantsSearch}
          onSelect={clearTenantsSearch}
          resources={tenantsData?.resources}
          currentResource={currentTenantData?.resources[0]}
          resourceSearchValue={tenantsSearchValue}
        />
      </Form.Item>

      <Form.Item label={t('shared.source.label')} name={DefinitionUpsertFormField.SOURCE} rules={[{ required: true }]}>
        <ResourceSelect
          showSearch
          filterOption={false}
          placeholder={t('shared.source.placeholder')}
          loading={sourcesLoading || currentSourceLoading}
          onSearch={applyDataSourceSearch}
          onSelect={setDataSource}
          resources={sourcesData?.resources}
          currentResource={currentSourceData?.resources[0]}
          resourceSearchValue={sourcesSearchValue}
        />
      </Form.Item>

      {isNewDefinition && (
        <>
          <Form.Item label={t('definitions.upsert.sourceType.label')}>
            <Radio.Group value={DefinitionSourceTypes.TABLES}>
              {Object.values(DefinitionSourceTypes).map((type, index) => (
                <Radio key={index} value={type} disabled={type === 'views'}>
                  {t(`definitions.upsert.sourceType.${type}`)}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>

          <Form.Item label={t('definitions.upsert.tables.label')} rules={[{ required: true }]}>
            <Select
              value={selectedTables}
              showSearch
              allowClear
              mode="multiple"
              placeholder={t('definitions.upsert.tables.placeholder')}
              loading={selectedSourceLoading}
              notFoundContent={<Empty description={t('shared.source.noSourceSelected')} />}
              onChange={setTables}
            >
              {tableSelectOptions}
            </Select>
          </Form.Item>

          <Divider orientation="left">{t('definitions.upsert.columns.title')}</Divider>
          {columnFormItems}
        </>
      )}

      <Divider orientation="left">{t(`${Feature.SHARED}:queryParams.label`)}</Divider>
      <Form.Item wrapperCol={customWrapperCol}>
        <Form.List name={DefinitionUpsertFormField.SQL_PARAMS}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...field }) => (
                <StyledSpace key={key}>
                  <Form.Item
                    {...field}
                    name={[name, DefinitionUpsertFormField.SQL_PARAM_KEY]}
                    rules={[{ required: true, type: 'string' }]}
                    wrapperCol={{ span: 24 }}
                    messageVariables={{ label: t(`${Feature.SHARED}:queryParams.key`) }}
                  >
                    <Input placeholder={t(`${Feature.SHARED}:queryParams.key`)} />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    // name={[name, DefinitionUpsertFormField.SQL_PARAM_TYPE]}
                    // rules={[{ required: true }]}
                    wrapperCol={{ span: 24 }}
                    messageVariables={{ label: t(`${Feature.SHARED}:type`) }}
                  >
                    <Select
                      showSearch
                      placeholder={t(`${Feature.SHARED}:type`)}
                      onSelect={(value: QueryParamType) => setParamType(key, value)}
                      disabled
                    />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[name, DefinitionUpsertFormField.SQL_PARAM_VALUE]}
                    rules={[{ required: true }]}
                    wrapperCol={{ span: 24 }}
                    messageVariables={{ label: t(`${Feature.SHARED}:value`) }}
                  >
                    {renderParamValueFieldByType(key)}
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </StyledSpace>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  {t(`${Feature.SHARED}:queryParams.add`)}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>

      {sqlParamsWarnings.map(
        warning => warning != null && <Alert message={warning} type="warning" css={marginBottomStyles} />
      )}

      <Divider orientation="left">{t('shared.dataQuery.title')}</Divider>
      <Tabs activeKey={activeTab} onTabClick={switchTab} type="card" items={tabsItems}></Tabs>
    </Form>
  );
};

const customWrapperCol: ColProps = {
  offset: 4,
  span: 16,
  xl: { offset: 4, span: 10 },
};

const noMarginStyles = css`
  margin: 0;
`;

const noMarginBottomStyles = css`
  margin-bottom: 0;
`;

const marginBottomStyles = css`
  margin-bottom: 8px;
`;

const StyledSpace = styled(Space)`
  display: flex;

  ${marginBottomStyles}

  > div:not(:last-of-type) {
    width: 100%;
  }

  > div:last-of-type {
    margin-bottom: 24px;
  }
`;
